/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CustomerMetadataInput = {
  account_id: string,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  object: string,
  address?: string | null,
  balance: number,
  created: number,
  currency?: string | null,
  default_source?: string | null,
  delinquent: boolean,
  description?: string | null,
  discount?: string | null,
  email: string,
  invoice_prefix: string,
  invoice_settings: InvoiceSettings,
  livemode: boolean,
  metadata: CustomerMetadata,
  name: string,
  next_invoice_sequence: number,
  phone?: string | null,
  preferred_locales: Array< string | null >,
  shipping?: string | null,
  tax_exempt: string,
};

export type InvoiceSettings = {
  __typename: "InvoiceSettings",
  custom_fields?: string | null,
  default_payment_method?: string | null,
  footer?: string | null,
};

export type CustomerMetadata = {
  __typename: "CustomerMetadata",
  account_id: string,
};

export type PaymentMetadataInput = {
  order_id: string,
  coupon_id?: string | null,
  shopName?: string | null,
  brandName?: string | null,
  products?: Array< MetadataProduct | null > | null,
};

export type MetadataProduct = {
  productName?: string | null,
  productNumber?: string | null,
  quantity?: number | null,
};

export type PaymentIntent = {
  __typename: "PaymentIntent",
  id?: string | null,
  client_secret?: string | null,
  error_code?: string | null,
  payment_method?: PaymentMethod | null,
  code?: string | null,
  status?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id: string,
  object: string,
  billing_details: BillingDetails,
  card: Card,
  created: number,
  customer: string,
  livemode: boolean,
  metadata: PaymentMethodMetaData,
  type: string,
};

export type BillingDetails = {
  __typename: "BillingDetails",
  address?: string | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
};

export type Card = {
  __typename: "Card",
  brand: string,
  checks?: string | null,
  country: string,
  exp_month: number,
  exp_year: number,
  fingerprint: string,
  funding: string,
  generated_from?: string | null,
  last4: string,
  networks?: string | null,
  three_d_secure_usage?: string | null,
  wallet?: string | null,
};

export type PaymentMethodMetaData = {
  __typename: "PaymentMethodMetaData",
  name?: string | null,
};

export type PaymentStatus = {
  __typename: "PaymentStatus",
  id?: string | null,
  status?: string | null,
  error_code?: string | null,
};

export type CreateHubspotContactInput = {
  email: string,
  website?: string | null,
  referrer: string,
};

export type OpenLogiItemInput = {
  code: string,
  name?: string | null,
};

export type CreateContactsInput = {
  email: string,
  store_name?: string | null,
  contact_name?: string | null,
  discount_rate?: number | null,
  contact_status: string,
  contact_source: string,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  contactListIds?: Array< string | null > | null,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  supplier_id: string,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt: string,
  owner: string,
  updatedAt: string,
  buyerAccount?: Account | null,
  buyerInfo?: BuyerInfo | null,
  supplierInfo?: SupplierInfo | null,
  orders?: ModelOrderConnection | null,
  contactListConnections?: ModelContactListConnectionConnection | null,
  activities?: ModelContactActivityConnection | null,
  pageViews?: ModelPageViewConnection | null,
  pageViewsByEmail?: ModelPageViewConnection | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  web_site_URL?: string | null,
  instagram_account?: string | null,
  zip_code?: string | null,
  location?: string | null,
  tel?: string | null,
  company_name?: string | null,
  term_approval_status?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  buyerInfo?: ModelBuyerInfoConnection | null,
  supplierInfo?: ModelSupplierInfoConnection | null,
};

export type ModelBuyerInfoConnection = {
  __typename: "ModelBuyerInfoConnection",
  items:  Array<BuyerInfo | null >,
  nextToken?: string | null,
};

export type BuyerInfo = {
  __typename: "BuyerInfo",
  id: string,
  account_id?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_id?: string | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_return_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  invoice_credit_facility?: number | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: Array< string | null > | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  referral_brand_id?: string | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  account?: Account | null,
  shop_images?: ModelShopImageConnection | null,
  shipping_address?: ShippingAddress | null,
  referralBrand?: Brand | null,
  contacts?: ModelContactConnection | null,
};

export type ModelShopImageConnection = {
  __typename: "ModelShopImageConnection",
  items:  Array<ShopImage | null >,
  nextToken?: string | null,
};

export type ShopImage = {
  __typename: "ShopImage",
  id: string,
  buyer_id: string,
  image_key?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ShippingAddress = {
  __typename: "ShippingAddress",
  id: string,
  name: string,
  buyer_id: string,
  zip_code: string,
  prefecture: string,
  city: string,
  building?: string | null,
  phone_number: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Brand = {
  __typename: "Brand",
  id: string,
  brand_owner: string,
  brand_name: string,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  brand_collections?: Array< string | null > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string | null > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?:  Array<Stockist | null > | null,
  featured_in?: Array< string | null > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string | null > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  supplier_group_id?: string | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
  account?: Account | null,
  supplierGroup?: SupplierGroup | null,
  product?: ModelProductConnection | null,
  collections?: ModelCollectionConnection | null,
  contacts?: ModelContactConnection | null,
  reviews?: ModelReviewConnection | null,
};

export type Stockist = {
  __typename: "Stockist",
  stockist?: string | null,
  address?: string | null,
};

export type SupplierGroup = {
  __typename: "SupplierGroup",
  id: string,
  title?: string | null,
  description?: string | null,
  image_key?: string | null,
  published: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  brands?: ModelBrandReadModelConnection | null,
};

export type ModelBrandReadModelConnection = {
  __typename: "ModelBrandReadModelConnection",
  items:  Array<BrandReadModel | null >,
  nextToken?: string | null,
};

export type BrandReadModel = {
  __typename: "BrandReadModel",
  id: string,
  company_name?: string | null,
  brand_owner: string,
  brand_name: string,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string > | null,
  tags?: Array< string > | null,
  brand_collections?: Array< string > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?:  Array<Stockist > | null,
  featured_in?: Array< string > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  is_published?: boolean | null,
  firstPublishedAt?: string | null,
  firstPublishedAtTimestamp?: number | null,
  lastPublishedAt?: string | null,
  lastPublishedAtTimestamp?: number | null,
  category_page_id?: Array< string > | null,
  product_category?: Array< string > | null,
  product_subcategory?: Array< string > | null,
  average_shipping_days?: number | null,
  service_rate?: number | null,
  review_average?: number | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  reviews?: number | null,
  items?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  service_rate_rate_tier?: number | null,
  average_shipping_days_tier?: number | null,
  minimum_tier?: number | null,
  items_tier?: number | null,
  contacts_tier?: number | null,
  supplier_group_id?: string | null,
  supplier_group_title?: string | null,
  supplier_group_description?: string | null,
  supplier_group_image_key?: string | null,
  supplier_group_published?: boolean | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
  collections?: ModelCollectionConnection | null,
  contacts?: ModelContactConnection | null,
  review?: ModelReviewConnection | null,
  products?: ModelProductReadModelConnection | null,
};

export type ModelCollectionConnection = {
  __typename: "ModelCollectionConnection",
  items:  Array<Collection | null >,
  nextToken?: string | null,
};

export type Collection = {
  __typename: "Collection",
  id: string,
  brand_id: string,
  src_brand_id?: string | null,
  name: string,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner: string,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  display_order?: number | null,
  updatedAt: string,
  brand?: Brand | null,
  srcBrand?: Brand | null,
  productCollections?: ModelProductCollectionConnection | null,
};

export type ModelProductCollectionConnection = {
  __typename: "ModelProductCollectionConnection",
  items:  Array<ProductCollection | null >,
  nextToken?: string | null,
};

export type ProductCollection = {
  __typename: "ProductCollection",
  id: string,
  collection_id: string,
  product_id: string,
  product_type_id?: string | null,
  quantity: number,
  sort_order?: number | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  collection?: Collection | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  product_name: string,
  product_number?: string | null,
  product_brand_id: string,
  product_content?: string | null,
  product_description?: string | null,
  product_owner: string,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  product_price?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  product_type?: string | null,
  product_color?: string | null,
  product_size?: string | null,
  product_public_status?: string | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_types?: Array< string | null > | null,
  product_colors?: Array< string | null > | null,
  product_sizes?: Array< string | null > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  publishedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sort_recommended?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string | null > | null,
  owner: string,
  contacts?: ContactConnection | null,
  imageKeys?: ModelProductImageKeyConnection | null,
  videoKeys?: ModelProductVideoKeyConnection | null,
  producttype?: ModelProductTypeConnection | null,
  inventoryConnection?: ModelInventoryConnection | null,
  brand?: Brand | null,
  copiedBrand?: Brand | null,
  collections?: ModelProductCollectionConnection | null,
};

export type ContactConnection = {
  __typename: "ContactConnection",
  items?:  Array<Contact | null > | null,
  nextToken?: string | null,
};

export type ModelProductImageKeyConnection = {
  __typename: "ModelProductImageKeyConnection",
  items:  Array<ProductImageKey | null >,
  nextToken?: string | null,
};

export type ProductImageKey = {
  __typename: "ProductImageKey",
  id: string,
  product_id: string,
  imageKey?: string | null,
  createdAt?: string | null,
  owner: string,
  updatedAt: string,
  product?: Product | null,
};

export type ModelProductVideoKeyConnection = {
  __typename: "ModelProductVideoKeyConnection",
  items:  Array<ProductVideoKey | null >,
  nextToken?: string | null,
};

export type ProductVideoKey = {
  __typename: "ProductVideoKey",
  id: string,
  product_id: string,
  videoKey?: string | null,
  createdAt?: string | null,
  owner: string,
  updatedAt: string,
  product?: Product | null,
};

export type ModelProductTypeConnection = {
  __typename: "ModelProductTypeConnection",
  items:  Array<ProductType | null >,
  nextToken?: string | null,
};

export type ProductType = {
  __typename: "ProductType",
  id: string,
  product_id: string,
  imageKey?: string | null,
  type_name?: string | null,
  color?: string | null,
  size?: string | null,
  createdAt?: string | null,
  suspended?: boolean | null,
  published?: boolean | null,
  sku?: string | null,
  out_of_stock?: boolean | null,
  copied_product_type_id?: string | null,
  copied_brand_id?: string | null,
  owner: string,
  updatedAt: string,
  product?: Product | null,
  inventoryConnection?: ModelInventoryConnection | null,
};

export type ModelInventoryConnection = {
  __typename: "ModelInventoryConnection",
  items:  Array<Inventory | null >,
  nextToken?: string | null,
};

export type Inventory = {
  __typename: "Inventory",
  id: string,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  inventory?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  logs?: ModelInventoryLogConnection | null,
  brand?: Brand | null,
};

export type ModelInventoryLogConnection = {
  __typename: "ModelInventoryLogConnection",
  items:  Array<InventoryLog | null >,
  nextToken?: string | null,
};

export type InventoryLog = {
  __typename: "InventoryLog",
  id: string,
  inventory_id: string,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  stock_type: StockType,
  stock_mode: StockMode,
  stock_date: string,
  quantity: number,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  brand?: Brand | null,
  order?: Order | null,
  orderProduct?: OrderProduct | null,
};

export enum StockType {
  STOCK = "STOCK",
  DESTOCK = "DESTOCK",
  RECONCILE = "RECONCILE",
}


export enum StockMode {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}


export type Order = {
  __typename: "Order",
  id: string,
  order_owner?: string | null,
  brand_id?: string | null,
  brand_owner?: string | null,
  campaign_code?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owners: Array< string | null >,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  fee?: number | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax?: number | null,
  payment_term?: number | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  shipping_date?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  first_order?: boolean | null,
  order_points?: number | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  transferred_date?: string | null,
  order_price?: number | null,
  origin_order_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  cashback?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  billing_count?: number | null,
  return_due_date?: string | null,
  bill_payment_status?: BillPaymentStatus | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
  buyer?: ModelBuyerInfoConnection | null,
  brand?: Brand | null,
  originOrder?: Order | null,
  chargeStatuses?: ModelChargeStatusConnection | null,
  orderproducts?: ModelOrderProductConnection | null,
  returnproducts?: ModelReturnProductConnection | null,
  reportProducts?: ModelReportProductConnection | null,
  editReasons?: ModelOrderEditReasonConnection | null,
  cancelReasons?: ModelOrderCancelReasonConnection | null,
  reviews?: ModelReviewConnection | null,
  coupon?: Coupon | null,
};

export enum BillPaymentStatus {
  UNREGISTERED = "UNREGISTERED",
  REGISTERED = "REGISTERED",
  REREGISTER = "REREGISTER",
}


export type ModelChargeStatusConnection = {
  __typename: "ModelChargeStatusConnection",
  items:  Array<ChargeStatus | null >,
  nextToken?: string | null,
};

export type ChargeStatus = {
  __typename: "ChargeStatus",
  id: string,
  order_id: string,
  status: number,
  payment_id?: string | null,
  amount?: number | null,
  createdAt?: string | null,
  owners: Array< string | null >,
  updatedAt: string,
};

export type ModelOrderProductConnection = {
  __typename: "ModelOrderProductConnection",
  items:  Array<OrderProduct | null >,
  nextToken?: string | null,
};

export type OrderProduct = {
  __typename: "OrderProduct",
  id: string,
  order_id: string,
  product_id: string,
  product_type_id?: string | null,
  order_product_quantity?: number | null,
  order_product_price?: number | null,
  order_product_wholesale_rate?: number | null,
  discount_percentage?: number | null,
  initial_order_product_quantity?: number | null,
  initial_order_product_price?: number | null,
  initial_order_product_wholesale_rate?: number | null,
  order_product_payment_status?: number | null,
  order_product_payment_method?: number | null,
  order_product_return_reason?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  order?: Order | null,
  orderstatus?: ModelOrderStatusConnection | null,
  returnProducts?: ModelReturnProductConnection | null,
  reportProducts?: ModelReportProductConnection | null,
};

export type ModelOrderStatusConnection = {
  __typename: "ModelOrderStatusConnection",
  items:  Array<OrderStatus | null >,
  nextToken?: string | null,
};

export type OrderStatus = {
  __typename: "OrderStatus",
  id: string,
  order_id?: string | null,
  order_product_id: string,
  quantity?: number | null,
  status?: number | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  orderProduct?: OrderProduct | null,
};

export type ModelReturnProductConnection = {
  __typename: "ModelReturnProductConnection",
  items:  Array<ReturnProduct | null >,
  nextToken?: string | null,
};

export type ReturnProduct = {
  __typename: "ReturnProduct",
  id: string,
  return_product_id?: string | null,
  order_id: string,
  order_product_id: string,
  return_quantity?: number | null,
  return_product_price?: number | null,
  return_status?: number | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  logiless_inbound_delivery_code?: string | null,
  updatedAt: string,
  order?: Order | null,
  orderproduct?: OrderProduct | null,
  children?: ModelReturnProductConnection | null,
};

export type ModelReportProductConnection = {
  __typename: "ModelReportProductConnection",
  items:  Array<ReportProduct | null >,
  nextToken?: string | null,
};

export type ReportProduct = {
  __typename: "ReportProduct",
  id: string,
  type?: string | null,
  order_id: string,
  order_product_id: string,
  report_type?: string | null,
  request_type?: string | null,
  report_quantity?: number | null,
  report_product_price?: number | null,
  report_status?: string | null,
  report_comment?: string | null,
  replace_order_id?: string | null,
  replace_order_product_id?: string | null,
  approval_date?: string | null,
  approval_quantity?: number | null,
  image_keys?: Array< string | null > | null,
  stripe_refund_id?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt: string,
  order?: Order | null,
  replaceOrder?: Order | null,
  orderproduct?: OrderProduct | null,
  replaceOrderproduct?: OrderProduct | null,
};

export type ModelOrderEditReasonConnection = {
  __typename: "ModelOrderEditReasonConnection",
  items:  Array<OrderEditReason | null >,
  nextToken?: string | null,
};

export type OrderEditReason = {
  __typename: "OrderEditReason",
  id: string,
  order_id: string,
  edit_reason?: EditReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  order?: Order | null,
};

export enum EditReason {
  CHANGESHIPPINGINFO = "CHANGESHIPPINGINFO",
  CHANGESHIPPINGFEE = "CHANGESHIPPINGFEE",
  DISCOUNT = "DISCOUNT",
  OUTOFSTOCK = "OUTOFSTOCK",
  MISTAKEOFBUYER = "MISTAKEOFBUYER",
  MISTAKEOFSUPPLIER = "MISTAKEOFSUPPLIER",
  OTHER = "OTHER",
}


export type ModelOrderCancelReasonConnection = {
  __typename: "ModelOrderCancelReasonConnection",
  items:  Array<OrderCancelReason | null >,
  nextToken?: string | null,
};

export type OrderCancelReason = {
  __typename: "OrderCancelReason",
  id: string,
  order_id: string,
  cancel_reason?: CancelReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  order?: Order | null,
};

export enum CancelReason {
  OUTOFSTOCK = "OUTOFSTOCK",
  BATTING = "BATTING",
  UNMATCH = "UNMATCH",
  MISTAKEOFBUYER = "MISTAKEOFBUYER",
  MISTAKEOFSUPPLIER = "MISTAKEOFSUPPLIER",
  OTHER = "OTHER",
}


export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  brand_id: string,
  product_ids?: Array< string | null > | null,
  buyer_id: string,
  order_id: string,
  rate?: number | null,
  title?: string | null,
  comment?: string | null,
  remove_flag?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
  buyerInfo?: ModelBuyerInfoConnection | null,
  brand?: Brand | null,
  order?: Order | null,
  replies?: ModelReviewReplyConnection | null,
};

export type ModelReviewReplyConnection = {
  __typename: "ModelReviewReplyConnection",
  items:  Array<ReviewReply | null >,
  nextToken?: string | null,
};

export type ReviewReply = {
  __typename: "ReviewReply",
  id: string,
  review_id: string,
  brand_id: string,
  comment?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type Coupon = {
  __typename: "Coupon",
  id: string,
  coupon_name?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  brand_ids?: Array< string | null > | null,
  discount_type?: DiscountType | null,
  discount_value?: number | null,
  maximum_amount?: number | null,
  available_times?: number | null,
  duration?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  logs?: ModelCouponLogConnection | null,
  buyers?: ModelBuyerCouponConnection | null,
};

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  AMOUNT = "AMOUNT",
  CASHBACK = "CASHBACK",
}


export type ModelCouponLogConnection = {
  __typename: "ModelCouponLogConnection",
  items:  Array<CouponLog | null >,
  nextToken?: string | null,
};

export type CouponLog = {
  __typename: "CouponLog",
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_ids?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelBuyerCouponConnection = {
  __typename: "ModelBuyerCouponConnection",
  items:  Array<BuyerCoupon | null >,
  nextToken?: string | null,
};

export type BuyerCoupon = {
  __typename: "BuyerCoupon",
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  max_redemptions?: number | null,
  times_redeemed?: number | null,
  redemptions_left?: number | null,
  expiresAt?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  account?: Account | null,
  coupon?: Coupon | null,
  owner?: string | null,
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
};

export type ModelProductReadModelConnection = {
  __typename: "ModelProductReadModelConnection",
  items:  Array<ProductReadModel | null >,
  nextToken?: string | null,
};

export type ProductReadModel = {
  __typename: "ProductReadModel",
  id: string,
  product_name?: string | null,
  product_number?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_yomigana?: string | null,
  brand_image_key?: string | null,
  brand_published?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_individual_minimum_buy?: number | null,
  brand_individual_wholesale_rate?: number | null,
  brand_tags?: Array< string > | null,
  product_content?: string | null,
  product_description?: string | null,
  product_size?: string | null,
  product_owner?: string | null,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  origin_wholesale_price?: number | null,
  wholesale_rate?: number | null,
  wholesale_price?: number | null,
  individual_wholesale_rate?: number | null,
  individual_price?: number | null,
  individual_minimum_buy?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  is_published?: boolean | null,
  is_limited?: boolean | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  category_page_id?: Array< string > | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_preorder_shipping_date_timestamp?: number | null,
  product_types?: Array< string > | null,
  product_colors?: Array< string > | null,
  product_sizes?: Array< string > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  image_key?: string | null,
  image_keys?: Array< string > | null,
  video_keys?: Array< string > | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string > | null,
  protected_zip_code?: Array< string > | null,
  options?:  Array<ProductOption > | null,
  publishedAt?: string | null,
  publishedAtTimestamp?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  wholesale_rate_tier?: number | null,
  brand_tier?: number | null,
  brand_conversion_rate_tier?: number | null,
  brand_sell_through_rate_tier?: number | null,
  brand_service_rate_rate_tier?: number | null,
  brand_average_shipping_days_tier?: number | null,
  brand_minimum_tier?: number | null,
  brand_items_tier?: number | null,
  brand_contacts_tier?: number | null,
  collection_id?: Array< string | null > | null,
  sell_only_direct?: boolean | null,
};

export type ProductOption = {
  __typename: "ProductOption",
  id?: string | null,
  product_id?: string | null,
  size?: string | null,
  color?: string | null,
  type_name?: string | null,
  sku?: string | null,
  imageKey?: string | null,
  out_of_stock?: boolean | null,
  suspended?: boolean | null,
  published?: boolean | null,
  createdAt?: string | null,
  copied_product_type_id?: string | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelSupplierInfoConnection = {
  __typename: "ModelSupplierInfoConnection",
  items:  Array<SupplierInfo | null >,
  nextToken?: string | null,
};

export type SupplierInfo = {
  __typename: "SupplierInfo",
  id: string,
  account_id: string,
  supplier_contract_type?: number | null,
  bank_name?: string | null,
  bank_branch_name?: string | null,
  bank_account_number?: string | null,
  bank_account_name?: string | null,
  bank_account_type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  account?: Account | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelContactListConnectionConnection = {
  __typename: "ModelContactListConnectionConnection",
  items:  Array<ContactListConnection | null >,
  nextToken?: string | null,
};

export type ContactListConnection = {
  __typename: "ContactListConnection",
  id: string,
  supplier_id: string,
  contact_list_id: string,
  contact_id: string,
  createdAt: string,
  updatedAt: string,
  owner: string,
  contact?: Contact | null,
  contactList?: ContactList | null,
};

export type ContactList = {
  __typename: "ContactList",
  id: string,
  list_name: string,
  supplier_id: string,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  owner: string,
  contactConnections?: ModelContactListConnectionConnection | null,
};

export type ModelContactActivityConnection = {
  __typename: "ModelContactActivityConnection",
  items:  Array<ContactActivity | null >,
  nextToken?: string | null,
};

export type ContactActivity = {
  __typename: "ContactActivity",
  id: string,
  contact_id: string,
  activity_date: string,
  activity_type?: string | null,
  order_id?: string | null,
  message_id?: string | null,
  campaign_id?: string | null,
  activity?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  order?: Order | null,
  campaign?: Campaign | null,
};

export type Campaign = {
  __typename: "Campaign",
  id: string,
  supplier_id: string,
  brand_id: string,
  campaign_sender?: string | null,
  campaign_from?: string | null,
  campaign_title?: string | null,
  campaign_status?: string | null,
  campaign_subject?: string | null,
  preview_text?: string | null,
  campaign_template?: string | null,
  campaign_message?: string | null,
  campaign_components?: string | null,
  campaign_style?: string | null,
  campaign_segments?: Array< string | null > | null,
  deliveryTime?: string | null,
  sentAt?: string | null,
  createdAt: string,
  owner: string,
  stats?: CampaignStats | null,
  updatedAt: string,
  brand?: Brand | null,
  contacts?: ModelCampaignContactConnection | null,
};

export type CampaignStats = {
  __typename: "CampaignStats",
  total?: number | null,
  sent?: number | null,
  delivered?: number | null,
  unsent?: number | null,
  open?: number | null,
  click?: number | null,
  bounce?: number | null,
  unsubscribe?: number | null,
  clickRate?: number | null,
  openRate?: number | null,
};

export type ModelCampaignContactConnection = {
  __typename: "ModelCampaignContactConnection",
  items:  Array<CampaignContact | null >,
  nextToken?: string | null,
};

export type CampaignContact = {
  __typename: "CampaignContact",
  id: string,
  supplier_id: string,
  campaign_id?: string | null,
  contact_id?: string | null,
  email?: string | null,
  source?: string | null,
  contact_list_id?: string | null,
  status?: string | null,
  clicks?: number | null,
  views?: number | null,
  sentAt?: string | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  contact?: Contact | null,
  campaign?: Campaign | null,
};

export type ModelPageViewConnection = {
  __typename: "ModelPageViewConnection",
  items:  Array<PageView | null >,
  nextToken?: string | null,
};

export type PageView = {
  __typename: "PageView",
  brand_id: string,
  buyer_id: string,
  email?: string | null,
  page_view?: number | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CampaignInput = {
  id: string,
  campaign_sender?: string | null,
  campaign_from?: string | null,
  campaign_subject?: string | null,
  preview_text?: string | null,
  campaign_template?: string | null,
  campaign_message?: string | null,
  campaign_components?: string | null,
  campaign_style?: string | null,
  campaign_segments?: Array< string | null > | null,
  deliveryTime?: string | null,
};

export type CampaignContactInput = {
  contactId: string,
  source: string,
};

export type ProductCsvInput = {
  brandId: string,
  productName: string,
  productNumber: string,
  isOpenPrice?: boolean | null,
  wholeSaleRate?: number | null,
  saleWholeSaleRate?: number | null,
  price: number,
  productCategory?: string | null,
  productSubCategory?: string | null,
  estimatedShipDateMin?: number | null,
  estimatedShipDateMax?: number | null,
  preOrderShippingDate?: string | null,
  orderLot: number,
  displayOrder?: number | null,
  colors?: Array< string | null > | null,
  sizes?: Array< string | null > | null,
  typeNames?: Array< string | null > | null,
  preOrder?: number | null,
  content?: string | null,
  description?: string | null,
  sizeDesc?: string | null,
  jancode?: string | null,
  stockQuantity?: number | null,
  publicStatus: string,
  sku?: string | null,
  id: string,
  typeId?: string | null,
  images?: Array< string | null > | null,
  isNewProduct: boolean,
  productYear?: number | null,
  productSeason?: string | null,
  publishedAt?: string | null,
};

export type BuyerReadModel = {
  __typename: "BuyerReadModel",
  id: string,
  account_company_name?: string | null,
  account_first_name?: string | null,
  account_id?: string | null,
  account_instagram_account?: string | null,
  account_last_name?: string | null,
  account_location?: string | null,
  account_tel?: string | null,
  account_web_site_URL?: string | null,
  account_zip_code?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_returen_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: string | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  primary_shipping_building?: string | null,
  primary_shipping_city?: string | null,
  primary_shipping_name?: string | null,
  primary_shipping_phone_number?: string | null,
  primary_shipping_prefecture?: string | null,
  primary_shipping_zip_code?: string | null,
  referral_brand_id?: string | null,
  referral_brand_name?: string | null,
  shop_image_keys?: Array< string | null > | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  total_order_amount?: number | null,
  total_order_amount_last_9m?: number | null,
  total_order_quantity?: number | null,
  total_order_quantity_last_9m?: number | null,
  total_return_amount?: number | null,
  total_return_amount_last_9m?: number | null,
  total_return_quantity?: number | null,
  total_return_quantity_last_9m?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateInventoriesInput = {
  product_id?: string | null,
  product_type_id?: string | null,
  sku: string,
  inventory_id?: string | null,
  inventory?: number | null,
};

export type InventoryReservationInput = {
  order_product_id: string,
  quantity: number,
};

export type InventoryReservationOutput = {
  __typename: "InventoryReservationOutput",
  order_product_id: string,
  inventory?: Inventory | null,
  error?: string | null,
};

export type OrderReadModel = {
  __typename: "OrderReadModel",
  id: string,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_image_key?: string | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  shipping_date?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_due_date?: string | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  order_quantity?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_amount?: number | null,
  report_approved_quantity?: number | null,
  report_approved_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  points?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  campaign_code?: string | null,
  cashback_amount?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  origin_order_id?: string | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  billing_count?: number | null,
  is_first?: boolean | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  is_preorder?: boolean | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  skus?: Array< string | null > | null,
  bill_payment_status?: BillPaymentStatus | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
  buyerAccount?: Account | null,
  brand?: Brand | null,
  reviews?: ModelReviewConnection | null,
  coupon?: Coupon | null,
  details?: ModelOrderDetailReadModelConnection | null,
};

export enum OrderReadModelStatus {
  UNCHECKED = "UNCHECKED",
  CHECKED = "CHECKED",
  SHIPPED = "SHIPPED",
  CANCELED = "CANCELED",
  CANCELED_BY_BUYER = "CANCELED_BY_BUYER",
  CANCELED_BY_SUPPLIER = "CANCELED_BY_SUPPLIER",
  CANCELED_BY_SYSTEM = "CANCELED_BY_SYSTEM",
}


export enum OrderPaymentStatus {
  UNCHARGED = "UNCHARGED",
  CHARGED = "CHARGED",
  PARTIAL_CHARGED = "PARTIAL_CHARGED",
  DEFAULT = "DEFAULT",
}


export enum PaymentTerm {
  CARD_DEFFERED_PAYMENT = "CARD_DEFFERED_PAYMENT",
  CARD_SHIPPING_PAYMENT = "CARD_SHIPPING_PAYMENT",
  BILL_PAYMENT_FACTORING = "BILL_PAYMENT_FACTORING",
  BILL_PAYMENT = "BILL_PAYMENT",
  DIRECT_PAYMENT = "DIRECT_PAYMENT",
}


export type ModelOrderDetailReadModelConnection = {
  __typename: "ModelOrderDetailReadModelConnection",
  items:  Array<OrderDetailReadModel | null >,
  nextToken?: string | null,
};

export type OrderDetailReadModel = {
  __typename: "OrderDetailReadModel",
  id: string,
  order_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  initial_quantity?: number | null,
  initial_price?: number | null,
  initial_wholesale_rate?: number | null,
  quantity?: number | null,
  price?: number | null,
  wholesale_rate?: number | null,
  subtotal?: number | null,
  amount?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_approved_amount?: number | null,
  report_approved_quantity?: number | null,
  report_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  discount_percentage?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  memo?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  product?: Product | null,
  productType?: ProductType | null,
  brand?: Brand | null,
  editReasons?: ModelOrderEditReasonConnection | null,
  cancelReasons?: ModelOrderCancelReasonConnection | null,
  order?: OrderReadModel | null,
};

export type ReturnDetailReadModel = {
  __typename: "ReturnDetailReadModel",
  id: string,
  order_id?: string | null,
  order_product_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_request_due_date?: string | null,
  return_request_date?: string | null,
  return_due_date?: string | null,
  return_date?: string | null,
  order_quantity?: number | null,
  order_price?: number | null,
  wholesale_rate?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  order_tax?: number | null,
  tax_rate?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  status?: ReturnStatus | null,
  status_label?: string | null,
  return_destination?: ReturnDestination | null,
  return_destination_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  brand?: Brand | null,
  order?: OrderReadModel | null,
};

export enum ReturnStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  RETURNED_TO_BRAND = "RETURNED_TO_BRAND",
  NOT_RETURNED = "NOT_RETURNED",
}


export enum ReturnDestination {
  WAREHOUSE = "WAREHOUSE",
  BRAND = "BRAND",
}


export type CollectionReadModel = {
  __typename: "CollectionReadModel",
  id: string,
  name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_categories?: Array< string | null > | null,
  brand_tags?: Array< string | null > | null,
  src_brand_id?: string | null,
  src_brand_name?: string | null,
  src_brand_categories?: Array< string | null > | null,
  src_brand_tags?: Array< string | null > | null,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_image_keys?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  product_categories?: Array< string | null > | null,
  product_subcategories?: Array< string | null > | null,
  total_quantity?: number | null,
  total_retail_amount?: number | null,
  total_original_amount?: number | null,
  total_amount?: number | null,
  total_items?: number | null,
  wholesale_rate?: number | null,
  minimum_wholesale_rate?: number | null,
  sell_only_direct?: boolean | null,
  display_order?: number | null,
  updatedAt: string,
};

export type CopyProductInput = {
  src_brand_id?: string | null,
  src_product_id?: string | null,
  src_product_type_id?: string | null,
  sku?: string | null,
  stock_quantity?: number | null,
  dest_brand_id?: string | null,
};

export type CreateBillingTransactionInput = {
  account_id?: string | null,
  number?: string | null,
  amount?: number | null,
  date?: string | null,
  issue_date?: string | null,
  due_date?: string | null,
  details?: Array< TransactionDetail | null > | null,
};

export type TransactionDetail = {
  description?: string | null,
  quantity?: number | null,
  unit_price?: number | null,
  amount?: number | null,
};

export type SplitOrderInput = {
  orderId?: string | null,
  newOrderProducts?: Array< SplitOrderNewOrderProduct | null > | null,
};

export type SplitOrderNewOrderProduct = {
  id?: string | null,
  quantity?: number | null,
};

export type CreateAccountInput = {
  id?: string | null,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  web_site_URL?: string | null,
  instagram_account?: string | null,
  zip_code?: string | null,
  location?: string | null,
  tel?: string | null,
  company_name?: string | null,
  term_approval_status?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type ModelAccountConditionInput = {
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  web_site_URL?: ModelStringInput | null,
  instagram_account?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  location?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  company_name?: ModelStringInput | null,
  term_approval_status?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateAccountInput = {
  id: string,
  email?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  web_site_URL?: string | null,
  instagram_account?: string | null,
  zip_code?: string | null,
  location?: string | null,
  tel?: string | null,
  company_name?: string | null,
  term_approval_status?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateBuyerInfoInput = {
  id?: string | null,
  account_id?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_id?: string | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_return_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  invoice_credit_facility?: number | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: Array< string | null > | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  referral_brand_id?: string | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelBuyerInfoConditionInput = {
  account_id?: ModelIDInput | null,
  billing_close_day?: ModelIntInput | null,
  buyer_brand?: ModelStringInput | null,
  buyer_credit?: ModelFloatInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_kyc_status?: ModelIntInput | null,
  buyer_product_category?: ModelStringInput | null,
  buyer_rating?: ModelFloatInput | null,
  buyer_return_credit?: ModelFloatInput | null,
  buyer_return_limit_per_brand?: ModelFloatInput | null,
  buyer_type?: ModelStringInput | null,
  invoice_credit_facility?: ModelFloatInput | null,
  item_category?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  mf_customer_id?: ModelStringInput | null,
  mf_destination_id?: ModelStringInput | null,
  mf_examination_status?: ModelStringInput | null,
  owner_name?: ModelStringInput | null,
  payment_type?: ModelIntInput | null,
  primary_payment_method_id?: ModelIDInput | null,
  primary_payment_term?: ModelIntInput | null,
  primary_shipping_address_id?: ModelIDInput | null,
  referral_brand_id?: ModelIDInput | null,
  shop_name?: ModelStringInput | null,
  store_category?: ModelStringInput | null,
  stripe_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBuyerInfoConditionInput | null > | null,
  or?: Array< ModelBuyerInfoConditionInput | null > | null,
  not?: ModelBuyerInfoConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateBuyerInfoInput = {
  id: string,
  account_id?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_id?: string | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_return_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  invoice_credit_facility?: number | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: Array< string | null > | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  referral_brand_id?: string | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBuyerInfoInput = {
  id: string,
};

export type CreateShopImageInput = {
  id?: string | null,
  buyer_id: string,
  image_key?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelShopImageConditionInput = {
  buyer_id?: ModelIDInput | null,
  image_key?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShopImageConditionInput | null > | null,
  or?: Array< ModelShopImageConditionInput | null > | null,
  not?: ModelShopImageConditionInput | null,
};

export type UpdateShopImageInput = {
  id: string,
  buyer_id?: string | null,
  image_key?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteShopImageInput = {
  id: string,
};

export type CreateIndividualInput = {
  brand_id: string,
  buyer_id: string,
  supplier_group_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelIndividualConditionInput = {
  supplier_group_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIndividualConditionInput | null > | null,
  or?: Array< ModelIndividualConditionInput | null > | null,
  not?: ModelIndividualConditionInput | null,
};

export type Individual = {
  __typename: "Individual",
  brand_id: string,
  buyer_id: string,
  supplier_group_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  buyerInfo?: BuyerInfo | null,
  supplierGroup?: SupplierGroup | null,
  brand?: BrandReadModel | null,
};

export type UpdateIndividualInput = {
  brand_id: string,
  buyer_id: string,
  supplier_group_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeleteIndividualInput = {
  buyer_id: string,
  brand_id: string,
};

export type CreateSupplierGroupInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  image_key?: string | null,
  published: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type ModelSupplierGroupConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSupplierGroupConditionInput | null > | null,
  or?: Array< ModelSupplierGroupConditionInput | null > | null,
  not?: ModelSupplierGroupConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateSupplierGroupInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  image_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type DeleteSupplierGroupInput = {
  id: string,
};

export type CreateSupplierInfoInput = {
  id?: string | null,
  account_id: string,
  supplier_contract_type?: number | null,
  bank_name?: string | null,
  bank_branch_name?: string | null,
  bank_account_number?: string | null,
  bank_account_name?: string | null,
  bank_account_type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type ModelSupplierInfoConditionInput = {
  account_id?: ModelIDInput | null,
  supplier_contract_type?: ModelIntInput | null,
  bank_name?: ModelStringInput | null,
  bank_branch_name?: ModelStringInput | null,
  bank_account_number?: ModelStringInput | null,
  bank_account_name?: ModelStringInput | null,
  bank_account_type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSupplierInfoConditionInput | null > | null,
  or?: Array< ModelSupplierInfoConditionInput | null > | null,
  not?: ModelSupplierInfoConditionInput | null,
};

export type UpdateSupplierInfoInput = {
  id: string,
  account_id?: string | null,
  supplier_contract_type?: number | null,
  bank_name?: string | null,
  bank_branch_name?: string | null,
  bank_account_number?: string | null,
  bank_account_name?: string | null,
  bank_account_type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
};

export type DeleteSupplierInfoInput = {
  id: string,
};

export type CreateShippingAddressInput = {
  id?: string | null,
  name: string,
  buyer_id: string,
  zip_code: string,
  prefecture: string,
  city: string,
  building?: string | null,
  phone_number: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelShippingAddressConditionInput = {
  name?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  zip_code?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  city?: ModelStringInput | null,
  building?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShippingAddressConditionInput | null > | null,
  or?: Array< ModelShippingAddressConditionInput | null > | null,
  not?: ModelShippingAddressConditionInput | null,
};

export type UpdateShippingAddressInput = {
  id: string,
  name?: string | null,
  buyer_id?: string | null,
  zip_code?: string | null,
  prefecture?: string | null,
  city?: string | null,
  building?: string | null,
  phone_number?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteShippingAddressInput = {
  id: string,
};

export type CreateEmailPreferencesInput = {
  id?: string | null,
  new_arrivals?: boolean | null,
  sale_items?: boolean | null,
  bounced?: boolean | null,
  owner?: string | null,
};

export type ModelEmailPreferencesConditionInput = {
  new_arrivals?: ModelBooleanInput | null,
  sale_items?: ModelBooleanInput | null,
  bounced?: ModelBooleanInput | null,
  and?: Array< ModelEmailPreferencesConditionInput | null > | null,
  or?: Array< ModelEmailPreferencesConditionInput | null > | null,
  not?: ModelEmailPreferencesConditionInput | null,
};

export type EmailPreferences = {
  __typename: "EmailPreferences",
  id: string,
  new_arrivals?: boolean | null,
  sale_items?: boolean | null,
  bounced?: boolean | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEmailPreferencesInput = {
  id: string,
  new_arrivals?: boolean | null,
  sale_items?: boolean | null,
  bounced?: boolean | null,
  owner?: string | null,
};

export type DeleteEmailPreferencesInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  product_name: string,
  product_number?: string | null,
  product_brand_id: string,
  product_content?: string | null,
  product_description?: string | null,
  product_owner: string,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  product_price?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  product_type?: string | null,
  product_color?: string | null,
  product_size?: string | null,
  product_public_status?: string | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_types?: Array< string | null > | null,
  product_colors?: Array< string | null > | null,
  product_sizes?: Array< string | null > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  publishedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sort_recommended?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string | null > | null,
  owner: string,
  contacts?: ContactConnectionInput | null,
};

export type ContactConnectionInput = {
  nextToken?: string | null,
};

export type ModelProductConditionInput = {
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  product_brand_id?: ModelIDInput | null,
  product_content?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  product_owner?: ModelIDInput | null,
  product_wholesale_rate?: ModelFloatInput | null,
  product_sale_wholesale_rate?: ModelFloatInput | null,
  product_retail_price?: ModelIntInput | null,
  product_price?: ModelIntInput | null,
  is_open_price?: ModelBooleanInput | null,
  product_jancode?: ModelStringInput | null,
  product_stock_quantity?: ModelIntInput | null,
  product_type?: ModelStringInput | null,
  product_color?: ModelStringInput | null,
  product_size?: ModelStringInput | null,
  product_public_status?: ModelStringInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  product_minimum_quantity?: ModelIntInput | null,
  product_estimated_ship_date_min?: ModelIntInput | null,
  product_estimated_ship_date_max?: ModelIntInput | null,
  product_order_lot?: ModelIntInput | null,
  product_preorder?: ModelIntInput | null,
  product_preorder_shipping_date?: ModelStringInput | null,
  product_types?: ModelStringInput | null,
  product_colors?: ModelStringInput | null,
  product_sizes?: ModelStringInput | null,
  product_display_order?: ModelIntInput | null,
  product_season?: ModelStringInput | null,
  product_year?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sort_recommended?: ModelIntInput | null,
  suspended?: ModelBooleanInput | null,
  continue_selling?: ModelBooleanInput | null,
  out_of_stock?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  copied_product_id?: ModelIDInput | null,
  copied_brand_id?: ModelIDInput | null,
  limited_publishing_list?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type UpdateProductInput = {
  id: string,
  product_name?: string | null,
  product_number?: string | null,
  product_brand_id?: string | null,
  product_content?: string | null,
  product_description?: string | null,
  product_owner?: string | null,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  product_price?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  product_type?: string | null,
  product_color?: string | null,
  product_size?: string | null,
  product_public_status?: string | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_types?: Array< string | null > | null,
  product_colors?: Array< string | null > | null,
  product_sizes?: Array< string | null > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  publishedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sort_recommended?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string | null > | null,
  owner?: string | null,
  contacts?: ContactConnectionInput | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateProductImageKeyInput = {
  id?: string | null,
  product_id: string,
  imageKey?: string | null,
  createdAt?: string | null,
  owner: string,
};

export type ModelProductImageKeyConditionInput = {
  product_id?: ModelIDInput | null,
  imageKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductImageKeyConditionInput | null > | null,
  or?: Array< ModelProductImageKeyConditionInput | null > | null,
  not?: ModelProductImageKeyConditionInput | null,
};

export type UpdateProductImageKeyInput = {
  id: string,
  product_id?: string | null,
  imageKey?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteProductImageKeyInput = {
  id: string,
};

export type CreateProductVideoKeyInput = {
  id?: string | null,
  product_id: string,
  videoKey?: string | null,
  createdAt?: string | null,
  owner: string,
};

export type ModelProductVideoKeyConditionInput = {
  product_id?: ModelIDInput | null,
  videoKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductVideoKeyConditionInput | null > | null,
  or?: Array< ModelProductVideoKeyConditionInput | null > | null,
  not?: ModelProductVideoKeyConditionInput | null,
};

export type UpdateProductVideoKeyInput = {
  id: string,
  product_id?: string | null,
  videoKey?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteProductVideoKeyInput = {
  id: string,
};

export type CreateProductTypeInput = {
  id?: string | null,
  product_id: string,
  imageKey?: string | null,
  type_name?: string | null,
  color?: string | null,
  size?: string | null,
  createdAt?: string | null,
  suspended?: boolean | null,
  published?: boolean | null,
  sku?: string | null,
  out_of_stock?: boolean | null,
  copied_product_type_id?: string | null,
  copied_brand_id?: string | null,
  owner: string,
};

export type ModelProductTypeConditionInput = {
  product_id?: ModelIDInput | null,
  imageKey?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  suspended?: ModelBooleanInput | null,
  published?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  out_of_stock?: ModelBooleanInput | null,
  copied_product_type_id?: ModelStringInput | null,
  copied_brand_id?: ModelStringInput | null,
  and?: Array< ModelProductTypeConditionInput | null > | null,
  or?: Array< ModelProductTypeConditionInput | null > | null,
  not?: ModelProductTypeConditionInput | null,
};

export type UpdateProductTypeInput = {
  id: string,
  product_id?: string | null,
  imageKey?: string | null,
  type_name?: string | null,
  color?: string | null,
  size?: string | null,
  createdAt?: string | null,
  suspended?: boolean | null,
  published?: boolean | null,
  sku?: string | null,
  out_of_stock?: boolean | null,
  copied_product_type_id?: string | null,
  copied_brand_id?: string | null,
  owner?: string | null,
};

export type DeleteProductTypeInput = {
  id: string,
};

export type CreateInventoryInput = {
  id?: string | null,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  inventory?: number | null,
  createdAt?: string | null,
};

export type ModelInventoryConditionInput = {
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  inventory?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInventoryConditionInput | null > | null,
  or?: Array< ModelInventoryConditionInput | null > | null,
  not?: ModelInventoryConditionInput | null,
};

export type UpdateInventoryInput = {
  id: string,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  sku?: string | null,
  inventory?: number | null,
  createdAt?: string | null,
};

export type DeleteInventoryInput = {
  id: string,
};

export type CreateReturnInventoryLogInput = {
  id?: string | null,
  inventory_id?: string | null,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  stock_type: StockType,
  stock_mode: StockMode,
  stock_date: string,
  quantity: number,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
};

export type ModelReturnInventoryLogConditionInput = {
  inventory_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  stock_type?: ModelStockTypeInput | null,
  stock_mode?: ModelStockModeInput | null,
  stock_date?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReturnInventoryLogConditionInput | null > | null,
  or?: Array< ModelReturnInventoryLogConditionInput | null > | null,
  not?: ModelReturnInventoryLogConditionInput | null,
};

export type ModelStockTypeInput = {
  eq?: StockType | null,
  ne?: StockType | null,
};

export type ModelStockModeInput = {
  eq?: StockMode | null,
  ne?: StockMode | null,
};

export type ReturnInventoryLog = {
  __typename: "ReturnInventoryLog",
  id: string,
  inventory_id?: string | null,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  stock_type: StockType,
  stock_mode: StockMode,
  stock_date: string,
  quantity: number,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
  brand?: Brand | null,
  order?: Order | null,
  orderProduct?: OrderProduct | null,
};

export type UpdateReturnInventoryLogInput = {
  id: string,
  inventory_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  sku?: string | null,
  stock_type?: StockType | null,
  stock_mode?: StockMode | null,
  stock_date?: string | null,
  quantity?: number | null,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
};

export type DeleteReturnInventoryLogInput = {
  id: string,
};

export type CreateInventoryLogInput = {
  id?: string | null,
  inventory_id: string,
  brand_id: string,
  product_id: string,
  product_type_id?: string | null,
  sku: string,
  stock_type: StockType,
  stock_mode: StockMode,
  stock_date: string,
  quantity: number,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
};

export type ModelInventoryLogConditionInput = {
  inventory_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  stock_type?: ModelStockTypeInput | null,
  stock_mode?: ModelStockModeInput | null,
  stock_date?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInventoryLogConditionInput | null > | null,
  or?: Array< ModelInventoryLogConditionInput | null > | null,
  not?: ModelInventoryLogConditionInput | null,
};

export type UpdateInventoryLogInput = {
  id: string,
  inventory_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  sku?: string | null,
  stock_type?: StockType | null,
  stock_mode?: StockMode | null,
  stock_date?: string | null,
  quantity?: number | null,
  order_id?: string | null,
  order_product_id?: string | null,
  createdAt?: string | null,
};

export type DeleteInventoryLogInput = {
  id: string,
};

export type CreateInStockNotificationInput = {
  id?: string | null,
  buyer_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  completed?: boolean | null,
  createdAt?: string | null,
};

export type ModelInStockNotificationConditionInput = {
  buyer_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInStockNotificationConditionInput | null > | null,
  or?: Array< ModelInStockNotificationConditionInput | null > | null,
  not?: ModelInStockNotificationConditionInput | null,
};

export type InStockNotification = {
  __typename: "InStockNotification",
  id: string,
  buyer_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  completed?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  productType?: ProductType | null,
};

export type UpdateInStockNotificationInput = {
  id: string,
  buyer_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  completed?: boolean | null,
  createdAt?: string | null,
};

export type DeleteInStockNotificationInput = {
  id: string,
};

export type CreateBrandInput = {
  id?: string | null,
  brand_owner: string,
  brand_name: string,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  brand_collections?: Array< string | null > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string | null > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?: Array< StockistInput | null > | null,
  featured_in?: Array< string | null > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string | null > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  supplier_group_id?: string | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
};

export type StockistInput = {
  stockist?: string | null,
  address?: string | null,
};

export type ModelBrandConditionInput = {
  brand_owner?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_type?: ModelStringInput | null,
  brand_content?: ModelStringInput | null,
  brand_imageKey?: ModelStringInput | null,
  brand_icon_imageKey?: ModelStringInput | null,
  brand_logo_imageKey?: ModelStringInput | null,
  brand_publication?: ModelStringInput | null,
  brand_retail_stores_number?: ModelStringInput | null,
  brand_category?: ModelStringInput | null,
  brand_target_age?: ModelStringInput | null,
  brand_target_gender?: ModelStringInput | null,
  brand_website_url?: ModelStringInput | null,
  brand_work_with_big_box?: ModelStringInput | null,
  brand_address?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_shippinng_fee?: ModelIntInput | null,
  brand_shippinng_fee_criteria?: ModelIntInput | null,
  brand_instagram_account?: ModelStringInput | null,
  brand_facebook_account?: ModelStringInput | null,
  brand_rating?: ModelFloatInput | null,
  brand_annual_turnover?: ModelIntInput | null,
  brand_public_status?: ModelStringInput | null,
  brand_howtoknowhomula?: ModelStringInput | null,
  brand_audit_status?: ModelIntInput | null,
  brand_stripe_id?: ModelStringInput | null,
  brand_first_order_fee?: ModelIntInput | null,
  brand_additional_order_fee?: ModelIntInput | null,
  brand_first_order_fee_referral?: ModelIntInput | null,
  brand_additional_order_fee_referral?: ModelIntInput | null,
  brand_direct_order_fee?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  designed_in?: ModelStringInput | null,
  established?: ModelStringInput | null,
  tagIds?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  brand_collections?: ModelStringInput | null,
  movie_url?: ModelStringInput | null,
  brand_modal_imageKeys?: ModelStringInput | null,
  brand_online_sale?: ModelBooleanInput | null,
  product_image_reprint?: ModelBooleanInput | null,
  brand_logo_reprint?: ModelBooleanInput | null,
  post_to_sns?: ModelBooleanInput | null,
  allow_direct_shipping?: ModelBooleanInput | null,
  allow_sell_before_buy?: ModelBooleanInput | null,
  other_terms?: ModelStringInput | null,
  featured_in?: ModelStringInput | null,
  maximum_wholesale_rate?: ModelFloatInput | null,
  exclude_zip_code?: ModelStringInput | null,
  is_sale_brand?: ModelBooleanInput | null,
  hubspot_company_id?: ModelStringInput | null,
  return_to_brand?: ModelBooleanInput | null,
  terms_of_sample?: ModelStringInput | null,
  auto_message_enabled?: ModelBooleanInput | null,
  auto_message?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  supplier_group_id?: ModelIDInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  individual_shippinng_fee?: ModelIntInput | null,
  individual_shippinng_fee_criteria?: ModelIntInput | null,
  individual_first_order_fee?: ModelIntInput | null,
  individual_additional_order_fee?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type UpdateBrandInput = {
  id: string,
  brand_owner?: string | null,
  brand_name?: string | null,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  brand_collections?: Array< string | null > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string | null > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?: Array< StockistInput | null > | null,
  featured_in?: Array< string | null > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string | null > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  supplier_group_id?: string | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
};

export type DeleteBrandInput = {
  id: string,
};

export type CreateTagInput = {
  id?: string | null,
  tag_name?: string | null,
};

export type ModelTagConditionInput = {
  tag_name?: ModelStringInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  tag_name?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTagInput = {
  id: string,
  tag_name?: string | null,
};

export type DeleteTagInput = {
  id: string,
};

export type CreateBrandCollectionInput = {
  id?: string | null,
  collection_name?: string | null,
  visible?: boolean | null,
};

export type ModelBrandCollectionConditionInput = {
  collection_name?: ModelStringInput | null,
  visible?: ModelBooleanInput | null,
  and?: Array< ModelBrandCollectionConditionInput | null > | null,
  or?: Array< ModelBrandCollectionConditionInput | null > | null,
  not?: ModelBrandCollectionConditionInput | null,
};

export type BrandCollection = {
  __typename: "BrandCollection",
  id: string,
  collection_name?: string | null,
  visible?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBrandCollectionInput = {
  id: string,
  collection_name?: string | null,
  visible?: boolean | null,
};

export type DeleteBrandCollectionInput = {
  id: string,
};

export type CreateShopCartInput = {
  id?: string | null,
  shopcart_owner: string,
  shopcart_quantity: number,
  product_id: string,
  product_type_id?: string | null,
  brand_id?: string | null,
  is_direct?: boolean | null,
  is_pay_directly?: boolean | null,
  is_consign?: boolean | null,
  createdAt?: string | null,
  saved_for_later?: boolean | null,
  collection_id?: string | null,
};

export type ModelShopCartConditionInput = {
  shopcart_owner?: ModelStringInput | null,
  shopcart_quantity?: ModelIntInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  is_direct?: ModelBooleanInput | null,
  is_pay_directly?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  saved_for_later?: ModelBooleanInput | null,
  collection_id?: ModelIDInput | null,
  and?: Array< ModelShopCartConditionInput | null > | null,
  or?: Array< ModelShopCartConditionInput | null > | null,
  not?: ModelShopCartConditionInput | null,
};

export type ShopCart = {
  __typename: "ShopCart",
  id?: string | null,
  shopcart_owner: string,
  shopcart_quantity: number,
  product_id: string,
  product_type_id?: string | null,
  brand_id?: string | null,
  is_direct?: boolean | null,
  is_pay_directly?: boolean | null,
  is_consign?: boolean | null,
  createdAt?: string | null,
  saved_for_later?: boolean | null,
  collection_id?: string | null,
  updatedAt: string,
  product?: Product | null,
  producttype?: ProductType | null,
  brand?: Brand | null,
  owner?: string | null,
};

export type UpdateShopCartInput = {
  id: string,
  shopcart_owner?: string | null,
  shopcart_quantity?: number | null,
  product_id?: string | null,
  product_type_id?: string | null,
  brand_id?: string | null,
  is_direct?: boolean | null,
  is_pay_directly?: boolean | null,
  is_consign?: boolean | null,
  createdAt?: string | null,
  saved_for_later?: boolean | null,
  collection_id?: string | null,
};

export type DeleteShopCartInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  order_owner?: string | null,
  brand_id?: string | null,
  brand_owner?: string | null,
  campaign_code?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owners: Array< string | null >,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  fee?: number | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax?: number | null,
  payment_term?: number | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  shipping_date?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  first_order?: boolean | null,
  order_points?: number | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  transferred_date?: string | null,
  order_price?: number | null,
  origin_order_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  cashback?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  billing_count?: number | null,
  return_due_date?: string | null,
  bill_payment_status?: BillPaymentStatus | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
};

export type ModelOrderConditionInput = {
  order_owner?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  brand_owner?: ModelIDInput | null,
  campaign_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  stripe_client_secret?: ModelStringInput | null,
  stripe_payment_method_id?: ModelStringInput | null,
  fee?: ModelIntInput | null,
  shipping_fee?: ModelIntInput | null,
  invoice_shipping_fee?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  payment_term?: ModelIntInput | null,
  shipping_zip_code?: ModelStringInput | null,
  shipping_address?: ModelStringInput | null,
  shipping_name?: ModelStringInput | null,
  shipping_phone_number?: ModelStringInput | null,
  shipping_date?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  tracking_number?: ModelStringInput | null,
  first_order?: ModelBooleanInput | null,
  order_points?: ModelIntInput | null,
  is_direct?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  transferred_date?: ModelStringInput | null,
  order_price?: ModelIntInput | null,
  origin_order_id?: ModelIDInput | null,
  mf_authorization_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  mf_canceled_transaction_id?: ModelStringInput | null,
  cashback?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  billing_count?: ModelIntInput | null,
  return_due_date?: ModelStringInput | null,
  bill_payment_status?: ModelBillPaymentStatusInput | null,
  memo?: ModelStringInput | null,
  split_source_id?: ModelIDInput | null,
  split_date?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type ModelBillPaymentStatusInput = {
  eq?: BillPaymentStatus | null,
  ne?: BillPaymentStatus | null,
};

export type UpdateOrderInput = {
  id: string,
  order_owner?: string | null,
  brand_id?: string | null,
  brand_owner?: string | null,
  campaign_code?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owners?: Array< string | null > | null,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  fee?: number | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax?: number | null,
  payment_term?: number | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  shipping_date?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  first_order?: boolean | null,
  order_points?: number | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  transferred_date?: string | null,
  order_price?: number | null,
  origin_order_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  cashback?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  billing_count?: number | null,
  return_due_date?: string | null,
  bill_payment_status?: BillPaymentStatus | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreateChargeStatusInput = {
  id?: string | null,
  order_id: string,
  status: number,
  payment_id?: string | null,
  amount?: number | null,
  createdAt?: string | null,
  owners: Array< string | null >,
};

export type ModelChargeStatusConditionInput = {
  order_id?: ModelIDInput | null,
  status?: ModelIntInput | null,
  payment_id?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelChargeStatusConditionInput | null > | null,
  or?: Array< ModelChargeStatusConditionInput | null > | null,
  not?: ModelChargeStatusConditionInput | null,
};

export type UpdateChargeStatusInput = {
  id: string,
  order_id?: string | null,
  status?: number | null,
  payment_id?: string | null,
  amount?: number | null,
  createdAt?: string | null,
  owners?: Array< string | null > | null,
};

export type DeleteChargeStatusInput = {
  id: string,
};

export type CreateOrderProductInput = {
  id?: string | null,
  order_id: string,
  product_id: string,
  product_type_id?: string | null,
  order_product_quantity?: number | null,
  order_product_price?: number | null,
  order_product_wholesale_rate?: number | null,
  discount_percentage?: number | null,
  initial_order_product_quantity?: number | null,
  initial_order_product_price?: number | null,
  initial_order_product_wholesale_rate?: number | null,
  order_product_payment_status?: number | null,
  order_product_payment_method?: number | null,
  order_product_return_reason?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
};

export type ModelOrderProductConditionInput = {
  order_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  order_product_quantity?: ModelIntInput | null,
  order_product_price?: ModelFloatInput | null,
  order_product_wholesale_rate?: ModelFloatInput | null,
  discount_percentage?: ModelFloatInput | null,
  initial_order_product_quantity?: ModelIntInput | null,
  initial_order_product_price?: ModelFloatInput | null,
  initial_order_product_wholesale_rate?: ModelFloatInput | null,
  order_product_payment_status?: ModelIntInput | null,
  order_product_payment_method?: ModelIntInput | null,
  order_product_return_reason?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderProductConditionInput | null > | null,
  or?: Array< ModelOrderProductConditionInput | null > | null,
  not?: ModelOrderProductConditionInput | null,
};

export type UpdateOrderProductInput = {
  id: string,
  order_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  order_product_quantity?: number | null,
  order_product_price?: number | null,
  order_product_wholesale_rate?: number | null,
  discount_percentage?: number | null,
  initial_order_product_quantity?: number | null,
  initial_order_product_price?: number | null,
  initial_order_product_wholesale_rate?: number | null,
  order_product_payment_status?: number | null,
  order_product_payment_method?: number | null,
  order_product_return_reason?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteOrderProductInput = {
  id: string,
};

export type CreateOrderStatusInput = {
  id?: string | null,
  order_id?: string | null,
  order_product_id: string,
  quantity?: number | null,
  status?: number | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOrderStatusConditionInput = {
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderStatusConditionInput | null > | null,
  or?: Array< ModelOrderStatusConditionInput | null > | null,
  not?: ModelOrderStatusConditionInput | null,
};

export type UpdateOrderStatusInput = {
  id: string,
  order_id?: string | null,
  order_product_id?: string | null,
  quantity?: number | null,
  status?: number | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOrderStatusInput = {
  id: string,
};

export type CreateReturnProductInput = {
  id?: string | null,
  return_product_id?: string | null,
  order_id: string,
  order_product_id: string,
  return_quantity?: number | null,
  return_product_price?: number | null,
  return_status?: number | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  logiless_inbound_delivery_code?: string | null,
};

export type ModelReturnProductConditionInput = {
  return_product_id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  return_quantity?: ModelIntInput | null,
  return_product_price?: ModelIntInput | null,
  return_status?: ModelIntInput | null,
  stripe_payment_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  logiless_inbound_delivery_code?: ModelStringInput | null,
  and?: Array< ModelReturnProductConditionInput | null > | null,
  or?: Array< ModelReturnProductConditionInput | null > | null,
  not?: ModelReturnProductConditionInput | null,
};

export type UpdateReturnProductInput = {
  id: string,
  return_product_id?: string | null,
  order_id?: string | null,
  order_product_id?: string | null,
  return_quantity?: number | null,
  return_product_price?: number | null,
  return_status?: number | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  logiless_inbound_delivery_code?: string | null,
};

export type DeleteReturnProductInput = {
  id: string,
};

export type CreateReportProductInput = {
  id?: string | null,
  type?: string | null,
  order_id: string,
  order_product_id: string,
  report_type?: string | null,
  request_type?: string | null,
  report_quantity?: number | null,
  report_product_price?: number | null,
  report_status?: string | null,
  report_comment?: string | null,
  replace_order_id?: string | null,
  replace_order_product_id?: string | null,
  approval_date?: string | null,
  approval_quantity?: number | null,
  image_keys?: Array< string | null > | null,
  stripe_refund_id?: string | null,
  owners: Array< string | null >,
  createdAt?: string | null,
};

export type ModelReportProductConditionInput = {
  type?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  report_type?: ModelStringInput | null,
  request_type?: ModelStringInput | null,
  report_quantity?: ModelIntInput | null,
  report_product_price?: ModelIntInput | null,
  report_status?: ModelStringInput | null,
  report_comment?: ModelStringInput | null,
  replace_order_id?: ModelIDInput | null,
  replace_order_product_id?: ModelIDInput | null,
  approval_date?: ModelStringInput | null,
  approval_quantity?: ModelIntInput | null,
  image_keys?: ModelStringInput | null,
  stripe_refund_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReportProductConditionInput | null > | null,
  or?: Array< ModelReportProductConditionInput | null > | null,
  not?: ModelReportProductConditionInput | null,
};

export type UpdateReportProductInput = {
  id: string,
  type?: string | null,
  order_id?: string | null,
  order_product_id?: string | null,
  report_type?: string | null,
  request_type?: string | null,
  report_quantity?: number | null,
  report_product_price?: number | null,
  report_status?: string | null,
  report_comment?: string | null,
  replace_order_id?: string | null,
  replace_order_product_id?: string | null,
  approval_date?: string | null,
  approval_quantity?: number | null,
  image_keys?: Array< string | null > | null,
  stripe_refund_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteReportProductInput = {
  id: string,
};

export type CreateFeedbackInput = {
  id?: string | null,
  order_id: string,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type ModelFeedbackConditionInput = {
  order_id?: ModelIDInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeedbackConditionInput | null > | null,
  or?: Array< ModelFeedbackConditionInput | null > | null,
  not?: ModelFeedbackConditionInput | null,
};

export type Feedback = {
  __typename: "Feedback",
  id: string,
  order_id: string,
  feedback_comment?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  order?: Order | null,
};

export type UpdateFeedbackInput = {
  id: string,
  order_id?: string | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type DeleteFeedbackInput = {
  id: string,
};

export type CreateOrderEditReasonInput = {
  id?: string | null,
  order_id: string,
  edit_reason?: EditReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type ModelOrderEditReasonConditionInput = {
  order_id?: ModelIDInput | null,
  edit_reason?: ModelEditReasonInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderEditReasonConditionInput | null > | null,
  or?: Array< ModelOrderEditReasonConditionInput | null > | null,
  not?: ModelOrderEditReasonConditionInput | null,
};

export type ModelEditReasonInput = {
  eq?: EditReason | null,
  ne?: EditReason | null,
};

export type UpdateOrderEditReasonInput = {
  id: string,
  order_id?: string | null,
  edit_reason?: EditReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type DeleteOrderEditReasonInput = {
  id: string,
};

export type CreateOrderCancelReasonInput = {
  id?: string | null,
  order_id: string,
  cancel_reason?: CancelReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type ModelOrderCancelReasonConditionInput = {
  order_id?: ModelIDInput | null,
  cancel_reason?: ModelCancelReasonInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderCancelReasonConditionInput | null > | null,
  or?: Array< ModelOrderCancelReasonConditionInput | null > | null,
  not?: ModelOrderCancelReasonConditionInput | null,
};

export type ModelCancelReasonInput = {
  eq?: CancelReason | null,
  ne?: CancelReason | null,
};

export type UpdateOrderCancelReasonInput = {
  id: string,
  order_id?: string | null,
  cancel_reason?: CancelReason | null,
  feedback_comment?: string | null,
  createdAt?: string | null,
};

export type DeleteOrderCancelReasonInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  thread_id: string,
  message_owner?: string | null,
  message_recipient?: string | null,
  message_content: string,
  message_product_name?: string | null,
  message_is_open: boolean,
  message_files?: Array< MessageFileInput | null > | null,
  owners: Array< string | null >,
  createdAt?: string | null,
};

export type MessageFileInput = {
  object_key: string,
  file_name: string,
  file_type: string,
};

export type ModelMessageConditionInput = {
  thread_id?: ModelIDInput | null,
  message_owner?: ModelStringInput | null,
  message_recipient?: ModelIDInput | null,
  message_content?: ModelStringInput | null,
  message_product_name?: ModelStringInput | null,
  message_is_open?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  thread_id: string,
  message_owner?: string | null,
  message_recipient?: string | null,
  message_content: string,
  message_product_name?: string | null,
  message_is_open: boolean,
  message_files?:  Array<MessageFile | null > | null,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt: string,
  thread?: Thread | null,
};

export type MessageFile = {
  __typename: "MessageFile",
  object_key: string,
  file_name: string,
  file_type: string,
};

export type Thread = {
  __typename: "Thread",
  id: string,
  thread_buyer_owner: string,
  thread_buyer_name?: string | null,
  thread_brand_owner: string,
  thread_brand_id: string,
  owners: Array< string | null >,
  createdAt?: string | null,
  updatedAt: string,
  brand?: Brand | null,
  messages?: ModelMessageConnection | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type UpdateMessageInput = {
  id: string,
  thread_id?: string | null,
  message_owner?: string | null,
  message_recipient?: string | null,
  message_content?: string | null,
  message_product_name?: string | null,
  message_is_open?: boolean | null,
  message_files?: Array< MessageFileInput | null > | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateThreadInput = {
  id?: string | null,
  thread_buyer_owner: string,
  thread_buyer_name?: string | null,
  thread_brand_owner: string,
  thread_brand_id: string,
  owners: Array< string | null >,
  createdAt?: string | null,
};

export type ModelThreadConditionInput = {
  thread_buyer_owner?: ModelStringInput | null,
  thread_buyer_name?: ModelStringInput | null,
  thread_brand_owner?: ModelStringInput | null,
  thread_brand_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelThreadConditionInput | null > | null,
  or?: Array< ModelThreadConditionInput | null > | null,
  not?: ModelThreadConditionInput | null,
};

export type UpdateThreadInput = {
  id: string,
  thread_buyer_owner?: string | null,
  thread_buyer_name?: string | null,
  thread_brand_owner?: string | null,
  thread_brand_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteThreadInput = {
  id: string,
};

export type CreatePickupProductInput = {
  id?: string | null,
  pickup_list_id: string,
  brand_id: string,
  product_id: string,
  display_order?: number | null,
  createdAt?: string | null,
};

export type ModelPickupProductConditionInput = {
  pickup_list_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  display_order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPickupProductConditionInput | null > | null,
  or?: Array< ModelPickupProductConditionInput | null > | null,
  not?: ModelPickupProductConditionInput | null,
};

export type PickupProduct = {
  __typename: "PickupProduct",
  id: string,
  pickup_list_id: string,
  brand_id: string,
  product_id: string,
  display_order?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  brand?: Brand | null,
};

export type UpdatePickupProductInput = {
  id: string,
  pickup_list_id?: string | null,
  brand_id?: string | null,
  product_id?: string | null,
  display_order?: number | null,
  createdAt?: string | null,
};

export type DeletePickupProductInput = {
  id: string,
};

export type CreatePickupBrandsInput = {
  id?: string | null,
  title: string,
  brands?: Array< PickupBrandInput | null > | null,
  display_num?: number | null,
  visible?: boolean | null,
  item_type?: PickupItemType | null,
  display_size?: number | null,
};

export type PickupBrandInput = {
  brand_id: string,
  display_order?: number | null,
};

export enum PickupItemType {
  BRAND = "BRAND",
  PRODUCT = "PRODUCT",
}


export type ModelPickupBrandsConditionInput = {
  title?: ModelStringInput | null,
  display_num?: ModelIntInput | null,
  visible?: ModelBooleanInput | null,
  item_type?: ModelPickupItemTypeInput | null,
  display_size?: ModelIntInput | null,
  and?: Array< ModelPickupBrandsConditionInput | null > | null,
  or?: Array< ModelPickupBrandsConditionInput | null > | null,
  not?: ModelPickupBrandsConditionInput | null,
};

export type ModelPickupItemTypeInput = {
  eq?: PickupItemType | null,
  ne?: PickupItemType | null,
};

export type PickupBrands = {
  __typename: "PickupBrands",
  id: string,
  title: string,
  brands?:  Array<PickupBrand | null > | null,
  display_num?: number | null,
  visible?: boolean | null,
  item_type?: PickupItemType | null,
  display_size?: number | null,
  createdAt: string,
  updatedAt: string,
  products?: ModelPickupProductConnection | null,
};

export type PickupBrand = {
  __typename: "PickupBrand",
  brand_id: string,
  display_order?: number | null,
  brand?: Brand | null,
};

export type ModelPickupProductConnection = {
  __typename: "ModelPickupProductConnection",
  items:  Array<PickupProduct | null >,
  nextToken?: string | null,
};

export type UpdatePickupBrandsInput = {
  id: string,
  title?: string | null,
  brands?: Array< PickupBrandInput | null > | null,
  display_num?: number | null,
  visible?: boolean | null,
  item_type?: PickupItemType | null,
  display_size?: number | null,
};

export type DeletePickupBrandsInput = {
  id: string,
};

export type CreateMenuInput = {
  id?: string | null,
  title: string,
  product_list_path: string,
  brand_list_path?: string | null,
  search_categories?: Array< CategoryInput | null > | null,
  sub_categories?: Array< CategoryInput | null > | null,
  display_order?: number | null,
};

export type CategoryInput = {
  name: string,
  path?: string | null,
};

export type ModelMenuConditionInput = {
  title?: ModelStringInput | null,
  product_list_path?: ModelStringInput | null,
  brand_list_path?: ModelStringInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelMenuConditionInput | null > | null,
  or?: Array< ModelMenuConditionInput | null > | null,
  not?: ModelMenuConditionInput | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  title: string,
  product_list_path: string,
  brand_list_path?: string | null,
  search_categories?:  Array<Category | null > | null,
  sub_categories?:  Array<Category | null > | null,
  display_order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type Category = {
  __typename: "Category",
  name: string,
  path?: string | null,
};

export type UpdateMenuInput = {
  id: string,
  title?: string | null,
  product_list_path?: string | null,
  brand_list_path?: string | null,
  search_categories?: Array< CategoryInput | null > | null,
  sub_categories?: Array< CategoryInput | null > | null,
  display_order?: number | null,
};

export type DeleteMenuInput = {
  id: string,
};

export type CreateFavoriteBrandInput = {
  id?: string | null,
  account_id: string,
  brand_id: string,
  createdAt?: string | null,
};

export type ModelFavoriteBrandConditionInput = {
  account_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteBrandConditionInput | null > | null,
  or?: Array< ModelFavoriteBrandConditionInput | null > | null,
  not?: ModelFavoriteBrandConditionInput | null,
};

export type FavoriteBrand = {
  __typename: "FavoriteBrand",
  id: string,
  account_id: string,
  brand_id: string,
  createdAt?: string | null,
  updatedAt: string,
  brand?: Brand | null,
  owner?: string | null,
};

export type UpdateFavoriteBrandInput = {
  id: string,
  account_id?: string | null,
  brand_id?: string | null,
  createdAt?: string | null,
};

export type DeleteFavoriteBrandInput = {
  id: string,
};

export type CreateFavoriteProductInput = {
  id?: string | null,
  account_id: string,
  favorite_list_id?: string | null,
  product_id: string,
  order?: number | null,
  createdAt?: string | null,
};

export type ModelFavoriteProductConditionInput = {
  account_id?: ModelIDInput | null,
  favorite_list_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteProductConditionInput | null > | null,
  or?: Array< ModelFavoriteProductConditionInput | null > | null,
  not?: ModelFavoriteProductConditionInput | null,
};

export type FavoriteProduct = {
  __typename: "FavoriteProduct",
  id: string,
  account_id: string,
  favorite_list_id?: string | null,
  product_id: string,
  order?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  product?: Product | null,
  owner?: string | null,
  favorite_list?: FavoriteProductList | null,
};

export type FavoriteProductList = {
  __typename: "FavoriteProductList",
  id: string,
  account_id: string,
  list_name?: string | null,
  order?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  products?: ModelFavoriteProductConnection | null,
  owner?: string | null,
};

export type ModelFavoriteProductConnection = {
  __typename: "ModelFavoriteProductConnection",
  items:  Array<FavoriteProduct | null >,
  nextToken?: string | null,
};

export type UpdateFavoriteProductInput = {
  id: string,
  account_id?: string | null,
  favorite_list_id?: string | null,
  product_id?: string | null,
  order?: number | null,
  createdAt?: string | null,
};

export type DeleteFavoriteProductInput = {
  id: string,
};

export type CreateFavoriteProductListInput = {
  id?: string | null,
  account_id: string,
  list_name?: string | null,
  order?: number | null,
  createdAt?: string | null,
};

export type ModelFavoriteProductListConditionInput = {
  account_id?: ModelIDInput | null,
  list_name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteProductListConditionInput | null > | null,
  or?: Array< ModelFavoriteProductListConditionInput | null > | null,
  not?: ModelFavoriteProductListConditionInput | null,
};

export type UpdateFavoriteProductListInput = {
  id: string,
  account_id?: string | null,
  list_name?: string | null,
  order?: number | null,
  createdAt?: string | null,
};

export type DeleteFavoriteProductListInput = {
  id: string,
};

export type CreateCollectionInput = {
  id?: string | null,
  brand_id: string,
  src_brand_id?: string | null,
  name: string,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner: string,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  display_order?: number | null,
};

export type ModelCollectionConditionInput = {
  brand_id?: ModelIDInput | null,
  src_brand_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  video_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  preorder?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  season?: ModelStringInput | null,
  season_year?: ModelIntInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelCollectionConditionInput | null > | null,
  or?: Array< ModelCollectionConditionInput | null > | null,
  not?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionInput = {
  id: string,
  brand_id?: string | null,
  src_brand_id?: string | null,
  name?: string | null,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  display_order?: number | null,
};

export type DeleteCollectionInput = {
  id: string,
};

export type CreateProductCollectionInput = {
  id?: string | null,
  collection_id: string,
  product_id: string,
  product_type_id?: string | null,
  quantity: number,
  sort_order?: number | null,
  owner: string,
};

export type ModelProductCollectionConditionInput = {
  collection_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  sort_order?: ModelIntInput | null,
  and?: Array< ModelProductCollectionConditionInput | null > | null,
  or?: Array< ModelProductCollectionConditionInput | null > | null,
  not?: ModelProductCollectionConditionInput | null,
};

export type UpdateProductCollectionInput = {
  id: string,
  collection_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  quantity?: number | null,
  sort_order?: number | null,
  owner?: string | null,
};

export type DeleteProductCollectionInput = {
  id: string,
};

export type CreateReferralInput = {
  id?: string | null,
  referral_type: number,
  referral_email?: string | null,
  referral_name?: string | null,
  referral_website?: string | null,
  referral_domain?: string | null,
  referral_by: string,
  referral_status: number,
  referral_brand_id?: string | null,
  referral_account_id?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type ModelReferralConditionInput = {
  referral_type?: ModelIntInput | null,
  referral_email?: ModelStringInput | null,
  referral_name?: ModelStringInput | null,
  referral_website?: ModelStringInput | null,
  referral_domain?: ModelStringInput | null,
  referral_by?: ModelStringInput | null,
  referral_status?: ModelIntInput | null,
  referral_brand_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReferralConditionInput | null > | null,
  or?: Array< ModelReferralConditionInput | null > | null,
  not?: ModelReferralConditionInput | null,
};

export type Referral = {
  __typename: "Referral",
  id: string,
  referral_type: number,
  referral_email?: string | null,
  referral_name?: string | null,
  referral_website?: string | null,
  referral_domain?: string | null,
  referral_by: string,
  referral_status: number,
  referral_brand_id?: string | null,
  referral_account_id?: string | null,
  createdAt: string,
  owner?: string | null,
  updatedAt: string,
  ownerAccount?: Account | null,
};

export type UpdateReferralInput = {
  id: string,
  referral_type?: number | null,
  referral_email?: string | null,
  referral_name?: string | null,
  referral_website?: string | null,
  referral_domain?: string | null,
  referral_by?: string | null,
  referral_status?: number | null,
  referral_brand_id?: string | null,
  referral_account_id?: string | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteReferralInput = {
  id: string,
};

export type CreateReferralMessageInput = {
  id?: string | null,
  from: string,
  subject: string,
  message: string,
  owner: string,
};

export type ModelReferralMessageConditionInput = {
  from?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  and?: Array< ModelReferralMessageConditionInput | null > | null,
  or?: Array< ModelReferralMessageConditionInput | null > | null,
  not?: ModelReferralMessageConditionInput | null,
};

export type ReferralMessage = {
  __typename: "ReferralMessage",
  id: string,
  from: string,
  subject: string,
  message: string,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReferralMessageInput = {
  id: string,
  from?: string | null,
  subject?: string | null,
  message?: string | null,
  owner?: string | null,
};

export type DeleteReferralMessageInput = {
  id: string,
};

export type CreatePointInput = {
  id?: string | null,
  account_id: string,
  points: number,
  point_type: number,
  order_id?: string | null,
  referral_id?: string | null,
  referral_brand_id?: string | null,
  createdAt?: string | null,
  duration: number,
  review_id?: string | null,
};

export type ModelPointConditionInput = {
  points?: ModelIntInput | null,
  point_type?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  referral_id?: ModelIDInput | null,
  referral_brand_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  review_id?: ModelIDInput | null,
  and?: Array< ModelPointConditionInput | null > | null,
  or?: Array< ModelPointConditionInput | null > | null,
  not?: ModelPointConditionInput | null,
};

export type Point = {
  __typename: "Point",
  id: string,
  account_id: string,
  points: number,
  point_type: number,
  order_id?: string | null,
  referral_id?: string | null,
  referral_brand_id?: string | null,
  createdAt: string,
  duration: number,
  review_id?: string | null,
  updatedAt: string,
  brand?: Brand | null,
  order?: Order | null,
  referral?: Referral | null,
  history?: ModelPointHistoryConnection | null,
};

export type ModelPointHistoryConnection = {
  __typename: "ModelPointHistoryConnection",
  items:  Array<PointHistory | null >,
  nextToken?: string | null,
};

export type PointHistory = {
  __typename: "PointHistory",
  id: string,
  point_id: string,
  account_id: string,
  points: number,
  history_type: number,
  order_id?: string | null,
  createdAt: string,
  updatedAt: string,
  order?: Order | null,
};

export type UpdatePointInput = {
  id: string,
  account_id?: string | null,
  points?: number | null,
  point_type?: number | null,
  order_id?: string | null,
  referral_id?: string | null,
  referral_brand_id?: string | null,
  createdAt?: string | null,
  duration?: number | null,
  review_id?: string | null,
};

export type DeletePointInput = {
  id: string,
};

export type CreatePointHistoryInput = {
  id?: string | null,
  point_id: string,
  account_id: string,
  points: number,
  history_type: number,
  order_id?: string | null,
  createdAt?: string | null,
};

export type ModelPointHistoryConditionInput = {
  point_id?: ModelIDInput | null,
  points?: ModelIntInput | null,
  history_type?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPointHistoryConditionInput | null > | null,
  or?: Array< ModelPointHistoryConditionInput | null > | null,
  not?: ModelPointHistoryConditionInput | null,
};

export type UpdatePointHistoryInput = {
  id: string,
  point_id?: string | null,
  account_id?: string | null,
  points?: number | null,
  history_type?: number | null,
  order_id?: string | null,
  createdAt?: string | null,
};

export type DeletePointHistoryInput = {
  id: string,
};

export type CreateContactInput = {
  id?: string | null,
  supplier_id: string,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt?: string | null,
  owner: string,
};

export type ModelContactConditionInput = {
  supplier_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  store_name?: ModelStringInput | null,
  contact_name?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  address?: ModelStringInput | null,
  discount_rate?: ModelIntInput | null,
  contact_status?: ModelStringInput | null,
  contact_source?: ModelStringInput | null,
  direct_payment?: ModelBooleanInput | null,
  contacted?: ModelBooleanInput | null,
  signedup?: ModelBooleanInput | null,
  ordered?: ModelBooleanInput | null,
  invited?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelContactConditionInput | null > | null,
  or?: Array< ModelContactConditionInput | null > | null,
  not?: ModelContactConditionInput | null,
};

export type UpdateContactInput = {
  id: string,
  supplier_id?: string | null,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
};

export type DeleteContactInput = {
  id: string,
};

export type CreateContactListInput = {
  id?: string | null,
  list_name: string,
  supplier_id: string,
  order?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner: string,
};

export type ModelContactListConditionInput = {
  list_name?: ModelStringInput | null,
  supplier_id?: ModelIDInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContactListConditionInput | null > | null,
  or?: Array< ModelContactListConditionInput | null > | null,
  not?: ModelContactListConditionInput | null,
};

export type UpdateContactListInput = {
  id: string,
  list_name?: string | null,
  supplier_id?: string | null,
  order?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeleteContactListInput = {
  id: string,
};

export type CreateContactListConnectionInput = {
  id?: string | null,
  supplier_id: string,
  contact_list_id: string,
  contact_id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner: string,
};

export type ModelContactListConnectionConditionInput = {
  supplier_id?: ModelIDInput | null,
  contact_list_id?: ModelIDInput | null,
  contact_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContactListConnectionConditionInput | null > | null,
  or?: Array< ModelContactListConnectionConditionInput | null > | null,
  not?: ModelContactListConnectionConditionInput | null,
};

export type UpdateContactListConnectionInput = {
  id: string,
  supplier_id?: string | null,
  contact_list_id?: string | null,
  contact_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type DeleteContactListConnectionInput = {
  id: string,
};

export type CreateContactActivityInput = {
  id?: string | null,
  contact_id: string,
  activity_date: string,
  activity_type?: string | null,
  order_id?: string | null,
  message_id?: string | null,
  campaign_id?: string | null,
  activity?: string | null,
  owner?: string | null,
};

export type ModelContactActivityConditionInput = {
  contact_id?: ModelIDInput | null,
  activity_date?: ModelStringInput | null,
  activity_type?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  message_id?: ModelIDInput | null,
  campaign_id?: ModelIDInput | null,
  activity?: ModelStringInput | null,
  and?: Array< ModelContactActivityConditionInput | null > | null,
  or?: Array< ModelContactActivityConditionInput | null > | null,
  not?: ModelContactActivityConditionInput | null,
};

export type UpdateContactActivityInput = {
  id: string,
  contact_id?: string | null,
  activity_date?: string | null,
  activity_type?: string | null,
  order_id?: string | null,
  message_id?: string | null,
  campaign_id?: string | null,
  activity?: string | null,
  owner?: string | null,
};

export type DeleteContactActivityInput = {
  id: string,
};

export type CreateCampaignInput = {
  id?: string | null,
  supplier_id: string,
  brand_id: string,
  campaign_sender?: string | null,
  campaign_from?: string | null,
  campaign_title?: string | null,
  campaign_status?: string | null,
  campaign_subject?: string | null,
  preview_text?: string | null,
  campaign_template?: string | null,
  campaign_message?: string | null,
  campaign_components?: string | null,
  campaign_style?: string | null,
  campaign_segments?: Array< string | null > | null,
  deliveryTime?: string | null,
  sentAt?: string | null,
  createdAt?: string | null,
  owner: string,
  stats?: CampaignStatsInput | null,
};

export type CampaignStatsInput = {
  total?: number | null,
  sent?: number | null,
  delivered?: number | null,
  unsent?: number | null,
  open?: number | null,
  click?: number | null,
  bounce?: number | null,
  unsubscribe?: number | null,
  clickRate?: number | null,
  openRate?: number | null,
};

export type ModelCampaignConditionInput = {
  supplier_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  campaign_sender?: ModelStringInput | null,
  campaign_from?: ModelStringInput | null,
  campaign_title?: ModelStringInput | null,
  campaign_status?: ModelStringInput | null,
  campaign_subject?: ModelStringInput | null,
  preview_text?: ModelStringInput | null,
  campaign_template?: ModelStringInput | null,
  campaign_message?: ModelStringInput | null,
  campaign_components?: ModelStringInput | null,
  campaign_style?: ModelStringInput | null,
  campaign_segments?: ModelStringInput | null,
  deliveryTime?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCampaignConditionInput | null > | null,
  or?: Array< ModelCampaignConditionInput | null > | null,
  not?: ModelCampaignConditionInput | null,
};

export type UpdateCampaignInput = {
  id: string,
  supplier_id?: string | null,
  brand_id?: string | null,
  campaign_sender?: string | null,
  campaign_from?: string | null,
  campaign_title?: string | null,
  campaign_status?: string | null,
  campaign_subject?: string | null,
  preview_text?: string | null,
  campaign_template?: string | null,
  campaign_message?: string | null,
  campaign_components?: string | null,
  campaign_style?: string | null,
  campaign_segments?: Array< string | null > | null,
  deliveryTime?: string | null,
  sentAt?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  stats?: CampaignStatsInput | null,
};

export type DeleteCampaignInput = {
  id: string,
};

export type CreateCampaignContactInput = {
  id?: string | null,
  supplier_id: string,
  campaign_id?: string | null,
  contact_id?: string | null,
  email?: string | null,
  source?: string | null,
  contact_list_id?: string | null,
  status?: string | null,
  clicks?: number | null,
  views?: number | null,
  sentAt?: string | null,
  owner: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCampaignContactConditionInput = {
  supplier_id?: ModelIDInput | null,
  campaign_id?: ModelIDInput | null,
  contact_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  source?: ModelStringInput | null,
  contact_list_id?: ModelStringInput | null,
  status?: ModelStringInput | null,
  clicks?: ModelIntInput | null,
  views?: ModelIntInput | null,
  sentAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCampaignContactConditionInput | null > | null,
  or?: Array< ModelCampaignContactConditionInput | null > | null,
  not?: ModelCampaignContactConditionInput | null,
};

export type UpdateCampaignContactInput = {
  id: string,
  supplier_id?: string | null,
  campaign_id?: string | null,
  contact_id?: string | null,
  email?: string | null,
  source?: string | null,
  contact_list_id?: string | null,
  status?: string | null,
  clicks?: number | null,
  views?: number | null,
  sentAt?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCampaignContactInput = {
  id: string,
};

export type CreateDailySessionInput = {
  brand_id: string,
  session_date: string,
  session_count?: number | null,
};

export type ModelDailySessionConditionInput = {
  session_count?: ModelIntInput | null,
  and?: Array< ModelDailySessionConditionInput | null > | null,
  or?: Array< ModelDailySessionConditionInput | null > | null,
  not?: ModelDailySessionConditionInput | null,
};

export type DailySession = {
  __typename: "DailySession",
  brand_id: string,
  session_date: string,
  session_count?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDailySessionInput = {
  brand_id: string,
  session_date: string,
  session_count?: number | null,
};

export type DeleteDailySessionInput = {
  brand_id: string,
  session_date: string,
};

export type CreateConversionRateInput = {
  brand_id: string,
  order?: number | null,
  click?: number | null,
  conversion?: number | null,
};

export type ModelConversionRateConditionInput = {
  order?: ModelIntInput | null,
  click?: ModelIntInput | null,
  conversion?: ModelFloatInput | null,
  and?: Array< ModelConversionRateConditionInput | null > | null,
  or?: Array< ModelConversionRateConditionInput | null > | null,
  not?: ModelConversionRateConditionInput | null,
};

export type ConversionRate = {
  __typename: "ConversionRate",
  brand_id: string,
  order?: number | null,
  click?: number | null,
  conversion?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateConversionRateInput = {
  brand_id: string,
  order?: number | null,
  click?: number | null,
  conversion?: number | null,
};

export type DeleteConversionRateInput = {
  brand_id: string,
};

export type CreatePageViewInput = {
  brand_id: string,
  buyer_id: string,
  email?: string | null,
  page_view?: number | null,
  owner?: string | null,
};

export type ModelPageViewConditionInput = {
  email?: ModelStringInput | null,
  page_view?: ModelIntInput | null,
  and?: Array< ModelPageViewConditionInput | null > | null,
  or?: Array< ModelPageViewConditionInput | null > | null,
  not?: ModelPageViewConditionInput | null,
};

export type UpdatePageViewInput = {
  brand_id: string,
  buyer_id: string,
  email?: string | null,
  page_view?: number | null,
  owner?: string | null,
};

export type DeletePageViewInput = {
  brand_id: string,
  buyer_id: string,
};

export type CreateReviewInput = {
  id?: string | null,
  brand_id: string,
  product_ids?: Array< string | null > | null,
  buyer_id: string,
  order_id: string,
  rate?: number | null,
  title?: string | null,
  comment?: string | null,
  remove_flag?: boolean | null,
  createdAt?: string | null,
};

export type ModelReviewConditionInput = {
  brand_id?: ModelIDInput | null,
  product_ids?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  rate?: ModelIntInput | null,
  title?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  remove_flag?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type UpdateReviewInput = {
  id: string,
  brand_id?: string | null,
  product_ids?: Array< string | null > | null,
  buyer_id?: string | null,
  order_id?: string | null,
  rate?: number | null,
  title?: string | null,
  comment?: string | null,
  remove_flag?: boolean | null,
  createdAt?: string | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateReviewReplyInput = {
  id?: string | null,
  review_id: string,
  brand_id: string,
  comment?: string | null,
  createdAt?: string | null,
};

export type ModelReviewReplyConditionInput = {
  review_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReviewReplyConditionInput | null > | null,
  or?: Array< ModelReviewReplyConditionInput | null > | null,
  not?: ModelReviewReplyConditionInput | null,
};

export type UpdateReviewReplyInput = {
  id: string,
  review_id?: string | null,
  brand_id?: string | null,
  comment?: string | null,
  createdAt?: string | null,
};

export type DeleteReviewReplyInput = {
  id: string,
};

export type CreateCouponInput = {
  id?: string | null,
  coupon_name?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  brand_ids?: Array< string | null > | null,
  discount_type?: DiscountType | null,
  discount_value?: number | null,
  maximum_amount?: number | null,
  available_times?: number | null,
  duration?: number | null,
  createdAt?: string | null,
};

export type ModelCouponConditionInput = {
  coupon_name?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  brand_ids?: ModelStringInput | null,
  discount_type?: ModelDiscountTypeInput | null,
  discount_value?: ModelFloatInput | null,
  maximum_amount?: ModelFloatInput | null,
  available_times?: ModelIntInput | null,
  duration?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCouponConditionInput | null > | null,
  or?: Array< ModelCouponConditionInput | null > | null,
  not?: ModelCouponConditionInput | null,
};

export type ModelDiscountTypeInput = {
  eq?: DiscountType | null,
  ne?: DiscountType | null,
};

export type UpdateCouponInput = {
  id: string,
  coupon_name?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  brand_ids?: Array< string | null > | null,
  discount_type?: DiscountType | null,
  discount_value?: number | null,
  maximum_amount?: number | null,
  available_times?: number | null,
  duration?: number | null,
  createdAt?: string | null,
};

export type DeleteCouponInput = {
  id: string,
};

export type CreateCouponLogInput = {
  id?: string | null,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_ids?: Array< string | null > | null,
  createdAt?: string | null,
};

export type ModelCouponLogConditionInput = {
  coupon_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  order_ids?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCouponLogConditionInput | null > | null,
  or?: Array< ModelCouponLogConditionInput | null > | null,
  not?: ModelCouponLogConditionInput | null,
};

export type UpdateCouponLogInput = {
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_ids?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteCouponLogInput = {
  id: string,
};

export type CreateBuyerCouponInput = {
  id?: string | null,
  coupon_id?: string | null,
  buyer_id?: string | null,
  max_redemptions?: number | null,
  times_redeemed?: number | null,
  redemptions_left?: number | null,
  expiresAt?: string | null,
  createdAt?: string | null,
};

export type ModelBuyerCouponConditionInput = {
  coupon_id?: ModelIDInput | null,
  max_redemptions?: ModelIntInput | null,
  times_redeemed?: ModelIntInput | null,
  redemptions_left?: ModelIntInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBuyerCouponConditionInput | null > | null,
  or?: Array< ModelBuyerCouponConditionInput | null > | null,
  not?: ModelBuyerCouponConditionInput | null,
};

export type UpdateBuyerCouponInput = {
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  max_redemptions?: number | null,
  times_redeemed?: number | null,
  redemptions_left?: number | null,
  expiresAt?: string | null,
  createdAt?: string | null,
};

export type DeleteBuyerCouponInput = {
  id: string,
};

export type CreateCashbackInput = {
  id?: string | null,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_id?: Array< string | null > | null,
  redeemed?: boolean | null,
  createdAt?: string | null,
};

export type ModelCashbackConditionInput = {
  coupon_id?: ModelIDInput | null,
  order_id?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCashbackConditionInput | null > | null,
  or?: Array< ModelCashbackConditionInput | null > | null,
  not?: ModelCashbackConditionInput | null,
};

export type Cashback = {
  __typename: "Cashback",
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_id?: Array< string | null > | null,
  redeemed?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
  account?: Account | null,
  coupon?: Coupon | null,
  owner?: string | null,
};

export type UpdateCashbackInput = {
  id: string,
  coupon_id?: string | null,
  buyer_id?: string | null,
  order_id?: Array< string | null > | null,
  redeemed?: boolean | null,
  createdAt?: string | null,
};

export type DeleteCashbackInput = {
  id: string,
};

export type CreateStockProductInput = {
  id?: string | null,
  stock_id: string,
  src_product_id: string,
  src_product_type_id?: string | null,
  src_brand_id: string,
  product_id?: string | null,
  product_type_id?: string | null,
};

export type ModelStockProductConditionInput = {
  stock_id?: ModelIDInput | null,
  src_product_id?: ModelIDInput | null,
  src_product_type_id?: ModelIDInput | null,
  src_brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  and?: Array< ModelStockProductConditionInput | null > | null,
  or?: Array< ModelStockProductConditionInput | null > | null,
  not?: ModelStockProductConditionInput | null,
};

export type StockProduct = {
  __typename: "StockProduct",
  id: string,
  stock_id: string,
  src_product_id: string,
  src_product_type_id?: string | null,
  src_brand_id: string,
  product_id?: string | null,
  product_type_id?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStockProductInput = {
  id: string,
  stock_id?: string | null,
  src_product_id?: string | null,
  src_product_type_id?: string | null,
  src_brand_id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
};

export type DeleteStockProductInput = {
  id: string,
};

export type CreateLogilessAuthTokenInput = {
  id?: string | null,
  logiless_client_id: string,
  access_token: string,
  refresh_token: string,
  expires_at: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelLogilessAuthTokenConditionInput = {
  logiless_client_id?: ModelStringInput | null,
  access_token?: ModelStringInput | null,
  refresh_token?: ModelStringInput | null,
  expires_at?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLogilessAuthTokenConditionInput | null > | null,
  or?: Array< ModelLogilessAuthTokenConditionInput | null > | null,
  not?: ModelLogilessAuthTokenConditionInput | null,
};

export type LogilessAuthToken = {
  __typename: "LogilessAuthToken",
  id: string,
  logiless_client_id: string,
  access_token: string,
  refresh_token: string,
  expires_at: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateLogilessAuthTokenInput = {
  id: string,
  logiless_client_id?: string | null,
  access_token?: string | null,
  refresh_token?: string | null,
  expires_at?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteLogilessAuthTokenInput = {
  id: string,
};

export type CreateBuyerReadModelInput = {
  id?: string | null,
  account_company_name?: string | null,
  account_first_name?: string | null,
  account_id?: string | null,
  account_instagram_account?: string | null,
  account_last_name?: string | null,
  account_location?: string | null,
  account_tel?: string | null,
  account_web_site_URL?: string | null,
  account_zip_code?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_returen_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: string | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  primary_shipping_building?: string | null,
  primary_shipping_city?: string | null,
  primary_shipping_name?: string | null,
  primary_shipping_phone_number?: string | null,
  primary_shipping_prefecture?: string | null,
  primary_shipping_zip_code?: string | null,
  referral_brand_id?: string | null,
  referral_brand_name?: string | null,
  shop_image_keys?: Array< string | null > | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  total_order_amount?: number | null,
  total_order_amount_last_9m?: number | null,
  total_order_quantity?: number | null,
  total_order_quantity_last_9m?: number | null,
  total_return_amount?: number | null,
  total_return_amount_last_9m?: number | null,
  total_return_quantity?: number | null,
  total_return_quantity_last_9m?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelBuyerReadModelConditionInput = {
  account_company_name?: ModelStringInput | null,
  account_first_name?: ModelStringInput | null,
  account_id?: ModelIDInput | null,
  account_instagram_account?: ModelStringInput | null,
  account_last_name?: ModelStringInput | null,
  account_location?: ModelStringInput | null,
  account_tel?: ModelStringInput | null,
  account_web_site_URL?: ModelStringInput | null,
  account_zip_code?: ModelStringInput | null,
  billing_close_day?: ModelIntInput | null,
  buyer_brand?: ModelStringInput | null,
  buyer_credit?: ModelFloatInput | null,
  buyer_kyc_status?: ModelIntInput | null,
  buyer_product_category?: ModelStringInput | null,
  buyer_rating?: ModelFloatInput | null,
  buyer_returen_credit?: ModelFloatInput | null,
  buyer_return_limit_per_brand?: ModelFloatInput | null,
  buyer_type?: ModelStringInput | null,
  item_category?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  mf_customer_id?: ModelStringInput | null,
  mf_destination_id?: ModelStringInput | null,
  mf_examination_status?: ModelStringInput | null,
  owner_name?: ModelStringInput | null,
  payment_type?: ModelIntInput | null,
  primary_payment_method_id?: ModelIDInput | null,
  primary_payment_term?: ModelIntInput | null,
  primary_shipping_address_id?: ModelIDInput | null,
  primary_shipping_building?: ModelStringInput | null,
  primary_shipping_city?: ModelStringInput | null,
  primary_shipping_name?: ModelStringInput | null,
  primary_shipping_phone_number?: ModelStringInput | null,
  primary_shipping_prefecture?: ModelStringInput | null,
  primary_shipping_zip_code?: ModelStringInput | null,
  referral_brand_id?: ModelIDInput | null,
  referral_brand_name?: ModelStringInput | null,
  shop_image_keys?: ModelStringInput | null,
  shop_name?: ModelStringInput | null,
  store_category?: ModelStringInput | null,
  stripe_id?: ModelStringInput | null,
  total_order_amount?: ModelFloatInput | null,
  total_order_amount_last_9m?: ModelFloatInput | null,
  total_order_quantity?: ModelFloatInput | null,
  total_order_quantity_last_9m?: ModelFloatInput | null,
  total_return_amount?: ModelFloatInput | null,
  total_return_amount_last_9m?: ModelFloatInput | null,
  total_return_quantity?: ModelFloatInput | null,
  total_return_quantity_last_9m?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBuyerReadModelConditionInput | null > | null,
  or?: Array< ModelBuyerReadModelConditionInput | null > | null,
  not?: ModelBuyerReadModelConditionInput | null,
};

export type UpdateBuyerReadModelInput = {
  id: string,
  account_company_name?: string | null,
  account_first_name?: string | null,
  account_id?: string | null,
  account_instagram_account?: string | null,
  account_last_name?: string | null,
  account_location?: string | null,
  account_tel?: string | null,
  account_web_site_URL?: string | null,
  account_zip_code?: string | null,
  billing_close_day?: number | null,
  buyer_brand?: string | null,
  buyer_credit?: number | null,
  buyer_kyc_status?: number | null,
  buyer_product_category?: string | null,
  buyer_rating?: number | null,
  buyer_returen_credit?: number | null,
  buyer_return_limit_per_brand?: number | null,
  buyer_type?: string | null,
  item_category?: Array< string | null > | null,
  metadata?: string | null,
  mf_customer_id?: string | null,
  mf_destination_id?: string | null,
  mf_examination_status?: string | null,
  owner?: string | null,
  owner_name?: string | null,
  payment_type?: number | null,
  primary_payment_method_id?: string | null,
  primary_payment_term?: number | null,
  primary_shipping_address_id?: string | null,
  primary_shipping_building?: string | null,
  primary_shipping_city?: string | null,
  primary_shipping_name?: string | null,
  primary_shipping_phone_number?: string | null,
  primary_shipping_prefecture?: string | null,
  primary_shipping_zip_code?: string | null,
  referral_brand_id?: string | null,
  referral_brand_name?: string | null,
  shop_image_keys?: Array< string | null > | null,
  shop_name?: string | null,
  store_category?: string | null,
  stripe_id?: string | null,
  total_order_amount?: number | null,
  total_order_amount_last_9m?: number | null,
  total_order_quantity?: number | null,
  total_order_quantity_last_9m?: number | null,
  total_return_amount?: number | null,
  total_return_amount_last_9m?: number | null,
  total_return_quantity?: number | null,
  total_return_quantity_last_9m?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBuyerReadModelInput = {
  id: string,
};

export type CreateBrandReadModelInput = {
  id?: string | null,
  company_name?: string | null,
  brand_owner: string,
  brand_name: string,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string > | null,
  tags?: Array< string > | null,
  brand_collections?: Array< string > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?: Array< StockistInput > | null,
  featured_in?: Array< string > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  is_published?: boolean | null,
  firstPublishedAt?: string | null,
  firstPublishedAtTimestamp?: number | null,
  lastPublishedAt?: string | null,
  lastPublishedAtTimestamp?: number | null,
  category_page_id?: Array< string > | null,
  product_category?: Array< string > | null,
  product_subcategory?: Array< string > | null,
  average_shipping_days?: number | null,
  service_rate?: number | null,
  review_average?: number | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  reviews?: number | null,
  items?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  service_rate_rate_tier?: number | null,
  average_shipping_days_tier?: number | null,
  minimum_tier?: number | null,
  items_tier?: number | null,
  contacts_tier?: number | null,
  supplier_group_id?: string | null,
  supplier_group_title?: string | null,
  supplier_group_description?: string | null,
  supplier_group_image_key?: string | null,
  supplier_group_published?: boolean | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
};

export type ModelBrandReadModelConditionInput = {
  company_name?: ModelStringInput | null,
  brand_owner?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_type?: ModelStringInput | null,
  brand_content?: ModelStringInput | null,
  brand_imageKey?: ModelStringInput | null,
  brand_icon_imageKey?: ModelStringInput | null,
  brand_logo_imageKey?: ModelStringInput | null,
  brand_publication?: ModelStringInput | null,
  brand_retail_stores_number?: ModelStringInput | null,
  brand_category?: ModelStringInput | null,
  brand_target_age?: ModelStringInput | null,
  brand_target_gender?: ModelStringInput | null,
  brand_website_url?: ModelStringInput | null,
  brand_work_with_big_box?: ModelStringInput | null,
  brand_address?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_shippinng_fee?: ModelIntInput | null,
  brand_shippinng_fee_criteria?: ModelIntInput | null,
  brand_instagram_account?: ModelStringInput | null,
  brand_facebook_account?: ModelStringInput | null,
  brand_rating?: ModelFloatInput | null,
  brand_annual_turnover?: ModelIntInput | null,
  brand_public_status?: ModelStringInput | null,
  brand_howtoknowhomula?: ModelStringInput | null,
  brand_audit_status?: ModelIntInput | null,
  brand_stripe_id?: ModelStringInput | null,
  brand_first_order_fee?: ModelIntInput | null,
  brand_additional_order_fee?: ModelIntInput | null,
  brand_first_order_fee_referral?: ModelIntInput | null,
  brand_additional_order_fee_referral?: ModelIntInput | null,
  brand_direct_order_fee?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdAtTimestamp?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  updatedAtTimestamp?: ModelFloatInput | null,
  owner?: ModelStringInput | null,
  designed_in?: ModelStringInput | null,
  established?: ModelStringInput | null,
  tagIds?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  brand_collections?: ModelStringInput | null,
  movie_url?: ModelStringInput | null,
  brand_modal_imageKeys?: ModelStringInput | null,
  brand_online_sale?: ModelBooleanInput | null,
  product_image_reprint?: ModelBooleanInput | null,
  brand_logo_reprint?: ModelBooleanInput | null,
  post_to_sns?: ModelBooleanInput | null,
  allow_direct_shipping?: ModelBooleanInput | null,
  allow_sell_before_buy?: ModelBooleanInput | null,
  other_terms?: ModelStringInput | null,
  featured_in?: ModelStringInput | null,
  maximum_wholesale_rate?: ModelFloatInput | null,
  exclude_zip_code?: ModelStringInput | null,
  is_sale_brand?: ModelBooleanInput | null,
  hubspot_company_id?: ModelStringInput | null,
  return_to_brand?: ModelBooleanInput | null,
  terms_of_sample?: ModelStringInput | null,
  auto_message_enabled?: ModelBooleanInput | null,
  auto_message?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  is_published?: ModelBooleanInput | null,
  firstPublishedAt?: ModelStringInput | null,
  firstPublishedAtTimestamp?: ModelFloatInput | null,
  lastPublishedAt?: ModelStringInput | null,
  lastPublishedAtTimestamp?: ModelFloatInput | null,
  category_page_id?: ModelStringInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  average_shipping_days?: ModelFloatInput | null,
  service_rate?: ModelFloatInput | null,
  review_average?: ModelFloatInput | null,
  conversion_rate?: ModelFloatInput | null,
  sell_through_rate?: ModelFloatInput | null,
  reviews?: ModelIntInput | null,
  items?: ModelIntInput | null,
  conversion_rate_tier?: ModelIntInput | null,
  sell_through_rate_tier?: ModelIntInput | null,
  service_rate_rate_tier?: ModelIntInput | null,
  average_shipping_days_tier?: ModelIntInput | null,
  minimum_tier?: ModelIntInput | null,
  items_tier?: ModelIntInput | null,
  contacts_tier?: ModelIntInput | null,
  supplier_group_id?: ModelIDInput | null,
  supplier_group_title?: ModelStringInput | null,
  supplier_group_description?: ModelStringInput | null,
  supplier_group_image_key?: ModelStringInput | null,
  supplier_group_published?: ModelBooleanInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  individual_shippinng_fee?: ModelIntInput | null,
  individual_shippinng_fee_criteria?: ModelIntInput | null,
  individual_first_order_fee?: ModelIntInput | null,
  individual_additional_order_fee?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  and?: Array< ModelBrandReadModelConditionInput | null > | null,
  or?: Array< ModelBrandReadModelConditionInput | null > | null,
  not?: ModelBrandReadModelConditionInput | null,
};

export type UpdateBrandReadModelInput = {
  id: string,
  company_name?: string | null,
  brand_owner?: string | null,
  brand_name?: string | null,
  brand_yomigana?: string | null,
  brand_type?: string | null,
  brand_content?: string | null,
  brand_imageKey?: string | null,
  brand_icon_imageKey?: string | null,
  brand_logo_imageKey?: string | null,
  brand_publication?: string | null,
  brand_retail_stores_number?: string | null,
  brand_category?: string | null,
  brand_target_age?: string | null,
  brand_target_gender?: string | null,
  brand_website_url?: string | null,
  brand_work_with_big_box?: string | null,
  brand_address?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_shippinng_fee?: number | null,
  brand_shippinng_fee_criteria?: number | null,
  brand_instagram_account?: string | null,
  brand_facebook_account?: string | null,
  brand_rating?: number | null,
  brand_annual_turnover?: number | null,
  brand_public_status?: string | null,
  brand_howtoknowhomula?: string | null,
  brand_audit_status?: number | null,
  brand_stripe_id?: string | null,
  brand_first_order_fee?: number | null,
  brand_additional_order_fee?: number | null,
  brand_first_order_fee_referral?: number | null,
  brand_additional_order_fee_referral?: number | null,
  brand_direct_order_fee?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  owner?: Array< string | null > | null,
  designed_in?: string | null,
  established?: string | null,
  tagIds?: Array< string > | null,
  tags?: Array< string > | null,
  brand_collections?: Array< string > | null,
  movie_url?: string | null,
  brand_modal_imageKeys?: Array< string > | null,
  brand_online_sale?: boolean | null,
  product_image_reprint?: boolean | null,
  brand_logo_reprint?: boolean | null,
  post_to_sns?: boolean | null,
  allow_direct_shipping?: boolean | null,
  allow_sell_before_buy?: boolean | null,
  other_terms?: string | null,
  stockists?: Array< StockistInput > | null,
  featured_in?: Array< string > | null,
  maximum_wholesale_rate?: number | null,
  exclude_zip_code?: Array< string > | null,
  is_sale_brand?: boolean | null,
  hubspot_company_id?: string | null,
  return_to_brand?: boolean | null,
  terms_of_sample?: string | null,
  auto_message_enabled?: boolean | null,
  auto_message?: string | null,
  sell_only_direct?: boolean | null,
  is_published?: boolean | null,
  firstPublishedAt?: string | null,
  firstPublishedAtTimestamp?: number | null,
  lastPublishedAt?: string | null,
  lastPublishedAtTimestamp?: number | null,
  category_page_id?: Array< string > | null,
  product_category?: Array< string > | null,
  product_subcategory?: Array< string > | null,
  average_shipping_days?: number | null,
  service_rate?: number | null,
  review_average?: number | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  reviews?: number | null,
  items?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  service_rate_rate_tier?: number | null,
  average_shipping_days_tier?: number | null,
  minimum_tier?: number | null,
  items_tier?: number | null,
  contacts_tier?: number | null,
  supplier_group_id?: string | null,
  supplier_group_title?: string | null,
  supplier_group_description?: string | null,
  supplier_group_image_key?: string | null,
  supplier_group_published?: boolean | null,
  individual_minimum_buy?: number | null,
  individual_shippinng_fee?: number | null,
  individual_shippinng_fee_criteria?: number | null,
  individual_first_order_fee?: number | null,
  individual_additional_order_fee?: number | null,
  individual_wholesale_rate?: number | null,
};

export type DeleteBrandReadModelInput = {
  id: string,
};

export type CreateProductReadModelInput = {
  id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_yomigana?: string | null,
  brand_image_key?: string | null,
  brand_published?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_individual_minimum_buy?: number | null,
  brand_individual_wholesale_rate?: number | null,
  brand_tags?: Array< string > | null,
  product_content?: string | null,
  product_description?: string | null,
  product_size?: string | null,
  product_owner?: string | null,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  origin_wholesale_price?: number | null,
  wholesale_rate?: number | null,
  wholesale_price?: number | null,
  individual_wholesale_rate?: number | null,
  individual_price?: number | null,
  individual_minimum_buy?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  is_published?: boolean | null,
  is_limited?: boolean | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  category_page_id?: Array< string > | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_preorder_shipping_date_timestamp?: number | null,
  product_types?: Array< string > | null,
  product_colors?: Array< string > | null,
  product_sizes?: Array< string > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  image_key?: string | null,
  image_keys?: Array< string > | null,
  video_keys?: Array< string > | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string > | null,
  protected_zip_code?: Array< string > | null,
  options?: Array< ProductOptionInput > | null,
  publishedAt?: string | null,
  publishedAtTimestamp?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  wholesale_rate_tier?: number | null,
  brand_tier?: number | null,
  brand_conversion_rate_tier?: number | null,
  brand_sell_through_rate_tier?: number | null,
  brand_service_rate_rate_tier?: number | null,
  brand_average_shipping_days_tier?: number | null,
  brand_minimum_tier?: number | null,
  brand_items_tier?: number | null,
  brand_contacts_tier?: number | null,
  collection_id?: Array< string | null > | null,
  sell_only_direct?: boolean | null,
};

export type ProductOptionInput = {
  id?: string | null,
  product_id?: string | null,
  size?: string | null,
  color?: string | null,
  type_name?: string | null,
  sku?: string | null,
  imageKey?: string | null,
  out_of_stock?: boolean | null,
  suspended?: boolean | null,
  published?: boolean | null,
  createdAt?: string | null,
  copied_product_type_id?: string | null,
};

export type ModelProductReadModelConditionInput = {
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_image_key?: ModelStringInput | null,
  brand_published?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_individual_minimum_buy?: ModelIntInput | null,
  brand_individual_wholesale_rate?: ModelFloatInput | null,
  brand_tags?: ModelStringInput | null,
  product_content?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  product_size?: ModelStringInput | null,
  product_owner?: ModelIDInput | null,
  product_wholesale_rate?: ModelFloatInput | null,
  product_sale_wholesale_rate?: ModelFloatInput | null,
  product_retail_price?: ModelIntInput | null,
  origin_wholesale_price?: ModelIntInput | null,
  wholesale_rate?: ModelFloatInput | null,
  wholesale_price?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  individual_price?: ModelIntInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  is_open_price?: ModelBooleanInput | null,
  product_jancode?: ModelStringInput | null,
  product_stock_quantity?: ModelIntInput | null,
  is_published?: ModelBooleanInput | null,
  is_limited?: ModelBooleanInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  category_page_id?: ModelStringInput | null,
  product_minimum_quantity?: ModelIntInput | null,
  product_estimated_ship_date_min?: ModelIntInput | null,
  product_estimated_ship_date_max?: ModelIntInput | null,
  product_order_lot?: ModelIntInput | null,
  product_preorder?: ModelIntInput | null,
  product_preorder_shipping_date?: ModelStringInput | null,
  product_preorder_shipping_date_timestamp?: ModelFloatInput | null,
  product_types?: ModelStringInput | null,
  product_colors?: ModelStringInput | null,
  product_sizes?: ModelStringInput | null,
  product_display_order?: ModelIntInput | null,
  product_season?: ModelStringInput | null,
  product_year?: ModelIntInput | null,
  image_key?: ModelStringInput | null,
  image_keys?: ModelStringInput | null,
  video_keys?: ModelStringInput | null,
  conversion_rate?: ModelFloatInput | null,
  sell_through_rate?: ModelFloatInput | null,
  suspended?: ModelBooleanInput | null,
  continue_selling?: ModelBooleanInput | null,
  out_of_stock?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  copied_product_id?: ModelIDInput | null,
  copied_brand_id?: ModelIDInput | null,
  limited_publishing_list?: ModelStringInput | null,
  protected_zip_code?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  publishedAtTimestamp?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  createdAtTimestamp?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  updatedAtTimestamp?: ModelFloatInput | null,
  conversion_rate_tier?: ModelIntInput | null,
  sell_through_rate_tier?: ModelIntInput | null,
  wholesale_rate_tier?: ModelIntInput | null,
  brand_tier?: ModelIntInput | null,
  brand_conversion_rate_tier?: ModelIntInput | null,
  brand_sell_through_rate_tier?: ModelIntInput | null,
  brand_service_rate_rate_tier?: ModelIntInput | null,
  brand_average_shipping_days_tier?: ModelIntInput | null,
  brand_minimum_tier?: ModelIntInput | null,
  brand_items_tier?: ModelIntInput | null,
  brand_contacts_tier?: ModelIntInput | null,
  collection_id?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  and?: Array< ModelProductReadModelConditionInput | null > | null,
  or?: Array< ModelProductReadModelConditionInput | null > | null,
  not?: ModelProductReadModelConditionInput | null,
};

export type UpdateProductReadModelInput = {
  id: string,
  product_name?: string | null,
  product_number?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_yomigana?: string | null,
  brand_image_key?: string | null,
  brand_published?: string | null,
  brand_minimum_buy?: number | null,
  brand_additional_minimum_buy?: number | null,
  brand_individual_minimum_buy?: number | null,
  brand_individual_wholesale_rate?: number | null,
  brand_tags?: Array< string > | null,
  product_content?: string | null,
  product_description?: string | null,
  product_size?: string | null,
  product_owner?: string | null,
  product_wholesale_rate?: number | null,
  product_sale_wholesale_rate?: number | null,
  product_retail_price?: number | null,
  origin_wholesale_price?: number | null,
  wholesale_rate?: number | null,
  wholesale_price?: number | null,
  individual_wholesale_rate?: number | null,
  individual_price?: number | null,
  individual_minimum_buy?: number | null,
  is_open_price?: boolean | null,
  product_jancode?: string | null,
  product_stock_quantity?: number | null,
  is_published?: boolean | null,
  is_limited?: boolean | null,
  product_category?: string | null,
  product_subcategory?: string | null,
  category_page_id?: Array< string > | null,
  product_minimum_quantity?: number | null,
  product_estimated_ship_date_min?: number | null,
  product_estimated_ship_date_max?: number | null,
  product_order_lot?: number | null,
  product_preorder?: number | null,
  product_preorder_shipping_date?: string | null,
  product_preorder_shipping_date_timestamp?: number | null,
  product_types?: Array< string > | null,
  product_colors?: Array< string > | null,
  product_sizes?: Array< string > | null,
  product_display_order?: number | null,
  product_season?: string | null,
  product_year?: number | null,
  image_key?: string | null,
  image_keys?: Array< string > | null,
  video_keys?: Array< string > | null,
  conversion_rate?: number | null,
  sell_through_rate?: number | null,
  suspended?: boolean | null,
  continue_selling?: boolean | null,
  out_of_stock?: boolean | null,
  sku?: string | null,
  copied_product_id?: string | null,
  copied_brand_id?: string | null,
  limited_publishing_list?: Array< string > | null,
  protected_zip_code?: Array< string > | null,
  options?: Array< ProductOptionInput > | null,
  publishedAt?: string | null,
  publishedAtTimestamp?: number | null,
  createdAt?: string | null,
  createdAtTimestamp?: number | null,
  updatedAt?: string | null,
  updatedAtTimestamp?: number | null,
  conversion_rate_tier?: number | null,
  sell_through_rate_tier?: number | null,
  wholesale_rate_tier?: number | null,
  brand_tier?: number | null,
  brand_conversion_rate_tier?: number | null,
  brand_sell_through_rate_tier?: number | null,
  brand_service_rate_rate_tier?: number | null,
  brand_average_shipping_days_tier?: number | null,
  brand_minimum_tier?: number | null,
  brand_items_tier?: number | null,
  brand_contacts_tier?: number | null,
  collection_id?: Array< string | null > | null,
  sell_only_direct?: boolean | null,
};

export type DeleteProductReadModelInput = {
  id: string,
};

export type CreateCollectionReadModelInput = {
  id?: string | null,
  name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_categories?: Array< string | null > | null,
  brand_tags?: Array< string | null > | null,
  src_brand_id?: string | null,
  src_brand_name?: string | null,
  src_brand_categories?: Array< string | null > | null,
  src_brand_tags?: Array< string | null > | null,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_image_keys?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  product_categories?: Array< string | null > | null,
  product_subcategories?: Array< string | null > | null,
  total_quantity?: number | null,
  total_retail_amount?: number | null,
  total_original_amount?: number | null,
  total_amount?: number | null,
  total_items?: number | null,
  wholesale_rate?: number | null,
  minimum_wholesale_rate?: number | null,
  sell_only_direct?: boolean | null,
  display_order?: number | null,
};

export type ModelCollectionReadModelConditionInput = {
  name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_categories?: ModelStringInput | null,
  brand_tags?: ModelStringInput | null,
  src_brand_id?: ModelIDInput | null,
  src_brand_name?: ModelStringInput | null,
  src_brand_categories?: ModelStringInput | null,
  src_brand_tags?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  video_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  preorder?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  season?: ModelStringInput | null,
  season_year?: ModelIntInput | null,
  product_ids?: ModelStringInput | null,
  product_names?: ModelStringInput | null,
  product_image_keys?: ModelStringInput | null,
  product_numbers?: ModelStringInput | null,
  product_categories?: ModelStringInput | null,
  product_subcategories?: ModelStringInput | null,
  total_quantity?: ModelFloatInput | null,
  total_retail_amount?: ModelFloatInput | null,
  total_original_amount?: ModelFloatInput | null,
  total_amount?: ModelFloatInput | null,
  total_items?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  minimum_wholesale_rate?: ModelFloatInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelCollectionReadModelConditionInput | null > | null,
  or?: Array< ModelCollectionReadModelConditionInput | null > | null,
  not?: ModelCollectionReadModelConditionInput | null,
};

export type UpdateCollectionReadModelInput = {
  id: string,
  name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_categories?: Array< string | null > | null,
  brand_tags?: Array< string | null > | null,
  src_brand_id?: string | null,
  src_brand_name?: string | null,
  src_brand_categories?: Array< string | null > | null,
  src_brand_tags?: Array< string | null > | null,
  description?: string | null,
  image_key?: string | null,
  video_key?: string | null,
  published?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  preorder?: boolean | null,
  is_consign?: boolean | null,
  season?: string | null,
  season_year?: number | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_image_keys?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  product_categories?: Array< string | null > | null,
  product_subcategories?: Array< string | null > | null,
  total_quantity?: number | null,
  total_retail_amount?: number | null,
  total_original_amount?: number | null,
  total_amount?: number | null,
  total_items?: number | null,
  wholesale_rate?: number | null,
  minimum_wholesale_rate?: number | null,
  sell_only_direct?: boolean | null,
  display_order?: number | null,
};

export type DeleteCollectionReadModelInput = {
  id: string,
};

export type CreateOrderReadModelInput = {
  id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_image_key?: string | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  shipping_date?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_due_date?: string | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  order_quantity?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_amount?: number | null,
  report_approved_quantity?: number | null,
  report_approved_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  points?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  campaign_code?: string | null,
  cashback_amount?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  origin_order_id?: string | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  billing_count?: number | null,
  is_first?: boolean | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  is_preorder?: boolean | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  skus?: Array< string | null > | null,
  bill_payment_status?: BillPaymentStatus | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
};

export type ModelOrderReadModelConditionInput = {
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_image_key?: ModelStringInput | null,
  shipping_zip_code?: ModelStringInput | null,
  shipping_address?: ModelStringInput | null,
  shipping_name?: ModelStringInput | null,
  shipping_phone_number?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  tracking_number?: ModelStringInput | null,
  shipping_date?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  return_due_date?: ModelStringInput | null,
  shipping_fee?: ModelIntInput | null,
  invoice_shipping_fee?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  order_quantity?: ModelIntInput | null,
  order_subtotal?: ModelIntInput | null,
  order_amount?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  report_quantity?: ModelIntInput | null,
  report_amount?: ModelIntInput | null,
  report_approved_quantity?: ModelIntInput | null,
  report_approved_amount?: ModelIntInput | null,
  extended_quantity?: ModelIntInput | null,
  extended_amount?: ModelIntInput | null,
  payment_quantity?: ModelIntInput | null,
  payment_amount?: ModelIntInput | null,
  points?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  campaign_code?: ModelStringInput | null,
  cashback_amount?: ModelIntInput | null,
  order_fee_rate?: ModelIntInput | null,
  order_fee?: ModelIntInput | null,
  origin_order_id?: ModelIDInput | null,
  status?: ModelOrderReadModelStatusInput | null,
  status_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  stripe_client_secret?: ModelStringInput | null,
  stripe_payment_method_id?: ModelStringInput | null,
  mf_authorization_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  mf_canceled_transaction_id?: ModelStringInput | null,
  billing_count?: ModelIntInput | null,
  is_first?: ModelBooleanInput | null,
  is_direct?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  is_preorder?: ModelBooleanInput | null,
  product_ids?: ModelStringInput | null,
  product_names?: ModelStringInput | null,
  product_numbers?: ModelStringInput | null,
  skus?: ModelStringInput | null,
  bill_payment_status?: ModelBillPaymentStatusInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  split_source_id?: ModelIDInput | null,
  split_date?: ModelStringInput | null,
  and?: Array< ModelOrderReadModelConditionInput | null > | null,
  or?: Array< ModelOrderReadModelConditionInput | null > | null,
  not?: ModelOrderReadModelConditionInput | null,
};

export type ModelOrderReadModelStatusInput = {
  eq?: OrderReadModelStatus | null,
  ne?: OrderReadModelStatus | null,
};

export type ModelOrderPaymentStatusInput = {
  eq?: OrderPaymentStatus | null,
  ne?: OrderPaymentStatus | null,
};

export type ModelPaymentTermInput = {
  eq?: PaymentTerm | null,
  ne?: PaymentTerm | null,
};

export type UpdateOrderReadModelInput = {
  id: string,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  brand_image_key?: string | null,
  shipping_zip_code?: string | null,
  shipping_address?: string | null,
  shipping_name?: string | null,
  shipping_phone_number?: string | null,
  carrier?: string | null,
  tracking_number?: string | null,
  shipping_date?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_due_date?: string | null,
  shipping_fee?: number | null,
  invoice_shipping_fee?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  order_quantity?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_amount?: number | null,
  report_approved_quantity?: number | null,
  report_approved_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  points?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  campaign_code?: string | null,
  cashback_amount?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  origin_order_id?: string | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  stripe_payment_id?: string | null,
  stripe_client_secret?: string | null,
  stripe_payment_method_id?: string | null,
  mf_authorization_id?: string | null,
  mf_transaction_id?: string | null,
  mf_canceled_transaction_id?: string | null,
  billing_count?: number | null,
  is_first?: boolean | null,
  is_direct?: boolean | null,
  is_consign?: boolean | null,
  is_preorder?: boolean | null,
  product_ids?: Array< string | null > | null,
  product_names?: Array< string | null > | null,
  product_numbers?: Array< string | null > | null,
  skus?: Array< string | null > | null,
  bill_payment_status?: BillPaymentStatus | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  memo?: string | null,
  split_source_id?: string | null,
  split_date?: string | null,
};

export type DeleteOrderReadModelInput = {
  id: string,
};

export type CreateOrderDetailReadModelInput = {
  id?: string | null,
  order_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  initial_quantity?: number | null,
  initial_price?: number | null,
  initial_wholesale_rate?: number | null,
  quantity?: number | null,
  price?: number | null,
  wholesale_rate?: number | null,
  subtotal?: number | null,
  amount?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_approved_amount?: number | null,
  report_approved_quantity?: number | null,
  report_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  discount_percentage?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  memo?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOrderDetailReadModelConditionInput = {
  order_id?: ModelIDInput | null,
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_type_id?: ModelIDInput | null,
  product_image_key?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  initial_quantity?: ModelIntInput | null,
  initial_price?: ModelFloatInput | null,
  initial_wholesale_rate?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  subtotal?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  report_quantity?: ModelIntInput | null,
  report_approved_amount?: ModelIntInput | null,
  report_approved_quantity?: ModelIntInput | null,
  report_amount?: ModelIntInput | null,
  extended_quantity?: ModelIntInput | null,
  extended_amount?: ModelIntInput | null,
  payment_quantity?: ModelIntInput | null,
  payment_amount?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  discount_percentage?: ModelIntInput | null,
  order_fee_rate?: ModelIntInput | null,
  order_fee?: ModelIntInput | null,
  status?: ModelOrderReadModelStatusInput | null,
  status_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderDetailReadModelConditionInput | null > | null,
  or?: Array< ModelOrderDetailReadModelConditionInput | null > | null,
  not?: ModelOrderDetailReadModelConditionInput | null,
};

export type UpdateOrderDetailReadModelInput = {
  id: string,
  order_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  initial_quantity?: number | null,
  initial_price?: number | null,
  initial_wholesale_rate?: number | null,
  quantity?: number | null,
  price?: number | null,
  wholesale_rate?: number | null,
  subtotal?: number | null,
  amount?: number | null,
  tax_rate?: number | null,
  tax?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  report_quantity?: number | null,
  report_approved_amount?: number | null,
  report_approved_quantity?: number | null,
  report_amount?: number | null,
  extended_quantity?: number | null,
  extended_amount?: number | null,
  payment_quantity?: number | null,
  payment_amount?: number | null,
  coupon_id?: string | null,
  discount_amount?: number | null,
  discount_percentage?: number | null,
  order_fee_rate?: number | null,
  order_fee?: number | null,
  status?: OrderReadModelStatus | null,
  status_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  memo?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOrderDetailReadModelInput = {
  id: string,
};

export type CreateReturnDetailReadModelInput = {
  id?: string | null,
  order_id?: string | null,
  order_product_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_request_due_date?: string | null,
  return_request_date?: string | null,
  return_due_date?: string | null,
  return_date?: string | null,
  order_quantity?: number | null,
  order_price?: number | null,
  wholesale_rate?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  order_tax?: number | null,
  tax_rate?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  status?: ReturnStatus | null,
  status_label?: string | null,
  return_destination?: ReturnDestination | null,
  return_destination_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type ModelReturnDetailReadModelConditionInput = {
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_type_id?: ModelIDInput | null,
  product_image_key?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  return_request_due_date?: ModelStringInput | null,
  return_request_date?: ModelStringInput | null,
  return_due_date?: ModelStringInput | null,
  return_date?: ModelStringInput | null,
  order_quantity?: ModelIntInput | null,
  order_price?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  order_subtotal?: ModelIntInput | null,
  order_amount?: ModelIntInput | null,
  order_tax?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  status?: ModelReturnStatusInput | null,
  status_label?: ModelStringInput | null,
  return_destination?: ModelReturnDestinationInput | null,
  return_destination_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReturnDetailReadModelConditionInput | null > | null,
  or?: Array< ModelReturnDetailReadModelConditionInput | null > | null,
  not?: ModelReturnDetailReadModelConditionInput | null,
};

export type ModelReturnStatusInput = {
  eq?: ReturnStatus | null,
  ne?: ReturnStatus | null,
};

export type ModelReturnDestinationInput = {
  eq?: ReturnDestination | null,
  ne?: ReturnDestination | null,
};

export type UpdateReturnDetailReadModelInput = {
  id: string,
  order_id?: string | null,
  order_product_id?: string | null,
  buyer_id?: string | null,
  buyer_company_name?: string | null,
  buyer_shop_name?: string | null,
  supplier_id?: string | null,
  supplier_company_name?: string | null,
  brand_id?: string | null,
  brand_name?: string | null,
  product_id?: string | null,
  product_name?: string | null,
  product_number?: string | null,
  sku?: string | null,
  product_type_id?: string | null,
  product_image_key?: string | null,
  color?: string | null,
  size?: string | null,
  type_name?: string | null,
  shipped_date?: string | null,
  extended_date?: string | null,
  payment_date?: string | null,
  extended_payment_date?: string | null,
  return_request_due_date?: string | null,
  return_request_date?: string | null,
  return_due_date?: string | null,
  return_date?: string | null,
  order_quantity?: number | null,
  order_price?: number | null,
  wholesale_rate?: number | null,
  order_subtotal?: number | null,
  order_amount?: number | null,
  order_tax?: number | null,
  tax_rate?: number | null,
  return_request_quantity?: number | null,
  return_request_amount?: number | null,
  return_quantity?: number | null,
  return_amount?: number | null,
  status?: ReturnStatus | null,
  status_label?: string | null,
  return_destination?: ReturnDestination | null,
  return_destination_label?: string | null,
  payment_term?: PaymentTerm | null,
  payment_term_label?: string | null,
  payment_status?: OrderPaymentStatus | null,
  payment_status_label?: string | null,
  stripe_payment_id?: string | null,
  mf_transaction_id?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteReturnDetailReadModelInput = {
  id: string,
};

export type CreateContactReadModelInput = {
  id?: string | null,
  supplier_id: string,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt?: string | null,
  owner: string,
  buyer_web_site_URL?: string | null,
  buyer_instagram_account?: string | null,
  buyer_company_name?: string | null,
  contact_list_id?: Array< string | null > | null,
  orderCount?: number | null,
  pageViewCount?: number | null,
};

export type ModelContactReadModelConditionInput = {
  supplier_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  store_name?: ModelStringInput | null,
  contact_name?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  address?: ModelStringInput | null,
  discount_rate?: ModelIntInput | null,
  contact_status?: ModelStringInput | null,
  contact_source?: ModelStringInput | null,
  direct_payment?: ModelBooleanInput | null,
  contacted?: ModelBooleanInput | null,
  signedup?: ModelBooleanInput | null,
  ordered?: ModelBooleanInput | null,
  invited?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  buyer_web_site_URL?: ModelStringInput | null,
  buyer_instagram_account?: ModelStringInput | null,
  buyer_company_name?: ModelStringInput | null,
  contact_list_id?: ModelStringInput | null,
  orderCount?: ModelIntInput | null,
  pageViewCount?: ModelIntInput | null,
  and?: Array< ModelContactReadModelConditionInput | null > | null,
  or?: Array< ModelContactReadModelConditionInput | null > | null,
  not?: ModelContactReadModelConditionInput | null,
};

export type ContactReadModel = {
  __typename: "ContactReadModel",
  id: string,
  supplier_id: string,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt: string,
  owner: string,
  buyer_web_site_URL?: string | null,
  buyer_instagram_account?: string | null,
  buyer_company_name?: string | null,
  contact_list_id?: Array< string | null > | null,
  orderCount?: number | null,
  pageViewCount?: number | null,
  updatedAt: string,
  supplierInfo?: SupplierInfo | null,
  orders?: ModelOrderConnection | null,
  activities?: ModelContactActivityConnection | null,
  pageViews?: ModelPageViewConnection | null,
  pageViewsByEmail?: ModelPageViewConnection | null,
};

export type UpdateContactReadModelInput = {
  id: string,
  supplier_id?: string | null,
  buyer_id?: string | null,
  buyer_owner?: string | null,
  email?: string | null,
  store_name?: string | null,
  contact_name?: string | null,
  tel?: string | null,
  zipcode?: string | null,
  address?: string | null,
  discount_rate?: number | null,
  contact_status?: string | null,
  contact_source?: string | null,
  direct_payment?: boolean | null,
  contacted?: boolean | null,
  signedup?: boolean | null,
  ordered?: boolean | null,
  invited?: boolean | null,
  createdAt?: string | null,
  owner?: string | null,
  buyer_web_site_URL?: string | null,
  buyer_instagram_account?: string | null,
  buyer_company_name?: string | null,
  contact_list_id?: Array< string | null > | null,
  orderCount?: number | null,
  pageViewCount?: number | null,
};

export type DeleteContactReadModelInput = {
  id: string,
};

export type CreateOrderEventInput = {
  id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  order_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export enum EventName {
  INSERT = "INSERT",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
}


export type ModelOrderEventConditionInput = {
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelOrderEventConditionInput | null > | null,
  or?: Array< ModelOrderEventConditionInput | null > | null,
  not?: ModelOrderEventConditionInput | null,
};

export type ModelEventNameInput = {
  eq?: EventName | null,
  ne?: EventName | null,
};

export type OrderEvent = {
  __typename: "OrderEvent",
  id: string,
  event_name?: EventName | null,
  event_date: string,
  order_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrderEventInput = {
  id: string,
  event_name?: EventName | null,
  event_date?: string | null,
  order_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type DeleteOrderEventInput = {
  id: string,
};

export type CreateOrderProductEventInput = {
  id?: string | null,
  order_id?: string | null,
  order_product_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
};

export type ModelOrderProductEventConditionInput = {
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelOrderProductEventConditionInput | null > | null,
  or?: Array< ModelOrderProductEventConditionInput | null > | null,
  not?: ModelOrderProductEventConditionInput | null,
};

export type OrderProductEvent = {
  __typename: "OrderProductEvent",
  id: string,
  order_id?: string | null,
  order_product_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrderProductEventInput = {
  id: string,
  order_id?: string | null,
  order_product_id?: string | null,
  event_name?: EventName | null,
  event_date?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type DeleteOrderProductEventInput = {
  id: string,
};

export type CreateBrandEventInput = {
  id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  brand_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type ModelBrandEventConditionInput = {
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelBrandEventConditionInput | null > | null,
  or?: Array< ModelBrandEventConditionInput | null > | null,
  not?: ModelBrandEventConditionInput | null,
};

export type BrandEvent = {
  __typename: "BrandEvent",
  id: string,
  event_name?: EventName | null,
  event_date: string,
  brand_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBrandEventInput = {
  id: string,
  event_name?: EventName | null,
  event_date?: string | null,
  brand_id?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type DeleteBrandEventInput = {
  id: string,
};

export type CreateProductEventInput = {
  id?: string | null,
  product_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
};

export type ModelProductEventConditionInput = {
  product_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelProductEventConditionInput | null > | null,
  or?: Array< ModelProductEventConditionInput | null > | null,
  not?: ModelProductEventConditionInput | null,
};

export type ProductEvent = {
  __typename: "ProductEvent",
  id: string,
  product_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductEventInput = {
  id: string,
  product_id?: string | null,
  event_name?: EventName | null,
  event_date?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type DeleteProductEventInput = {
  id: string,
};

export type CreateProductTypeEventInput = {
  id?: string | null,
  product_id?: string | null,
  product_type_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
};

export type ModelProductTypeEventConditionInput = {
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelProductTypeEventConditionInput | null > | null,
  or?: Array< ModelProductTypeEventConditionInput | null > | null,
  not?: ModelProductTypeEventConditionInput | null,
};

export type ProductTypeEvent = {
  __typename: "ProductTypeEvent",
  id: string,
  product_id?: string | null,
  product_type_id?: string | null,
  event_name?: EventName | null,
  event_date: string,
  new_item?: string | null,
  old_item?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductTypeEventInput = {
  id: string,
  product_id?: string | null,
  product_type_id?: string | null,
  event_name?: EventName | null,
  event_date?: string | null,
  new_item?: string | null,
  old_item?: string | null,
};

export type DeleteProductTypeEventInput = {
  id: string,
};

export type SendEmailInput = {
  bccAddresses?: Array< string | null > | null,
  bccAccountIds?: Array< string | null > | null,
  ccAddresses?: Array< string | null > | null,
  ccAccountIds?: Array< string | null > | null,
  toAddresses?: Array< string | null > | null,
  toAccountIds?: Array< string | null > | null,
  source?: string | null,
  sourceAccountId?: string | null,
  templateName: string,
  templateData: string,
  replyToAddresses?: Array< string | null > | null,
  sendToAdminBcc?: boolean | null,
  configurationSetName?: string | null,
  campaignName?: string | null,
  brandIds?: Array< string | null > | null,
  productIds?: Array< string | null > | null,
  emailId?: string | null,
};

export type CreateBuyerAccountInput = {
  email: string,
  firstname?: string | null,
  lastname?: string | null,
  website?: string | null,
  location?: string | null,
  company?: string | null,
  shop_name?: string | null,
  store_category?: string | null,
  referral_id?: string | null,
  item_category?: Array< string | null > | null,
  instagram?: string | null,
  interested_items?: string | null,
  interested_keywords?: string | null,
  introducer?: string | null,
  open_date?: string | null,
  phone?: string | null,
  terms_of_service?: string | null,
  shop_sales_channel?: string | null,
  main_product?: string | null,
  password?: string | null,
  token?: string | null,
  contactId?: string | null,
  accountId?: string | null,
  metadata?: string | null,
};

export type CreateBuyerAccountOutput = {
  __typename: "CreateBuyerAccountOutput",
  username?: string | null,
  token?: string | null,
};

export type ReturnDueDate = {
  __typename: "ReturnDueDate",
  isReturnable: boolean,
  dueDate?: string | null,
};

export type OrderConnection = {
  __typename: "OrderConnection",
  items?:  Array<Order | null > | null,
  nextToken?: string | null,
};

export type OrderProductConnection = {
  __typename: "OrderProductConnection",
  items?:  Array<OrderProduct | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type InvoiceCreditFacility = {
  __typename: "InvoiceCreditFacility",
  amount?: number | null,
  balance?: number | null,
};

export type ContactListResponse = {
  __typename: "ContactListResponse",
  id?: string | null,
  item?: ContactList | null,
  totalContacts?: number | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  first_name?: ModelStringInput | null,
  last_name?: ModelStringInput | null,
  web_site_URL?: ModelStringInput | null,
  instagram_account?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  location?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  company_name?: ModelStringInput | null,
  term_approval_status?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type SearchableAccountFilterInput = {
  id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  first_name?: SearchableStringFilterInput | null,
  last_name?: SearchableStringFilterInput | null,
  web_site_URL?: SearchableStringFilterInput | null,
  instagram_account?: SearchableStringFilterInput | null,
  zip_code?: SearchableStringFilterInput | null,
  location?: SearchableStringFilterInput | null,
  tel?: SearchableStringFilterInput | null,
  company_name?: SearchableStringFilterInput | null,
  term_approval_status?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableAccountFilterInput | null > | null,
  or?: Array< SearchableAccountFilterInput | null > | null,
  not?: SearchableAccountFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableAccountSortInput = {
  field?: SearchableAccountSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableAccountSortableFields {
  id = "id",
  email = "email",
  first_name = "first_name",
  last_name = "last_name",
  web_site_URL = "web_site_URL",
  instagram_account = "instagram_account",
  zip_code = "zip_code",
  location = "location",
  tel = "tel",
  company_name = "company_name",
  term_approval_status = "term_approval_status",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableAccountConnection = {
  __typename: "SearchableAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBuyerInfoFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  billing_close_day?: ModelIntInput | null,
  buyer_brand?: ModelStringInput | null,
  buyer_credit?: ModelFloatInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_kyc_status?: ModelIntInput | null,
  buyer_product_category?: ModelStringInput | null,
  buyer_rating?: ModelFloatInput | null,
  buyer_return_credit?: ModelFloatInput | null,
  buyer_return_limit_per_brand?: ModelFloatInput | null,
  buyer_type?: ModelStringInput | null,
  invoice_credit_facility?: ModelFloatInput | null,
  item_category?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  mf_customer_id?: ModelStringInput | null,
  mf_destination_id?: ModelStringInput | null,
  mf_examination_status?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  owner_name?: ModelStringInput | null,
  payment_type?: ModelIntInput | null,
  primary_payment_method_id?: ModelIDInput | null,
  primary_payment_term?: ModelIntInput | null,
  primary_shipping_address_id?: ModelIDInput | null,
  referral_brand_id?: ModelIDInput | null,
  shop_name?: ModelStringInput | null,
  store_category?: ModelStringInput | null,
  stripe_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBuyerInfoFilterInput | null > | null,
  or?: Array< ModelBuyerInfoFilterInput | null > | null,
  not?: ModelBuyerInfoFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchableBuyerInfoFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  billing_close_day?: SearchableIntFilterInput | null,
  buyer_brand?: SearchableStringFilterInput | null,
  buyer_credit?: SearchableFloatFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_kyc_status?: SearchableIntFilterInput | null,
  buyer_product_category?: SearchableStringFilterInput | null,
  buyer_rating?: SearchableFloatFilterInput | null,
  buyer_return_credit?: SearchableFloatFilterInput | null,
  buyer_return_limit_per_brand?: SearchableFloatFilterInput | null,
  buyer_type?: SearchableStringFilterInput | null,
  invoice_credit_facility?: SearchableFloatFilterInput | null,
  item_category?: SearchableStringFilterInput | null,
  metadata?: SearchableStringFilterInput | null,
  mf_customer_id?: SearchableStringFilterInput | null,
  mf_destination_id?: SearchableStringFilterInput | null,
  mf_examination_status?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  owner_name?: SearchableStringFilterInput | null,
  payment_type?: SearchableIntFilterInput | null,
  primary_payment_method_id?: SearchableIDFilterInput | null,
  primary_payment_term?: SearchableIntFilterInput | null,
  primary_shipping_address_id?: SearchableIDFilterInput | null,
  referral_brand_id?: SearchableIDFilterInput | null,
  shop_name?: SearchableStringFilterInput | null,
  store_category?: SearchableStringFilterInput | null,
  stripe_id?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableBuyerInfoFilterInput | null > | null,
  or?: Array< SearchableBuyerInfoFilterInput | null > | null,
  not?: SearchableBuyerInfoFilterInput | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBuyerInfoSortInput = {
  field?: SearchableBuyerInfoSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBuyerInfoSortableFields {
  id = "id",
  account_id = "account_id",
  billing_close_day = "billing_close_day",
  buyer_brand = "buyer_brand",
  buyer_credit = "buyer_credit",
  buyer_id = "buyer_id",
  buyer_kyc_status = "buyer_kyc_status",
  buyer_product_category = "buyer_product_category",
  buyer_rating = "buyer_rating",
  buyer_return_credit = "buyer_return_credit",
  buyer_return_limit_per_brand = "buyer_return_limit_per_brand",
  buyer_type = "buyer_type",
  invoice_credit_facility = "invoice_credit_facility",
  item_category = "item_category",
  metadata = "metadata",
  mf_customer_id = "mf_customer_id",
  mf_destination_id = "mf_destination_id",
  mf_examination_status = "mf_examination_status",
  owner = "owner",
  owner_name = "owner_name",
  payment_type = "payment_type",
  primary_payment_method_id = "primary_payment_method_id",
  primary_payment_term = "primary_payment_term",
  primary_shipping_address_id = "primary_shipping_address_id",
  referral_brand_id = "referral_brand_id",
  shop_name = "shop_name",
  store_category = "store_category",
  stripe_id = "stripe_id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBuyerInfoConnection = {
  __typename: "SearchableBuyerInfoConnection",
  items:  Array<BuyerInfo | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelShopImageFilterInput = {
  id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  image_key?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShopImageFilterInput | null > | null,
  or?: Array< ModelShopImageFilterInput | null > | null,
  not?: ModelShopImageFilterInput | null,
};

export type SearchableShopImageFilterInput = {
  id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  image_key?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableShopImageFilterInput | null > | null,
  or?: Array< SearchableShopImageFilterInput | null > | null,
  not?: SearchableShopImageFilterInput | null,
};

export type SearchableShopImageSortInput = {
  field?: SearchableShopImageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableShopImageSortableFields {
  id = "id",
  buyer_id = "buyer_id",
  image_key = "image_key",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableShopImageConnection = {
  __typename: "SearchableShopImageConnection",
  items:  Array<ShopImage | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIndividualFilterInput = {
  brand_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  supplier_group_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelIndividualFilterInput | null > | null,
  or?: Array< ModelIndividualFilterInput | null > | null,
  not?: ModelIndividualFilterInput | null,
};

export type ModelIndividualConnection = {
  __typename: "ModelIndividualConnection",
  items:  Array<Individual | null >,
  nextToken?: string | null,
};

export type SearchableIndividualFilterInput = {
  brand_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  supplier_group_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableIndividualFilterInput | null > | null,
  or?: Array< SearchableIndividualFilterInput | null > | null,
  not?: SearchableIndividualFilterInput | null,
};

export type SearchableIndividualSortInput = {
  field?: SearchableIndividualSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableIndividualSortableFields {
  brand_id = "brand_id",
  buyer_id = "buyer_id",
  supplier_group_id = "supplier_group_id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export type SearchableIndividualConnection = {
  __typename: "SearchableIndividualConnection",
  items:  Array<Individual | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelSupplierGroupFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelSupplierGroupFilterInput | null > | null,
  or?: Array< ModelSupplierGroupFilterInput | null > | null,
  not?: ModelSupplierGroupFilterInput | null,
};

export type ModelSupplierGroupConnection = {
  __typename: "ModelSupplierGroupConnection",
  items:  Array<SupplierGroup | null >,
  nextToken?: string | null,
};

export type SearchableSupplierGroupFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  image_key?: SearchableStringFilterInput | null,
  published?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableSupplierGroupFilterInput | null > | null,
  or?: Array< SearchableSupplierGroupFilterInput | null > | null,
  not?: SearchableSupplierGroupFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableSupplierGroupSortInput = {
  field?: SearchableSupplierGroupSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSupplierGroupSortableFields {
  id = "id",
  title = "title",
  description = "description",
  image_key = "image_key",
  published = "published",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export type SearchableSupplierGroupConnection = {
  __typename: "SearchableSupplierGroupConnection",
  items:  Array<SupplierGroup | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelSupplierInfoFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  supplier_contract_type?: ModelIntInput | null,
  bank_name?: ModelStringInput | null,
  bank_branch_name?: ModelStringInput | null,
  bank_account_number?: ModelStringInput | null,
  bank_account_name?: ModelStringInput | null,
  bank_account_type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelSupplierInfoFilterInput | null > | null,
  or?: Array< ModelSupplierInfoFilterInput | null > | null,
  not?: ModelSupplierInfoFilterInput | null,
};

export type SearchableSupplierInfoFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  supplier_contract_type?: SearchableIntFilterInput | null,
  bank_name?: SearchableStringFilterInput | null,
  bank_branch_name?: SearchableStringFilterInput | null,
  bank_account_number?: SearchableStringFilterInput | null,
  bank_account_name?: SearchableStringFilterInput | null,
  bank_account_type?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableSupplierInfoFilterInput | null > | null,
  or?: Array< SearchableSupplierInfoFilterInput | null > | null,
  not?: SearchableSupplierInfoFilterInput | null,
};

export type SearchableSupplierInfoSortInput = {
  field?: SearchableSupplierInfoSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSupplierInfoSortableFields {
  id = "id",
  account_id = "account_id",
  supplier_contract_type = "supplier_contract_type",
  bank_name = "bank_name",
  bank_branch_name = "bank_branch_name",
  bank_account_number = "bank_account_number",
  bank_account_name = "bank_account_name",
  bank_account_type = "bank_account_type",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export type SearchableSupplierInfoConnection = {
  __typename: "SearchableSupplierInfoConnection",
  items:  Array<SupplierInfo | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelShippingAddressFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  buyer_id?: ModelIDInput | null,
  zip_code?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  city?: ModelStringInput | null,
  building?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShippingAddressFilterInput | null > | null,
  or?: Array< ModelShippingAddressFilterInput | null > | null,
  not?: ModelShippingAddressFilterInput | null,
};

export type ModelShippingAddressConnection = {
  __typename: "ModelShippingAddressConnection",
  items:  Array<ShippingAddress | null >,
  nextToken?: string | null,
};

export type SearchableShippingAddressFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  zip_code?: SearchableStringFilterInput | null,
  prefecture?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  building?: SearchableStringFilterInput | null,
  phone_number?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableShippingAddressFilterInput | null > | null,
  or?: Array< SearchableShippingAddressFilterInput | null > | null,
  not?: SearchableShippingAddressFilterInput | null,
};

export type SearchableShippingAddressSortInput = {
  field?: SearchableShippingAddressSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableShippingAddressSortableFields {
  id = "id",
  name = "name",
  buyer_id = "buyer_id",
  zip_code = "zip_code",
  prefecture = "prefecture",
  city = "city",
  building = "building",
  phone_number = "phone_number",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableShippingAddressConnection = {
  __typename: "SearchableShippingAddressConnection",
  items:  Array<ShippingAddress | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelEmailPreferencesFilterInput = {
  id?: ModelIDInput | null,
  new_arrivals?: ModelBooleanInput | null,
  sale_items?: ModelBooleanInput | null,
  bounced?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelEmailPreferencesFilterInput | null > | null,
  or?: Array< ModelEmailPreferencesFilterInput | null > | null,
  not?: ModelEmailPreferencesFilterInput | null,
};

export type ModelEmailPreferencesConnection = {
  __typename: "ModelEmailPreferencesConnection",
  items:  Array<EmailPreferences | null >,
  nextToken?: string | null,
};

export type SearchableEmailPreferencesFilterInput = {
  id?: SearchableIDFilterInput | null,
  new_arrivals?: SearchableBooleanFilterInput | null,
  sale_items?: SearchableBooleanFilterInput | null,
  bounced?: SearchableBooleanFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableEmailPreferencesFilterInput | null > | null,
  or?: Array< SearchableEmailPreferencesFilterInput | null > | null,
  not?: SearchableEmailPreferencesFilterInput | null,
};

export type SearchableEmailPreferencesSortInput = {
  field?: SearchableEmailPreferencesSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEmailPreferencesSortableFields {
  id = "id",
  new_arrivals = "new_arrivals",
  sale_items = "sale_items",
  bounced = "bounced",
  owner = "owner",
}


export type SearchableEmailPreferencesConnection = {
  __typename: "SearchableEmailPreferencesConnection",
  items:  Array<EmailPreferences | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  product_brand_id?: ModelIDInput | null,
  product_content?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  product_owner?: ModelIDInput | null,
  product_wholesale_rate?: ModelFloatInput | null,
  product_sale_wholesale_rate?: ModelFloatInput | null,
  product_retail_price?: ModelIntInput | null,
  product_price?: ModelIntInput | null,
  is_open_price?: ModelBooleanInput | null,
  product_jancode?: ModelStringInput | null,
  product_stock_quantity?: ModelIntInput | null,
  product_type?: ModelStringInput | null,
  product_color?: ModelStringInput | null,
  product_size?: ModelStringInput | null,
  product_public_status?: ModelStringInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  product_minimum_quantity?: ModelIntInput | null,
  product_estimated_ship_date_min?: ModelIntInput | null,
  product_estimated_ship_date_max?: ModelIntInput | null,
  product_order_lot?: ModelIntInput | null,
  product_preorder?: ModelIntInput | null,
  product_preorder_shipping_date?: ModelStringInput | null,
  product_types?: ModelStringInput | null,
  product_colors?: ModelStringInput | null,
  product_sizes?: ModelStringInput | null,
  product_display_order?: ModelIntInput | null,
  product_season?: ModelStringInput | null,
  product_year?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sort_recommended?: ModelIntInput | null,
  suspended?: ModelBooleanInput | null,
  continue_selling?: ModelBooleanInput | null,
  out_of_stock?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  copied_product_id?: ModelIDInput | null,
  copied_brand_id?: ModelIDInput | null,
  limited_publishing_list?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type SearchableProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  product_name?: SearchableStringFilterInput | null,
  product_number?: SearchableStringFilterInput | null,
  product_brand_id?: SearchableIDFilterInput | null,
  product_content?: SearchableStringFilterInput | null,
  product_description?: SearchableStringFilterInput | null,
  product_owner?: SearchableIDFilterInput | null,
  product_wholesale_rate?: SearchableFloatFilterInput | null,
  product_sale_wholesale_rate?: SearchableFloatFilterInput | null,
  product_retail_price?: SearchableIntFilterInput | null,
  product_price?: SearchableIntFilterInput | null,
  is_open_price?: SearchableBooleanFilterInput | null,
  product_jancode?: SearchableStringFilterInput | null,
  product_stock_quantity?: SearchableIntFilterInput | null,
  product_type?: SearchableStringFilterInput | null,
  product_color?: SearchableStringFilterInput | null,
  product_size?: SearchableStringFilterInput | null,
  product_public_status?: SearchableStringFilterInput | null,
  product_category?: SearchableStringFilterInput | null,
  product_subcategory?: SearchableStringFilterInput | null,
  product_minimum_quantity?: SearchableIntFilterInput | null,
  product_estimated_ship_date_min?: SearchableIntFilterInput | null,
  product_estimated_ship_date_max?: SearchableIntFilterInput | null,
  product_order_lot?: SearchableIntFilterInput | null,
  product_preorder?: SearchableIntFilterInput | null,
  product_preorder_shipping_date?: SearchableStringFilterInput | null,
  product_types?: SearchableStringFilterInput | null,
  product_colors?: SearchableStringFilterInput | null,
  product_sizes?: SearchableStringFilterInput | null,
  product_display_order?: SearchableIntFilterInput | null,
  product_season?: SearchableStringFilterInput | null,
  product_year?: SearchableIntFilterInput | null,
  publishedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  sort_recommended?: SearchableIntFilterInput | null,
  suspended?: SearchableBooleanFilterInput | null,
  continue_selling?: SearchableBooleanFilterInput | null,
  out_of_stock?: SearchableBooleanFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  copied_product_id?: SearchableIDFilterInput | null,
  copied_brand_id?: SearchableIDFilterInput | null,
  limited_publishing_list?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductFilterInput | null > | null,
  or?: Array< SearchableProductFilterInput | null > | null,
  not?: SearchableProductFilterInput | null,
};

export type SearchableProductSortInput = {
  field?: SearchableProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductSortableFields {
  id = "id",
  product_name = "product_name",
  product_number = "product_number",
  product_brand_id = "product_brand_id",
  product_content = "product_content",
  product_description = "product_description",
  product_owner = "product_owner",
  product_wholesale_rate = "product_wholesale_rate",
  product_sale_wholesale_rate = "product_sale_wholesale_rate",
  product_retail_price = "product_retail_price",
  product_price = "product_price",
  is_open_price = "is_open_price",
  product_jancode = "product_jancode",
  product_stock_quantity = "product_stock_quantity",
  product_type = "product_type",
  product_color = "product_color",
  product_size = "product_size",
  product_public_status = "product_public_status",
  product_category = "product_category",
  product_subcategory = "product_subcategory",
  product_minimum_quantity = "product_minimum_quantity",
  product_estimated_ship_date_min = "product_estimated_ship_date_min",
  product_estimated_ship_date_max = "product_estimated_ship_date_max",
  product_order_lot = "product_order_lot",
  product_preorder = "product_preorder",
  product_preorder_shipping_date = "product_preorder_shipping_date",
  product_types = "product_types",
  product_colors = "product_colors",
  product_sizes = "product_sizes",
  product_display_order = "product_display_order",
  product_season = "product_season",
  product_year = "product_year",
  publishedAt = "publishedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  sort_recommended = "sort_recommended",
  suspended = "suspended",
  continue_selling = "continue_selling",
  out_of_stock = "out_of_stock",
  sku = "sku",
  copied_product_id = "copied_product_id",
  copied_brand_id = "copied_brand_id",
  limited_publishing_list = "limited_publishing_list",
  owner = "owner",
}


export type SearchableProductConnection = {
  __typename: "SearchableProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProductImageKeyFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  imageKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductImageKeyFilterInput | null > | null,
  or?: Array< ModelProductImageKeyFilterInput | null > | null,
  not?: ModelProductImageKeyFilterInput | null,
};

export type SearchableProductImageKeyFilterInput = {
  id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  imageKey?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductImageKeyFilterInput | null > | null,
  or?: Array< SearchableProductImageKeyFilterInput | null > | null,
  not?: SearchableProductImageKeyFilterInput | null,
};

export type SearchableProductImageKeySortInput = {
  field?: SearchableProductImageKeySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductImageKeySortableFields {
  id = "id",
  product_id = "product_id",
  imageKey = "imageKey",
  createdAt = "createdAt",
  owner = "owner",
}


export type SearchableProductImageKeyConnection = {
  __typename: "SearchableProductImageKeyConnection",
  items:  Array<ProductImageKey | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProductVideoKeyFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  videoKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductVideoKeyFilterInput | null > | null,
  or?: Array< ModelProductVideoKeyFilterInput | null > | null,
  not?: ModelProductVideoKeyFilterInput | null,
};

export type SearchableProductVideoKeyFilterInput = {
  id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  videoKey?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductVideoKeyFilterInput | null > | null,
  or?: Array< SearchableProductVideoKeyFilterInput | null > | null,
  not?: SearchableProductVideoKeyFilterInput | null,
};

export type SearchableProductVideoKeySortInput = {
  field?: SearchableProductVideoKeySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductVideoKeySortableFields {
  id = "id",
  product_id = "product_id",
  videoKey = "videoKey",
  createdAt = "createdAt",
  owner = "owner",
}


export type SearchableProductVideoKeyConnection = {
  __typename: "SearchableProductVideoKeyConnection",
  items:  Array<ProductVideoKey | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProductTypeFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  imageKey?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  suspended?: ModelBooleanInput | null,
  published?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  out_of_stock?: ModelBooleanInput | null,
  copied_product_type_id?: ModelStringInput | null,
  copied_brand_id?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductTypeFilterInput | null > | null,
  or?: Array< ModelProductTypeFilterInput | null > | null,
  not?: ModelProductTypeFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableProductTypeFilterInput = {
  id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  imageKey?: SearchableStringFilterInput | null,
  type_name?: SearchableStringFilterInput | null,
  color?: SearchableStringFilterInput | null,
  size?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  suspended?: SearchableBooleanFilterInput | null,
  published?: SearchableBooleanFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  out_of_stock?: SearchableBooleanFilterInput | null,
  copied_product_type_id?: SearchableStringFilterInput | null,
  copied_brand_id?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductTypeFilterInput | null > | null,
  or?: Array< SearchableProductTypeFilterInput | null > | null,
  not?: SearchableProductTypeFilterInput | null,
};

export type SearchableProductTypeSortInput = {
  field?: SearchableProductTypeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductTypeSortableFields {
  id = "id",
  product_id = "product_id",
  imageKey = "imageKey",
  type_name = "type_name",
  color = "color",
  size = "size",
  createdAt = "createdAt",
  suspended = "suspended",
  published = "published",
  sku = "sku",
  out_of_stock = "out_of_stock",
  copied_product_type_id = "copied_product_type_id",
  copied_brand_id = "copied_brand_id",
  owner = "owner",
}


export type SearchableProductTypeConnection = {
  __typename: "SearchableProductTypeConnection",
  items:  Array<ProductType | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelInventoryFilterInput = {
  id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  inventory?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInventoryFilterInput | null > | null,
  or?: Array< ModelInventoryFilterInput | null > | null,
  not?: ModelInventoryFilterInput | null,
};

export type SearchableInventoryFilterInput = {
  id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  inventory?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableInventoryFilterInput | null > | null,
  or?: Array< SearchableInventoryFilterInput | null > | null,
  not?: SearchableInventoryFilterInput | null,
};

export type SearchableInventorySortInput = {
  field?: SearchableInventorySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInventorySortableFields {
  id = "id",
  brand_id = "brand_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  sku = "sku",
  inventory = "inventory",
  createdAt = "createdAt",
}


export type SearchableInventoryConnection = {
  __typename: "SearchableInventoryConnection",
  items:  Array<Inventory | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReturnInventoryLogFilterInput = {
  id?: ModelIDInput | null,
  inventory_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  stock_type?: ModelStockTypeInput | null,
  stock_mode?: ModelStockModeInput | null,
  stock_date?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReturnInventoryLogFilterInput | null > | null,
  or?: Array< ModelReturnInventoryLogFilterInput | null > | null,
  not?: ModelReturnInventoryLogFilterInput | null,
};

export type ModelReturnInventoryLogConnection = {
  __typename: "ModelReturnInventoryLogConnection",
  items:  Array<ReturnInventoryLog | null >,
  nextToken?: string | null,
};

export type SearchableReturnInventoryLogFilterInput = {
  id?: SearchableIDFilterInput | null,
  inventory_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  stock_date?: SearchableStringFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableReturnInventoryLogFilterInput | null > | null,
  or?: Array< SearchableReturnInventoryLogFilterInput | null > | null,
  not?: SearchableReturnInventoryLogFilterInput | null,
};

export type SearchableReturnInventoryLogSortInput = {
  field?: SearchableReturnInventoryLogSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReturnInventoryLogSortableFields {
  id = "id",
  inventory_id = "inventory_id",
  brand_id = "brand_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  sku = "sku",
  stock_date = "stock_date",
  quantity = "quantity",
  order_id = "order_id",
  order_product_id = "order_product_id",
  createdAt = "createdAt",
}


export type SearchableReturnInventoryLogConnection = {
  __typename: "SearchableReturnInventoryLogConnection",
  items:  Array<ReturnInventoryLog | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelInventoryLogFilterInput = {
  id?: ModelIDInput | null,
  inventory_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  sku?: ModelStringInput | null,
  stock_type?: ModelStockTypeInput | null,
  stock_mode?: ModelStockModeInput | null,
  stock_date?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInventoryLogFilterInput | null > | null,
  or?: Array< ModelInventoryLogFilterInput | null > | null,
  not?: ModelInventoryLogFilterInput | null,
};

export type SearchableInventoryLogFilterInput = {
  id?: SearchableIDFilterInput | null,
  inventory_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  stock_date?: SearchableStringFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableInventoryLogFilterInput | null > | null,
  or?: Array< SearchableInventoryLogFilterInput | null > | null,
  not?: SearchableInventoryLogFilterInput | null,
};

export type SearchableInventoryLogSortInput = {
  field?: SearchableInventoryLogSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInventoryLogSortableFields {
  id = "id",
  inventory_id = "inventory_id",
  brand_id = "brand_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  sku = "sku",
  stock_date = "stock_date",
  quantity = "quantity",
  order_id = "order_id",
  order_product_id = "order_product_id",
  createdAt = "createdAt",
}


export type SearchableInventoryLogConnection = {
  __typename: "SearchableInventoryLogConnection",
  items:  Array<InventoryLog | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelInStockNotificationFilterInput = {
  id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInStockNotificationFilterInput | null > | null,
  or?: Array< ModelInStockNotificationFilterInput | null > | null,
  not?: ModelInStockNotificationFilterInput | null,
};

export type ModelInStockNotificationConnection = {
  __typename: "ModelInStockNotificationConnection",
  items:  Array<InStockNotification | null >,
  nextToken?: string | null,
};

export type SearchableInStockNotificationFilterInput = {
  id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  completed?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableInStockNotificationFilterInput | null > | null,
  or?: Array< SearchableInStockNotificationFilterInput | null > | null,
  not?: SearchableInStockNotificationFilterInput | null,
};

export type SearchableInStockNotificationSortInput = {
  field?: SearchableInStockNotificationSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInStockNotificationSortableFields {
  id = "id",
  buyer_id = "buyer_id",
  brand_id = "brand_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  completed = "completed",
  createdAt = "createdAt",
}


export type SearchableInStockNotificationConnection = {
  __typename: "SearchableInStockNotificationConnection",
  items:  Array<InStockNotification | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  brand_owner?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_type?: ModelStringInput | null,
  brand_content?: ModelStringInput | null,
  brand_imageKey?: ModelStringInput | null,
  brand_icon_imageKey?: ModelStringInput | null,
  brand_logo_imageKey?: ModelStringInput | null,
  brand_publication?: ModelStringInput | null,
  brand_retail_stores_number?: ModelStringInput | null,
  brand_category?: ModelStringInput | null,
  brand_target_age?: ModelStringInput | null,
  brand_target_gender?: ModelStringInput | null,
  brand_website_url?: ModelStringInput | null,
  brand_work_with_big_box?: ModelStringInput | null,
  brand_address?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_shippinng_fee?: ModelIntInput | null,
  brand_shippinng_fee_criteria?: ModelIntInput | null,
  brand_instagram_account?: ModelStringInput | null,
  brand_facebook_account?: ModelStringInput | null,
  brand_rating?: ModelFloatInput | null,
  brand_annual_turnover?: ModelIntInput | null,
  brand_public_status?: ModelStringInput | null,
  brand_howtoknowhomula?: ModelStringInput | null,
  brand_audit_status?: ModelIntInput | null,
  brand_stripe_id?: ModelStringInput | null,
  brand_first_order_fee?: ModelIntInput | null,
  brand_additional_order_fee?: ModelIntInput | null,
  brand_first_order_fee_referral?: ModelIntInput | null,
  brand_additional_order_fee_referral?: ModelIntInput | null,
  brand_direct_order_fee?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  designed_in?: ModelStringInput | null,
  established?: ModelStringInput | null,
  tagIds?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  brand_collections?: ModelStringInput | null,
  movie_url?: ModelStringInput | null,
  brand_modal_imageKeys?: ModelStringInput | null,
  brand_online_sale?: ModelBooleanInput | null,
  product_image_reprint?: ModelBooleanInput | null,
  brand_logo_reprint?: ModelBooleanInput | null,
  post_to_sns?: ModelBooleanInput | null,
  allow_direct_shipping?: ModelBooleanInput | null,
  allow_sell_before_buy?: ModelBooleanInput | null,
  other_terms?: ModelStringInput | null,
  featured_in?: ModelStringInput | null,
  maximum_wholesale_rate?: ModelFloatInput | null,
  exclude_zip_code?: ModelStringInput | null,
  is_sale_brand?: ModelBooleanInput | null,
  hubspot_company_id?: ModelStringInput | null,
  return_to_brand?: ModelBooleanInput | null,
  terms_of_sample?: ModelStringInput | null,
  auto_message_enabled?: ModelBooleanInput | null,
  auto_message?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  supplier_group_id?: ModelIDInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  individual_shippinng_fee?: ModelIntInput | null,
  individual_shippinng_fee_criteria?: ModelIntInput | null,
  individual_first_order_fee?: ModelIntInput | null,
  individual_additional_order_fee?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
};

export type SearchableBrandFilterInput = {
  id?: SearchableIDFilterInput | null,
  brand_owner?: SearchableIDFilterInput | null,
  brand_name?: SearchableStringFilterInput | null,
  brand_yomigana?: SearchableStringFilterInput | null,
  brand_type?: SearchableStringFilterInput | null,
  brand_content?: SearchableStringFilterInput | null,
  brand_imageKey?: SearchableStringFilterInput | null,
  brand_icon_imageKey?: SearchableStringFilterInput | null,
  brand_logo_imageKey?: SearchableStringFilterInput | null,
  brand_publication?: SearchableStringFilterInput | null,
  brand_retail_stores_number?: SearchableStringFilterInput | null,
  brand_category?: SearchableStringFilterInput | null,
  brand_target_age?: SearchableStringFilterInput | null,
  brand_target_gender?: SearchableStringFilterInput | null,
  brand_website_url?: SearchableStringFilterInput | null,
  brand_work_with_big_box?: SearchableStringFilterInput | null,
  brand_address?: SearchableStringFilterInput | null,
  brand_minimum_buy?: SearchableIntFilterInput | null,
  brand_additional_minimum_buy?: SearchableIntFilterInput | null,
  brand_shippinng_fee?: SearchableIntFilterInput | null,
  brand_shippinng_fee_criteria?: SearchableIntFilterInput | null,
  brand_instagram_account?: SearchableStringFilterInput | null,
  brand_facebook_account?: SearchableStringFilterInput | null,
  brand_rating?: SearchableFloatFilterInput | null,
  brand_annual_turnover?: SearchableIntFilterInput | null,
  brand_public_status?: SearchableStringFilterInput | null,
  brand_howtoknowhomula?: SearchableStringFilterInput | null,
  brand_audit_status?: SearchableIntFilterInput | null,
  brand_stripe_id?: SearchableStringFilterInput | null,
  brand_first_order_fee?: SearchableIntFilterInput | null,
  brand_additional_order_fee?: SearchableIntFilterInput | null,
  brand_first_order_fee_referral?: SearchableIntFilterInput | null,
  brand_additional_order_fee_referral?: SearchableIntFilterInput | null,
  brand_direct_order_fee?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  designed_in?: SearchableStringFilterInput | null,
  established?: SearchableStringFilterInput | null,
  tagIds?: SearchableStringFilterInput | null,
  tags?: SearchableStringFilterInput | null,
  brand_collections?: SearchableStringFilterInput | null,
  movie_url?: SearchableStringFilterInput | null,
  brand_modal_imageKeys?: SearchableStringFilterInput | null,
  brand_online_sale?: SearchableBooleanFilterInput | null,
  product_image_reprint?: SearchableBooleanFilterInput | null,
  brand_logo_reprint?: SearchableBooleanFilterInput | null,
  post_to_sns?: SearchableBooleanFilterInput | null,
  allow_direct_shipping?: SearchableBooleanFilterInput | null,
  allow_sell_before_buy?: SearchableBooleanFilterInput | null,
  other_terms?: SearchableStringFilterInput | null,
  featured_in?: SearchableStringFilterInput | null,
  maximum_wholesale_rate?: SearchableFloatFilterInput | null,
  exclude_zip_code?: SearchableStringFilterInput | null,
  is_sale_brand?: SearchableBooleanFilterInput | null,
  hubspot_company_id?: SearchableStringFilterInput | null,
  return_to_brand?: SearchableBooleanFilterInput | null,
  terms_of_sample?: SearchableStringFilterInput | null,
  auto_message_enabled?: SearchableBooleanFilterInput | null,
  auto_message?: SearchableStringFilterInput | null,
  sell_only_direct?: SearchableBooleanFilterInput | null,
  supplier_group_id?: SearchableIDFilterInput | null,
  individual_minimum_buy?: SearchableIntFilterInput | null,
  individual_shippinng_fee?: SearchableIntFilterInput | null,
  individual_shippinng_fee_criteria?: SearchableIntFilterInput | null,
  individual_first_order_fee?: SearchableIntFilterInput | null,
  individual_additional_order_fee?: SearchableIntFilterInput | null,
  individual_wholesale_rate?: SearchableFloatFilterInput | null,
  and?: Array< SearchableBrandFilterInput | null > | null,
  or?: Array< SearchableBrandFilterInput | null > | null,
  not?: SearchableBrandFilterInput | null,
};

export type SearchableBrandSortInput = {
  field?: SearchableBrandSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBrandSortableFields {
  id = "id",
  brand_owner = "brand_owner",
  brand_name = "brand_name",
  brand_yomigana = "brand_yomigana",
  brand_type = "brand_type",
  brand_content = "brand_content",
  brand_imageKey = "brand_imageKey",
  brand_icon_imageKey = "brand_icon_imageKey",
  brand_logo_imageKey = "brand_logo_imageKey",
  brand_publication = "brand_publication",
  brand_retail_stores_number = "brand_retail_stores_number",
  brand_category = "brand_category",
  brand_target_age = "brand_target_age",
  brand_target_gender = "brand_target_gender",
  brand_website_url = "brand_website_url",
  brand_work_with_big_box = "brand_work_with_big_box",
  brand_address = "brand_address",
  brand_minimum_buy = "brand_minimum_buy",
  brand_additional_minimum_buy = "brand_additional_minimum_buy",
  brand_shippinng_fee = "brand_shippinng_fee",
  brand_shippinng_fee_criteria = "brand_shippinng_fee_criteria",
  brand_instagram_account = "brand_instagram_account",
  brand_facebook_account = "brand_facebook_account",
  brand_rating = "brand_rating",
  brand_annual_turnover = "brand_annual_turnover",
  brand_public_status = "brand_public_status",
  brand_howtoknowhomula = "brand_howtoknowhomula",
  brand_audit_status = "brand_audit_status",
  brand_stripe_id = "brand_stripe_id",
  brand_first_order_fee = "brand_first_order_fee",
  brand_additional_order_fee = "brand_additional_order_fee",
  brand_first_order_fee_referral = "brand_first_order_fee_referral",
  brand_additional_order_fee_referral = "brand_additional_order_fee_referral",
  brand_direct_order_fee = "brand_direct_order_fee",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
  designed_in = "designed_in",
  established = "established",
  tagIds = "tagIds",
  tags = "tags",
  brand_collections = "brand_collections",
  movie_url = "movie_url",
  brand_modal_imageKeys = "brand_modal_imageKeys",
  brand_online_sale = "brand_online_sale",
  product_image_reprint = "product_image_reprint",
  brand_logo_reprint = "brand_logo_reprint",
  post_to_sns = "post_to_sns",
  allow_direct_shipping = "allow_direct_shipping",
  allow_sell_before_buy = "allow_sell_before_buy",
  other_terms = "other_terms",
  featured_in = "featured_in",
  maximum_wholesale_rate = "maximum_wholesale_rate",
  exclude_zip_code = "exclude_zip_code",
  is_sale_brand = "is_sale_brand",
  hubspot_company_id = "hubspot_company_id",
  return_to_brand = "return_to_brand",
  terms_of_sample = "terms_of_sample",
  auto_message_enabled = "auto_message_enabled",
  auto_message = "auto_message",
  sell_only_direct = "sell_only_direct",
  supplier_group_id = "supplier_group_id",
  individual_minimum_buy = "individual_minimum_buy",
  individual_shippinng_fee = "individual_shippinng_fee",
  individual_shippinng_fee_criteria = "individual_shippinng_fee_criteria",
  individual_first_order_fee = "individual_first_order_fee",
  individual_additional_order_fee = "individual_additional_order_fee",
  individual_wholesale_rate = "individual_wholesale_rate",
}


export type SearchableBrandConnection = {
  __typename: "SearchableBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  tag_name?: ModelStringInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
};

export type SearchableTagFilterInput = {
  id?: SearchableIDFilterInput | null,
  tag_name?: SearchableStringFilterInput | null,
  and?: Array< SearchableTagFilterInput | null > | null,
  or?: Array< SearchableTagFilterInput | null > | null,
  not?: SearchableTagFilterInput | null,
};

export type SearchableTagSortInput = {
  field?: SearchableTagSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTagSortableFields {
  id = "id",
  tag_name = "tag_name",
}


export type SearchableTagConnection = {
  __typename: "SearchableTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBrandCollectionFilterInput = {
  id?: ModelIDInput | null,
  collection_name?: ModelStringInput | null,
  visible?: ModelBooleanInput | null,
  and?: Array< ModelBrandCollectionFilterInput | null > | null,
  or?: Array< ModelBrandCollectionFilterInput | null > | null,
  not?: ModelBrandCollectionFilterInput | null,
};

export type ModelBrandCollectionConnection = {
  __typename: "ModelBrandCollectionConnection",
  items:  Array<BrandCollection | null >,
  nextToken?: string | null,
};

export type SearchableBrandCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  collection_name?: SearchableStringFilterInput | null,
  visible?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableBrandCollectionFilterInput | null > | null,
  or?: Array< SearchableBrandCollectionFilterInput | null > | null,
  not?: SearchableBrandCollectionFilterInput | null,
};

export type SearchableBrandCollectionSortInput = {
  field?: SearchableBrandCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBrandCollectionSortableFields {
  id = "id",
  collection_name = "collection_name",
  visible = "visible",
}


export type SearchableBrandCollectionConnection = {
  __typename: "SearchableBrandCollectionConnection",
  items:  Array<BrandCollection | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelShopCartFilterInput = {
  id?: ModelIDInput | null,
  shopcart_owner?: ModelStringInput | null,
  shopcart_quantity?: ModelIntInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  is_direct?: ModelBooleanInput | null,
  is_pay_directly?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  saved_for_later?: ModelBooleanInput | null,
  collection_id?: ModelIDInput | null,
  and?: Array< ModelShopCartFilterInput | null > | null,
  or?: Array< ModelShopCartFilterInput | null > | null,
  not?: ModelShopCartFilterInput | null,
};

export type ModelShopCartConnection = {
  __typename: "ModelShopCartConnection",
  items:  Array<ShopCart | null >,
  nextToken?: string | null,
};

export type SearchableShopCartFilterInput = {
  id?: SearchableIDFilterInput | null,
  shopcart_owner?: SearchableStringFilterInput | null,
  shopcart_quantity?: SearchableIntFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  is_direct?: SearchableBooleanFilterInput | null,
  is_pay_directly?: SearchableBooleanFilterInput | null,
  is_consign?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  saved_for_later?: SearchableBooleanFilterInput | null,
  collection_id?: SearchableIDFilterInput | null,
  and?: Array< SearchableShopCartFilterInput | null > | null,
  or?: Array< SearchableShopCartFilterInput | null > | null,
  not?: SearchableShopCartFilterInput | null,
};

export type SearchableShopCartSortInput = {
  field?: SearchableShopCartSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableShopCartSortableFields {
  id = "id",
  shopcart_owner = "shopcart_owner",
  shopcart_quantity = "shopcart_quantity",
  product_id = "product_id",
  product_type_id = "product_type_id",
  brand_id = "brand_id",
  is_direct = "is_direct",
  is_pay_directly = "is_pay_directly",
  is_consign = "is_consign",
  createdAt = "createdAt",
  saved_for_later = "saved_for_later",
  collection_id = "collection_id",
}


export type SearchableShopCartConnection = {
  __typename: "SearchableShopCartConnection",
  items:  Array<ShopCart | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  order_owner?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  brand_owner?: ModelIDInput | null,
  campaign_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  stripe_client_secret?: ModelStringInput | null,
  stripe_payment_method_id?: ModelStringInput | null,
  fee?: ModelIntInput | null,
  shipping_fee?: ModelIntInput | null,
  invoice_shipping_fee?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  payment_term?: ModelIntInput | null,
  shipping_zip_code?: ModelStringInput | null,
  shipping_address?: ModelStringInput | null,
  shipping_name?: ModelStringInput | null,
  shipping_phone_number?: ModelStringInput | null,
  shipping_date?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  tracking_number?: ModelStringInput | null,
  first_order?: ModelBooleanInput | null,
  order_points?: ModelIntInput | null,
  is_direct?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  transferred_date?: ModelStringInput | null,
  order_price?: ModelIntInput | null,
  origin_order_id?: ModelIDInput | null,
  mf_authorization_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  mf_canceled_transaction_id?: ModelStringInput | null,
  cashback?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  billing_count?: ModelIntInput | null,
  return_due_date?: ModelStringInput | null,
  bill_payment_status?: ModelBillPaymentStatusInput | null,
  memo?: ModelStringInput | null,
  split_source_id?: ModelIDInput | null,
  split_date?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type SearchableOrderFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_owner?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  brand_owner?: SearchableIDFilterInput | null,
  campaign_code?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  stripe_payment_id?: SearchableStringFilterInput | null,
  stripe_client_secret?: SearchableStringFilterInput | null,
  stripe_payment_method_id?: SearchableStringFilterInput | null,
  fee?: SearchableIntFilterInput | null,
  shipping_fee?: SearchableIntFilterInput | null,
  invoice_shipping_fee?: SearchableIntFilterInput | null,
  tax?: SearchableIntFilterInput | null,
  payment_term?: SearchableIntFilterInput | null,
  shipping_zip_code?: SearchableStringFilterInput | null,
  shipping_address?: SearchableStringFilterInput | null,
  shipping_name?: SearchableStringFilterInput | null,
  shipping_phone_number?: SearchableStringFilterInput | null,
  shipping_date?: SearchableStringFilterInput | null,
  carrier?: SearchableStringFilterInput | null,
  tracking_number?: SearchableStringFilterInput | null,
  first_order?: SearchableBooleanFilterInput | null,
  order_points?: SearchableIntFilterInput | null,
  is_direct?: SearchableBooleanFilterInput | null,
  is_consign?: SearchableBooleanFilterInput | null,
  transferred_date?: SearchableStringFilterInput | null,
  order_price?: SearchableIntFilterInput | null,
  origin_order_id?: SearchableIDFilterInput | null,
  mf_authorization_id?: SearchableStringFilterInput | null,
  mf_transaction_id?: SearchableStringFilterInput | null,
  mf_canceled_transaction_id?: SearchableStringFilterInput | null,
  cashback?: SearchableIntFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  discount_amount?: SearchableIntFilterInput | null,
  billing_count?: SearchableIntFilterInput | null,
  return_due_date?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  split_source_id?: SearchableIDFilterInput | null,
  split_date?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderFilterInput | null > | null,
  or?: Array< SearchableOrderFilterInput | null > | null,
  not?: SearchableOrderFilterInput | null,
};

export type SearchableOrderSortInput = {
  field?: SearchableOrderSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderSortableFields {
  id = "id",
  order_owner = "order_owner",
  brand_id = "brand_id",
  brand_owner = "brand_owner",
  campaign_code = "campaign_code",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owners = "owners",
  stripe_payment_id = "stripe_payment_id",
  stripe_client_secret = "stripe_client_secret",
  stripe_payment_method_id = "stripe_payment_method_id",
  fee = "fee",
  shipping_fee = "shipping_fee",
  invoice_shipping_fee = "invoice_shipping_fee",
  tax = "tax",
  payment_term = "payment_term",
  shipping_zip_code = "shipping_zip_code",
  shipping_address = "shipping_address",
  shipping_name = "shipping_name",
  shipping_phone_number = "shipping_phone_number",
  shipping_date = "shipping_date",
  carrier = "carrier",
  tracking_number = "tracking_number",
  first_order = "first_order",
  order_points = "order_points",
  is_direct = "is_direct",
  is_consign = "is_consign",
  transferred_date = "transferred_date",
  order_price = "order_price",
  origin_order_id = "origin_order_id",
  mf_authorization_id = "mf_authorization_id",
  mf_transaction_id = "mf_transaction_id",
  mf_canceled_transaction_id = "mf_canceled_transaction_id",
  cashback = "cashback",
  coupon_id = "coupon_id",
  discount_amount = "discount_amount",
  billing_count = "billing_count",
  return_due_date = "return_due_date",
  memo = "memo",
  split_source_id = "split_source_id",
  split_date = "split_date",
}


export type SearchableOrderConnection = {
  __typename: "SearchableOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelChargeStatusFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  status?: ModelIntInput | null,
  payment_id?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  and?: Array< ModelChargeStatusFilterInput | null > | null,
  or?: Array< ModelChargeStatusFilterInput | null > | null,
  not?: ModelChargeStatusFilterInput | null,
};

export type SearchableChargeStatusFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  status?: SearchableIntFilterInput | null,
  payment_id?: SearchableStringFilterInput | null,
  amount?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  and?: Array< SearchableChargeStatusFilterInput | null > | null,
  or?: Array< SearchableChargeStatusFilterInput | null > | null,
  not?: SearchableChargeStatusFilterInput | null,
};

export type SearchableChargeStatusSortInput = {
  field?: SearchableChargeStatusSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableChargeStatusSortableFields {
  id = "id",
  order_id = "order_id",
  status = "status",
  payment_id = "payment_id",
  amount = "amount",
  createdAt = "createdAt",
  owners = "owners",
}


export type SearchableChargeStatusConnection = {
  __typename: "SearchableChargeStatusConnection",
  items:  Array<ChargeStatus | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderProductFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  order_product_quantity?: ModelIntInput | null,
  order_product_price?: ModelFloatInput | null,
  order_product_wholesale_rate?: ModelFloatInput | null,
  discount_percentage?: ModelFloatInput | null,
  initial_order_product_quantity?: ModelIntInput | null,
  initial_order_product_price?: ModelFloatInput | null,
  initial_order_product_wholesale_rate?: ModelFloatInput | null,
  order_product_payment_status?: ModelIntInput | null,
  order_product_payment_method?: ModelIntInput | null,
  order_product_return_reason?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderProductFilterInput | null > | null,
  or?: Array< ModelOrderProductFilterInput | null > | null,
  not?: ModelOrderProductFilterInput | null,
};

export type SearchableOrderProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  order_product_quantity?: SearchableIntFilterInput | null,
  order_product_price?: SearchableFloatFilterInput | null,
  order_product_wholesale_rate?: SearchableFloatFilterInput | null,
  discount_percentage?: SearchableFloatFilterInput | null,
  initial_order_product_quantity?: SearchableIntFilterInput | null,
  initial_order_product_price?: SearchableFloatFilterInput | null,
  initial_order_product_wholesale_rate?: SearchableFloatFilterInput | null,
  order_product_payment_status?: SearchableIntFilterInput | null,
  order_product_payment_method?: SearchableIntFilterInput | null,
  order_product_return_reason?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderProductFilterInput | null > | null,
  or?: Array< SearchableOrderProductFilterInput | null > | null,
  not?: SearchableOrderProductFilterInput | null,
};

export type SearchableOrderProductSortInput = {
  field?: SearchableOrderProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderProductSortableFields {
  id = "id",
  order_id = "order_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  order_product_quantity = "order_product_quantity",
  order_product_price = "order_product_price",
  order_product_wholesale_rate = "order_product_wholesale_rate",
  discount_percentage = "discount_percentage",
  initial_order_product_quantity = "initial_order_product_quantity",
  initial_order_product_price = "initial_order_product_price",
  initial_order_product_wholesale_rate = "initial_order_product_wholesale_rate",
  order_product_payment_status = "order_product_payment_status",
  order_product_payment_method = "order_product_payment_method",
  order_product_return_reason = "order_product_return_reason",
  owners = "owners",
  createdAt = "createdAt",
}


export type SearchableOrderProductConnection = {
  __typename: "SearchableOrderProductConnection",
  items:  Array<OrderProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderStatusFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelIntInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderStatusFilterInput | null > | null,
  or?: Array< ModelOrderStatusFilterInput | null > | null,
  not?: ModelOrderStatusFilterInput | null,
};

export type SearchableOrderStatusFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  status?: SearchableIntFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderStatusFilterInput | null > | null,
  or?: Array< SearchableOrderStatusFilterInput | null > | null,
  not?: SearchableOrderStatusFilterInput | null,
};

export type SearchableOrderStatusSortInput = {
  field?: SearchableOrderStatusSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderStatusSortableFields {
  id = "id",
  order_id = "order_id",
  order_product_id = "order_product_id",
  quantity = "quantity",
  status = "status",
  owners = "owners",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableOrderStatusConnection = {
  __typename: "SearchableOrderStatusConnection",
  items:  Array<OrderStatus | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReturnProductFilterInput = {
  id?: ModelIDInput | null,
  return_product_id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  return_quantity?: ModelIntInput | null,
  return_product_price?: ModelIntInput | null,
  return_status?: ModelIntInput | null,
  stripe_payment_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  logiless_inbound_delivery_code?: ModelStringInput | null,
  and?: Array< ModelReturnProductFilterInput | null > | null,
  or?: Array< ModelReturnProductFilterInput | null > | null,
  not?: ModelReturnProductFilterInput | null,
};

export type SearchableReturnProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  return_product_id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  return_quantity?: SearchableIntFilterInput | null,
  return_product_price?: SearchableIntFilterInput | null,
  return_status?: SearchableIntFilterInput | null,
  stripe_payment_id?: SearchableStringFilterInput | null,
  mf_transaction_id?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  logiless_inbound_delivery_code?: SearchableStringFilterInput | null,
  and?: Array< SearchableReturnProductFilterInput | null > | null,
  or?: Array< SearchableReturnProductFilterInput | null > | null,
  not?: SearchableReturnProductFilterInput | null,
};

export type SearchableReturnProductSortInput = {
  field?: SearchableReturnProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReturnProductSortableFields {
  id = "id",
  return_product_id = "return_product_id",
  order_id = "order_id",
  order_product_id = "order_product_id",
  return_quantity = "return_quantity",
  return_product_price = "return_product_price",
  return_status = "return_status",
  stripe_payment_id = "stripe_payment_id",
  mf_transaction_id = "mf_transaction_id",
  owners = "owners",
  createdAt = "createdAt",
  logiless_inbound_delivery_code = "logiless_inbound_delivery_code",
}


export type SearchableReturnProductConnection = {
  __typename: "SearchableReturnProductConnection",
  items:  Array<ReturnProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReportProductFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  report_type?: ModelStringInput | null,
  request_type?: ModelStringInput | null,
  report_quantity?: ModelIntInput | null,
  report_product_price?: ModelIntInput | null,
  report_status?: ModelStringInput | null,
  report_comment?: ModelStringInput | null,
  replace_order_id?: ModelIDInput | null,
  replace_order_product_id?: ModelIDInput | null,
  approval_date?: ModelStringInput | null,
  approval_quantity?: ModelIntInput | null,
  image_keys?: ModelStringInput | null,
  stripe_refund_id?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReportProductFilterInput | null > | null,
  or?: Array< ModelReportProductFilterInput | null > | null,
  not?: ModelReportProductFilterInput | null,
};

export type SearchableReportProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  type?: SearchableStringFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  report_type?: SearchableStringFilterInput | null,
  request_type?: SearchableStringFilterInput | null,
  report_quantity?: SearchableIntFilterInput | null,
  report_product_price?: SearchableIntFilterInput | null,
  report_status?: SearchableStringFilterInput | null,
  report_comment?: SearchableStringFilterInput | null,
  replace_order_id?: SearchableIDFilterInput | null,
  replace_order_product_id?: SearchableIDFilterInput | null,
  approval_date?: SearchableStringFilterInput | null,
  approval_quantity?: SearchableIntFilterInput | null,
  image_keys?: SearchableStringFilterInput | null,
  stripe_refund_id?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableReportProductFilterInput | null > | null,
  or?: Array< SearchableReportProductFilterInput | null > | null,
  not?: SearchableReportProductFilterInput | null,
};

export type SearchableReportProductSortInput = {
  field?: SearchableReportProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReportProductSortableFields {
  id = "id",
  type = "type",
  order_id = "order_id",
  order_product_id = "order_product_id",
  report_type = "report_type",
  request_type = "request_type",
  report_quantity = "report_quantity",
  report_product_price = "report_product_price",
  report_status = "report_status",
  report_comment = "report_comment",
  replace_order_id = "replace_order_id",
  replace_order_product_id = "replace_order_product_id",
  approval_date = "approval_date",
  approval_quantity = "approval_quantity",
  image_keys = "image_keys",
  stripe_refund_id = "stripe_refund_id",
  owners = "owners",
  createdAt = "createdAt",
}


export type SearchableReportProductConnection = {
  __typename: "SearchableReportProductConnection",
  items:  Array<ReportProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFeedbackFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeedbackFilterInput | null > | null,
  or?: Array< ModelFeedbackFilterInput | null > | null,
  not?: ModelFeedbackFilterInput | null,
};

export type ModelFeedbackConnection = {
  __typename: "ModelFeedbackConnection",
  items:  Array<Feedback | null >,
  nextToken?: string | null,
};

export type SearchableFeedbackFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  feedback_comment?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFeedbackFilterInput | null > | null,
  or?: Array< SearchableFeedbackFilterInput | null > | null,
  not?: SearchableFeedbackFilterInput | null,
};

export type SearchableFeedbackSortInput = {
  field?: SearchableFeedbackSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFeedbackSortableFields {
  id = "id",
  order_id = "order_id",
  feedback_comment = "feedback_comment",
  createdAt = "createdAt",
}


export type SearchableFeedbackConnection = {
  __typename: "SearchableFeedbackConnection",
  items:  Array<Feedback | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderEditReasonFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  edit_reason?: ModelEditReasonInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderEditReasonFilterInput | null > | null,
  or?: Array< ModelOrderEditReasonFilterInput | null > | null,
  not?: ModelOrderEditReasonFilterInput | null,
};

export type SearchableOrderEditReasonFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  feedback_comment?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderEditReasonFilterInput | null > | null,
  or?: Array< SearchableOrderEditReasonFilterInput | null > | null,
  not?: SearchableOrderEditReasonFilterInput | null,
};

export type SearchableOrderEditReasonSortInput = {
  field?: SearchableOrderEditReasonSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderEditReasonSortableFields {
  id = "id",
  order_id = "order_id",
  feedback_comment = "feedback_comment",
  createdAt = "createdAt",
}


export type SearchableOrderEditReasonConnection = {
  __typename: "SearchableOrderEditReasonConnection",
  items:  Array<OrderEditReason | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderCancelReasonFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  cancel_reason?: ModelCancelReasonInput | null,
  feedback_comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderCancelReasonFilterInput | null > | null,
  or?: Array< ModelOrderCancelReasonFilterInput | null > | null,
  not?: ModelOrderCancelReasonFilterInput | null,
};

export type SearchableOrderCancelReasonFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  feedback_comment?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderCancelReasonFilterInput | null > | null,
  or?: Array< SearchableOrderCancelReasonFilterInput | null > | null,
  not?: SearchableOrderCancelReasonFilterInput | null,
};

export type SearchableOrderCancelReasonSortInput = {
  field?: SearchableOrderCancelReasonSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderCancelReasonSortableFields {
  id = "id",
  order_id = "order_id",
  feedback_comment = "feedback_comment",
  createdAt = "createdAt",
}


export type SearchableOrderCancelReasonConnection = {
  __typename: "SearchableOrderCancelReasonConnection",
  items:  Array<OrderCancelReason | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  thread_id?: ModelIDInput | null,
  message_owner?: ModelStringInput | null,
  message_recipient?: ModelIDInput | null,
  message_content?: ModelStringInput | null,
  message_product_name?: ModelStringInput | null,
  message_is_open?: ModelBooleanInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type SearchableMessageFilterInput = {
  id?: SearchableIDFilterInput | null,
  thread_id?: SearchableIDFilterInput | null,
  message_owner?: SearchableStringFilterInput | null,
  message_recipient?: SearchableIDFilterInput | null,
  message_content?: SearchableStringFilterInput | null,
  message_product_name?: SearchableStringFilterInput | null,
  message_is_open?: SearchableBooleanFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableMessageFilterInput | null > | null,
  or?: Array< SearchableMessageFilterInput | null > | null,
  not?: SearchableMessageFilterInput | null,
};

export type SearchableMessageSortInput = {
  field?: SearchableMessageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMessageSortableFields {
  id = "id",
  thread_id = "thread_id",
  message_owner = "message_owner",
  message_recipient = "message_recipient",
  message_content = "message_content",
  message_product_name = "message_product_name",
  message_is_open = "message_is_open",
  owners = "owners",
  createdAt = "createdAt",
}


export type SearchableMessageConnection = {
  __typename: "SearchableMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelThreadFilterInput = {
  id?: ModelIDInput | null,
  thread_buyer_owner?: ModelStringInput | null,
  thread_buyer_name?: ModelStringInput | null,
  thread_brand_owner?: ModelStringInput | null,
  thread_brand_id?: ModelIDInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelThreadFilterInput | null > | null,
  or?: Array< ModelThreadFilterInput | null > | null,
  not?: ModelThreadFilterInput | null,
};

export type ModelThreadConnection = {
  __typename: "ModelThreadConnection",
  items:  Array<Thread | null >,
  nextToken?: string | null,
};

export type SearchableThreadFilterInput = {
  id?: SearchableIDFilterInput | null,
  thread_buyer_owner?: SearchableStringFilterInput | null,
  thread_buyer_name?: SearchableStringFilterInput | null,
  thread_brand_owner?: SearchableStringFilterInput | null,
  thread_brand_id?: SearchableIDFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableThreadFilterInput | null > | null,
  or?: Array< SearchableThreadFilterInput | null > | null,
  not?: SearchableThreadFilterInput | null,
};

export type SearchableThreadSortInput = {
  field?: SearchableThreadSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableThreadSortableFields {
  id = "id",
  thread_buyer_owner = "thread_buyer_owner",
  thread_buyer_name = "thread_buyer_name",
  thread_brand_owner = "thread_brand_owner",
  thread_brand_id = "thread_brand_id",
  owners = "owners",
  createdAt = "createdAt",
}


export type SearchableThreadConnection = {
  __typename: "SearchableThreadConnection",
  items:  Array<Thread | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPickupProductFilterInput = {
  id?: ModelIDInput | null,
  pickup_list_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  display_order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPickupProductFilterInput | null > | null,
  or?: Array< ModelPickupProductFilterInput | null > | null,
  not?: ModelPickupProductFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type SearchablePickupProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  pickup_list_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  display_order?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePickupProductFilterInput | null > | null,
  or?: Array< SearchablePickupProductFilterInput | null > | null,
  not?: SearchablePickupProductFilterInput | null,
};

export type SearchablePickupProductSortInput = {
  field?: SearchablePickupProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePickupProductSortableFields {
  id = "id",
  pickup_list_id = "pickup_list_id",
  brand_id = "brand_id",
  product_id = "product_id",
  display_order = "display_order",
  createdAt = "createdAt",
}


export type SearchablePickupProductConnection = {
  __typename: "SearchablePickupProductConnection",
  items:  Array<PickupProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPickupBrandsFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  display_num?: ModelIntInput | null,
  visible?: ModelBooleanInput | null,
  item_type?: ModelPickupItemTypeInput | null,
  display_size?: ModelIntInput | null,
  and?: Array< ModelPickupBrandsFilterInput | null > | null,
  or?: Array< ModelPickupBrandsFilterInput | null > | null,
  not?: ModelPickupBrandsFilterInput | null,
};

export type ModelPickupBrandsConnection = {
  __typename: "ModelPickupBrandsConnection",
  items:  Array<PickupBrands | null >,
  nextToken?: string | null,
};

export type SearchablePickupBrandsFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  display_num?: SearchableIntFilterInput | null,
  visible?: SearchableBooleanFilterInput | null,
  display_size?: SearchableIntFilterInput | null,
  and?: Array< SearchablePickupBrandsFilterInput | null > | null,
  or?: Array< SearchablePickupBrandsFilterInput | null > | null,
  not?: SearchablePickupBrandsFilterInput | null,
};

export type SearchablePickupBrandsSortInput = {
  field?: SearchablePickupBrandsSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePickupBrandsSortableFields {
  id = "id",
  title = "title",
  display_num = "display_num",
  visible = "visible",
  display_size = "display_size",
}


export type SearchablePickupBrandsConnection = {
  __typename: "SearchablePickupBrandsConnection",
  items:  Array<PickupBrands | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelMenuFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  product_list_path?: ModelStringInput | null,
  brand_list_path?: ModelStringInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelMenuFilterInput | null > | null,
  or?: Array< ModelMenuFilterInput | null > | null,
  not?: ModelMenuFilterInput | null,
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection",
  items:  Array<Menu | null >,
  nextToken?: string | null,
};

export type SearchableMenuFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  product_list_path?: SearchableStringFilterInput | null,
  brand_list_path?: SearchableStringFilterInput | null,
  display_order?: SearchableIntFilterInput | null,
  and?: Array< SearchableMenuFilterInput | null > | null,
  or?: Array< SearchableMenuFilterInput | null > | null,
  not?: SearchableMenuFilterInput | null,
};

export type SearchableMenuSortInput = {
  field?: SearchableMenuSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMenuSortableFields {
  id = "id",
  title = "title",
  product_list_path = "product_list_path",
  brand_list_path = "brand_list_path",
  display_order = "display_order",
}


export type SearchableMenuConnection = {
  __typename: "SearchableMenuConnection",
  items:  Array<Menu | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFavoriteBrandFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteBrandFilterInput | null > | null,
  or?: Array< ModelFavoriteBrandFilterInput | null > | null,
  not?: ModelFavoriteBrandFilterInput | null,
};

export type ModelFavoriteBrandConnection = {
  __typename: "ModelFavoriteBrandConnection",
  items:  Array<FavoriteBrand | null >,
  nextToken?: string | null,
};

export type SearchableFavoriteBrandFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFavoriteBrandFilterInput | null > | null,
  or?: Array< SearchableFavoriteBrandFilterInput | null > | null,
  not?: SearchableFavoriteBrandFilterInput | null,
};

export type SearchableFavoriteBrandSortInput = {
  field?: SearchableFavoriteBrandSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFavoriteBrandSortableFields {
  id = "id",
  account_id = "account_id",
  brand_id = "brand_id",
  createdAt = "createdAt",
}


export type SearchableFavoriteBrandConnection = {
  __typename: "SearchableFavoriteBrandConnection",
  items:  Array<FavoriteBrand | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFavoriteProductFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  favorite_list_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteProductFilterInput | null > | null,
  or?: Array< ModelFavoriteProductFilterInput | null > | null,
  not?: ModelFavoriteProductFilterInput | null,
};

export type SearchableFavoriteProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  favorite_list_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  order?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFavoriteProductFilterInput | null > | null,
  or?: Array< SearchableFavoriteProductFilterInput | null > | null,
  not?: SearchableFavoriteProductFilterInput | null,
};

export type SearchableFavoriteProductSortInput = {
  field?: SearchableFavoriteProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFavoriteProductSortableFields {
  id = "id",
  account_id = "account_id",
  favorite_list_id = "favorite_list_id",
  product_id = "product_id",
  order = "order",
  createdAt = "createdAt",
}


export type SearchableFavoriteProductConnection = {
  __typename: "SearchableFavoriteProductConnection",
  items:  Array<FavoriteProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFavoriteProductListFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  list_name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFavoriteProductListFilterInput | null > | null,
  or?: Array< ModelFavoriteProductListFilterInput | null > | null,
  not?: ModelFavoriteProductListFilterInput | null,
};

export type ModelFavoriteProductListConnection = {
  __typename: "ModelFavoriteProductListConnection",
  items:  Array<FavoriteProductList | null >,
  nextToken?: string | null,
};

export type SearchableFavoriteProductListFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  list_name?: SearchableStringFilterInput | null,
  order?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFavoriteProductListFilterInput | null > | null,
  or?: Array< SearchableFavoriteProductListFilterInput | null > | null,
  not?: SearchableFavoriteProductListFilterInput | null,
};

export type SearchableFavoriteProductListSortInput = {
  field?: SearchableFavoriteProductListSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFavoriteProductListSortableFields {
  id = "id",
  account_id = "account_id",
  list_name = "list_name",
  order = "order",
  createdAt = "createdAt",
}


export type SearchableFavoriteProductListConnection = {
  __typename: "SearchableFavoriteProductListConnection",
  items:  Array<FavoriteProductList | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCollectionFilterInput = {
  id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  src_brand_id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  video_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  preorder?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  season?: ModelStringInput | null,
  season_year?: ModelIntInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelCollectionFilterInput | null > | null,
  or?: Array< ModelCollectionFilterInput | null > | null,
  not?: ModelCollectionFilterInput | null,
};

export type SearchableCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  src_brand_id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  image_key?: SearchableStringFilterInput | null,
  video_key?: SearchableStringFilterInput | null,
  published?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  preorder?: SearchableBooleanFilterInput | null,
  is_consign?: SearchableBooleanFilterInput | null,
  season?: SearchableStringFilterInput | null,
  season_year?: SearchableIntFilterInput | null,
  display_order?: SearchableIntFilterInput | null,
  and?: Array< SearchableCollectionFilterInput | null > | null,
  or?: Array< SearchableCollectionFilterInput | null > | null,
  not?: SearchableCollectionFilterInput | null,
};

export type SearchableCollectionSortInput = {
  field?: SearchableCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCollectionSortableFields {
  id = "id",
  brand_id = "brand_id",
  src_brand_id = "src_brand_id",
  name = "name",
  description = "description",
  image_key = "image_key",
  video_key = "video_key",
  published = "published",
  createdAt = "createdAt",
  owner = "owner",
  preorder = "preorder",
  is_consign = "is_consign",
  season = "season",
  season_year = "season_year",
  display_order = "display_order",
}


export type SearchableCollectionConnection = {
  __typename: "SearchableCollectionConnection",
  items:  Array<Collection | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProductCollectionFilterInput = {
  id?: ModelIDInput | null,
  collection_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  sort_order?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductCollectionFilterInput | null > | null,
  or?: Array< ModelProductCollectionFilterInput | null > | null,
  not?: ModelProductCollectionFilterInput | null,
};

export type SearchableProductCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  collection_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  sort_order?: SearchableIntFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableProductCollectionFilterInput | null > | null,
  or?: Array< SearchableProductCollectionFilterInput | null > | null,
  not?: SearchableProductCollectionFilterInput | null,
};

export type SearchableProductCollectionSortInput = {
  field?: SearchableProductCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProductCollectionSortableFields {
  id = "id",
  collection_id = "collection_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
  quantity = "quantity",
  sort_order = "sort_order",
  owner = "owner",
}


export type SearchableProductCollectionConnection = {
  __typename: "SearchableProductCollectionConnection",
  items:  Array<ProductCollection | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReferralFilterInput = {
  id?: ModelIDInput | null,
  referral_type?: ModelIntInput | null,
  referral_email?: ModelStringInput | null,
  referral_name?: ModelStringInput | null,
  referral_website?: ModelStringInput | null,
  referral_domain?: ModelStringInput | null,
  referral_by?: ModelStringInput | null,
  referral_status?: ModelIntInput | null,
  referral_brand_id?: ModelStringInput | null,
  referral_account_id?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelReferralFilterInput | null > | null,
  or?: Array< ModelReferralFilterInput | null > | null,
  not?: ModelReferralFilterInput | null,
};

export type ModelReferralConnection = {
  __typename: "ModelReferralConnection",
  items:  Array<Referral | null >,
  nextToken?: string | null,
};

export type SearchableReferralFilterInput = {
  id?: SearchableIDFilterInput | null,
  referral_type?: SearchableIntFilterInput | null,
  referral_email?: SearchableStringFilterInput | null,
  referral_name?: SearchableStringFilterInput | null,
  referral_website?: SearchableStringFilterInput | null,
  referral_domain?: SearchableStringFilterInput | null,
  referral_by?: SearchableStringFilterInput | null,
  referral_status?: SearchableIntFilterInput | null,
  referral_brand_id?: SearchableStringFilterInput | null,
  referral_account_id?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  and?: Array< SearchableReferralFilterInput | null > | null,
  or?: Array< SearchableReferralFilterInput | null > | null,
  not?: SearchableReferralFilterInput | null,
};

export type SearchableReferralSortInput = {
  field?: SearchableReferralSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReferralSortableFields {
  id = "id",
  referral_type = "referral_type",
  referral_email = "referral_email",
  referral_name = "referral_name",
  referral_website = "referral_website",
  referral_domain = "referral_domain",
  referral_by = "referral_by",
  referral_status = "referral_status",
  referral_brand_id = "referral_brand_id",
  referral_account_id = "referral_account_id",
  createdAt = "createdAt",
  owner = "owner",
}


export type SearchableReferralConnection = {
  __typename: "SearchableReferralConnection",
  items:  Array<Referral | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReferralMessageFilterInput = {
  id?: ModelIDInput | null,
  from?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelReferralMessageFilterInput | null > | null,
  or?: Array< ModelReferralMessageFilterInput | null > | null,
  not?: ModelReferralMessageFilterInput | null,
};

export type ModelReferralMessageConnection = {
  __typename: "ModelReferralMessageConnection",
  items:  Array<ReferralMessage | null >,
  nextToken?: string | null,
};

export type SearchableReferralMessageFilterInput = {
  id?: SearchableIDFilterInput | null,
  from?: SearchableStringFilterInput | null,
  subject?: SearchableStringFilterInput | null,
  message?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableReferralMessageFilterInput | null > | null,
  or?: Array< SearchableReferralMessageFilterInput | null > | null,
  not?: SearchableReferralMessageFilterInput | null,
};

export type SearchableReferralMessageSortInput = {
  field?: SearchableReferralMessageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReferralMessageSortableFields {
  id = "id",
  from = "from",
  subject = "subject",
  message = "message",
  owner = "owner",
}


export type SearchableReferralMessageConnection = {
  __typename: "SearchableReferralMessageConnection",
  items:  Array<ReferralMessage | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPointFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  points?: ModelIntInput | null,
  point_type?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  referral_id?: ModelIDInput | null,
  referral_brand_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  review_id?: ModelIDInput | null,
  and?: Array< ModelPointFilterInput | null > | null,
  or?: Array< ModelPointFilterInput | null > | null,
  not?: ModelPointFilterInput | null,
};

export type ModelPointConnection = {
  __typename: "ModelPointConnection",
  items:  Array<Point | null >,
  nextToken?: string | null,
};

export type SearchablePointFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  points?: SearchableIntFilterInput | null,
  point_type?: SearchableIntFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  referral_id?: SearchableIDFilterInput | null,
  referral_brand_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  duration?: SearchableIntFilterInput | null,
  review_id?: SearchableIDFilterInput | null,
  and?: Array< SearchablePointFilterInput | null > | null,
  or?: Array< SearchablePointFilterInput | null > | null,
  not?: SearchablePointFilterInput | null,
};

export type SearchablePointSortInput = {
  field?: SearchablePointSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePointSortableFields {
  id = "id",
  account_id = "account_id",
  points = "points",
  point_type = "point_type",
  order_id = "order_id",
  referral_id = "referral_id",
  referral_brand_id = "referral_brand_id",
  createdAt = "createdAt",
  duration = "duration",
  review_id = "review_id",
}


export type SearchablePointConnection = {
  __typename: "SearchablePointConnection",
  items:  Array<Point | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPointHistoryFilterInput = {
  id?: ModelIDInput | null,
  point_id?: ModelIDInput | null,
  account_id?: ModelIDInput | null,
  points?: ModelIntInput | null,
  history_type?: ModelIntInput | null,
  order_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPointHistoryFilterInput | null > | null,
  or?: Array< ModelPointHistoryFilterInput | null > | null,
  not?: ModelPointHistoryFilterInput | null,
};

export type SearchablePointHistoryFilterInput = {
  id?: SearchableIDFilterInput | null,
  point_id?: SearchableIDFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  points?: SearchableIntFilterInput | null,
  history_type?: SearchableIntFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePointHistoryFilterInput | null > | null,
  or?: Array< SearchablePointHistoryFilterInput | null > | null,
  not?: SearchablePointHistoryFilterInput | null,
};

export type SearchablePointHistorySortInput = {
  field?: SearchablePointHistorySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePointHistorySortableFields {
  id = "id",
  point_id = "point_id",
  account_id = "account_id",
  points = "points",
  history_type = "history_type",
  order_id = "order_id",
  createdAt = "createdAt",
}


export type SearchablePointHistoryConnection = {
  __typename: "SearchablePointHistoryConnection",
  items:  Array<PointHistory | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null,
  supplier_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_owner?: ModelIDInput | null,
  email?: ModelStringInput | null,
  store_name?: ModelStringInput | null,
  contact_name?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  address?: ModelStringInput | null,
  discount_rate?: ModelIntInput | null,
  contact_status?: ModelStringInput | null,
  contact_source?: ModelStringInput | null,
  direct_payment?: ModelBooleanInput | null,
  contacted?: ModelBooleanInput | null,
  signedup?: ModelBooleanInput | null,
  ordered?: ModelBooleanInput | null,
  invited?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelContactFilterInput | null > | null,
  or?: Array< ModelContactFilterInput | null > | null,
  not?: ModelContactFilterInput | null,
};

export type SearchableContactFilterInput = {
  id?: SearchableIDFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_owner?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  store_name?: SearchableStringFilterInput | null,
  contact_name?: SearchableStringFilterInput | null,
  tel?: SearchableStringFilterInput | null,
  zipcode?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  discount_rate?: SearchableIntFilterInput | null,
  contact_status?: SearchableStringFilterInput | null,
  contact_source?: SearchableStringFilterInput | null,
  direct_payment?: SearchableBooleanFilterInput | null,
  contacted?: SearchableBooleanFilterInput | null,
  signedup?: SearchableBooleanFilterInput | null,
  ordered?: SearchableBooleanFilterInput | null,
  invited?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  and?: Array< SearchableContactFilterInput | null > | null,
  or?: Array< SearchableContactFilterInput | null > | null,
  not?: SearchableContactFilterInput | null,
};

export type SearchableContactSortInput = {
  field?: SearchableContactSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactSortableFields {
  id = "id",
  supplier_id = "supplier_id",
  buyer_id = "buyer_id",
  buyer_owner = "buyer_owner",
  email = "email",
  store_name = "store_name",
  contact_name = "contact_name",
  tel = "tel",
  zipcode = "zipcode",
  address = "address",
  discount_rate = "discount_rate",
  contact_status = "contact_status",
  contact_source = "contact_source",
  direct_payment = "direct_payment",
  contacted = "contacted",
  signedup = "signedup",
  ordered = "ordered",
  invited = "invited",
  createdAt = "createdAt",
  owner = "owner",
}


export type SearchableContactConnection = {
  __typename: "SearchableContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelContactListFilterInput = {
  id?: ModelIDInput | null,
  list_name?: ModelStringInput | null,
  supplier_id?: ModelIDInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelContactListFilterInput | null > | null,
  or?: Array< ModelContactListFilterInput | null > | null,
  not?: ModelContactListFilterInput | null,
};

export type ModelContactListConnection = {
  __typename: "ModelContactListConnection",
  items:  Array<ContactList | null >,
  nextToken?: string | null,
};

export type ModelContactListBySupplierCompositeKeyConditionInput = {
  eq?: ModelContactListBySupplierCompositeKeyInput | null,
  le?: ModelContactListBySupplierCompositeKeyInput | null,
  lt?: ModelContactListBySupplierCompositeKeyInput | null,
  ge?: ModelContactListBySupplierCompositeKeyInput | null,
  gt?: ModelContactListBySupplierCompositeKeyInput | null,
  between?: Array< ModelContactListBySupplierCompositeKeyInput | null > | null,
  beginsWith?: ModelContactListBySupplierCompositeKeyInput | null,
};

export type ModelContactListBySupplierCompositeKeyInput = {
  order?: number | null,
  createdAt?: string | null,
};

export type SearchableContactListFilterInput = {
  id?: SearchableIDFilterInput | null,
  list_name?: SearchableStringFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  order?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  and?: Array< SearchableContactListFilterInput | null > | null,
  or?: Array< SearchableContactListFilterInput | null > | null,
  not?: SearchableContactListFilterInput | null,
};

export type SearchableContactListSortInput = {
  field?: SearchableContactListSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactListSortableFields {
  id = "id",
  list_name = "list_name",
  supplier_id = "supplier_id",
  order = "order",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export type SearchableContactListConnection = {
  __typename: "SearchableContactListConnection",
  items:  Array<ContactList | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelContactListConnectionFilterInput = {
  id?: ModelIDInput | null,
  supplier_id?: ModelIDInput | null,
  contact_list_id?: ModelIDInput | null,
  contact_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelContactListConnectionFilterInput | null > | null,
  or?: Array< ModelContactListConnectionFilterInput | null > | null,
  not?: ModelContactListConnectionFilterInput | null,
};

export type SearchableContactListConnectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  contact_list_id?: SearchableIDFilterInput | null,
  contact_id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  and?: Array< SearchableContactListConnectionFilterInput | null > | null,
  or?: Array< SearchableContactListConnectionFilterInput | null > | null,
  not?: SearchableContactListConnectionFilterInput | null,
};

export type SearchableContactListConnectionSortInput = {
  field?: SearchableContactListConnectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactListConnectionSortableFields {
  id = "id",
  supplier_id = "supplier_id",
  contact_list_id = "contact_list_id",
  contact_id = "contact_id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  owner = "owner",
}


export type SearchableContactListConnectionConnection = {
  __typename: "SearchableContactListConnectionConnection",
  items:  Array<ContactListConnection | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelContactActivityFilterInput = {
  id?: ModelIDInput | null,
  contact_id?: ModelIDInput | null,
  activity_date?: ModelStringInput | null,
  activity_type?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  message_id?: ModelIDInput | null,
  campaign_id?: ModelIDInput | null,
  activity?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelContactActivityFilterInput | null > | null,
  or?: Array< ModelContactActivityFilterInput | null > | null,
  not?: ModelContactActivityFilterInput | null,
};

export type SearchableContactActivityFilterInput = {
  id?: SearchableIDFilterInput | null,
  contact_id?: SearchableIDFilterInput | null,
  activity_date?: SearchableStringFilterInput | null,
  activity_type?: SearchableStringFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  message_id?: SearchableIDFilterInput | null,
  campaign_id?: SearchableIDFilterInput | null,
  activity?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableContactActivityFilterInput | null > | null,
  or?: Array< SearchableContactActivityFilterInput | null > | null,
  not?: SearchableContactActivityFilterInput | null,
};

export type SearchableContactActivitySortInput = {
  field?: SearchableContactActivitySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactActivitySortableFields {
  id = "id",
  contact_id = "contact_id",
  activity_date = "activity_date",
  activity_type = "activity_type",
  order_id = "order_id",
  message_id = "message_id",
  campaign_id = "campaign_id",
  activity = "activity",
  owner = "owner",
}


export type SearchableContactActivityConnection = {
  __typename: "SearchableContactActivityConnection",
  items:  Array<ContactActivity | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCampaignFilterInput = {
  id?: ModelIDInput | null,
  supplier_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  campaign_sender?: ModelStringInput | null,
  campaign_from?: ModelStringInput | null,
  campaign_title?: ModelStringInput | null,
  campaign_status?: ModelStringInput | null,
  campaign_subject?: ModelStringInput | null,
  preview_text?: ModelStringInput | null,
  campaign_template?: ModelStringInput | null,
  campaign_message?: ModelStringInput | null,
  campaign_components?: ModelStringInput | null,
  campaign_style?: ModelStringInput | null,
  campaign_segments?: ModelStringInput | null,
  deliveryTime?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCampaignFilterInput | null > | null,
  or?: Array< ModelCampaignFilterInput | null > | null,
  not?: ModelCampaignFilterInput | null,
};

export type ModelCampaignConnection = {
  __typename: "ModelCampaignConnection",
  items:  Array<Campaign | null >,
  nextToken?: string | null,
};

export type SearchableCampaignFilterInput = {
  id?: SearchableIDFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  campaign_sender?: SearchableStringFilterInput | null,
  campaign_from?: SearchableStringFilterInput | null,
  campaign_title?: SearchableStringFilterInput | null,
  campaign_status?: SearchableStringFilterInput | null,
  campaign_subject?: SearchableStringFilterInput | null,
  preview_text?: SearchableStringFilterInput | null,
  campaign_template?: SearchableStringFilterInput | null,
  campaign_message?: SearchableStringFilterInput | null,
  campaign_components?: SearchableStringFilterInput | null,
  campaign_style?: SearchableStringFilterInput | null,
  campaign_segments?: SearchableStringFilterInput | null,
  deliveryTime?: SearchableStringFilterInput | null,
  sentAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchableCampaignFilterInput | null > | null,
  or?: Array< SearchableCampaignFilterInput | null > | null,
  not?: SearchableCampaignFilterInput | null,
};

export type SearchableCampaignSortInput = {
  field?: SearchableCampaignSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCampaignSortableFields {
  id = "id",
  supplier_id = "supplier_id",
  brand_id = "brand_id",
  campaign_sender = "campaign_sender",
  campaign_from = "campaign_from",
  campaign_title = "campaign_title",
  campaign_status = "campaign_status",
  campaign_subject = "campaign_subject",
  preview_text = "preview_text",
  campaign_template = "campaign_template",
  campaign_message = "campaign_message",
  campaign_components = "campaign_components",
  campaign_style = "campaign_style",
  campaign_segments = "campaign_segments",
  deliveryTime = "deliveryTime",
  sentAt = "sentAt",
  createdAt = "createdAt",
  owner = "owner",
}


export type SearchableCampaignConnection = {
  __typename: "SearchableCampaignConnection",
  items:  Array<Campaign | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCampaignContactFilterInput = {
  id?: ModelIDInput | null,
  supplier_id?: ModelIDInput | null,
  campaign_id?: ModelIDInput | null,
  contact_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  source?: ModelStringInput | null,
  contact_list_id?: ModelStringInput | null,
  status?: ModelStringInput | null,
  clicks?: ModelIntInput | null,
  views?: ModelIntInput | null,
  sentAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCampaignContactFilterInput | null > | null,
  or?: Array< ModelCampaignContactFilterInput | null > | null,
  not?: ModelCampaignContactFilterInput | null,
};

export type SearchableCampaignContactFilterInput = {
  id?: SearchableIDFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  campaign_id?: SearchableIDFilterInput | null,
  contact_id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  source?: SearchableStringFilterInput | null,
  contact_list_id?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  clicks?: SearchableIntFilterInput | null,
  views?: SearchableIntFilterInput | null,
  sentAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableCampaignContactFilterInput | null > | null,
  or?: Array< SearchableCampaignContactFilterInput | null > | null,
  not?: SearchableCampaignContactFilterInput | null,
};

export type SearchableCampaignContactSortInput = {
  field?: SearchableCampaignContactSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCampaignContactSortableFields {
  id = "id",
  supplier_id = "supplier_id",
  campaign_id = "campaign_id",
  contact_id = "contact_id",
  email = "email",
  source = "source",
  contact_list_id = "contact_list_id",
  status = "status",
  clicks = "clicks",
  views = "views",
  sentAt = "sentAt",
  owner = "owner",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCampaignContactConnection = {
  __typename: "SearchableCampaignContactConnection",
  items:  Array<CampaignContact | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelDailySessionFilterInput = {
  brand_id?: ModelIDInput | null,
  session_date?: ModelStringInput | null,
  session_count?: ModelIntInput | null,
  and?: Array< ModelDailySessionFilterInput | null > | null,
  or?: Array< ModelDailySessionFilterInput | null > | null,
  not?: ModelDailySessionFilterInput | null,
};

export type ModelDailySessionConnection = {
  __typename: "ModelDailySessionConnection",
  items:  Array<DailySession | null >,
  nextToken?: string | null,
};

export type SearchableDailySessionFilterInput = {
  brand_id?: SearchableIDFilterInput | null,
  session_date?: SearchableStringFilterInput | null,
  session_count?: SearchableIntFilterInput | null,
  and?: Array< SearchableDailySessionFilterInput | null > | null,
  or?: Array< SearchableDailySessionFilterInput | null > | null,
  not?: SearchableDailySessionFilterInput | null,
};

export type SearchableDailySessionSortInput = {
  field?: SearchableDailySessionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDailySessionSortableFields {
  brand_id = "brand_id",
  session_date = "session_date",
  session_count = "session_count",
}


export type SearchableDailySessionConnection = {
  __typename: "SearchableDailySessionConnection",
  items:  Array<DailySession | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelConversionRateFilterInput = {
  brand_id?: ModelIDInput | null,
  order?: ModelIntInput | null,
  click?: ModelIntInput | null,
  conversion?: ModelFloatInput | null,
  and?: Array< ModelConversionRateFilterInput | null > | null,
  or?: Array< ModelConversionRateFilterInput | null > | null,
  not?: ModelConversionRateFilterInput | null,
};

export type ModelConversionRateConnection = {
  __typename: "ModelConversionRateConnection",
  items:  Array<ConversionRate | null >,
  nextToken?: string | null,
};

export type SearchableConversionRateFilterInput = {
  brand_id?: SearchableIDFilterInput | null,
  order?: SearchableIntFilterInput | null,
  click?: SearchableIntFilterInput | null,
  conversion?: SearchableFloatFilterInput | null,
  and?: Array< SearchableConversionRateFilterInput | null > | null,
  or?: Array< SearchableConversionRateFilterInput | null > | null,
  not?: SearchableConversionRateFilterInput | null,
};

export type SearchableConversionRateSortInput = {
  field?: SearchableConversionRateSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableConversionRateSortableFields {
  brand_id = "brand_id",
  order = "order",
  click = "click",
  conversion = "conversion",
}


export type SearchableConversionRateConnection = {
  __typename: "SearchableConversionRateConnection",
  items:  Array<ConversionRate | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPageViewFilterInput = {
  brand_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  page_view?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPageViewFilterInput | null > | null,
  or?: Array< ModelPageViewFilterInput | null > | null,
  not?: ModelPageViewFilterInput | null,
};

export type SearchablePageViewFilterInput = {
  brand_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  page_view?: SearchableIntFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  and?: Array< SearchablePageViewFilterInput | null > | null,
  or?: Array< SearchablePageViewFilterInput | null > | null,
  not?: SearchablePageViewFilterInput | null,
};

export type SearchablePageViewSortInput = {
  field?: SearchablePageViewSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePageViewSortableFields {
  brand_id = "brand_id",
  buyer_id = "buyer_id",
  email = "email",
  page_view = "page_view",
  owner = "owner",
}


export type SearchablePageViewConnection = {
  __typename: "SearchablePageViewConnection",
  items:  Array<PageView | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  product_ids?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  rate?: ModelIntInput | null,
  title?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  remove_flag?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type SearchableReviewFilterInput = {
  id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  product_ids?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  rate?: SearchableIntFilterInput | null,
  title?: SearchableStringFilterInput | null,
  comment?: SearchableStringFilterInput | null,
  remove_flag?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableReviewFilterInput | null > | null,
  or?: Array< SearchableReviewFilterInput | null > | null,
  not?: SearchableReviewFilterInput | null,
};

export type SearchableReviewSortInput = {
  field?: SearchableReviewSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReviewSortableFields {
  id = "id",
  brand_id = "brand_id",
  product_ids = "product_ids",
  buyer_id = "buyer_id",
  order_id = "order_id",
  rate = "rate",
  title = "title",
  comment = "comment",
  remove_flag = "remove_flag",
  createdAt = "createdAt",
}


export type SearchableReviewConnection = {
  __typename: "SearchableReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReviewReplyFilterInput = {
  id?: ModelIDInput | null,
  review_id?: ModelIDInput | null,
  brand_id?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReviewReplyFilterInput | null > | null,
  or?: Array< ModelReviewReplyFilterInput | null > | null,
  not?: ModelReviewReplyFilterInput | null,
};

export type SearchableReviewReplyFilterInput = {
  id?: SearchableIDFilterInput | null,
  review_id?: SearchableIDFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  comment?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableReviewReplyFilterInput | null > | null,
  or?: Array< SearchableReviewReplyFilterInput | null > | null,
  not?: SearchableReviewReplyFilterInput | null,
};

export type SearchableReviewReplySortInput = {
  field?: SearchableReviewReplySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReviewReplySortableFields {
  id = "id",
  review_id = "review_id",
  brand_id = "brand_id",
  comment = "comment",
  createdAt = "createdAt",
}


export type SearchableReviewReplyConnection = {
  __typename: "SearchableReviewReplyConnection",
  items:  Array<ReviewReply | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCouponFilterInput = {
  id?: ModelIDInput | null,
  coupon_name?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  brand_ids?: ModelStringInput | null,
  discount_type?: ModelDiscountTypeInput | null,
  discount_value?: ModelFloatInput | null,
  maximum_amount?: ModelFloatInput | null,
  available_times?: ModelIntInput | null,
  duration?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCouponFilterInput | null > | null,
  or?: Array< ModelCouponFilterInput | null > | null,
  not?: ModelCouponFilterInput | null,
};

export type ModelCouponConnection = {
  __typename: "ModelCouponConnection",
  items:  Array<Coupon | null >,
  nextToken?: string | null,
};

export type SearchableCouponFilterInput = {
  id?: SearchableIDFilterInput | null,
  coupon_name?: SearchableStringFilterInput | null,
  start_date?: SearchableStringFilterInput | null,
  end_date?: SearchableStringFilterInput | null,
  brand_ids?: SearchableStringFilterInput | null,
  discount_value?: SearchableFloatFilterInput | null,
  maximum_amount?: SearchableFloatFilterInput | null,
  available_times?: SearchableIntFilterInput | null,
  duration?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableCouponFilterInput | null > | null,
  or?: Array< SearchableCouponFilterInput | null > | null,
  not?: SearchableCouponFilterInput | null,
};

export type SearchableCouponSortInput = {
  field?: SearchableCouponSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCouponSortableFields {
  id = "id",
  coupon_name = "coupon_name",
  start_date = "start_date",
  end_date = "end_date",
  brand_ids = "brand_ids",
  discount_value = "discount_value",
  maximum_amount = "maximum_amount",
  available_times = "available_times",
  duration = "duration",
  createdAt = "createdAt",
}


export type SearchableCouponConnection = {
  __typename: "SearchableCouponConnection",
  items:  Array<Coupon | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCouponLogFilterInput = {
  id?: ModelIDInput | null,
  coupon_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  order_ids?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCouponLogFilterInput | null > | null,
  or?: Array< ModelCouponLogFilterInput | null > | null,
  not?: ModelCouponLogFilterInput | null,
};

export type SearchableCouponLogFilterInput = {
  id?: SearchableIDFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  order_ids?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableCouponLogFilterInput | null > | null,
  or?: Array< SearchableCouponLogFilterInput | null > | null,
  not?: SearchableCouponLogFilterInput | null,
};

export type SearchableCouponLogSortInput = {
  field?: SearchableCouponLogSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCouponLogSortableFields {
  id = "id",
  coupon_id = "coupon_id",
  buyer_id = "buyer_id",
  order_ids = "order_ids",
  createdAt = "createdAt",
}


export type SearchableCouponLogConnection = {
  __typename: "SearchableCouponLogConnection",
  items:  Array<CouponLog | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBuyerCouponFilterInput = {
  id?: ModelIDInput | null,
  coupon_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  max_redemptions?: ModelIntInput | null,
  times_redeemed?: ModelIntInput | null,
  redemptions_left?: ModelIntInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBuyerCouponFilterInput | null > | null,
  or?: Array< ModelBuyerCouponFilterInput | null > | null,
  not?: ModelBuyerCouponFilterInput | null,
};

export type SearchableBuyerCouponFilterInput = {
  id?: SearchableIDFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  max_redemptions?: SearchableIntFilterInput | null,
  times_redeemed?: SearchableIntFilterInput | null,
  redemptions_left?: SearchableIntFilterInput | null,
  expiresAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableBuyerCouponFilterInput | null > | null,
  or?: Array< SearchableBuyerCouponFilterInput | null > | null,
  not?: SearchableBuyerCouponFilterInput | null,
};

export type SearchableBuyerCouponSortInput = {
  field?: SearchableBuyerCouponSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBuyerCouponSortableFields {
  id = "id",
  coupon_id = "coupon_id",
  buyer_id = "buyer_id",
  max_redemptions = "max_redemptions",
  times_redeemed = "times_redeemed",
  redemptions_left = "redemptions_left",
  expiresAt = "expiresAt",
  createdAt = "createdAt",
}


export type SearchableBuyerCouponConnection = {
  __typename: "SearchableBuyerCouponConnection",
  items:  Array<BuyerCoupon | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCashbackFilterInput = {
  id?: ModelIDInput | null,
  coupon_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  order_id?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCashbackFilterInput | null > | null,
  or?: Array< ModelCashbackFilterInput | null > | null,
  not?: ModelCashbackFilterInput | null,
};

export type ModelCashbackConnection = {
  __typename: "ModelCashbackConnection",
  items:  Array<Cashback | null >,
  nextToken?: string | null,
};

export type SearchableCashbackFilterInput = {
  id?: SearchableIDFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  order_id?: SearchableStringFilterInput | null,
  redeemed?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableCashbackFilterInput | null > | null,
  or?: Array< SearchableCashbackFilterInput | null > | null,
  not?: SearchableCashbackFilterInput | null,
};

export type SearchableCashbackSortInput = {
  field?: SearchableCashbackSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCashbackSortableFields {
  id = "id",
  coupon_id = "coupon_id",
  buyer_id = "buyer_id",
  order_id = "order_id",
  redeemed = "redeemed",
  createdAt = "createdAt",
}


export type SearchableCashbackConnection = {
  __typename: "SearchableCashbackConnection",
  items:  Array<Cashback | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelStockProductFilterInput = {
  id?: ModelIDInput | null,
  stock_id?: ModelIDInput | null,
  src_product_id?: ModelIDInput | null,
  src_product_type_id?: ModelIDInput | null,
  src_brand_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  and?: Array< ModelStockProductFilterInput | null > | null,
  or?: Array< ModelStockProductFilterInput | null > | null,
  not?: ModelStockProductFilterInput | null,
};

export type ModelStockProductConnection = {
  __typename: "ModelStockProductConnection",
  items:  Array<StockProduct | null >,
  nextToken?: string | null,
};

export type SearchableStockProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  stock_id?: SearchableIDFilterInput | null,
  src_product_id?: SearchableIDFilterInput | null,
  src_product_type_id?: SearchableIDFilterInput | null,
  src_brand_id?: SearchableIDFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  and?: Array< SearchableStockProductFilterInput | null > | null,
  or?: Array< SearchableStockProductFilterInput | null > | null,
  not?: SearchableStockProductFilterInput | null,
};

export type SearchableStockProductSortInput = {
  field?: SearchableStockProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableStockProductSortableFields {
  id = "id",
  stock_id = "stock_id",
  src_product_id = "src_product_id",
  src_product_type_id = "src_product_type_id",
  src_brand_id = "src_brand_id",
  product_id = "product_id",
  product_type_id = "product_type_id",
}


export type SearchableStockProductConnection = {
  __typename: "SearchableStockProductConnection",
  items:  Array<StockProduct | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelLogilessAuthTokenFilterInput = {
  id?: ModelIDInput | null,
  logiless_client_id?: ModelStringInput | null,
  access_token?: ModelStringInput | null,
  refresh_token?: ModelStringInput | null,
  expires_at?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLogilessAuthTokenFilterInput | null > | null,
  or?: Array< ModelLogilessAuthTokenFilterInput | null > | null,
  not?: ModelLogilessAuthTokenFilterInput | null,
};

export type ModelLogilessAuthTokenConnection = {
  __typename: "ModelLogilessAuthTokenConnection",
  items:  Array<LogilessAuthToken | null >,
  nextToken?: string | null,
};

export type SearchableLogilessAuthTokenFilterInput = {
  id?: SearchableIDFilterInput | null,
  logiless_client_id?: SearchableStringFilterInput | null,
  access_token?: SearchableStringFilterInput | null,
  refresh_token?: SearchableStringFilterInput | null,
  expires_at?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableLogilessAuthTokenFilterInput | null > | null,
  or?: Array< SearchableLogilessAuthTokenFilterInput | null > | null,
  not?: SearchableLogilessAuthTokenFilterInput | null,
};

export type SearchableLogilessAuthTokenSortInput = {
  field?: SearchableLogilessAuthTokenSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableLogilessAuthTokenSortableFields {
  id = "id",
  logiless_client_id = "logiless_client_id",
  access_token = "access_token",
  refresh_token = "refresh_token",
  expires_at = "expires_at",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableLogilessAuthTokenConnection = {
  __typename: "SearchableLogilessAuthTokenConnection",
  items:  Array<LogilessAuthToken | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBuyerReadModelFilterInput = {
  id?: ModelIDInput | null,
  account_company_name?: ModelStringInput | null,
  account_first_name?: ModelStringInput | null,
  account_id?: ModelIDInput | null,
  account_instagram_account?: ModelStringInput | null,
  account_last_name?: ModelStringInput | null,
  account_location?: ModelStringInput | null,
  account_tel?: ModelStringInput | null,
  account_web_site_URL?: ModelStringInput | null,
  account_zip_code?: ModelStringInput | null,
  billing_close_day?: ModelIntInput | null,
  buyer_brand?: ModelStringInput | null,
  buyer_credit?: ModelFloatInput | null,
  buyer_kyc_status?: ModelIntInput | null,
  buyer_product_category?: ModelStringInput | null,
  buyer_rating?: ModelFloatInput | null,
  buyer_returen_credit?: ModelFloatInput | null,
  buyer_return_limit_per_brand?: ModelFloatInput | null,
  buyer_type?: ModelStringInput | null,
  item_category?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  mf_customer_id?: ModelStringInput | null,
  mf_destination_id?: ModelStringInput | null,
  mf_examination_status?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  owner_name?: ModelStringInput | null,
  payment_type?: ModelIntInput | null,
  primary_payment_method_id?: ModelIDInput | null,
  primary_payment_term?: ModelIntInput | null,
  primary_shipping_address_id?: ModelIDInput | null,
  primary_shipping_building?: ModelStringInput | null,
  primary_shipping_city?: ModelStringInput | null,
  primary_shipping_name?: ModelStringInput | null,
  primary_shipping_phone_number?: ModelStringInput | null,
  primary_shipping_prefecture?: ModelStringInput | null,
  primary_shipping_zip_code?: ModelStringInput | null,
  referral_brand_id?: ModelIDInput | null,
  referral_brand_name?: ModelStringInput | null,
  shop_image_keys?: ModelStringInput | null,
  shop_name?: ModelStringInput | null,
  store_category?: ModelStringInput | null,
  stripe_id?: ModelStringInput | null,
  total_order_amount?: ModelFloatInput | null,
  total_order_amount_last_9m?: ModelFloatInput | null,
  total_order_quantity?: ModelFloatInput | null,
  total_order_quantity_last_9m?: ModelFloatInput | null,
  total_return_amount?: ModelFloatInput | null,
  total_return_amount_last_9m?: ModelFloatInput | null,
  total_return_quantity?: ModelFloatInput | null,
  total_return_quantity_last_9m?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBuyerReadModelFilterInput | null > | null,
  or?: Array< ModelBuyerReadModelFilterInput | null > | null,
  not?: ModelBuyerReadModelFilterInput | null,
};

export type ModelBuyerReadModelConnection = {
  __typename: "ModelBuyerReadModelConnection",
  items:  Array<BuyerReadModel | null >,
  nextToken?: string | null,
};

export type SearchableBuyerReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  account_company_name?: SearchableStringFilterInput | null,
  account_first_name?: SearchableStringFilterInput | null,
  account_id?: SearchableIDFilterInput | null,
  account_instagram_account?: SearchableStringFilterInput | null,
  account_last_name?: SearchableStringFilterInput | null,
  account_location?: SearchableStringFilterInput | null,
  account_tel?: SearchableStringFilterInput | null,
  account_web_site_URL?: SearchableStringFilterInput | null,
  account_zip_code?: SearchableStringFilterInput | null,
  billing_close_day?: SearchableIntFilterInput | null,
  buyer_brand?: SearchableStringFilterInput | null,
  buyer_credit?: SearchableFloatFilterInput | null,
  buyer_kyc_status?: SearchableIntFilterInput | null,
  buyer_product_category?: SearchableStringFilterInput | null,
  buyer_rating?: SearchableFloatFilterInput | null,
  buyer_returen_credit?: SearchableFloatFilterInput | null,
  buyer_return_limit_per_brand?: SearchableFloatFilterInput | null,
  buyer_type?: SearchableStringFilterInput | null,
  item_category?: SearchableStringFilterInput | null,
  metadata?: SearchableStringFilterInput | null,
  mf_customer_id?: SearchableStringFilterInput | null,
  mf_destination_id?: SearchableStringFilterInput | null,
  mf_examination_status?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  owner_name?: SearchableStringFilterInput | null,
  payment_type?: SearchableIntFilterInput | null,
  primary_payment_method_id?: SearchableIDFilterInput | null,
  primary_payment_term?: SearchableIntFilterInput | null,
  primary_shipping_address_id?: SearchableIDFilterInput | null,
  primary_shipping_building?: SearchableStringFilterInput | null,
  primary_shipping_city?: SearchableStringFilterInput | null,
  primary_shipping_name?: SearchableStringFilterInput | null,
  primary_shipping_phone_number?: SearchableStringFilterInput | null,
  primary_shipping_prefecture?: SearchableStringFilterInput | null,
  primary_shipping_zip_code?: SearchableStringFilterInput | null,
  referral_brand_id?: SearchableIDFilterInput | null,
  referral_brand_name?: SearchableStringFilterInput | null,
  shop_image_keys?: SearchableStringFilterInput | null,
  shop_name?: SearchableStringFilterInput | null,
  store_category?: SearchableStringFilterInput | null,
  stripe_id?: SearchableStringFilterInput | null,
  total_order_amount?: SearchableFloatFilterInput | null,
  total_order_amount_last_9m?: SearchableFloatFilterInput | null,
  total_order_quantity?: SearchableFloatFilterInput | null,
  total_order_quantity_last_9m?: SearchableFloatFilterInput | null,
  total_return_amount?: SearchableFloatFilterInput | null,
  total_return_amount_last_9m?: SearchableFloatFilterInput | null,
  total_return_quantity?: SearchableFloatFilterInput | null,
  total_return_quantity_last_9m?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableBuyerReadModelFilterInput | null > | null,
  or?: Array< SearchableBuyerReadModelFilterInput | null > | null,
  not?: SearchableBuyerReadModelFilterInput | null,
};

export type SearchableBuyerReadModelSortInput = {
  field?: SearchableBuyerReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBuyerReadModelSortableFields {
  id = "id",
  account_company_name = "account_company_name",
  account_first_name = "account_first_name",
  account_id = "account_id",
  account_instagram_account = "account_instagram_account",
  account_last_name = "account_last_name",
  account_location = "account_location",
  account_tel = "account_tel",
  account_web_site_URL = "account_web_site_URL",
  account_zip_code = "account_zip_code",
  billing_close_day = "billing_close_day",
  buyer_brand = "buyer_brand",
  buyer_credit = "buyer_credit",
  buyer_kyc_status = "buyer_kyc_status",
  buyer_product_category = "buyer_product_category",
  buyer_rating = "buyer_rating",
  buyer_returen_credit = "buyer_returen_credit",
  buyer_return_limit_per_brand = "buyer_return_limit_per_brand",
  buyer_type = "buyer_type",
  item_category = "item_category",
  metadata = "metadata",
  mf_customer_id = "mf_customer_id",
  mf_destination_id = "mf_destination_id",
  mf_examination_status = "mf_examination_status",
  owner = "owner",
  owner_name = "owner_name",
  payment_type = "payment_type",
  primary_payment_method_id = "primary_payment_method_id",
  primary_payment_term = "primary_payment_term",
  primary_shipping_address_id = "primary_shipping_address_id",
  primary_shipping_building = "primary_shipping_building",
  primary_shipping_city = "primary_shipping_city",
  primary_shipping_name = "primary_shipping_name",
  primary_shipping_phone_number = "primary_shipping_phone_number",
  primary_shipping_prefecture = "primary_shipping_prefecture",
  primary_shipping_zip_code = "primary_shipping_zip_code",
  referral_brand_id = "referral_brand_id",
  referral_brand_name = "referral_brand_name",
  shop_image_keys = "shop_image_keys",
  shop_name = "shop_name",
  store_category = "store_category",
  stripe_id = "stripe_id",
  total_order_amount = "total_order_amount",
  total_order_amount_last_9m = "total_order_amount_last_9m",
  total_order_quantity = "total_order_quantity",
  total_order_quantity_last_9m = "total_order_quantity_last_9m",
  total_return_amount = "total_return_amount",
  total_return_amount_last_9m = "total_return_amount_last_9m",
  total_return_quantity = "total_return_quantity",
  total_return_quantity_last_9m = "total_return_quantity_last_9m",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBuyerReadModelConnection = {
  __typename: "SearchableBuyerReadModelConnection",
  items:  Array<BuyerReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBrandReadModelFilterInput = {
  id?: ModelIDInput | null,
  company_name?: ModelStringInput | null,
  brand_owner?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_type?: ModelStringInput | null,
  brand_content?: ModelStringInput | null,
  brand_imageKey?: ModelStringInput | null,
  brand_icon_imageKey?: ModelStringInput | null,
  brand_logo_imageKey?: ModelStringInput | null,
  brand_publication?: ModelStringInput | null,
  brand_retail_stores_number?: ModelStringInput | null,
  brand_category?: ModelStringInput | null,
  brand_target_age?: ModelStringInput | null,
  brand_target_gender?: ModelStringInput | null,
  brand_website_url?: ModelStringInput | null,
  brand_work_with_big_box?: ModelStringInput | null,
  brand_address?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_shippinng_fee?: ModelIntInput | null,
  brand_shippinng_fee_criteria?: ModelIntInput | null,
  brand_instagram_account?: ModelStringInput | null,
  brand_facebook_account?: ModelStringInput | null,
  brand_rating?: ModelFloatInput | null,
  brand_annual_turnover?: ModelIntInput | null,
  brand_public_status?: ModelStringInput | null,
  brand_howtoknowhomula?: ModelStringInput | null,
  brand_audit_status?: ModelIntInput | null,
  brand_stripe_id?: ModelStringInput | null,
  brand_first_order_fee?: ModelIntInput | null,
  brand_additional_order_fee?: ModelIntInput | null,
  brand_first_order_fee_referral?: ModelIntInput | null,
  brand_additional_order_fee_referral?: ModelIntInput | null,
  brand_direct_order_fee?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdAtTimestamp?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  updatedAtTimestamp?: ModelFloatInput | null,
  owner?: ModelStringInput | null,
  designed_in?: ModelStringInput | null,
  established?: ModelStringInput | null,
  tagIds?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  brand_collections?: ModelStringInput | null,
  movie_url?: ModelStringInput | null,
  brand_modal_imageKeys?: ModelStringInput | null,
  brand_online_sale?: ModelBooleanInput | null,
  product_image_reprint?: ModelBooleanInput | null,
  brand_logo_reprint?: ModelBooleanInput | null,
  post_to_sns?: ModelBooleanInput | null,
  allow_direct_shipping?: ModelBooleanInput | null,
  allow_sell_before_buy?: ModelBooleanInput | null,
  other_terms?: ModelStringInput | null,
  featured_in?: ModelStringInput | null,
  maximum_wholesale_rate?: ModelFloatInput | null,
  exclude_zip_code?: ModelStringInput | null,
  is_sale_brand?: ModelBooleanInput | null,
  hubspot_company_id?: ModelStringInput | null,
  return_to_brand?: ModelBooleanInput | null,
  terms_of_sample?: ModelStringInput | null,
  auto_message_enabled?: ModelBooleanInput | null,
  auto_message?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  is_published?: ModelBooleanInput | null,
  firstPublishedAt?: ModelStringInput | null,
  firstPublishedAtTimestamp?: ModelFloatInput | null,
  lastPublishedAt?: ModelStringInput | null,
  lastPublishedAtTimestamp?: ModelFloatInput | null,
  category_page_id?: ModelStringInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  average_shipping_days?: ModelFloatInput | null,
  service_rate?: ModelFloatInput | null,
  review_average?: ModelFloatInput | null,
  conversion_rate?: ModelFloatInput | null,
  sell_through_rate?: ModelFloatInput | null,
  reviews?: ModelIntInput | null,
  items?: ModelIntInput | null,
  conversion_rate_tier?: ModelIntInput | null,
  sell_through_rate_tier?: ModelIntInput | null,
  service_rate_rate_tier?: ModelIntInput | null,
  average_shipping_days_tier?: ModelIntInput | null,
  minimum_tier?: ModelIntInput | null,
  items_tier?: ModelIntInput | null,
  contacts_tier?: ModelIntInput | null,
  supplier_group_id?: ModelIDInput | null,
  supplier_group_title?: ModelStringInput | null,
  supplier_group_description?: ModelStringInput | null,
  supplier_group_image_key?: ModelStringInput | null,
  supplier_group_published?: ModelBooleanInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  individual_shippinng_fee?: ModelIntInput | null,
  individual_shippinng_fee_criteria?: ModelIntInput | null,
  individual_first_order_fee?: ModelIntInput | null,
  individual_additional_order_fee?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  and?: Array< ModelBrandReadModelFilterInput | null > | null,
  or?: Array< ModelBrandReadModelFilterInput | null > | null,
  not?: ModelBrandReadModelFilterInput | null,
};

export type ModelProductReadModelFilterInput = {
  id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_yomigana?: ModelStringInput | null,
  brand_image_key?: ModelStringInput | null,
  brand_published?: ModelStringInput | null,
  brand_minimum_buy?: ModelIntInput | null,
  brand_additional_minimum_buy?: ModelIntInput | null,
  brand_individual_minimum_buy?: ModelIntInput | null,
  brand_individual_wholesale_rate?: ModelFloatInput | null,
  brand_tags?: ModelStringInput | null,
  product_content?: ModelStringInput | null,
  product_description?: ModelStringInput | null,
  product_size?: ModelStringInput | null,
  product_owner?: ModelIDInput | null,
  product_wholesale_rate?: ModelFloatInput | null,
  product_sale_wholesale_rate?: ModelFloatInput | null,
  product_retail_price?: ModelIntInput | null,
  origin_wholesale_price?: ModelIntInput | null,
  wholesale_rate?: ModelFloatInput | null,
  wholesale_price?: ModelIntInput | null,
  individual_wholesale_rate?: ModelFloatInput | null,
  individual_price?: ModelIntInput | null,
  individual_minimum_buy?: ModelIntInput | null,
  is_open_price?: ModelBooleanInput | null,
  product_jancode?: ModelStringInput | null,
  product_stock_quantity?: ModelIntInput | null,
  is_published?: ModelBooleanInput | null,
  is_limited?: ModelBooleanInput | null,
  product_category?: ModelStringInput | null,
  product_subcategory?: ModelStringInput | null,
  category_page_id?: ModelStringInput | null,
  product_minimum_quantity?: ModelIntInput | null,
  product_estimated_ship_date_min?: ModelIntInput | null,
  product_estimated_ship_date_max?: ModelIntInput | null,
  product_order_lot?: ModelIntInput | null,
  product_preorder?: ModelIntInput | null,
  product_preorder_shipping_date?: ModelStringInput | null,
  product_preorder_shipping_date_timestamp?: ModelFloatInput | null,
  product_types?: ModelStringInput | null,
  product_colors?: ModelStringInput | null,
  product_sizes?: ModelStringInput | null,
  product_display_order?: ModelIntInput | null,
  product_season?: ModelStringInput | null,
  product_year?: ModelIntInput | null,
  image_key?: ModelStringInput | null,
  image_keys?: ModelStringInput | null,
  video_keys?: ModelStringInput | null,
  conversion_rate?: ModelFloatInput | null,
  sell_through_rate?: ModelFloatInput | null,
  suspended?: ModelBooleanInput | null,
  continue_selling?: ModelBooleanInput | null,
  out_of_stock?: ModelBooleanInput | null,
  sku?: ModelStringInput | null,
  copied_product_id?: ModelIDInput | null,
  copied_brand_id?: ModelIDInput | null,
  limited_publishing_list?: ModelStringInput | null,
  protected_zip_code?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  publishedAtTimestamp?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  createdAtTimestamp?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  updatedAtTimestamp?: ModelFloatInput | null,
  conversion_rate_tier?: ModelIntInput | null,
  sell_through_rate_tier?: ModelIntInput | null,
  wholesale_rate_tier?: ModelIntInput | null,
  brand_tier?: ModelIntInput | null,
  brand_conversion_rate_tier?: ModelIntInput | null,
  brand_sell_through_rate_tier?: ModelIntInput | null,
  brand_service_rate_rate_tier?: ModelIntInput | null,
  brand_average_shipping_days_tier?: ModelIntInput | null,
  brand_minimum_tier?: ModelIntInput | null,
  brand_items_tier?: ModelIntInput | null,
  brand_contacts_tier?: ModelIntInput | null,
  collection_id?: ModelStringInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  and?: Array< ModelProductReadModelFilterInput | null > | null,
  or?: Array< ModelProductReadModelFilterInput | null > | null,
  not?: ModelProductReadModelFilterInput | null,
};

export type ModelCollectionReadModelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_categories?: ModelStringInput | null,
  brand_tags?: ModelStringInput | null,
  src_brand_id?: ModelIDInput | null,
  src_brand_name?: ModelStringInput | null,
  src_brand_categories?: ModelStringInput | null,
  src_brand_tags?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image_key?: ModelStringInput | null,
  video_key?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  preorder?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  season?: ModelStringInput | null,
  season_year?: ModelIntInput | null,
  product_ids?: ModelStringInput | null,
  product_names?: ModelStringInput | null,
  product_image_keys?: ModelStringInput | null,
  product_numbers?: ModelStringInput | null,
  product_categories?: ModelStringInput | null,
  product_subcategories?: ModelStringInput | null,
  total_quantity?: ModelFloatInput | null,
  total_retail_amount?: ModelFloatInput | null,
  total_original_amount?: ModelFloatInput | null,
  total_amount?: ModelFloatInput | null,
  total_items?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  minimum_wholesale_rate?: ModelFloatInput | null,
  sell_only_direct?: ModelBooleanInput | null,
  display_order?: ModelIntInput | null,
  and?: Array< ModelCollectionReadModelFilterInput | null > | null,
  or?: Array< ModelCollectionReadModelFilterInput | null > | null,
  not?: ModelCollectionReadModelFilterInput | null,
};

export type ModelCollectionReadModelConnection = {
  __typename: "ModelCollectionReadModelConnection",
  items:  Array<CollectionReadModel | null >,
  nextToken?: string | null,
};

export type SearchableCollectionReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  brand_name?: SearchableStringFilterInput | null,
  brand_categories?: SearchableStringFilterInput | null,
  brand_tags?: SearchableStringFilterInput | null,
  src_brand_id?: SearchableIDFilterInput | null,
  src_brand_name?: SearchableStringFilterInput | null,
  src_brand_categories?: SearchableStringFilterInput | null,
  src_brand_tags?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  image_key?: SearchableStringFilterInput | null,
  video_key?: SearchableStringFilterInput | null,
  published?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  preorder?: SearchableBooleanFilterInput | null,
  is_consign?: SearchableBooleanFilterInput | null,
  season?: SearchableStringFilterInput | null,
  season_year?: SearchableIntFilterInput | null,
  product_ids?: SearchableStringFilterInput | null,
  product_names?: SearchableStringFilterInput | null,
  product_image_keys?: SearchableStringFilterInput | null,
  product_numbers?: SearchableStringFilterInput | null,
  product_categories?: SearchableStringFilterInput | null,
  product_subcategories?: SearchableStringFilterInput | null,
  total_quantity?: SearchableFloatFilterInput | null,
  total_retail_amount?: SearchableFloatFilterInput | null,
  total_original_amount?: SearchableFloatFilterInput | null,
  total_amount?: SearchableFloatFilterInput | null,
  total_items?: SearchableFloatFilterInput | null,
  wholesale_rate?: SearchableFloatFilterInput | null,
  minimum_wholesale_rate?: SearchableFloatFilterInput | null,
  sell_only_direct?: SearchableBooleanFilterInput | null,
  display_order?: SearchableIntFilterInput | null,
  and?: Array< SearchableCollectionReadModelFilterInput | null > | null,
  or?: Array< SearchableCollectionReadModelFilterInput | null > | null,
  not?: SearchableCollectionReadModelFilterInput | null,
};

export type SearchableCollectionReadModelSortInput = {
  field?: SearchableCollectionReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCollectionReadModelSortableFields {
  id = "id",
  name = "name",
  brand_id = "brand_id",
  brand_name = "brand_name",
  brand_categories = "brand_categories",
  brand_tags = "brand_tags",
  src_brand_id = "src_brand_id",
  src_brand_name = "src_brand_name",
  src_brand_categories = "src_brand_categories",
  src_brand_tags = "src_brand_tags",
  description = "description",
  image_key = "image_key",
  video_key = "video_key",
  published = "published",
  createdAt = "createdAt",
  owner = "owner",
  preorder = "preorder",
  is_consign = "is_consign",
  season = "season",
  season_year = "season_year",
  product_ids = "product_ids",
  product_names = "product_names",
  product_image_keys = "product_image_keys",
  product_numbers = "product_numbers",
  product_categories = "product_categories",
  product_subcategories = "product_subcategories",
  total_quantity = "total_quantity",
  total_retail_amount = "total_retail_amount",
  total_original_amount = "total_original_amount",
  total_amount = "total_amount",
  total_items = "total_items",
  wholesale_rate = "wholesale_rate",
  minimum_wholesale_rate = "minimum_wholesale_rate",
  sell_only_direct = "sell_only_direct",
  display_order = "display_order",
}


export type SearchableCollectionReadModelConnection = {
  __typename: "SearchableCollectionReadModelConnection",
  items:  Array<CollectionReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderReadModelFilterInput = {
  id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_id?: ModelIDInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  brand_image_key?: ModelStringInput | null,
  shipping_zip_code?: ModelStringInput | null,
  shipping_address?: ModelStringInput | null,
  shipping_name?: ModelStringInput | null,
  shipping_phone_number?: ModelStringInput | null,
  carrier?: ModelStringInput | null,
  tracking_number?: ModelStringInput | null,
  shipping_date?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  return_due_date?: ModelStringInput | null,
  shipping_fee?: ModelIntInput | null,
  invoice_shipping_fee?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  order_quantity?: ModelIntInput | null,
  order_subtotal?: ModelIntInput | null,
  order_amount?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  report_quantity?: ModelIntInput | null,
  report_amount?: ModelIntInput | null,
  report_approved_quantity?: ModelIntInput | null,
  report_approved_amount?: ModelIntInput | null,
  extended_quantity?: ModelIntInput | null,
  extended_amount?: ModelIntInput | null,
  payment_quantity?: ModelIntInput | null,
  payment_amount?: ModelIntInput | null,
  points?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  campaign_code?: ModelStringInput | null,
  cashback_amount?: ModelIntInput | null,
  order_fee_rate?: ModelIntInput | null,
  order_fee?: ModelIntInput | null,
  origin_order_id?: ModelIDInput | null,
  status?: ModelOrderReadModelStatusInput | null,
  status_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  stripe_client_secret?: ModelStringInput | null,
  stripe_payment_method_id?: ModelStringInput | null,
  mf_authorization_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  mf_canceled_transaction_id?: ModelStringInput | null,
  billing_count?: ModelIntInput | null,
  is_first?: ModelBooleanInput | null,
  is_direct?: ModelBooleanInput | null,
  is_consign?: ModelBooleanInput | null,
  is_preorder?: ModelBooleanInput | null,
  product_ids?: ModelStringInput | null,
  product_names?: ModelStringInput | null,
  product_numbers?: ModelStringInput | null,
  skus?: ModelStringInput | null,
  bill_payment_status?: ModelBillPaymentStatusInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  split_source_id?: ModelIDInput | null,
  split_date?: ModelStringInput | null,
  and?: Array< ModelOrderReadModelFilterInput | null > | null,
  or?: Array< ModelOrderReadModelFilterInput | null > | null,
  not?: ModelOrderReadModelFilterInput | null,
};

export type ModelOrderReadModelConnection = {
  __typename: "ModelOrderReadModelConnection",
  items:  Array<OrderReadModel | null >,
  nextToken?: string | null,
};

export type SearchableOrderReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_company_name?: SearchableStringFilterInput | null,
  buyer_shop_name?: SearchableStringFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  supplier_company_name?: SearchableStringFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  brand_name?: SearchableStringFilterInput | null,
  brand_image_key?: SearchableStringFilterInput | null,
  shipping_zip_code?: SearchableStringFilterInput | null,
  shipping_address?: SearchableStringFilterInput | null,
  shipping_name?: SearchableStringFilterInput | null,
  shipping_phone_number?: SearchableStringFilterInput | null,
  carrier?: SearchableStringFilterInput | null,
  tracking_number?: SearchableStringFilterInput | null,
  shipping_date?: SearchableStringFilterInput | null,
  shipped_date?: SearchableStringFilterInput | null,
  extended_date?: SearchableStringFilterInput | null,
  payment_date?: SearchableStringFilterInput | null,
  extended_payment_date?: SearchableStringFilterInput | null,
  return_due_date?: SearchableStringFilterInput | null,
  shipping_fee?: SearchableIntFilterInput | null,
  invoice_shipping_fee?: SearchableIntFilterInput | null,
  tax_rate?: SearchableIntFilterInput | null,
  tax?: SearchableIntFilterInput | null,
  order_quantity?: SearchableIntFilterInput | null,
  order_subtotal?: SearchableIntFilterInput | null,
  order_amount?: SearchableIntFilterInput | null,
  return_request_quantity?: SearchableIntFilterInput | null,
  return_request_amount?: SearchableIntFilterInput | null,
  return_quantity?: SearchableIntFilterInput | null,
  return_amount?: SearchableIntFilterInput | null,
  report_quantity?: SearchableIntFilterInput | null,
  report_amount?: SearchableIntFilterInput | null,
  report_approved_quantity?: SearchableIntFilterInput | null,
  report_approved_amount?: SearchableIntFilterInput | null,
  extended_quantity?: SearchableIntFilterInput | null,
  extended_amount?: SearchableIntFilterInput | null,
  payment_quantity?: SearchableIntFilterInput | null,
  payment_amount?: SearchableIntFilterInput | null,
  points?: SearchableIntFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  discount_amount?: SearchableIntFilterInput | null,
  campaign_code?: SearchableStringFilterInput | null,
  cashback_amount?: SearchableIntFilterInput | null,
  order_fee_rate?: SearchableIntFilterInput | null,
  order_fee?: SearchableIntFilterInput | null,
  origin_order_id?: SearchableIDFilterInput | null,
  status_label?: SearchableStringFilterInput | null,
  payment_status_label?: SearchableStringFilterInput | null,
  payment_term_label?: SearchableStringFilterInput | null,
  stripe_payment_id?: SearchableStringFilterInput | null,
  stripe_client_secret?: SearchableStringFilterInput | null,
  stripe_payment_method_id?: SearchableStringFilterInput | null,
  mf_authorization_id?: SearchableStringFilterInput | null,
  mf_transaction_id?: SearchableStringFilterInput | null,
  mf_canceled_transaction_id?: SearchableStringFilterInput | null,
  billing_count?: SearchableIntFilterInput | null,
  is_first?: SearchableBooleanFilterInput | null,
  is_direct?: SearchableBooleanFilterInput | null,
  is_consign?: SearchableBooleanFilterInput | null,
  is_preorder?: SearchableBooleanFilterInput | null,
  product_ids?: SearchableStringFilterInput | null,
  product_names?: SearchableStringFilterInput | null,
  product_numbers?: SearchableStringFilterInput | null,
  skus?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  split_source_id?: SearchableIDFilterInput | null,
  split_date?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderReadModelFilterInput | null > | null,
  or?: Array< SearchableOrderReadModelFilterInput | null > | null,
  not?: SearchableOrderReadModelFilterInput | null,
};

export type SearchableOrderReadModelSortInput = {
  field?: SearchableOrderReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderReadModelSortableFields {
  id = "id",
  buyer_id = "buyer_id",
  buyer_company_name = "buyer_company_name",
  buyer_shop_name = "buyer_shop_name",
  supplier_id = "supplier_id",
  supplier_company_name = "supplier_company_name",
  brand_id = "brand_id",
  brand_name = "brand_name",
  brand_image_key = "brand_image_key",
  shipping_zip_code = "shipping_zip_code",
  shipping_address = "shipping_address",
  shipping_name = "shipping_name",
  shipping_phone_number = "shipping_phone_number",
  carrier = "carrier",
  tracking_number = "tracking_number",
  shipping_date = "shipping_date",
  shipped_date = "shipped_date",
  extended_date = "extended_date",
  payment_date = "payment_date",
  extended_payment_date = "extended_payment_date",
  return_due_date = "return_due_date",
  shipping_fee = "shipping_fee",
  invoice_shipping_fee = "invoice_shipping_fee",
  tax_rate = "tax_rate",
  tax = "tax",
  order_quantity = "order_quantity",
  order_subtotal = "order_subtotal",
  order_amount = "order_amount",
  return_request_quantity = "return_request_quantity",
  return_request_amount = "return_request_amount",
  return_quantity = "return_quantity",
  return_amount = "return_amount",
  report_quantity = "report_quantity",
  report_amount = "report_amount",
  report_approved_quantity = "report_approved_quantity",
  report_approved_amount = "report_approved_amount",
  extended_quantity = "extended_quantity",
  extended_amount = "extended_amount",
  payment_quantity = "payment_quantity",
  payment_amount = "payment_amount",
  points = "points",
  coupon_id = "coupon_id",
  discount_amount = "discount_amount",
  campaign_code = "campaign_code",
  cashback_amount = "cashback_amount",
  order_fee_rate = "order_fee_rate",
  order_fee = "order_fee",
  origin_order_id = "origin_order_id",
  status_label = "status_label",
  payment_status_label = "payment_status_label",
  payment_term_label = "payment_term_label",
  stripe_payment_id = "stripe_payment_id",
  stripe_client_secret = "stripe_client_secret",
  stripe_payment_method_id = "stripe_payment_method_id",
  mf_authorization_id = "mf_authorization_id",
  mf_transaction_id = "mf_transaction_id",
  mf_canceled_transaction_id = "mf_canceled_transaction_id",
  billing_count = "billing_count",
  is_first = "is_first",
  is_direct = "is_direct",
  is_consign = "is_consign",
  is_preorder = "is_preorder",
  product_ids = "product_ids",
  product_names = "product_names",
  product_numbers = "product_numbers",
  skus = "skus",
  owners = "owners",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  memo = "memo",
  split_source_id = "split_source_id",
  split_date = "split_date",
}


export type SearchableOrderReadModelConnection = {
  __typename: "SearchableOrderReadModelConnection",
  items:  Array<OrderReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderDetailReadModelFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_id?: ModelIDInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_type_id?: ModelIDInput | null,
  product_image_key?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  initial_quantity?: ModelIntInput | null,
  initial_price?: ModelFloatInput | null,
  initial_wholesale_rate?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  subtotal?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  tax?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  report_quantity?: ModelIntInput | null,
  report_approved_amount?: ModelIntInput | null,
  report_approved_quantity?: ModelIntInput | null,
  report_amount?: ModelIntInput | null,
  extended_quantity?: ModelIntInput | null,
  extended_amount?: ModelIntInput | null,
  payment_quantity?: ModelIntInput | null,
  payment_amount?: ModelIntInput | null,
  coupon_id?: ModelIDInput | null,
  discount_amount?: ModelIntInput | null,
  discount_percentage?: ModelIntInput | null,
  order_fee_rate?: ModelIntInput | null,
  order_fee?: ModelIntInput | null,
  status?: ModelOrderReadModelStatusInput | null,
  status_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderDetailReadModelFilterInput | null > | null,
  or?: Array< ModelOrderDetailReadModelFilterInput | null > | null,
  not?: ModelOrderDetailReadModelFilterInput | null,
};

export type SearchableOrderDetailReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_company_name?: SearchableStringFilterInput | null,
  buyer_shop_name?: SearchableStringFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  supplier_company_name?: SearchableStringFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  brand_name?: SearchableStringFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_name?: SearchableStringFilterInput | null,
  product_number?: SearchableStringFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  product_image_key?: SearchableStringFilterInput | null,
  color?: SearchableStringFilterInput | null,
  size?: SearchableStringFilterInput | null,
  type_name?: SearchableStringFilterInput | null,
  shipped_date?: SearchableStringFilterInput | null,
  extended_date?: SearchableStringFilterInput | null,
  payment_date?: SearchableStringFilterInput | null,
  extended_payment_date?: SearchableStringFilterInput | null,
  initial_quantity?: SearchableIntFilterInput | null,
  initial_price?: SearchableFloatFilterInput | null,
  initial_wholesale_rate?: SearchableFloatFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  wholesale_rate?: SearchableFloatFilterInput | null,
  subtotal?: SearchableIntFilterInput | null,
  amount?: SearchableIntFilterInput | null,
  tax_rate?: SearchableIntFilterInput | null,
  tax?: SearchableIntFilterInput | null,
  return_request_quantity?: SearchableIntFilterInput | null,
  return_request_amount?: SearchableIntFilterInput | null,
  return_quantity?: SearchableIntFilterInput | null,
  return_amount?: SearchableIntFilterInput | null,
  report_quantity?: SearchableIntFilterInput | null,
  report_approved_amount?: SearchableIntFilterInput | null,
  report_approved_quantity?: SearchableIntFilterInput | null,
  report_amount?: SearchableIntFilterInput | null,
  extended_quantity?: SearchableIntFilterInput | null,
  extended_amount?: SearchableIntFilterInput | null,
  payment_quantity?: SearchableIntFilterInput | null,
  payment_amount?: SearchableIntFilterInput | null,
  coupon_id?: SearchableIDFilterInput | null,
  discount_amount?: SearchableIntFilterInput | null,
  discount_percentage?: SearchableIntFilterInput | null,
  order_fee_rate?: SearchableIntFilterInput | null,
  order_fee?: SearchableIntFilterInput | null,
  status_label?: SearchableStringFilterInput | null,
  payment_term_label?: SearchableStringFilterInput | null,
  payment_status_label?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableOrderDetailReadModelFilterInput | null > | null,
  or?: Array< SearchableOrderDetailReadModelFilterInput | null > | null,
  not?: SearchableOrderDetailReadModelFilterInput | null,
};

export type SearchableOrderDetailReadModelSortInput = {
  field?: SearchableOrderDetailReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableOrderDetailReadModelSortableFields {
  id = "id",
  order_id = "order_id",
  buyer_id = "buyer_id",
  buyer_company_name = "buyer_company_name",
  buyer_shop_name = "buyer_shop_name",
  supplier_id = "supplier_id",
  supplier_company_name = "supplier_company_name",
  brand_id = "brand_id",
  brand_name = "brand_name",
  product_id = "product_id",
  product_name = "product_name",
  product_number = "product_number",
  sku = "sku",
  product_type_id = "product_type_id",
  product_image_key = "product_image_key",
  color = "color",
  size = "size",
  type_name = "type_name",
  shipped_date = "shipped_date",
  extended_date = "extended_date",
  payment_date = "payment_date",
  extended_payment_date = "extended_payment_date",
  initial_quantity = "initial_quantity",
  initial_price = "initial_price",
  initial_wholesale_rate = "initial_wholesale_rate",
  quantity = "quantity",
  price = "price",
  wholesale_rate = "wholesale_rate",
  subtotal = "subtotal",
  amount = "amount",
  tax_rate = "tax_rate",
  tax = "tax",
  return_request_quantity = "return_request_quantity",
  return_request_amount = "return_request_amount",
  return_quantity = "return_quantity",
  return_amount = "return_amount",
  report_quantity = "report_quantity",
  report_approved_amount = "report_approved_amount",
  report_approved_quantity = "report_approved_quantity",
  report_amount = "report_amount",
  extended_quantity = "extended_quantity",
  extended_amount = "extended_amount",
  payment_quantity = "payment_quantity",
  payment_amount = "payment_amount",
  coupon_id = "coupon_id",
  discount_amount = "discount_amount",
  discount_percentage = "discount_percentage",
  order_fee_rate = "order_fee_rate",
  order_fee = "order_fee",
  status_label = "status_label",
  payment_term_label = "payment_term_label",
  payment_status_label = "payment_status_label",
  memo = "memo",
  owners = "owners",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableOrderDetailReadModelConnection = {
  __typename: "SearchableOrderDetailReadModelConnection",
  items:  Array<OrderDetailReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReturnDetailReadModelFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_company_name?: ModelStringInput | null,
  buyer_shop_name?: ModelStringInput | null,
  supplier_id?: ModelIDInput | null,
  supplier_company_name?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  brand_name?: ModelStringInput | null,
  product_id?: ModelIDInput | null,
  product_name?: ModelStringInput | null,
  product_number?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_type_id?: ModelIDInput | null,
  product_image_key?: ModelStringInput | null,
  color?: ModelStringInput | null,
  size?: ModelStringInput | null,
  type_name?: ModelStringInput | null,
  shipped_date?: ModelStringInput | null,
  extended_date?: ModelStringInput | null,
  payment_date?: ModelStringInput | null,
  extended_payment_date?: ModelStringInput | null,
  return_request_due_date?: ModelStringInput | null,
  return_request_date?: ModelStringInput | null,
  return_due_date?: ModelStringInput | null,
  return_date?: ModelStringInput | null,
  order_quantity?: ModelIntInput | null,
  order_price?: ModelFloatInput | null,
  wholesale_rate?: ModelFloatInput | null,
  order_subtotal?: ModelIntInput | null,
  order_amount?: ModelIntInput | null,
  order_tax?: ModelIntInput | null,
  tax_rate?: ModelIntInput | null,
  return_request_quantity?: ModelIntInput | null,
  return_request_amount?: ModelIntInput | null,
  return_quantity?: ModelIntInput | null,
  return_amount?: ModelIntInput | null,
  status?: ModelReturnStatusInput | null,
  status_label?: ModelStringInput | null,
  return_destination?: ModelReturnDestinationInput | null,
  return_destination_label?: ModelStringInput | null,
  payment_term?: ModelPaymentTermInput | null,
  payment_term_label?: ModelStringInput | null,
  payment_status?: ModelOrderPaymentStatusInput | null,
  payment_status_label?: ModelStringInput | null,
  stripe_payment_id?: ModelStringInput | null,
  mf_transaction_id?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReturnDetailReadModelFilterInput | null > | null,
  or?: Array< ModelReturnDetailReadModelFilterInput | null > | null,
  not?: ModelReturnDetailReadModelFilterInput | null,
};

export type ModelReturnDetailReadModelConnection = {
  __typename: "ModelReturnDetailReadModelConnection",
  items:  Array<ReturnDetailReadModel | null >,
  nextToken?: string | null,
};

export type SearchableReturnDetailReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  order_id?: SearchableIDFilterInput | null,
  order_product_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_company_name?: SearchableStringFilterInput | null,
  buyer_shop_name?: SearchableStringFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  supplier_company_name?: SearchableStringFilterInput | null,
  brand_id?: SearchableIDFilterInput | null,
  brand_name?: SearchableStringFilterInput | null,
  product_id?: SearchableIDFilterInput | null,
  product_name?: SearchableStringFilterInput | null,
  product_number?: SearchableStringFilterInput | null,
  sku?: SearchableStringFilterInput | null,
  product_type_id?: SearchableIDFilterInput | null,
  product_image_key?: SearchableStringFilterInput | null,
  color?: SearchableStringFilterInput | null,
  size?: SearchableStringFilterInput | null,
  type_name?: SearchableStringFilterInput | null,
  shipped_date?: SearchableStringFilterInput | null,
  extended_date?: SearchableStringFilterInput | null,
  payment_date?: SearchableStringFilterInput | null,
  extended_payment_date?: SearchableStringFilterInput | null,
  return_request_due_date?: SearchableStringFilterInput | null,
  return_request_date?: SearchableStringFilterInput | null,
  return_due_date?: SearchableStringFilterInput | null,
  return_date?: SearchableStringFilterInput | null,
  order_quantity?: SearchableIntFilterInput | null,
  order_price?: SearchableFloatFilterInput | null,
  wholesale_rate?: SearchableFloatFilterInput | null,
  order_subtotal?: SearchableIntFilterInput | null,
  order_amount?: SearchableIntFilterInput | null,
  order_tax?: SearchableIntFilterInput | null,
  tax_rate?: SearchableIntFilterInput | null,
  return_request_quantity?: SearchableIntFilterInput | null,
  return_request_amount?: SearchableIntFilterInput | null,
  return_quantity?: SearchableIntFilterInput | null,
  return_amount?: SearchableIntFilterInput | null,
  status_label?: SearchableStringFilterInput | null,
  return_destination_label?: SearchableStringFilterInput | null,
  payment_term_label?: SearchableStringFilterInput | null,
  payment_status_label?: SearchableStringFilterInput | null,
  stripe_payment_id?: SearchableStringFilterInput | null,
  mf_transaction_id?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableReturnDetailReadModelFilterInput | null > | null,
  or?: Array< SearchableReturnDetailReadModelFilterInput | null > | null,
  not?: SearchableReturnDetailReadModelFilterInput | null,
};

export type SearchableReturnDetailReadModelSortInput = {
  field?: SearchableReturnDetailReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReturnDetailReadModelSortableFields {
  id = "id",
  order_id = "order_id",
  order_product_id = "order_product_id",
  buyer_id = "buyer_id",
  buyer_company_name = "buyer_company_name",
  buyer_shop_name = "buyer_shop_name",
  supplier_id = "supplier_id",
  supplier_company_name = "supplier_company_name",
  brand_id = "brand_id",
  brand_name = "brand_name",
  product_id = "product_id",
  product_name = "product_name",
  product_number = "product_number",
  sku = "sku",
  product_type_id = "product_type_id",
  product_image_key = "product_image_key",
  color = "color",
  size = "size",
  type_name = "type_name",
  shipped_date = "shipped_date",
  extended_date = "extended_date",
  payment_date = "payment_date",
  extended_payment_date = "extended_payment_date",
  return_request_due_date = "return_request_due_date",
  return_request_date = "return_request_date",
  return_due_date = "return_due_date",
  return_date = "return_date",
  order_quantity = "order_quantity",
  order_price = "order_price",
  wholesale_rate = "wholesale_rate",
  order_subtotal = "order_subtotal",
  order_amount = "order_amount",
  order_tax = "order_tax",
  tax_rate = "tax_rate",
  return_request_quantity = "return_request_quantity",
  return_request_amount = "return_request_amount",
  return_quantity = "return_quantity",
  return_amount = "return_amount",
  status_label = "status_label",
  return_destination_label = "return_destination_label",
  payment_term_label = "payment_term_label",
  payment_status_label = "payment_status_label",
  stripe_payment_id = "stripe_payment_id",
  mf_transaction_id = "mf_transaction_id",
  owners = "owners",
  createdAt = "createdAt",
}


export type SearchableReturnDetailReadModelConnection = {
  __typename: "SearchableReturnDetailReadModelConnection",
  items:  Array<ReturnDetailReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelContactReadModelFilterInput = {
  id?: ModelIDInput | null,
  supplier_id?: ModelIDInput | null,
  buyer_id?: ModelIDInput | null,
  buyer_owner?: ModelIDInput | null,
  email?: ModelStringInput | null,
  store_name?: ModelStringInput | null,
  contact_name?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  address?: ModelStringInput | null,
  discount_rate?: ModelIntInput | null,
  contact_status?: ModelStringInput | null,
  contact_source?: ModelStringInput | null,
  direct_payment?: ModelBooleanInput | null,
  contacted?: ModelBooleanInput | null,
  signedup?: ModelBooleanInput | null,
  ordered?: ModelBooleanInput | null,
  invited?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  buyer_web_site_URL?: ModelStringInput | null,
  buyer_instagram_account?: ModelStringInput | null,
  buyer_company_name?: ModelStringInput | null,
  contact_list_id?: ModelStringInput | null,
  orderCount?: ModelIntInput | null,
  pageViewCount?: ModelIntInput | null,
  and?: Array< ModelContactReadModelFilterInput | null > | null,
  or?: Array< ModelContactReadModelFilterInput | null > | null,
  not?: ModelContactReadModelFilterInput | null,
};

export type ModelContactReadModelConnection = {
  __typename: "ModelContactReadModelConnection",
  items:  Array<ContactReadModel | null >,
  nextToken?: string | null,
};

export type SearchableContactReadModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  supplier_id?: SearchableIDFilterInput | null,
  buyer_id?: SearchableIDFilterInput | null,
  buyer_owner?: SearchableIDFilterInput | null,
  email?: SearchableStringFilterInput | null,
  store_name?: SearchableStringFilterInput | null,
  contact_name?: SearchableStringFilterInput | null,
  tel?: SearchableStringFilterInput | null,
  zipcode?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  discount_rate?: SearchableIntFilterInput | null,
  contact_status?: SearchableStringFilterInput | null,
  contact_source?: SearchableStringFilterInput | null,
  direct_payment?: SearchableBooleanFilterInput | null,
  contacted?: SearchableBooleanFilterInput | null,
  signedup?: SearchableBooleanFilterInput | null,
  ordered?: SearchableBooleanFilterInput | null,
  invited?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  buyer_web_site_URL?: SearchableStringFilterInput | null,
  buyer_instagram_account?: SearchableStringFilterInput | null,
  buyer_company_name?: SearchableStringFilterInput | null,
  contact_list_id?: SearchableStringFilterInput | null,
  orderCount?: SearchableIntFilterInput | null,
  pageViewCount?: SearchableIntFilterInput | null,
  and?: Array< SearchableContactReadModelFilterInput | null > | null,
  or?: Array< SearchableContactReadModelFilterInput | null > | null,
  not?: SearchableContactReadModelFilterInput | null,
};

export type SearchableContactReadModelSortInput = {
  field?: SearchableContactReadModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactReadModelSortableFields {
  id = "id",
  supplier_id = "supplier_id",
  buyer_id = "buyer_id",
  buyer_owner = "buyer_owner",
  email = "email",
  store_name = "store_name",
  contact_name = "contact_name",
  tel = "tel",
  zipcode = "zipcode",
  address = "address",
  discount_rate = "discount_rate",
  contact_status = "contact_status",
  contact_source = "contact_source",
  direct_payment = "direct_payment",
  contacted = "contacted",
  signedup = "signedup",
  ordered = "ordered",
  invited = "invited",
  createdAt = "createdAt",
  owner = "owner",
  buyer_web_site_URL = "buyer_web_site_URL",
  buyer_instagram_account = "buyer_instagram_account",
  buyer_company_name = "buyer_company_name",
  contact_list_id = "contact_list_id",
  orderCount = "orderCount",
  pageViewCount = "pageViewCount",
}


export type SearchableContactReadModelConnection = {
  __typename: "SearchableContactReadModelConnection",
  items:  Array<ContactReadModel | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelOrderEventFilterInput = {
  id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  order_id?: ModelIDInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelOrderEventFilterInput | null > | null,
  or?: Array< ModelOrderEventFilterInput | null > | null,
  not?: ModelOrderEventFilterInput | null,
};

export type ModelOrderEventConnection = {
  __typename: "ModelOrderEventConnection",
  items:  Array<OrderEvent | null >,
  nextToken?: string | null,
};

export type ModelOrderProductEventFilterInput = {
  id?: ModelIDInput | null,
  order_id?: ModelIDInput | null,
  order_product_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelOrderProductEventFilterInput | null > | null,
  or?: Array< ModelOrderProductEventFilterInput | null > | null,
  not?: ModelOrderProductEventFilterInput | null,
};

export type ModelOrderProductEventConnection = {
  __typename: "ModelOrderProductEventConnection",
  items:  Array<OrderProductEvent | null >,
  nextToken?: string | null,
};

export type ModelBrandEventFilterInput = {
  id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  brand_id?: ModelIDInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelBrandEventFilterInput | null > | null,
  or?: Array< ModelBrandEventFilterInput | null > | null,
  not?: ModelBrandEventFilterInput | null,
};

export type ModelBrandEventConnection = {
  __typename: "ModelBrandEventConnection",
  items:  Array<BrandEvent | null >,
  nextToken?: string | null,
};

export type ModelProductEventFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelProductEventFilterInput | null > | null,
  or?: Array< ModelProductEventFilterInput | null > | null,
  not?: ModelProductEventFilterInput | null,
};

export type ModelProductEventConnection = {
  __typename: "ModelProductEventConnection",
  items:  Array<ProductEvent | null >,
  nextToken?: string | null,
};

export type ModelProductTypeEventFilterInput = {
  id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  product_type_id?: ModelIDInput | null,
  event_name?: ModelEventNameInput | null,
  event_date?: ModelStringInput | null,
  new_item?: ModelStringInput | null,
  old_item?: ModelStringInput | null,
  and?: Array< ModelProductTypeEventFilterInput | null > | null,
  or?: Array< ModelProductTypeEventFilterInput | null > | null,
  not?: ModelProductTypeEventFilterInput | null,
};

export type ModelProductTypeEventConnection = {
  __typename: "ModelProductTypeEventConnection",
  items:  Array<ProductTypeEvent | null >,
  nextToken?: string | null,
};

export type SendToSlackMutationVariables = {
  channelId: string,
  text: string,
};

export type SendToSlackMutation = {
  sendToSlack?: string | null,
};

export type CreateCustomerMutationVariables = {
  email: string,
  name: string,
  metadata?: CustomerMetadataInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    object: string,
    address?: string | null,
    balance: number,
    created: number,
    currency?: string | null,
    default_source?: string | null,
    delinquent: boolean,
    description?: string | null,
    discount?: string | null,
    email: string,
    invoice_prefix: string,
    invoice_settings:  {
      __typename: "InvoiceSettings",
      custom_fields?: string | null,
      default_payment_method?: string | null,
      footer?: string | null,
    },
    livemode: boolean,
    metadata:  {
      __typename: "CustomerMetadata",
      account_id: string,
    },
    name: string,
    next_invoice_sequence: number,
    phone?: string | null,
    preferred_locales: Array< string | null >,
    shipping?: string | null,
    tax_exempt: string,
  } | null,
};

export type CreatePaymentMutationVariables = {
  buyerId: string,
  price: number,
  captureMethod: string,
  metadata?: PaymentMetadataInput | null,
};

export type CreatePaymentMutation = {
  createPayment:  {
    __typename: "PaymentIntent",
    id?: string | null,
    client_secret?: string | null,
    error_code?: string | null,
    payment_method?:  {
      __typename: "PaymentMethod",
      id: string,
      object: string,
      billing_details:  {
        __typename: "BillingDetails",
        address?: string | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      },
      card:  {
        __typename: "Card",
        brand: string,
        checks?: string | null,
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from?: string | null,
        last4: string,
        networks?: string | null,
        three_d_secure_usage?: string | null,
        wallet?: string | null,
      },
      created: number,
      customer: string,
      livemode: boolean,
      metadata:  {
        __typename: "PaymentMethodMetaData",
        name?: string | null,
      },
      type: string,
    } | null,
    code?: string | null,
    status?: string | null,
  },
};

export type RefundPaymentMutationVariables = {
  paymentId: string,
  amount: number,
  orderId?: string | null,
  shopName?: string | null,
  brandName?: string | null,
  productName?: string | null,
  productNumber?: string | null,
  quantity?: number | null,
};

export type RefundPaymentMutation = {
  refundPayment?: string | null,
};

export type UpdatePaymentMutationVariables = {
  paymentId: string,
  price?: number | null,
  metadata?: PaymentMetadataInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment:  {
    __typename: "PaymentIntent",
    id?: string | null,
    client_secret?: string | null,
    error_code?: string | null,
    payment_method?:  {
      __typename: "PaymentMethod",
      id: string,
      object: string,
      billing_details:  {
        __typename: "BillingDetails",
        address?: string | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      },
      card:  {
        __typename: "Card",
        brand: string,
        checks?: string | null,
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from?: string | null,
        last4: string,
        networks?: string | null,
        three_d_secure_usage?: string | null,
        wallet?: string | null,
      },
      created: number,
      customer: string,
      livemode: boolean,
      metadata:  {
        __typename: "PaymentMethodMetaData",
        name?: string | null,
      },
      type: string,
    } | null,
    code?: string | null,
    status?: string | null,
  },
};

export type UpdatePaymentMetaMutationVariables = {
  paymentId: string,
  metadata?: PaymentMetadataInput | null,
};

export type UpdatePaymentMetaMutation = {
  updatePaymentMeta:  {
    __typename: "PaymentIntent",
    id?: string | null,
    client_secret?: string | null,
    error_code?: string | null,
    payment_method?:  {
      __typename: "PaymentMethod",
      id: string,
      object: string,
      billing_details:  {
        __typename: "BillingDetails",
        address?: string | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      },
      card:  {
        __typename: "Card",
        brand: string,
        checks?: string | null,
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from?: string | null,
        last4: string,
        networks?: string | null,
        three_d_secure_usage?: string | null,
        wallet?: string | null,
      },
      created: number,
      customer: string,
      livemode: boolean,
      metadata:  {
        __typename: "PaymentMethodMetaData",
        name?: string | null,
      },
      type: string,
    } | null,
    code?: string | null,
    status?: string | null,
  },
};

export type CapturePaymentMutationVariables = {
  paymentIntentId: string,
};

export type CapturePaymentMutation = {
  capturePayment:  {
    __typename: "PaymentStatus",
    id?: string | null,
    status?: string | null,
    error_code?: string | null,
  },
};

export type DeletePaymentMethodMutationVariables = {
  paymentMethodId: string,
};

export type DeletePaymentMethodMutation = {
  deletePaymentMethod:  {
    __typename: "PaymentIntent",
    id?: string | null,
    client_secret?: string | null,
    error_code?: string | null,
    payment_method?:  {
      __typename: "PaymentMethod",
      id: string,
      object: string,
      billing_details:  {
        __typename: "BillingDetails",
        address?: string | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      },
      card:  {
        __typename: "Card",
        brand: string,
        checks?: string | null,
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from?: string | null,
        last4: string,
        networks?: string | null,
        three_d_secure_usage?: string | null,
        wallet?: string | null,
      },
      created: number,
      customer: string,
      livemode: boolean,
      metadata:  {
        __typename: "PaymentMethodMetaData",
        name?: string | null,
      },
      type: string,
    } | null,
    code?: string | null,
    status?: string | null,
  },
};

export type CancelPaymentMutationVariables = {
  paymentId: string,
};

export type CancelPaymentMutation = {
  cancelPayment:  {
    __typename: "PaymentIntent",
    id?: string | null,
    client_secret?: string | null,
    error_code?: string | null,
    payment_method?:  {
      __typename: "PaymentMethod",
      id: string,
      object: string,
      billing_details:  {
        __typename: "BillingDetails",
        address?: string | null,
        email?: string | null,
        name?: string | null,
        phone?: string | null,
      },
      card:  {
        __typename: "Card",
        brand: string,
        checks?: string | null,
        country: string,
        exp_month: number,
        exp_year: number,
        fingerprint: string,
        funding: string,
        generated_from?: string | null,
        last4: string,
        networks?: string | null,
        three_d_secure_usage?: string | null,
        wallet?: string | null,
      },
      created: number,
      customer: string,
      livemode: boolean,
      metadata:  {
        __typename: "PaymentMethodMetaData",
        name?: string | null,
      },
      type: string,
    } | null,
    code?: string | null,
    status?: string | null,
  },
};

export type SendFeedbackMailMutationVariables = {
  message: string,
};

export type SendFeedbackMailMutation = {
  sendFeedbackMail?: string | null,
};

export type ChargeReturnFeeMutationVariables = {
  stripeId: string,
  paymentMethodId: string,
};

export type ChargeReturnFeeMutation = {
  chargeReturnFee?: string | null,
};

export type AuthorizePaymentMutationVariables = {
  paymentId: string,
};

export type AuthorizePaymentMutation = {
  authorizePayment:  {
    __typename: "PaymentStatus",
    id?: string | null,
    status?: string | null,
    error_code?: string | null,
  },
};

export type CreateHubspotContactMutationVariables = {
  options: CreateHubspotContactInput,
};

export type CreateHubspotContactMutation = {
  createHubspotContact?: string | null,
};

export type RegisterOpenLogiItemMutationVariables = {
  item: OpenLogiItemInput,
};

export type RegisterOpenLogiItemMutation = {
  registerOpenLogiItem?: string | null,
};

export type CreateContactsMutationVariables = {
  accountId: string,
  supplierId: string,
  contacts: Array< CreateContactsInput | null >,
};

export type CreateContactsMutation = {
  createContacts?:  Array< {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type ProcessCampaignMutationVariables = {
  campaign: CampaignInput,
  contacts?: Array< CampaignContactInput | null > | null,
  operation: string,
};

export type ProcessCampaignMutation = {
  processCampaign?: boolean | null,
};

export type CreateProductsInBulkMutationVariables = {
  owner: string,
  productDataList: Array< ProductCsvInput | null >,
  imageKeys?: Array< string | null > | null,
  isOldFormat?: boolean | null,
};

export type CreateProductsInBulkMutation = {
  createProductsInBulk?:  Array< {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null > | null,
};

export type CreateHubspotDealMutationVariables = {
  order_id: string,
  order_date?: string | null,
  amount?: number | null,
  quantity?: number | null,
  shipping_fee?: number | null,
  tax?: number | null,
  fee_rate?: number | null,
  email?: string | null,
  store_name?: string | null,
  brand_name?: string | null,
};

export type CreateHubspotDealMutation = {
  createHubspotDeal?: boolean | null,
};

export type ApproveReportsMutationVariables = {
  report_product_ids: Array< string | null >,
  reject?: boolean | null,
};

export type ApproveReportsMutation = {
  approveReports?: boolean | null,
};

export type BillingAuthorizationMutationVariables = {
  amount: number,
  customer_id: string,
  number: string,
};

export type BillingAuthorizationMutation = {
  billingAuthorization?: string | null,
};

export type CreateTransactionMutationVariables = {
  orderId: string,
  canceledTransactionId?: string | null,
};

export type CreateTransactionMutation = {
  createTransaction?: string | null,
};

export type UpdateTransactionMutationVariables = {
  orderId: string,
};

export type UpdateTransactionMutation = {
  updateTransaction?: string | null,
};

export type CancelTransactionMutationVariables = {
  transaction_id: string,
};

export type CancelTransactionMutation = {
  cancelTransaction?: string | null,
};

export type CancelAuthorizationMutationVariables = {
  authorization_id: string,
};

export type CancelAuthorizationMutation = {
  cancelAuthorization?: string | null,
};

export type UpdateAuthorizationMutationVariables = {
  buyer_id: string,
  order_id: string,
  amount: number,
};

export type UpdateAuthorizationMutation = {
  updateAuthorization?: string | null,
};

export type CashbackMutationVariables = {
  orderId: string,
  amount: number,
};

export type CashbackMutation = {
  cashback?: string | null,
};

export type SyncBuyerReadModelMutationVariables = {
  buyerId?: string | null,
};

export type SyncBuyerReadModelMutation = {
  syncBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateInventoriesMutationVariables = {
  brandId: string,
  products: Array< UpdateInventoriesInput | null >,
};

export type UpdateInventoriesMutation = {
  updateInventories?:  Array< {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null > | null,
};

export type InventoryReservationMutationVariables = {
  input: Array< InventoryReservationInput | null >,
};

export type InventoryReservationMutation = {
  inventoryReservation?:  Array< {
    __typename: "InventoryReservationOutput",
    order_product_id: string,
    inventory?:  {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    error?: string | null,
  } | null > | null,
};

export type TrackEventMutationVariables = {
  accountId?: string | null,
  event?: string | null,
  properties?: string | null,
};

export type TrackEventMutation = {
  trackEvent?: string | null,
};

export type SyncOrderReadModelMutationVariables = {
  orderId?: string | null,
};

export type SyncOrderReadModelMutation = {
  syncOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type SyncOrderDetailReadModelMutationVariables = {
  orderId?: string | null,
  orderProductId?: string | null,
};

export type SyncOrderDetailReadModelMutation = {
  syncOrderDetailReadModel?:  Array< {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null > | null,
};

export type SyncReturnDetailReadModelMutationVariables = {
  returnProductId?: string | null,
};

export type SyncReturnDetailReadModelMutation = {
  syncReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderPaymentMethodMutationVariables = {
  orderId: string,
  paymentMethodId: string,
};

export type UpdateOrderPaymentMethodMutation = {
  updateOrderPaymentMethod?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type SyncProductReadModelMutationVariables = {
  productId?: string | null,
};

export type SyncProductReadModelMutation = {
  syncProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type SyncCollectionReadModelMutationVariables = {
  collectionId?: string | null,
};

export type SyncCollectionReadModelMutation = {
  syncCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type CopyProductsMutationVariables = {
  input: Array< CopyProductInput | null >,
};

export type CopyProductsMutation = {
  copyProducts?: string | null,
};

export type SyncBrandReadModelMutationVariables = {
  brandId?: string | null,
};

export type SyncBrandReadModelMutation = {
  syncBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBillingTransactionMutationVariables = {
  input: CreateBillingTransactionInput,
};

export type CreateBillingTransactionMutation = {
  createBillingTransaction?: string | null,
};

export type CreateLogilessTokenMutationVariables = {
  code: string,
};

export type CreateLogilessTokenMutation = {
  createLogilessToken?: string | null,
};

export type CreateLogilessProductMutationVariables = {
  productId?: string | null,
  productTypeId?: string | null,
};

export type CreateLogilessProductMutation = {
  createLogilessProduct?: string | null,
};

export type UpdateLogilessProductMutationVariables = {
  product?: string | null,
};

export type UpdateLogilessProductMutation = {
  updateLogilessProduct?: string | null,
};

export type ArrivalLogilessProductMutationVariables = {
  returnProductIds: Array< string | null >,
};

export type ArrivalLogilessProductMutation = {
  arrivalLogilessProduct?: string | null,
};

export type OrderLogilessProductMutationVariables = {
  orderIds: Array< string | null >,
};

export type OrderLogilessProductMutation = {
  orderLogilessProduct?: string | null,
};

export type UpdateProductSkuMutationVariables = {
  productId?: string | null,
  productTypeIds?: Array< string | null > | null,
};

export type UpdateProductSkuMutation = {
  updateProductSku?: string | null,
};

export type UpdateStockProductByProductIdMutationVariables = {
  srcProductId?: string | null,
  srcProductTypeId?: string | null,
  productId?: string | null,
  productTypeId?: string | null,
};

export type UpdateStockProductByProductIdMutation = {
  updateStockProductByProductId?: string | null,
};

export type ImportStockProductMutationVariables = {
};

export type ImportStockProductMutation = {
  importStockProduct?: string | null,
};

export type DeleteContactListConnectionsByListIdMutationVariables = {
  listId?: string | null,
};

export type DeleteContactListConnectionsByListIdMutation = {
  deleteContactListConnectionsByListId?: string | null,
};

export type AddContactsToListMutationVariables = {
  listId: string,
  contactIds: Array< string | null >,
  owner: string,
  supplierId: string,
};

export type AddContactsToListMutation = {
  addContactsToList?: Array< string | null > | null,
};

export type RemoveContactsFromListMutationVariables = {
  listId: string,
  contactIds: Array< string | null >,
};

export type RemoveContactsFromListMutation = {
  removeContactsFromList?: Array< string | null > | null,
};

export type SplitOrderMutationVariables = {
  input?: SplitOrderInput | null,
};

export type SplitOrderMutation = {
  splitOrder?: string | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBuyerInfoMutationVariables = {
  input: CreateBuyerInfoInput,
  condition?: ModelBuyerInfoConditionInput | null,
};

export type CreateBuyerInfoMutation = {
  createBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBuyerInfoMutationVariables = {
  input: UpdateBuyerInfoInput,
  condition?: ModelBuyerInfoConditionInput | null,
};

export type UpdateBuyerInfoMutation = {
  updateBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBuyerInfoMutationVariables = {
  input: DeleteBuyerInfoInput,
  condition?: ModelBuyerInfoConditionInput | null,
};

export type DeleteBuyerInfoMutation = {
  deleteBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateShopImageMutationVariables = {
  input: CreateShopImageInput,
  condition?: ModelShopImageConditionInput | null,
};

export type CreateShopImageMutation = {
  createShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateShopImageMutationVariables = {
  input: UpdateShopImageInput,
  condition?: ModelShopImageConditionInput | null,
};

export type UpdateShopImageMutation = {
  updateShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteShopImageMutationVariables = {
  input: DeleteShopImageInput,
  condition?: ModelShopImageConditionInput | null,
};

export type DeleteShopImageMutation = {
  deleteShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateIndividualMutationVariables = {
  input: CreateIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type CreateIndividualMutation = {
  createIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateIndividualMutationVariables = {
  input: UpdateIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type UpdateIndividualMutation = {
  updateIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteIndividualMutationVariables = {
  input: DeleteIndividualInput,
  condition?: ModelIndividualConditionInput | null,
};

export type DeleteIndividualMutation = {
  deleteIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSupplierGroupMutationVariables = {
  input: CreateSupplierGroupInput,
  condition?: ModelSupplierGroupConditionInput | null,
};

export type CreateSupplierGroupMutation = {
  createSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSupplierGroupMutationVariables = {
  input: UpdateSupplierGroupInput,
  condition?: ModelSupplierGroupConditionInput | null,
};

export type UpdateSupplierGroupMutation = {
  updateSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSupplierGroupMutationVariables = {
  input: DeleteSupplierGroupInput,
  condition?: ModelSupplierGroupConditionInput | null,
};

export type DeleteSupplierGroupMutation = {
  deleteSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSupplierInfoMutationVariables = {
  input: CreateSupplierInfoInput,
  condition?: ModelSupplierInfoConditionInput | null,
};

export type CreateSupplierInfoMutation = {
  createSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSupplierInfoMutationVariables = {
  input: UpdateSupplierInfoInput,
  condition?: ModelSupplierInfoConditionInput | null,
};

export type UpdateSupplierInfoMutation = {
  updateSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSupplierInfoMutationVariables = {
  input: DeleteSupplierInfoInput,
  condition?: ModelSupplierInfoConditionInput | null,
};

export type DeleteSupplierInfoMutation = {
  deleteSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateShippingAddressMutationVariables = {
  input: CreateShippingAddressInput,
  condition?: ModelShippingAddressConditionInput | null,
};

export type CreateShippingAddressMutation = {
  createShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateShippingAddressMutationVariables = {
  input: UpdateShippingAddressInput,
  condition?: ModelShippingAddressConditionInput | null,
};

export type UpdateShippingAddressMutation = {
  updateShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteShippingAddressMutationVariables = {
  input: DeleteShippingAddressInput,
  condition?: ModelShippingAddressConditionInput | null,
};

export type DeleteShippingAddressMutation = {
  deleteShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateEmailPreferencesMutationVariables = {
  input: CreateEmailPreferencesInput,
  condition?: ModelEmailPreferencesConditionInput | null,
};

export type CreateEmailPreferencesMutation = {
  createEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmailPreferencesMutationVariables = {
  input: UpdateEmailPreferencesInput,
  condition?: ModelEmailPreferencesConditionInput | null,
};

export type UpdateEmailPreferencesMutation = {
  updateEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmailPreferencesMutationVariables = {
  input: DeleteEmailPreferencesInput,
  condition?: ModelEmailPreferencesConditionInput | null,
};

export type DeleteEmailPreferencesMutation = {
  deleteEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProductImageKeyMutationVariables = {
  input: CreateProductImageKeyInput,
  condition?: ModelProductImageKeyConditionInput | null,
};

export type CreateProductImageKeyMutation = {
  createProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateProductImageKeyMutationVariables = {
  input: UpdateProductImageKeyInput,
  condition?: ModelProductImageKeyConditionInput | null,
};

export type UpdateProductImageKeyMutation = {
  updateProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteProductImageKeyMutationVariables = {
  input: DeleteProductImageKeyInput,
  condition?: ModelProductImageKeyConditionInput | null,
};

export type DeleteProductImageKeyMutation = {
  deleteProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProductVideoKeyMutationVariables = {
  input: CreateProductVideoKeyInput,
  condition?: ModelProductVideoKeyConditionInput | null,
};

export type CreateProductVideoKeyMutation = {
  createProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateProductVideoKeyMutationVariables = {
  input: UpdateProductVideoKeyInput,
  condition?: ModelProductVideoKeyConditionInput | null,
};

export type UpdateProductVideoKeyMutation = {
  updateProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteProductVideoKeyMutationVariables = {
  input: DeleteProductVideoKeyInput,
  condition?: ModelProductVideoKeyConditionInput | null,
};

export type DeleteProductVideoKeyMutation = {
  deleteProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProductTypeMutationVariables = {
  input: CreateProductTypeInput,
  condition?: ModelProductTypeConditionInput | null,
};

export type CreateProductTypeMutation = {
  createProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProductTypeMutationVariables = {
  input: UpdateProductTypeInput,
  condition?: ModelProductTypeConditionInput | null,
};

export type UpdateProductTypeMutation = {
  updateProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProductTypeMutationVariables = {
  input: DeleteProductTypeInput,
  condition?: ModelProductTypeConditionInput | null,
};

export type DeleteProductTypeMutation = {
  deleteProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateInventoryMutationVariables = {
  input: CreateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type CreateInventoryMutation = {
  createInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateInventoryMutationVariables = {
  input: UpdateInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type UpdateInventoryMutation = {
  updateInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteInventoryMutationVariables = {
  input: DeleteInventoryInput,
  condition?: ModelInventoryConditionInput | null,
};

export type DeleteInventoryMutation = {
  deleteInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReturnInventoryLogMutationVariables = {
  input: CreateReturnInventoryLogInput,
  condition?: ModelReturnInventoryLogConditionInput | null,
};

export type CreateReturnInventoryLogMutation = {
  createReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReturnInventoryLogMutationVariables = {
  input: UpdateReturnInventoryLogInput,
  condition?: ModelReturnInventoryLogConditionInput | null,
};

export type UpdateReturnInventoryLogMutation = {
  updateReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReturnInventoryLogMutationVariables = {
  input: DeleteReturnInventoryLogInput,
  condition?: ModelReturnInventoryLogConditionInput | null,
};

export type DeleteReturnInventoryLogMutation = {
  deleteReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateInventoryLogMutationVariables = {
  input: CreateInventoryLogInput,
  condition?: ModelInventoryLogConditionInput | null,
};

export type CreateInventoryLogMutation = {
  createInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateInventoryLogMutationVariables = {
  input: UpdateInventoryLogInput,
  condition?: ModelInventoryLogConditionInput | null,
};

export type UpdateInventoryLogMutation = {
  updateInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteInventoryLogMutationVariables = {
  input: DeleteInventoryLogInput,
  condition?: ModelInventoryLogConditionInput | null,
};

export type DeleteInventoryLogMutation = {
  deleteInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateInStockNotificationMutationVariables = {
  input: CreateInStockNotificationInput,
  condition?: ModelInStockNotificationConditionInput | null,
};

export type CreateInStockNotificationMutation = {
  createInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateInStockNotificationMutationVariables = {
  input: UpdateInStockNotificationInput,
  condition?: ModelInStockNotificationConditionInput | null,
};

export type UpdateInStockNotificationMutation = {
  updateInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteInStockNotificationMutationVariables = {
  input: DeleteInStockNotificationInput,
  condition?: ModelInStockNotificationConditionInput | null,
};

export type DeleteInStockNotificationMutation = {
  deleteInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBrandCollectionMutationVariables = {
  input: CreateBrandCollectionInput,
  condition?: ModelBrandCollectionConditionInput | null,
};

export type CreateBrandCollectionMutation = {
  createBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandCollectionMutationVariables = {
  input: UpdateBrandCollectionInput,
  condition?: ModelBrandCollectionConditionInput | null,
};

export type UpdateBrandCollectionMutation = {
  updateBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandCollectionMutationVariables = {
  input: DeleteBrandCollectionInput,
  condition?: ModelBrandCollectionConditionInput | null,
};

export type DeleteBrandCollectionMutation = {
  deleteBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateShopCartMutationVariables = {
  input: CreateShopCartInput,
  condition?: ModelShopCartConditionInput | null,
};

export type CreateShopCartMutation = {
  createShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateShopCartMutationVariables = {
  input: UpdateShopCartInput,
  condition?: ModelShopCartConditionInput | null,
};

export type UpdateShopCartMutation = {
  updateShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteShopCartMutationVariables = {
  input: DeleteShopCartInput,
  condition?: ModelShopCartConditionInput | null,
};

export type DeleteShopCartMutation = {
  deleteShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateChargeStatusMutationVariables = {
  input: CreateChargeStatusInput,
  condition?: ModelChargeStatusConditionInput | null,
};

export type CreateChargeStatusMutation = {
  createChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type UpdateChargeStatusMutationVariables = {
  input: UpdateChargeStatusInput,
  condition?: ModelChargeStatusConditionInput | null,
};

export type UpdateChargeStatusMutation = {
  updateChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type DeleteChargeStatusMutationVariables = {
  input: DeleteChargeStatusInput,
  condition?: ModelChargeStatusConditionInput | null,
};

export type DeleteChargeStatusMutation = {
  deleteChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type CreateOrderProductMutationVariables = {
  input: CreateOrderProductInput,
  condition?: ModelOrderProductConditionInput | null,
};

export type CreateOrderProductMutation = {
  createOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrderProductMutationVariables = {
  input: UpdateOrderProductInput,
  condition?: ModelOrderProductConditionInput | null,
};

export type UpdateOrderProductMutation = {
  updateOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrderProductMutationVariables = {
  input: DeleteOrderProductInput,
  condition?: ModelOrderProductConditionInput | null,
};

export type DeleteOrderProductMutation = {
  deleteOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOrderStatusMutationVariables = {
  input: CreateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type CreateOrderStatusMutation = {
  createOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderStatusMutationVariables = {
  input: UpdateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type UpdateOrderStatusMutation = {
  updateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderStatusMutationVariables = {
  input: DeleteOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type DeleteOrderStatusMutation = {
  deleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReturnProductMutationVariables = {
  input: CreateReturnProductInput,
  condition?: ModelReturnProductConditionInput | null,
};

export type CreateReturnProductMutation = {
  createReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReturnProductMutationVariables = {
  input: UpdateReturnProductInput,
  condition?: ModelReturnProductConditionInput | null,
};

export type UpdateReturnProductMutation = {
  updateReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReturnProductMutationVariables = {
  input: DeleteReturnProductInput,
  condition?: ModelReturnProductConditionInput | null,
};

export type DeleteReturnProductMutation = {
  deleteReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReportProductMutationVariables = {
  input: CreateReportProductInput,
  condition?: ModelReportProductConditionInput | null,
};

export type CreateReportProductMutation = {
  createReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReportProductMutationVariables = {
  input: UpdateReportProductInput,
  condition?: ModelReportProductConditionInput | null,
};

export type UpdateReportProductMutation = {
  updateReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReportProductMutationVariables = {
  input: DeleteReportProductInput,
  condition?: ModelReportProductConditionInput | null,
};

export type DeleteReportProductMutation = {
  deleteReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateFeedbackMutationVariables = {
  input: CreateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type CreateFeedbackMutation = {
  createFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateFeedbackMutationVariables = {
  input: UpdateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type UpdateFeedbackMutation = {
  updateFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFeedbackMutationVariables = {
  input: DeleteFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type DeleteFeedbackMutation = {
  deleteFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrderEditReasonMutationVariables = {
  input: CreateOrderEditReasonInput,
  condition?: ModelOrderEditReasonConditionInput | null,
};

export type CreateOrderEditReasonMutation = {
  createOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderEditReasonMutationVariables = {
  input: UpdateOrderEditReasonInput,
  condition?: ModelOrderEditReasonConditionInput | null,
};

export type UpdateOrderEditReasonMutation = {
  updateOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderEditReasonMutationVariables = {
  input: DeleteOrderEditReasonInput,
  condition?: ModelOrderEditReasonConditionInput | null,
};

export type DeleteOrderEditReasonMutation = {
  deleteOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrderCancelReasonMutationVariables = {
  input: CreateOrderCancelReasonInput,
  condition?: ModelOrderCancelReasonConditionInput | null,
};

export type CreateOrderCancelReasonMutation = {
  createOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderCancelReasonMutationVariables = {
  input: UpdateOrderCancelReasonInput,
  condition?: ModelOrderCancelReasonConditionInput | null,
};

export type UpdateOrderCancelReasonMutation = {
  updateOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderCancelReasonMutationVariables = {
  input: DeleteOrderCancelReasonInput,
  condition?: ModelOrderCancelReasonConditionInput | null,
};

export type DeleteOrderCancelReasonMutation = {
  deleteOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateThreadMutationVariables = {
  input: CreateThreadInput,
  condition?: ModelThreadConditionInput | null,
};

export type CreateThreadMutation = {
  createThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateThreadMutationVariables = {
  input: UpdateThreadInput,
  condition?: ModelThreadConditionInput | null,
};

export type UpdateThreadMutation = {
  updateThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteThreadMutationVariables = {
  input: DeleteThreadInput,
  condition?: ModelThreadConditionInput | null,
};

export type DeleteThreadMutation = {
  deleteThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreatePickupProductMutationVariables = {
  input: CreatePickupProductInput,
  condition?: ModelPickupProductConditionInput | null,
};

export type CreatePickupProductMutation = {
  createPickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePickupProductMutationVariables = {
  input: UpdatePickupProductInput,
  condition?: ModelPickupProductConditionInput | null,
};

export type UpdatePickupProductMutation = {
  updatePickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePickupProductMutationVariables = {
  input: DeletePickupProductInput,
  condition?: ModelPickupProductConditionInput | null,
};

export type DeletePickupProductMutation = {
  deletePickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePickupBrandsMutationVariables = {
  input: CreatePickupBrandsInput,
  condition?: ModelPickupBrandsConditionInput | null,
};

export type CreatePickupBrandsMutation = {
  createPickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePickupBrandsMutationVariables = {
  input: UpdatePickupBrandsInput,
  condition?: ModelPickupBrandsConditionInput | null,
};

export type UpdatePickupBrandsMutation = {
  updatePickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePickupBrandsMutationVariables = {
  input: DeletePickupBrandsInput,
  condition?: ModelPickupBrandsConditionInput | null,
};

export type DeletePickupBrandsMutation = {
  deletePickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateMenuMutationVariables = {
  input: CreateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type CreateMenuMutation = {
  createMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMenuMutationVariables = {
  input: UpdateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type UpdateMenuMutation = {
  updateMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMenuMutationVariables = {
  input: DeleteMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type DeleteMenuMutation = {
  deleteMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFavoriteBrandMutationVariables = {
  input: CreateFavoriteBrandInput,
  condition?: ModelFavoriteBrandConditionInput | null,
};

export type CreateFavoriteBrandMutation = {
  createFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateFavoriteBrandMutationVariables = {
  input: UpdateFavoriteBrandInput,
  condition?: ModelFavoriteBrandConditionInput | null,
};

export type UpdateFavoriteBrandMutation = {
  updateFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteFavoriteBrandMutationVariables = {
  input: DeleteFavoriteBrandInput,
  condition?: ModelFavoriteBrandConditionInput | null,
};

export type DeleteFavoriteBrandMutation = {
  deleteFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateFavoriteProductMutationVariables = {
  input: CreateFavoriteProductInput,
  condition?: ModelFavoriteProductConditionInput | null,
};

export type CreateFavoriteProductMutation = {
  createFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type UpdateFavoriteProductMutationVariables = {
  input: UpdateFavoriteProductInput,
  condition?: ModelFavoriteProductConditionInput | null,
};

export type UpdateFavoriteProductMutation = {
  updateFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type DeleteFavoriteProductMutationVariables = {
  input: DeleteFavoriteProductInput,
  condition?: ModelFavoriteProductConditionInput | null,
};

export type DeleteFavoriteProductMutation = {
  deleteFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type CreateFavoriteProductListMutationVariables = {
  input: CreateFavoriteProductListInput,
  condition?: ModelFavoriteProductListConditionInput | null,
};

export type CreateFavoriteProductListMutation = {
  createFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateFavoriteProductListMutationVariables = {
  input: UpdateFavoriteProductListInput,
  condition?: ModelFavoriteProductListConditionInput | null,
};

export type UpdateFavoriteProductListMutation = {
  updateFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteFavoriteProductListMutationVariables = {
  input: DeleteFavoriteProductListInput,
  condition?: ModelFavoriteProductListConditionInput | null,
};

export type DeleteFavoriteProductListMutation = {
  deleteFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateCollectionMutationVariables = {
  input: CreateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type CreateCollectionMutation = {
  createCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCollectionMutationVariables = {
  input: UpdateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionMutation = {
  updateCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCollectionMutationVariables = {
  input: DeleteCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type DeleteCollectionMutation = {
  deleteCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProductCollectionMutationVariables = {
  input: CreateProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type CreateProductCollectionMutation = {
  createProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateProductCollectionMutationVariables = {
  input: UpdateProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type UpdateProductCollectionMutation = {
  updateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteProductCollectionMutationVariables = {
  input: DeleteProductCollectionInput,
  condition?: ModelProductCollectionConditionInput | null,
};

export type DeleteProductCollectionMutation = {
  deleteProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReferralMutationVariables = {
  input: CreateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type CreateReferralMutation = {
  createReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReferralMutationVariables = {
  input: UpdateReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type UpdateReferralMutation = {
  updateReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReferralMutationVariables = {
  input: DeleteReferralInput,
  condition?: ModelReferralConditionInput | null,
};

export type DeleteReferralMutation = {
  deleteReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReferralMessageMutationVariables = {
  input: CreateReferralMessageInput,
  condition?: ModelReferralMessageConditionInput | null,
};

export type CreateReferralMessageMutation = {
  createReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReferralMessageMutationVariables = {
  input: UpdateReferralMessageInput,
  condition?: ModelReferralMessageConditionInput | null,
};

export type UpdateReferralMessageMutation = {
  updateReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReferralMessageMutationVariables = {
  input: DeleteReferralMessageInput,
  condition?: ModelReferralMessageConditionInput | null,
};

export type DeleteReferralMessageMutation = {
  deleteReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePointMutationVariables = {
  input: CreatePointInput,
  condition?: ModelPointConditionInput | null,
};

export type CreatePointMutation = {
  createPoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePointMutationVariables = {
  input: UpdatePointInput,
  condition?: ModelPointConditionInput | null,
};

export type UpdatePointMutation = {
  updatePoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePointMutationVariables = {
  input: DeletePointInput,
  condition?: ModelPointConditionInput | null,
};

export type DeletePointMutation = {
  deletePoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreatePointHistoryMutationVariables = {
  input: CreatePointHistoryInput,
  condition?: ModelPointHistoryConditionInput | null,
};

export type CreatePointHistoryMutation = {
  createPointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePointHistoryMutationVariables = {
  input: UpdatePointHistoryInput,
  condition?: ModelPointHistoryConditionInput | null,
};

export type UpdatePointHistoryMutation = {
  updatePointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePointHistoryMutationVariables = {
  input: DeletePointHistoryInput,
  condition?: ModelPointHistoryConditionInput | null,
};

export type DeletePointHistoryMutation = {
  deletePointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateContactListMutationVariables = {
  input: CreateContactListInput,
  condition?: ModelContactListConditionInput | null,
};

export type CreateContactListMutation = {
  createContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateContactListMutationVariables = {
  input: UpdateContactListInput,
  condition?: ModelContactListConditionInput | null,
};

export type UpdateContactListMutation = {
  updateContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteContactListMutationVariables = {
  input: DeleteContactListInput,
  condition?: ModelContactListConditionInput | null,
};

export type DeleteContactListMutation = {
  deleteContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateContactListConnectionMutationVariables = {
  input: CreateContactListConnectionInput,
  condition?: ModelContactListConnectionConditionInput | null,
};

export type CreateContactListConnectionMutation = {
  createContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateContactListConnectionMutationVariables = {
  input: UpdateContactListConnectionInput,
  condition?: ModelContactListConnectionConditionInput | null,
};

export type UpdateContactListConnectionMutation = {
  updateContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteContactListConnectionMutationVariables = {
  input: DeleteContactListConnectionInput,
  condition?: ModelContactListConnectionConditionInput | null,
};

export type DeleteContactListConnectionMutation = {
  deleteContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateContactActivityMutationVariables = {
  input: CreateContactActivityInput,
  condition?: ModelContactActivityConditionInput | null,
};

export type CreateContactActivityMutation = {
  createContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateContactActivityMutationVariables = {
  input: UpdateContactActivityInput,
  condition?: ModelContactActivityConditionInput | null,
};

export type UpdateContactActivityMutation = {
  updateContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteContactActivityMutationVariables = {
  input: DeleteContactActivityInput,
  condition?: ModelContactActivityConditionInput | null,
};

export type DeleteContactActivityMutation = {
  deleteContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCampaignMutationVariables = {
  input: CreateCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type CreateCampaignMutation = {
  createCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCampaignMutationVariables = {
  input: UpdateCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type UpdateCampaignMutation = {
  updateCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCampaignMutationVariables = {
  input: DeleteCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type DeleteCampaignMutation = {
  deleteCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCampaignContactMutationVariables = {
  input: CreateCampaignContactInput,
  condition?: ModelCampaignContactConditionInput | null,
};

export type CreateCampaignContactMutation = {
  createCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCampaignContactMutationVariables = {
  input: UpdateCampaignContactInput,
  condition?: ModelCampaignContactConditionInput | null,
};

export type UpdateCampaignContactMutation = {
  updateCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCampaignContactMutationVariables = {
  input: DeleteCampaignContactInput,
  condition?: ModelCampaignContactConditionInput | null,
};

export type DeleteCampaignContactMutation = {
  deleteCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDailySessionMutationVariables = {
  input: CreateDailySessionInput,
  condition?: ModelDailySessionConditionInput | null,
};

export type CreateDailySessionMutation = {
  createDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDailySessionMutationVariables = {
  input: UpdateDailySessionInput,
  condition?: ModelDailySessionConditionInput | null,
};

export type UpdateDailySessionMutation = {
  updateDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDailySessionMutationVariables = {
  input: DeleteDailySessionInput,
  condition?: ModelDailySessionConditionInput | null,
};

export type DeleteDailySessionMutation = {
  deleteDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConversionRateMutationVariables = {
  input: CreateConversionRateInput,
  condition?: ModelConversionRateConditionInput | null,
};

export type CreateConversionRateMutation = {
  createConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversionRateMutationVariables = {
  input: UpdateConversionRateInput,
  condition?: ModelConversionRateConditionInput | null,
};

export type UpdateConversionRateMutation = {
  updateConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversionRateMutationVariables = {
  input: DeleteConversionRateInput,
  condition?: ModelConversionRateConditionInput | null,
};

export type DeleteConversionRateMutation = {
  deleteConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePageViewMutationVariables = {
  input: CreatePageViewInput,
  condition?: ModelPageViewConditionInput | null,
};

export type CreatePageViewMutation = {
  createPageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePageViewMutationVariables = {
  input: UpdatePageViewInput,
  condition?: ModelPageViewConditionInput | null,
};

export type UpdatePageViewMutation = {
  updatePageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePageViewMutationVariables = {
  input: DeletePageViewInput,
  condition?: ModelPageViewConditionInput | null,
};

export type DeletePageViewMutation = {
  deletePageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReviewReplyMutationVariables = {
  input: CreateReviewReplyInput,
  condition?: ModelReviewReplyConditionInput | null,
};

export type CreateReviewReplyMutation = {
  createReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateReviewReplyMutationVariables = {
  input: UpdateReviewReplyInput,
  condition?: ModelReviewReplyConditionInput | null,
};

export type UpdateReviewReplyMutation = {
  updateReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteReviewReplyMutationVariables = {
  input: DeleteReviewReplyInput,
  condition?: ModelReviewReplyConditionInput | null,
};

export type DeleteReviewReplyMutation = {
  deleteReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateCouponMutationVariables = {
  input: CreateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type CreateCouponMutation = {
  createCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type UpdateCouponMutation = {
  updateCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCouponMutationVariables = {
  input: DeleteCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type DeleteCouponMutation = {
  deleteCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCouponLogMutationVariables = {
  input: CreateCouponLogInput,
  condition?: ModelCouponLogConditionInput | null,
};

export type CreateCouponLogMutation = {
  createCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCouponLogMutationVariables = {
  input: UpdateCouponLogInput,
  condition?: ModelCouponLogConditionInput | null,
};

export type UpdateCouponLogMutation = {
  updateCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCouponLogMutationVariables = {
  input: DeleteCouponLogInput,
  condition?: ModelCouponLogConditionInput | null,
};

export type DeleteCouponLogMutation = {
  deleteCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBuyerCouponMutationVariables = {
  input: CreateBuyerCouponInput,
  condition?: ModelBuyerCouponConditionInput | null,
};

export type CreateBuyerCouponMutation = {
  createBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateBuyerCouponMutationVariables = {
  input: UpdateBuyerCouponInput,
  condition?: ModelBuyerCouponConditionInput | null,
};

export type UpdateBuyerCouponMutation = {
  updateBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteBuyerCouponMutationVariables = {
  input: DeleteBuyerCouponInput,
  condition?: ModelBuyerCouponConditionInput | null,
};

export type DeleteBuyerCouponMutation = {
  deleteBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateCashbackMutationVariables = {
  input: CreateCashbackInput,
  condition?: ModelCashbackConditionInput | null,
};

export type CreateCashbackMutation = {
  createCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateCashbackMutationVariables = {
  input: UpdateCashbackInput,
  condition?: ModelCashbackConditionInput | null,
};

export type UpdateCashbackMutation = {
  updateCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteCashbackMutationVariables = {
  input: DeleteCashbackInput,
  condition?: ModelCashbackConditionInput | null,
};

export type DeleteCashbackMutation = {
  deleteCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateStockProductMutationVariables = {
  input: CreateStockProductInput,
  condition?: ModelStockProductConditionInput | null,
};

export type CreateStockProductMutation = {
  createStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStockProductMutationVariables = {
  input: UpdateStockProductInput,
  condition?: ModelStockProductConditionInput | null,
};

export type UpdateStockProductMutation = {
  updateStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStockProductMutationVariables = {
  input: DeleteStockProductInput,
  condition?: ModelStockProductConditionInput | null,
};

export type DeleteStockProductMutation = {
  deleteStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLogilessAuthTokenMutationVariables = {
  input: CreateLogilessAuthTokenInput,
  condition?: ModelLogilessAuthTokenConditionInput | null,
};

export type CreateLogilessAuthTokenMutation = {
  createLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateLogilessAuthTokenMutationVariables = {
  input: UpdateLogilessAuthTokenInput,
  condition?: ModelLogilessAuthTokenConditionInput | null,
};

export type UpdateLogilessAuthTokenMutation = {
  updateLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteLogilessAuthTokenMutationVariables = {
  input: DeleteLogilessAuthTokenInput,
  condition?: ModelLogilessAuthTokenConditionInput | null,
};

export type DeleteLogilessAuthTokenMutation = {
  deleteLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateBuyerReadModelMutationVariables = {
  input: CreateBuyerReadModelInput,
  condition?: ModelBuyerReadModelConditionInput | null,
};

export type CreateBuyerReadModelMutation = {
  createBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateBuyerReadModelMutationVariables = {
  input: UpdateBuyerReadModelInput,
  condition?: ModelBuyerReadModelConditionInput | null,
};

export type UpdateBuyerReadModelMutation = {
  updateBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteBuyerReadModelMutationVariables = {
  input: DeleteBuyerReadModelInput,
  condition?: ModelBuyerReadModelConditionInput | null,
};

export type DeleteBuyerReadModelMutation = {
  deleteBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateBrandReadModelMutationVariables = {
  input: CreateBrandReadModelInput,
  condition?: ModelBrandReadModelConditionInput | null,
};

export type CreateBrandReadModelMutation = {
  createBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBrandReadModelMutationVariables = {
  input: UpdateBrandReadModelInput,
  condition?: ModelBrandReadModelConditionInput | null,
};

export type UpdateBrandReadModelMutation = {
  updateBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBrandReadModelMutationVariables = {
  input: DeleteBrandReadModelInput,
  condition?: ModelBrandReadModelConditionInput | null,
};

export type DeleteBrandReadModelMutation = {
  deleteBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProductReadModelMutationVariables = {
  input: CreateProductReadModelInput,
  condition?: ModelProductReadModelConditionInput | null,
};

export type CreateProductReadModelMutation = {
  createProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type UpdateProductReadModelMutationVariables = {
  input: UpdateProductReadModelInput,
  condition?: ModelProductReadModelConditionInput | null,
};

export type UpdateProductReadModelMutation = {
  updateProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type DeleteProductReadModelMutationVariables = {
  input: DeleteProductReadModelInput,
  condition?: ModelProductReadModelConditionInput | null,
};

export type DeleteProductReadModelMutation = {
  deleteProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type CreateCollectionReadModelMutationVariables = {
  input: CreateCollectionReadModelInput,
  condition?: ModelCollectionReadModelConditionInput | null,
};

export type CreateCollectionReadModelMutation = {
  createCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type UpdateCollectionReadModelMutationVariables = {
  input: UpdateCollectionReadModelInput,
  condition?: ModelCollectionReadModelConditionInput | null,
};

export type UpdateCollectionReadModelMutation = {
  updateCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type DeleteCollectionReadModelMutationVariables = {
  input: DeleteCollectionReadModelInput,
  condition?: ModelCollectionReadModelConditionInput | null,
};

export type DeleteCollectionReadModelMutation = {
  deleteCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type CreateOrderReadModelMutationVariables = {
  input: CreateOrderReadModelInput,
  condition?: ModelOrderReadModelConditionInput | null,
};

export type CreateOrderReadModelMutation = {
  createOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrderReadModelMutationVariables = {
  input: UpdateOrderReadModelInput,
  condition?: ModelOrderReadModelConditionInput | null,
};

export type UpdateOrderReadModelMutation = {
  updateOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrderReadModelMutationVariables = {
  input: DeleteOrderReadModelInput,
  condition?: ModelOrderReadModelConditionInput | null,
};

export type DeleteOrderReadModelMutation = {
  deleteOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOrderDetailReadModelMutationVariables = {
  input: CreateOrderDetailReadModelInput,
  condition?: ModelOrderDetailReadModelConditionInput | null,
};

export type CreateOrderDetailReadModelMutation = {
  createOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateOrderDetailReadModelMutationVariables = {
  input: UpdateOrderDetailReadModelInput,
  condition?: ModelOrderDetailReadModelConditionInput | null,
};

export type UpdateOrderDetailReadModelMutation = {
  updateOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrderDetailReadModelMutationVariables = {
  input: DeleteOrderDetailReadModelInput,
  condition?: ModelOrderDetailReadModelConditionInput | null,
};

export type DeleteOrderDetailReadModelMutation = {
  deleteOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateReturnDetailReadModelMutationVariables = {
  input: CreateReturnDetailReadModelInput,
  condition?: ModelReturnDetailReadModelConditionInput | null,
};

export type CreateReturnDetailReadModelMutation = {
  createReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReturnDetailReadModelMutationVariables = {
  input: UpdateReturnDetailReadModelInput,
  condition?: ModelReturnDetailReadModelConditionInput | null,
};

export type UpdateReturnDetailReadModelMutation = {
  updateReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReturnDetailReadModelMutationVariables = {
  input: DeleteReturnDetailReadModelInput,
  condition?: ModelReturnDetailReadModelConditionInput | null,
};

export type DeleteReturnDetailReadModelMutation = {
  deleteReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateContactReadModelMutationVariables = {
  input: CreateContactReadModelInput,
  condition?: ModelContactReadModelConditionInput | null,
};

export type CreateContactReadModelMutation = {
  createContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateContactReadModelMutationVariables = {
  input: UpdateContactReadModelInput,
  condition?: ModelContactReadModelConditionInput | null,
};

export type UpdateContactReadModelMutation = {
  updateContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteContactReadModelMutationVariables = {
  input: DeleteContactReadModelInput,
  condition?: ModelContactReadModelConditionInput | null,
};

export type DeleteContactReadModelMutation = {
  deleteContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOrderEventMutationVariables = {
  input: CreateOrderEventInput,
  condition?: ModelOrderEventConditionInput | null,
};

export type CreateOrderEventMutation = {
  createOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderEventMutationVariables = {
  input: UpdateOrderEventInput,
  condition?: ModelOrderEventConditionInput | null,
};

export type UpdateOrderEventMutation = {
  updateOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderEventMutationVariables = {
  input: DeleteOrderEventInput,
  condition?: ModelOrderEventConditionInput | null,
};

export type DeleteOrderEventMutation = {
  deleteOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderProductEventMutationVariables = {
  input: CreateOrderProductEventInput,
  condition?: ModelOrderProductEventConditionInput | null,
};

export type CreateOrderProductEventMutation = {
  createOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderProductEventMutationVariables = {
  input: UpdateOrderProductEventInput,
  condition?: ModelOrderProductEventConditionInput | null,
};

export type UpdateOrderProductEventMutation = {
  updateOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderProductEventMutationVariables = {
  input: DeleteOrderProductEventInput,
  condition?: ModelOrderProductEventConditionInput | null,
};

export type DeleteOrderProductEventMutation = {
  deleteOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBrandEventMutationVariables = {
  input: CreateBrandEventInput,
  condition?: ModelBrandEventConditionInput | null,
};

export type CreateBrandEventMutation = {
  createBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandEventMutationVariables = {
  input: UpdateBrandEventInput,
  condition?: ModelBrandEventConditionInput | null,
};

export type UpdateBrandEventMutation = {
  updateBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBrandEventMutationVariables = {
  input: DeleteBrandEventInput,
  condition?: ModelBrandEventConditionInput | null,
};

export type DeleteBrandEventMutation = {
  deleteBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductEventMutationVariables = {
  input: CreateProductEventInput,
  condition?: ModelProductEventConditionInput | null,
};

export type CreateProductEventMutation = {
  createProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductEventMutationVariables = {
  input: UpdateProductEventInput,
  condition?: ModelProductEventConditionInput | null,
};

export type UpdateProductEventMutation = {
  updateProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductEventMutationVariables = {
  input: DeleteProductEventInput,
  condition?: ModelProductEventConditionInput | null,
};

export type DeleteProductEventMutation = {
  deleteProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductTypeEventMutationVariables = {
  input: CreateProductTypeEventInput,
  condition?: ModelProductTypeEventConditionInput | null,
};

export type CreateProductTypeEventMutation = {
  createProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductTypeEventMutationVariables = {
  input: UpdateProductTypeEventInput,
  condition?: ModelProductTypeEventConditionInput | null,
};

export type UpdateProductTypeEventMutation = {
  updateProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductTypeEventMutationVariables = {
  input: DeleteProductTypeEventInput,
  condition?: ModelProductTypeEventConditionInput | null,
};

export type DeleteProductTypeEventMutation = {
  deleteProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SendEmailMutationVariables = {
  options: SendEmailInput,
};

export type SendEmailMutation = {
  sendEmail?: string | null,
};

export type CreateBuyerReferralMutationVariables = {
  email: string,
  name?: string | null,
  referralType: number,
  referralBrandId: string,
};

export type CreateBuyerReferralMutation = {
  createBuyerReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBuyerAccountMutationVariables = {
  input: CreateBuyerAccountInput,
};

export type CreateBuyerAccountMutation = {
  createBuyerAccount?:  {
    __typename: "CreateBuyerAccountOutput",
    username?: string | null,
    token?: string | null,
  } | null,
};

export type SendInvitationMutationVariables = {
  brandId: string,
  brandName: string,
  contactIds?: Array< string | null > | null,
  sender: string,
  senderEmail: string,
  subject: string,
  body: string,
  testmode?: boolean | null,
};

export type SendInvitationMutation = {
  sendInvitation?: string | null,
};

export type CreateMagicLinkMutationVariables = {
  email: string,
};

export type CreateMagicLinkMutation = {
  createMagicLink?: string | null,
};

export type SyncContactReadModelMutationVariables = {
  contactId?: string | null,
};

export type SyncContactReadModelMutation = {
  syncContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetPaymentMethodsQueryVariables = {
  stripeId: string,
};

export type GetPaymentMethodsQuery = {
  getPaymentMethods?:  Array< {
    __typename: "PaymentMethod",
    id: string,
    object: string,
    billing_details:  {
      __typename: "BillingDetails",
      address?: string | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    },
    card:  {
      __typename: "Card",
      brand: string,
      checks?: string | null,
      country: string,
      exp_month: number,
      exp_year: number,
      fingerprint: string,
      funding: string,
      generated_from?: string | null,
      last4: string,
      networks?: string | null,
      three_d_secure_usage?: string | null,
      wallet?: string | null,
    },
    created: number,
    customer: string,
    livemode: boolean,
    metadata:  {
      __typename: "PaymentMethodMetaData",
      name?: string | null,
    },
    type: string,
  } | null > | null,
};

export type AttachPaymentMethodQueryVariables = {
  stripeId: string,
  paymentMethodId: string,
};

export type AttachPaymentMethodQuery = {
  attachPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    object: string,
    billing_details:  {
      __typename: "BillingDetails",
      address?: string | null,
      email?: string | null,
      name?: string | null,
      phone?: string | null,
    },
    card:  {
      __typename: "Card",
      brand: string,
      checks?: string | null,
      country: string,
      exp_month: number,
      exp_year: number,
      fingerprint: string,
      funding: string,
      generated_from?: string | null,
      last4: string,
      networks?: string | null,
      three_d_secure_usage?: string | null,
      wallet?: string | null,
    },
    created: number,
    customer: string,
    livemode: boolean,
    metadata:  {
      __typename: "PaymentMethodMetaData",
      name?: string | null,
    },
    type: string,
  } | null,
};

export type IsOrderCancelableQueryVariables = {
  orderId: string,
};

export type IsOrderCancelableQuery = {
  isOrderCancelable?: boolean | null,
};

export type GetReturnDueDateQueryVariables = {
  orderId: string,
};

export type GetReturnDueDateQuery = {
  getReturnDueDate?:  {
    __typename: "ReturnDueDate",
    isReturnable: boolean,
    dueDate?: string | null,
  } | null,
};

export type GetReturnableAmountQueryVariables = {
  accountId: string,
  brandId?: string | null,
  orderId?: string | null,
};

export type GetReturnableAmountQuery = {
  getReturnableAmount: number,
};

export type GetNewOrdersQueryVariables = {
  owner: string,
};

export type GetNewOrdersQuery = {
  getNewOrders?: Array< string | null > | null,
};

export type GetBlogPostsQueryVariables = {
  limit?: number | null,
};

export type GetBlogPostsQuery = {
  getBlogPosts?: string | null,
};

export type GetOrdersByDateRangeQueryVariables = {
  owner: string,
  from?: string | null,
  to?: string | null,
};

export type GetOrdersByDateRangeQuery = {
  getOrdersByDateRange?:  {
    __typename: "OrderConnection",
    items?:  Array< {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetOrderProductsByDateRangeQueryVariables = {
  owner: string,
  fromDate?: string | null,
  toDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetOrderProductsByDateRangeQuery = {
  getOrderProductsByDateRange?:  {
    __typename: "OrderProductConnection",
    items?:  Array< {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBillingCustomerQueryVariables = {
  customer_id: string,
  destination_id: string,
};

export type GetBillingCustomerQuery = {
  getBillingCustomer?: string | null,
};

export type GetFactoringCreditFacilityQueryVariables = {
  buyer_id: string,
  credit_ym?: string | null,
};

export type GetFactoringCreditFacilityQuery = {
  getFactoringCreditFacility?:  {
    __typename: "InvoiceCreditFacility",
    amount?: number | null,
    balance?: number | null,
  } | null,
};

export type GetInvoiceCreditFacilityQueryVariables = {
  buyer_id: string,
  credit_ym?: string | null,
};

export type GetInvoiceCreditFacilityQuery = {
  getInvoiceCreditFacility?:  {
    __typename: "InvoiceCreditFacility",
    amount?: number | null,
    balance?: number | null,
  } | null,
};

export type ListProfilesByCohortQueryVariables = {
  cohortId: string,
};

export type ListProfilesByCohortQuery = {
  listProfilesByCohort?: string | null,
};

export type GetRecentlyViewedProductsQueryVariables = {
  userId: string,
};

export type GetRecentlyViewedProductsQuery = {
  getRecentlyViewedProducts?: Array< string | null > | null,
};

export type GetCreditFacilityQueryVariables = {
  buyerId: string,
  ignoreOrderId?: string | null,
};

export type GetCreditFacilityQuery = {
  getCreditFacility?: number | null,
};

export type GetAccountByEmailQueryVariables = {
  email: string,
};

export type GetAccountByEmailQuery = {
  getAccountByEmail?: string | null,
};

export type GetLogilessTokenQueryVariables = {
};

export type GetLogilessTokenQuery = {
  getLogilessToken?: string | null,
};

export type GetNewLogilessTokenQueryVariables = {
  code?: string | null,
};

export type GetNewLogilessTokenQuery = {
  getNewLogilessToken?: string | null,
};

export type GetNewLogilessTokenByRefreshTokenQueryVariables = {
  refresh_token?: string | null,
};

export type GetNewLogilessTokenByRefreshTokenQuery = {
  getNewLogilessTokenByRefreshToken?: string | null,
};

export type GetLogilessProductQueryVariables = {
  productId?: string | null,
  productTypeId?: string | null,
};

export type GetLogilessProductQuery = {
  getLogilessProduct?: string | null,
};

export type GetLogilessProductsQueryVariables = {
  page?: number | null,
};

export type GetLogilessProductsQuery = {
  getLogilessProducts?: string | null,
};

export type SearchIndexQueryVariables = {
  indexName?: string | null,
  query?: string | null,
  filters?: string | null,
  hitsPerPage?: number | null,
  attributesToRetrieve?: Array< string | null > | null,
};

export type SearchIndexQuery = {
  searchIndex?: string | null,
};

export type GetLookerSignedEmbedUrlQueryVariables = {
  options?: string | null,
};

export type GetLookerSignedEmbedUrlQuery = {
  getLookerSignedEmbedUrl?: string | null,
};

export type GetContactListsQueryVariables = {
  supplierId?: string | null,
};

export type GetContactListsQuery = {
  getContactLists?:  Array< {
    __typename: "ContactListResponse",
    id?: string | null,
    item?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    totalContacts?: number | null,
  } | null > | null,
};

export type GetTotalContactsQueryVariables = {
  supplierId?: string | null,
  segment?: string | null,
  listId?: string | null,
  keywords?: string | null,
};

export type GetTotalContactsQuery = {
  getTotalContacts?: number | null,
};

export type ListContactsAllBySupplierQueryVariables = {
  supplier_id: string,
  segment?: string | null,
};

export type ListContactsAllBySupplierQuery = {
  listContactsAllBySupplier?:  {
    __typename: "ContactConnection",
    items?:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignContactsAllByCampaignQueryVariables = {
  campaign_id: string,
  filter?: string | null,
};

export type ListCampaignContactsAllByCampaignQuery = {
  listCampaignContactsAllByCampaign?:  {
    __typename: "ContactConnection",
    items?:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetQlikAuthTokenQueryVariables = {
  appId?: string | null,
};

export type GetQlikAuthTokenQuery = {
  getQlikAuthToken?: string | null,
};

export type GetRecommendationProductsQueryVariables = {
  userId?: string | null,
  count?: number | null,
};

export type GetRecommendationProductsQuery = {
  getRecommendationProducts?: Array< string | null > | null,
};

export type GetRecommendationProducts2QueryVariables = {
  userId?: string | null,
  count?: number | null,
};

export type GetRecommendationProducts2Query = {
  getRecommendationProducts2?: Array< string | null > | null,
};

export type GetPersonalizedProductsQueryVariables = {
  userId?: string | null,
  itemIds?: Array< string | null > | null,
  personalizeRate?: number | null,
};

export type GetPersonalizedProductsQuery = {
  getPersonalizedProducts?: Array< string | null > | null,
};

export type GetRecommendationFeaturesQueryVariables = {
  userId?: string | null,
  count?: number | null,
};

export type GetRecommendationFeaturesQuery = {
  getRecommendationFeatures?: Array< string | null > | null,
};

export type GetSimilarProductsQueryVariables = {
  userId: string,
  productId?: string | null,
  count?: number | null,
};

export type GetSimilarProductsQuery = {
  getSimilarProducts?: Array< string | null > | null,
};

export type GetSimilarProducts2QueryVariables = {
  userId: string,
  productId?: string | null,
  count?: number | null,
};

export type GetSimilarProducts2Query = {
  getSimilarProducts2?: Array< string | null > | null,
};

export type GetRecommendationBrandsQueryVariables = {
  userId?: string | null,
  count?: number | null,
};

export type GetRecommendationBrandsQuery = {
  getRecommendationBrands?: Array< string | null > | null,
};

export type GetSimilarBrandsQueryVariables = {
  userId: string,
  brandId?: string | null,
  count?: number | null,
};

export type GetSimilarBrandsQuery = {
  getSimilarBrands?: Array< string | null > | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchAccountsQueryVariables = {
  filter?: SearchableAccountFilterInput | null,
  sort?: SearchableAccountSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchAccountsQuery = {
  searchAccounts?:  {
    __typename: "SearchableAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBuyerInfoQueryVariables = {
  id: string,
};

export type GetBuyerInfoQuery = {
  getBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBuyerInfosQueryVariables = {
  filter?: ModelBuyerInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerInfosQuery = {
  listBuyerInfos?:  {
    __typename: "ModelBuyerInfoConnection",
    items:  Array< {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBuyerInfosByBuyerQueryVariables = {
  buyer_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuyerInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerInfosByBuyerQuery = {
  listBuyerInfosByBuyer?:  {
    __typename: "ModelBuyerInfoConnection",
    items:  Array< {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBuyerInfosQueryVariables = {
  filter?: SearchableBuyerInfoFilterInput | null,
  sort?: SearchableBuyerInfoSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBuyerInfosQuery = {
  searchBuyerInfos?:  {
    __typename: "SearchableBuyerInfoConnection",
    items:  Array< {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetShopImageQueryVariables = {
  id: string,
};

export type GetShopImageQuery = {
  getShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListShopImagesQueryVariables = {
  filter?: ModelShopImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopImagesQuery = {
  listShopImages?:  {
    __typename: "ModelShopImageConnection",
    items:  Array< {
      __typename: "ShopImage",
      id: string,
      buyer_id: string,
      image_key?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchShopImagesQueryVariables = {
  filter?: SearchableShopImageFilterInput | null,
  sort?: SearchableShopImageSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchShopImagesQuery = {
  searchShopImages?:  {
    __typename: "SearchableShopImageConnection",
    items:  Array< {
      __typename: "ShopImage",
      id: string,
      buyer_id: string,
      image_key?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetIndividualQueryVariables = {
  buyer_id: string,
  brand_id: string,
};

export type GetIndividualQuery = {
  getIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListIndividualsQueryVariables = {
  buyer_id?: string | null,
  brand_id?: ModelIDKeyConditionInput | null,
  filter?: ModelIndividualFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIndividualsQuery = {
  listIndividuals?:  {
    __typename: "ModelIndividualConnection",
    items:  Array< {
      __typename: "Individual",
      brand_id: string,
      buyer_id: string,
      supplier_group_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchIndividualsQueryVariables = {
  filter?: SearchableIndividualFilterInput | null,
  sort?: SearchableIndividualSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchIndividualsQuery = {
  searchIndividuals?:  {
    __typename: "SearchableIndividualConnection",
    items:  Array< {
      __typename: "Individual",
      brand_id: string,
      buyer_id: string,
      supplier_group_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetSupplierGroupQueryVariables = {
  id: string,
};

export type GetSupplierGroupQuery = {
  getSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSupplierGroupsQueryVariables = {
  filter?: ModelSupplierGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSupplierGroupsQuery = {
  listSupplierGroups?:  {
    __typename: "ModelSupplierGroupConnection",
    items:  Array< {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSupplierGroupsQueryVariables = {
  filter?: SearchableSupplierGroupFilterInput | null,
  sort?: SearchableSupplierGroupSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchSupplierGroupsQuery = {
  searchSupplierGroups?:  {
    __typename: "SearchableSupplierGroupConnection",
    items:  Array< {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetSupplierInfoQueryVariables = {
  id: string,
};

export type GetSupplierInfoQuery = {
  getSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSupplierInfosQueryVariables = {
  filter?: ModelSupplierInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSupplierInfosQuery = {
  listSupplierInfos?:  {
    __typename: "ModelSupplierInfoConnection",
    items:  Array< {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSupplierInfosByAccountIdQueryVariables = {
  account_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSupplierInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSupplierInfosByAccountIdQuery = {
  listSupplierInfosByAccountId?:  {
    __typename: "ModelSupplierInfoConnection",
    items:  Array< {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSupplierInfosQueryVariables = {
  filter?: SearchableSupplierInfoFilterInput | null,
  sort?: SearchableSupplierInfoSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchSupplierInfosQuery = {
  searchSupplierInfos?:  {
    __typename: "SearchableSupplierInfoConnection",
    items:  Array< {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetShippingAddressQueryVariables = {
  id: string,
};

export type GetShippingAddressQuery = {
  getShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListShippingAddresssQueryVariables = {
  filter?: ModelShippingAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingAddresssQuery = {
  listShippingAddresss?:  {
    __typename: "ModelShippingAddressConnection",
    items:  Array< {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchShippingAddresssQueryVariables = {
  filter?: SearchableShippingAddressFilterInput | null,
  sort?: SearchableShippingAddressSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchShippingAddresssQuery = {
  searchShippingAddresss?:  {
    __typename: "SearchableShippingAddressConnection",
    items:  Array< {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetEmailPreferencesQueryVariables = {
  id: string,
};

export type GetEmailPreferencesQuery = {
  getEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmailPreferencessQueryVariables = {
  filter?: ModelEmailPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailPreferencessQuery = {
  listEmailPreferencess?:  {
    __typename: "ModelEmailPreferencesConnection",
    items:  Array< {
      __typename: "EmailPreferences",
      id: string,
      new_arrivals?: boolean | null,
      sale_items?: boolean | null,
      bounced?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEmailPreferencessQueryVariables = {
  filter?: SearchableEmailPreferencesFilterInput | null,
  sort?: SearchableEmailPreferencesSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchEmailPreferencessQuery = {
  searchEmailPreferencess?:  {
    __typename: "SearchableEmailPreferencesConnection",
    items:  Array< {
      __typename: "EmailPreferences",
      id: string,
      new_arrivals?: boolean | null,
      sale_items?: boolean | null,
      bounced?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductsByBrandQueryVariables = {
  product_brand_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByBrandQuery = {
  listProductsByBrand?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductsBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsBySkuQuery = {
  listProductsBySku?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductsByCopiedProductQueryVariables = {
  copied_product_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByCopiedProductQuery = {
  listProductsByCopiedProduct?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductsQueryVariables = {
  filter?: SearchableProductFilterInput | null,
  sort?: SearchableProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProductsQuery = {
  searchProducts?:  {
    __typename: "SearchableProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProductImageKeyQueryVariables = {
  id: string,
};

export type GetProductImageKeyQuery = {
  getProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListProductImageKeysQueryVariables = {
  filter?: ModelProductImageKeyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductImageKeysQuery = {
  listProductImageKeys?:  {
    __typename: "ModelProductImageKeyConnection",
    items:  Array< {
      __typename: "ProductImageKey",
      id: string,
      product_id: string,
      imageKey?: string | null,
      createdAt?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductImageKeysQueryVariables = {
  filter?: SearchableProductImageKeyFilterInput | null,
  sort?: SearchableProductImageKeySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProductImageKeysQuery = {
  searchProductImageKeys?:  {
    __typename: "SearchableProductImageKeyConnection",
    items:  Array< {
      __typename: "ProductImageKey",
      id: string,
      product_id: string,
      imageKey?: string | null,
      createdAt?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProductVideoKeyQueryVariables = {
  id: string,
};

export type GetProductVideoKeyQuery = {
  getProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListProductVideoKeysQueryVariables = {
  filter?: ModelProductVideoKeyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductVideoKeysQuery = {
  listProductVideoKeys?:  {
    __typename: "ModelProductVideoKeyConnection",
    items:  Array< {
      __typename: "ProductVideoKey",
      id: string,
      product_id: string,
      videoKey?: string | null,
      createdAt?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductVideoKeysQueryVariables = {
  filter?: SearchableProductVideoKeyFilterInput | null,
  sort?: SearchableProductVideoKeySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProductVideoKeysQuery = {
  searchProductVideoKeys?:  {
    __typename: "SearchableProductVideoKeyConnection",
    items:  Array< {
      __typename: "ProductVideoKey",
      id: string,
      product_id: string,
      videoKey?: string | null,
      createdAt?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProductTypeQueryVariables = {
  id: string,
};

export type GetProductTypeQuery = {
  getProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProductTypesQueryVariables = {
  filter?: ModelProductTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypesQuery = {
  listProductTypes?:  {
    __typename: "ModelProductTypeConnection",
    items:  Array< {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypesByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypesByProductQuery = {
  listProductTypesByProduct?:  {
    __typename: "ModelProductTypeConnection",
    items:  Array< {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypesBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypesBySkuQuery = {
  listProductTypesBySku?:  {
    __typename: "ModelProductTypeConnection",
    items:  Array< {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypesByCopiedProductTypeQueryVariables = {
  copied_product_type_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypesByCopiedProductTypeQuery = {
  listProductTypesByCopiedProductType?:  {
    __typename: "ModelProductTypeConnection",
    items:  Array< {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductTypesQueryVariables = {
  filter?: SearchableProductTypeFilterInput | null,
  sort?: SearchableProductTypeSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProductTypesQuery = {
  searchProductTypes?:  {
    __typename: "SearchableProductTypeConnection",
    items:  Array< {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetInventoryQueryVariables = {
  id: string,
};

export type GetInventoryQuery = {
  getInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListInventorysQueryVariables = {
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventorysQuery = {
  listInventorys?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoriesByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoriesByBrandQuery = {
  listInventoriesByBrand?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoriesByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoriesByProductQuery = {
  listInventoriesByProduct?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoriesByProductTypeQueryVariables = {
  product_type_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoriesByProductTypeQuery = {
  listInventoriesByProductType?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoriesBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoriesBySkuQuery = {
  listInventoriesBySku?:  {
    __typename: "ModelInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchInventorysQueryVariables = {
  filter?: SearchableInventoryFilterInput | null,
  sort?: SearchableInventorySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchInventorysQuery = {
  searchInventorys?:  {
    __typename: "SearchableInventoryConnection",
    items:  Array< {
      __typename: "Inventory",
      id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      inventory?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      logs?:  {
        __typename: "ModelInventoryLogConnection",
        items:  Array< {
          __typename: "InventoryLog",
          id: string,
          inventory_id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          stock_type: StockType,
          stock_mode: StockMode,
          stock_date: string,
          quantity: number,
          order_id?: string | null,
          order_product_id?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReturnInventoryLogQueryVariables = {
  id: string,
};

export type GetReturnInventoryLogQuery = {
  getReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReturnInventoryLogsQueryVariables = {
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsQuery = {
  listReturnInventoryLogs?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnInventoryLogsByInventoryQueryVariables = {
  inventory_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsByInventoryQuery = {
  listReturnInventoryLogsByInventory?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnInventoryLogsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsByBrandQuery = {
  listReturnInventoryLogsByBrand?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnInventoryLogsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsByProductQuery = {
  listReturnInventoryLogsByProduct?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnInventoryLogsByProductTypeQueryVariables = {
  product_type_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsByProductTypeQuery = {
  listReturnInventoryLogsByProductType?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnInventoryLogsBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnInventoryLogsBySkuQuery = {
  listReturnInventoryLogsBySku?:  {
    __typename: "ModelReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReturnInventoryLogsQueryVariables = {
  filter?: SearchableReturnInventoryLogFilterInput | null,
  sort?: SearchableReturnInventoryLogSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReturnInventoryLogsQuery = {
  searchReturnInventoryLogs?:  {
    __typename: "SearchableReturnInventoryLogConnection",
    items:  Array< {
      __typename: "ReturnInventoryLog",
      id: string,
      inventory_id?: string | null,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetInventoryLogQueryVariables = {
  id: string,
};

export type GetInventoryLogQuery = {
  getInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListInventoryLogsQueryVariables = {
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsQuery = {
  listInventoryLogs?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoryLogsByInventoryQueryVariables = {
  inventory_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsByInventoryQuery = {
  listInventoryLogsByInventory?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoryLogsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsByBrandQuery = {
  listInventoryLogsByBrand?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoryLogsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsByProductQuery = {
  listInventoryLogsByProduct?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoryLogsByProductTypeQueryVariables = {
  product_type_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsByProductTypeQuery = {
  listInventoryLogsByProductType?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInventoryLogsBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInventoryLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryLogsBySkuQuery = {
  listInventoryLogsBySku?:  {
    __typename: "ModelInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchInventoryLogsQueryVariables = {
  filter?: SearchableInventoryLogFilterInput | null,
  sort?: SearchableInventoryLogSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchInventoryLogsQuery = {
  searchInventoryLogs?:  {
    __typename: "SearchableInventoryLogConnection",
    items:  Array< {
      __typename: "InventoryLog",
      id: string,
      inventory_id: string,
      brand_id: string,
      product_id: string,
      product_type_id?: string | null,
      sku: string,
      stock_type: StockType,
      stock_mode: StockMode,
      stock_date: string,
      quantity: number,
      order_id?: string | null,
      order_product_id?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetInStockNotificationQueryVariables = {
  id: string,
};

export type GetInStockNotificationQuery = {
  getInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListInStockNotificationsQueryVariables = {
  filter?: ModelInStockNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInStockNotificationsQuery = {
  listInStockNotifications?:  {
    __typename: "ModelInStockNotificationConnection",
    items:  Array< {
      __typename: "InStockNotification",
      id: string,
      buyer_id?: string | null,
      brand_id?: string | null,
      product_id?: string | null,
      product_type_id?: string | null,
      completed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInStockNotificationsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInStockNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInStockNotificationsByBuyerQuery = {
  listInStockNotificationsByBuyer?:  {
    __typename: "ModelInStockNotificationConnection",
    items:  Array< {
      __typename: "InStockNotification",
      id: string,
      buyer_id?: string | null,
      brand_id?: string | null,
      product_id?: string | null,
      product_type_id?: string | null,
      completed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInStockNotificationsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInStockNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInStockNotificationsByProductQuery = {
  listInStockNotificationsByProduct?:  {
    __typename: "ModelInStockNotificationConnection",
    items:  Array< {
      __typename: "InStockNotification",
      id: string,
      buyer_id?: string | null,
      brand_id?: string | null,
      product_id?: string | null,
      product_type_id?: string | null,
      completed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInStockNotificationsByProductTypeQueryVariables = {
  product_type_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInStockNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInStockNotificationsByProductTypeQuery = {
  listInStockNotificationsByProductType?:  {
    __typename: "ModelInStockNotificationConnection",
    items:  Array< {
      __typename: "InStockNotification",
      id: string,
      buyer_id?: string | null,
      brand_id?: string | null,
      product_id?: string | null,
      product_type_id?: string | null,
      completed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchInStockNotificationsQueryVariables = {
  filter?: SearchableInStockNotificationFilterInput | null,
  sort?: SearchableInStockNotificationSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchInStockNotificationsQuery = {
  searchInStockNotifications?:  {
    __typename: "SearchableInStockNotificationConnection",
    items:  Array< {
      __typename: "InStockNotification",
      id: string,
      buyer_id?: string | null,
      brand_id?: string | null,
      product_id?: string | null,
      product_type_id?: string | null,
      completed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBrandsByOwnerQueryVariables = {
  brand_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsByOwnerQuery = {
  listBrandsByOwner?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBrandsQueryVariables = {
  filter?: SearchableBrandFilterInput | null,
  sort?: SearchableBrandSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBrandsQuery = {
  searchBrands?:  {
    __typename: "SearchableBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTagsQueryVariables = {
  filter?: SearchableTagFilterInput | null,
  sort?: SearchableTagSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTagsQuery = {
  searchTags?:  {
    __typename: "SearchableTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      tag_name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBrandCollectionQueryVariables = {
  id: string,
};

export type GetBrandCollectionQuery = {
  getBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandCollectionsQueryVariables = {
  filter?: ModelBrandCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandCollectionsQuery = {
  listBrandCollections?:  {
    __typename: "ModelBrandCollectionConnection",
    items:  Array< {
      __typename: "BrandCollection",
      id: string,
      collection_name?: string | null,
      visible?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBrandCollectionsQueryVariables = {
  filter?: SearchableBrandCollectionFilterInput | null,
  sort?: SearchableBrandCollectionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBrandCollectionsQuery = {
  searchBrandCollections?:  {
    __typename: "SearchableBrandCollectionConnection",
    items:  Array< {
      __typename: "BrandCollection",
      id: string,
      collection_name?: string | null,
      visible?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetShopCartQueryVariables = {
  id: string,
};

export type GetShopCartQuery = {
  getShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListShopCartsQueryVariables = {
  filter?: ModelShopCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopCartsQuery = {
  listShopCarts?:  {
    __typename: "ModelShopCartConnection",
    items:  Array< {
      __typename: "ShopCart",
      id?: string | null,
      shopcart_owner: string,
      shopcart_quantity: number,
      product_id: string,
      product_type_id?: string | null,
      brand_id?: string | null,
      is_direct?: boolean | null,
      is_pay_directly?: boolean | null,
      is_consign?: boolean | null,
      createdAt?: string | null,
      saved_for_later?: boolean | null,
      collection_id?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      producttype?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListShopCartsByOwnerQueryVariables = {
  shopcart_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopCartsByOwnerQuery = {
  listShopCartsByOwner?:  {
    __typename: "ModelShopCartConnection",
    items:  Array< {
      __typename: "ShopCart",
      id?: string | null,
      shopcart_owner: string,
      shopcart_quantity: number,
      product_id: string,
      product_type_id?: string | null,
      brand_id?: string | null,
      is_direct?: boolean | null,
      is_pay_directly?: boolean | null,
      is_consign?: boolean | null,
      createdAt?: string | null,
      saved_for_later?: boolean | null,
      collection_id?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      producttype?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchShopCartsQueryVariables = {
  filter?: SearchableShopCartFilterInput | null,
  sort?: SearchableShopCartSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchShopCartsQuery = {
  searchShopCarts?:  {
    __typename: "SearchableShopCartConnection",
    items:  Array< {
      __typename: "ShopCart",
      id?: string | null,
      shopcart_owner: string,
      shopcart_quantity: number,
      product_id: string,
      product_type_id?: string | null,
      brand_id?: string | null,
      is_direct?: boolean | null,
      is_pay_directly?: boolean | null,
      is_consign?: boolean | null,
      createdAt?: string | null,
      saved_for_later?: boolean | null,
      collection_id?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      producttype?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrdersByOwnerQueryVariables = {
  order_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersByOwnerQuery = {
  listOrdersByOwner?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrdersByBrandOwnerQueryVariables = {
  brand_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersByBrandOwnerQuery = {
  listOrdersByBrandOwner?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrdersQueryVariables = {
  filter?: SearchableOrderFilterInput | null,
  sort?: SearchableOrderSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrdersQuery = {
  searchOrders?:  {
    __typename: "SearchableOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetChargeStatusQueryVariables = {
  id: string,
};

export type GetChargeStatusQuery = {
  getChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type ListChargeStatussQueryVariables = {
  filter?: ModelChargeStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargeStatussQuery = {
  listChargeStatuss?:  {
    __typename: "ModelChargeStatusConnection",
    items:  Array< {
      __typename: "ChargeStatus",
      id: string,
      order_id: string,
      status: number,
      payment_id?: string | null,
      amount?: number | null,
      createdAt?: string | null,
      owners: Array< string | null >,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchChargeStatussQueryVariables = {
  filter?: SearchableChargeStatusFilterInput | null,
  sort?: SearchableChargeStatusSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchChargeStatussQuery = {
  searchChargeStatuss?:  {
    __typename: "SearchableChargeStatusConnection",
    items:  Array< {
      __typename: "ChargeStatus",
      id: string,
      order_id: string,
      status: number,
      payment_id?: string | null,
      amount?: number | null,
      createdAt?: string | null,
      owners: Array< string | null >,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderProductQueryVariables = {
  id: string,
};

export type GetOrderProductQuery = {
  getOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrderProductsQueryVariables = {
  filter?: ModelOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderProductsQuery = {
  listOrderProducts?:  {
    __typename: "ModelOrderProductConnection",
    items:  Array< {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderProductsByOrderQueryVariables = {
  order_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderProductsByOrderQuery = {
  listOrderProductsByOrder?:  {
    __typename: "ModelOrderProductConnection",
    items:  Array< {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderProductsQueryVariables = {
  filter?: SearchableOrderProductFilterInput | null,
  sort?: SearchableOrderProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderProductsQuery = {
  searchOrderProducts?:  {
    __typename: "SearchableOrderProductConnection",
    items:  Array< {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderStatusQueryVariables = {
  id: string,
};

export type GetOrderStatusQuery = {
  getOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrderStatussQueryVariables = {
  filter?: ModelOrderStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderStatussQuery = {
  listOrderStatuss?:  {
    __typename: "ModelOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      order_id?: string | null,
      order_product_id: string,
      quantity?: number | null,
      status?: number | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt?: string | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderStatussQueryVariables = {
  filter?: SearchableOrderStatusFilterInput | null,
  sort?: SearchableOrderStatusSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderStatussQuery = {
  searchOrderStatuss?:  {
    __typename: "SearchableOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      order_id?: string | null,
      order_product_id: string,
      quantity?: number | null,
      status?: number | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt?: string | null,
      orderProduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReturnProductQueryVariables = {
  id: string,
};

export type GetReturnProductQuery = {
  getReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReturnProductsQueryVariables = {
  filter?: ModelReturnProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnProductsQuery = {
  listReturnProducts?:  {
    __typename: "ModelReturnProductConnection",
    items:  Array< {
      __typename: "ReturnProduct",
      id: string,
      return_product_id?: string | null,
      order_id: string,
      order_product_id: string,
      return_quantity?: number | null,
      return_product_price?: number | null,
      return_status?: number | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      logiless_inbound_delivery_code?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      children?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnProductsByReturnProductQueryVariables = {
  return_product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnProductsByReturnProductQuery = {
  listReturnProductsByReturnProduct?:  {
    __typename: "ModelReturnProductConnection",
    items:  Array< {
      __typename: "ReturnProduct",
      id: string,
      return_product_id?: string | null,
      order_id: string,
      order_product_id: string,
      return_quantity?: number | null,
      return_product_price?: number | null,
      return_status?: number | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      logiless_inbound_delivery_code?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      children?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReturnProductsQueryVariables = {
  filter?: SearchableReturnProductFilterInput | null,
  sort?: SearchableReturnProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReturnProductsQuery = {
  searchReturnProducts?:  {
    __typename: "SearchableReturnProductConnection",
    items:  Array< {
      __typename: "ReturnProduct",
      id: string,
      return_product_id?: string | null,
      order_id: string,
      order_product_id: string,
      return_quantity?: number | null,
      return_product_price?: number | null,
      return_status?: number | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      logiless_inbound_delivery_code?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      children?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReportProductQueryVariables = {
  id: string,
};

export type GetReportProductQuery = {
  getReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReportProductsQueryVariables = {
  filter?: ModelReportProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportProductsQuery = {
  listReportProducts?:  {
    __typename: "ModelReportProductConnection",
    items:  Array< {
      __typename: "ReportProduct",
      id: string,
      type?: string | null,
      order_id: string,
      order_product_id: string,
      report_type?: string | null,
      request_type?: string | null,
      report_quantity?: number | null,
      report_product_price?: number | null,
      report_status?: string | null,
      report_comment?: string | null,
      replace_order_id?: string | null,
      replace_order_product_id?: string | null,
      approval_date?: string | null,
      approval_quantity?: number | null,
      image_keys?: Array< string | null > | null,
      stripe_refund_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replaceOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      replaceOrderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportProductsByTypeQueryVariables = {
  type?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportProductsByTypeQuery = {
  listReportProductsByType?:  {
    __typename: "ModelReportProductConnection",
    items:  Array< {
      __typename: "ReportProduct",
      id: string,
      type?: string | null,
      order_id: string,
      order_product_id: string,
      report_type?: string | null,
      request_type?: string | null,
      report_quantity?: number | null,
      report_product_price?: number | null,
      report_status?: string | null,
      report_comment?: string | null,
      replace_order_id?: string | null,
      replace_order_product_id?: string | null,
      approval_date?: string | null,
      approval_quantity?: number | null,
      image_keys?: Array< string | null > | null,
      stripe_refund_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replaceOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      replaceOrderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportProductsByOrderQueryVariables = {
  order_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportProductsByOrderQuery = {
  listReportProductsByOrder?:  {
    __typename: "ModelReportProductConnection",
    items:  Array< {
      __typename: "ReportProduct",
      id: string,
      type?: string | null,
      order_id: string,
      order_product_id: string,
      report_type?: string | null,
      request_type?: string | null,
      report_quantity?: number | null,
      report_product_price?: number | null,
      report_status?: string | null,
      report_comment?: string | null,
      replace_order_id?: string | null,
      replace_order_product_id?: string | null,
      approval_date?: string | null,
      approval_quantity?: number | null,
      image_keys?: Array< string | null > | null,
      stripe_refund_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replaceOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      replaceOrderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReportProductsQueryVariables = {
  filter?: SearchableReportProductFilterInput | null,
  sort?: SearchableReportProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReportProductsQuery = {
  searchReportProducts?:  {
    __typename: "SearchableReportProductConnection",
    items:  Array< {
      __typename: "ReportProduct",
      id: string,
      type?: string | null,
      order_id: string,
      order_product_id: string,
      report_type?: string | null,
      request_type?: string | null,
      report_quantity?: number | null,
      report_product_price?: number | null,
      report_status?: string | null,
      report_comment?: string | null,
      replace_order_id?: string | null,
      replace_order_product_id?: string | null,
      approval_date?: string | null,
      approval_quantity?: number | null,
      image_keys?: Array< string | null > | null,
      stripe_refund_id?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replaceOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      replaceOrderproduct?:  {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFeedbackQueryVariables = {
  id: string,
};

export type GetFeedbackQuery = {
  getFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListFeedbacksQueryVariables = {
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedbacksQuery = {
  listFeedbacks?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      id: string,
      order_id: string,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchFeedbacksQueryVariables = {
  filter?: SearchableFeedbackFilterInput | null,
  sort?: SearchableFeedbackSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFeedbacksQuery = {
  searchFeedbacks?:  {
    __typename: "SearchableFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      id: string,
      order_id: string,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderEditReasonQueryVariables = {
  id: string,
};

export type GetOrderEditReasonQuery = {
  getOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrderEditReasonsQueryVariables = {
  filter?: ModelOrderEditReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderEditReasonsQuery = {
  listOrderEditReasons?:  {
    __typename: "ModelOrderEditReasonConnection",
    items:  Array< {
      __typename: "OrderEditReason",
      id: string,
      order_id: string,
      edit_reason?: EditReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderEditReasonsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderEditReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderEditReasonsByOrderQuery = {
  listOrderEditReasonsByOrder?:  {
    __typename: "ModelOrderEditReasonConnection",
    items:  Array< {
      __typename: "OrderEditReason",
      id: string,
      order_id: string,
      edit_reason?: EditReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderEditReasonsQueryVariables = {
  filter?: SearchableOrderEditReasonFilterInput | null,
  sort?: SearchableOrderEditReasonSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderEditReasonsQuery = {
  searchOrderEditReasons?:  {
    __typename: "SearchableOrderEditReasonConnection",
    items:  Array< {
      __typename: "OrderEditReason",
      id: string,
      order_id: string,
      edit_reason?: EditReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderCancelReasonQueryVariables = {
  id: string,
};

export type GetOrderCancelReasonQuery = {
  getOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrderCancelReasonsQueryVariables = {
  filter?: ModelOrderCancelReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderCancelReasonsQuery = {
  listOrderCancelReasons?:  {
    __typename: "ModelOrderCancelReasonConnection",
    items:  Array< {
      __typename: "OrderCancelReason",
      id: string,
      order_id: string,
      cancel_reason?: CancelReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderCancelReasonsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderCancelReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderCancelReasonsByOrderQuery = {
  listOrderCancelReasonsByOrder?:  {
    __typename: "ModelOrderCancelReasonConnection",
    items:  Array< {
      __typename: "OrderCancelReason",
      id: string,
      order_id: string,
      cancel_reason?: CancelReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderCancelReasonsQueryVariables = {
  filter?: SearchableOrderCancelReasonFilterInput | null,
  sort?: SearchableOrderCancelReasonSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderCancelReasonsQuery = {
  searchOrderCancelReasons?:  {
    __typename: "SearchableOrderCancelReasonConnection",
    items:  Array< {
      __typename: "OrderCancelReason",
      id: string,
      order_id: string,
      cancel_reason?: CancelReason | null,
      feedback_comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      thread_id: string,
      message_owner?: string | null,
      message_recipient?: string | null,
      message_content: string,
      message_product_name?: string | null,
      message_is_open: boolean,
      message_files?:  Array< {
        __typename: "MessageFile",
        object_key: string,
        file_name: string,
        file_type: string,
      } | null > | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      thread?:  {
        __typename: "Thread",
        id: string,
        thread_buyer_owner: string,
        thread_buyer_name?: string | null,
        thread_brand_owner: string,
        thread_brand_id: string,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMessagesQueryVariables = {
  filter?: SearchableMessageFilterInput | null,
  sort?: SearchableMessageSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMessagesQuery = {
  searchMessages?:  {
    __typename: "SearchableMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      thread_id: string,
      message_owner?: string | null,
      message_recipient?: string | null,
      message_content: string,
      message_product_name?: string | null,
      message_is_open: boolean,
      message_files?:  Array< {
        __typename: "MessageFile",
        object_key: string,
        file_name: string,
        file_type: string,
      } | null > | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      thread?:  {
        __typename: "Thread",
        id: string,
        thread_buyer_owner: string,
        thread_buyer_name?: string | null,
        thread_brand_owner: string,
        thread_brand_id: string,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        messages?:  {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetThreadQueryVariables = {
  id: string,
};

export type GetThreadQuery = {
  getThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListThreadsQueryVariables = {
  filter?: ModelThreadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListThreadsQuery = {
  listThreads?:  {
    __typename: "ModelThreadConnection",
    items:  Array< {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListThreadsByBuyerQueryVariables = {
  thread_buyer_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelThreadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListThreadsByBuyerQuery = {
  listThreadsByBuyer?:  {
    __typename: "ModelThreadConnection",
    items:  Array< {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListThreadsByBrandQueryVariables = {
  thread_brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelThreadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListThreadsByBrandQuery = {
  listThreadsByBrand?:  {
    __typename: "ModelThreadConnection",
    items:  Array< {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchThreadsQueryVariables = {
  filter?: SearchableThreadFilterInput | null,
  sort?: SearchableThreadSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchThreadsQuery = {
  searchThreads?:  {
    __typename: "SearchableThreadConnection",
    items:  Array< {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPickupProductQueryVariables = {
  id: string,
};

export type GetPickupProductQuery = {
  getPickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListPickupProductsQueryVariables = {
  filter?: ModelPickupProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPickupProductsQuery = {
  listPickupProducts?:  {
    __typename: "ModelPickupProductConnection",
    items:  Array< {
      __typename: "PickupProduct",
      id: string,
      pickup_list_id: string,
      brand_id: string,
      product_id: string,
      display_order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductsByPickupListQueryVariables = {
  pickup_list_id?: string | null,
  display_order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPickupProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByPickupListQuery = {
  listProductsByPickupList?:  {
    __typename: "ModelPickupProductConnection",
    items:  Array< {
      __typename: "PickupProduct",
      id: string,
      pickup_list_id: string,
      brand_id: string,
      product_id: string,
      display_order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPickupProductsQueryVariables = {
  filter?: SearchablePickupProductFilterInput | null,
  sort?: SearchablePickupProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPickupProductsQuery = {
  searchPickupProducts?:  {
    __typename: "SearchablePickupProductConnection",
    items:  Array< {
      __typename: "PickupProduct",
      id: string,
      pickup_list_id: string,
      brand_id: string,
      product_id: string,
      display_order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPickupBrandsQueryVariables = {
  id: string,
};

export type GetPickupBrandsQuery = {
  getPickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPickupBrandssQueryVariables = {
  filter?: ModelPickupBrandsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPickupBrandssQuery = {
  listPickupBrandss?:  {
    __typename: "ModelPickupBrandsConnection",
    items:  Array< {
      __typename: "PickupBrands",
      id: string,
      title: string,
      brands?:  Array< {
        __typename: "PickupBrand",
        brand_id: string,
        display_order?: number | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null > | null,
      display_num?: number | null,
      visible?: boolean | null,
      item_type?: PickupItemType | null,
      display_size?: number | null,
      createdAt: string,
      updatedAt: string,
      products?:  {
        __typename: "ModelPickupProductConnection",
        items:  Array< {
          __typename: "PickupProduct",
          id: string,
          pickup_list_id: string,
          brand_id: string,
          product_id: string,
          display_order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPickupBrandssQueryVariables = {
  filter?: SearchablePickupBrandsFilterInput | null,
  sort?: SearchablePickupBrandsSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPickupBrandssQuery = {
  searchPickupBrandss?:  {
    __typename: "SearchablePickupBrandsConnection",
    items:  Array< {
      __typename: "PickupBrands",
      id: string,
      title: string,
      brands?:  Array< {
        __typename: "PickupBrand",
        brand_id: string,
        display_order?: number | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null > | null,
      display_num?: number | null,
      visible?: boolean | null,
      item_type?: PickupItemType | null,
      display_size?: number | null,
      createdAt: string,
      updatedAt: string,
      products?:  {
        __typename: "ModelPickupProductConnection",
        items:  Array< {
          __typename: "PickupProduct",
          id: string,
          pickup_list_id: string,
          brand_id: string,
          product_id: string,
          display_order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetMenuQueryVariables = {
  id: string,
};

export type GetMenuQuery = {
  getMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMenusQueryVariables = {
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenusQuery = {
  listMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      product_list_path: string,
      brand_list_path?: string | null,
      search_categories?:  Array< {
        __typename: "Category",
        name: string,
        path?: string | null,
      } | null > | null,
      sub_categories?:  Array< {
        __typename: "Category",
        name: string,
        path?: string | null,
      } | null > | null,
      display_order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMenusQueryVariables = {
  filter?: SearchableMenuFilterInput | null,
  sort?: SearchableMenuSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMenusQuery = {
  searchMenus?:  {
    __typename: "SearchableMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      title: string,
      product_list_path: string,
      brand_list_path?: string | null,
      search_categories?:  Array< {
        __typename: "Category",
        name: string,
        path?: string | null,
      } | null > | null,
      sub_categories?:  Array< {
        __typename: "Category",
        name: string,
        path?: string | null,
      } | null > | null,
      display_order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFavoriteBrandQueryVariables = {
  id: string,
};

export type GetFavoriteBrandQuery = {
  getFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListFavoriteBrandsQueryVariables = {
  filter?: ModelFavoriteBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteBrandsQuery = {
  listFavoriteBrands?:  {
    __typename: "ModelFavoriteBrandConnection",
    items:  Array< {
      __typename: "FavoriteBrand",
      id: string,
      account_id: string,
      brand_id: string,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchFavoriteBrandsQueryVariables = {
  filter?: SearchableFavoriteBrandFilterInput | null,
  sort?: SearchableFavoriteBrandSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFavoriteBrandsQuery = {
  searchFavoriteBrands?:  {
    __typename: "SearchableFavoriteBrandConnection",
    items:  Array< {
      __typename: "FavoriteBrand",
      id: string,
      account_id: string,
      brand_id: string,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFavoriteProductQueryVariables = {
  id: string,
};

export type GetFavoriteProductQuery = {
  getFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type ListFavoriteProductsQueryVariables = {
  filter?: ModelFavoriteProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProductsQuery = {
  listFavoriteProducts?:  {
    __typename: "ModelFavoriteProductConnection",
    items:  Array< {
      __typename: "FavoriteProduct",
      id: string,
      account_id: string,
      favorite_list_id?: string | null,
      product_id: string,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      favorite_list?:  {
        __typename: "FavoriteProductList",
        id: string,
        account_id: string,
        list_name?: string | null,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        products?:  {
          __typename: "ModelFavoriteProductConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFavoriteProductsByListQueryVariables = {
  favorite_list_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFavoriteProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProductsByListQuery = {
  listFavoriteProductsByList?:  {
    __typename: "ModelFavoriteProductConnection",
    items:  Array< {
      __typename: "FavoriteProduct",
      id: string,
      account_id: string,
      favorite_list_id?: string | null,
      product_id: string,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      favorite_list?:  {
        __typename: "FavoriteProductList",
        id: string,
        account_id: string,
        list_name?: string | null,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        products?:  {
          __typename: "ModelFavoriteProductConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFavoriteProductsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFavoriteProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProductsByProductQuery = {
  listFavoriteProductsByProduct?:  {
    __typename: "ModelFavoriteProductConnection",
    items:  Array< {
      __typename: "FavoriteProduct",
      id: string,
      account_id: string,
      favorite_list_id?: string | null,
      product_id: string,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      favorite_list?:  {
        __typename: "FavoriteProductList",
        id: string,
        account_id: string,
        list_name?: string | null,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        products?:  {
          __typename: "ModelFavoriteProductConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchFavoriteProductsQueryVariables = {
  filter?: SearchableFavoriteProductFilterInput | null,
  sort?: SearchableFavoriteProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFavoriteProductsQuery = {
  searchFavoriteProducts?:  {
    __typename: "SearchableFavoriteProductConnection",
    items:  Array< {
      __typename: "FavoriteProduct",
      id: string,
      account_id: string,
      favorite_list_id?: string | null,
      product_id: string,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      favorite_list?:  {
        __typename: "FavoriteProductList",
        id: string,
        account_id: string,
        list_name?: string | null,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        products?:  {
          __typename: "ModelFavoriteProductConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFavoriteProductListQueryVariables = {
  id: string,
};

export type GetFavoriteProductListQuery = {
  getFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListFavoriteProductListsQueryVariables = {
  filter?: ModelFavoriteProductListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProductListsQuery = {
  listFavoriteProductLists?:  {
    __typename: "ModelFavoriteProductListConnection",
    items:  Array< {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFavoriteProductsByAccountQueryVariables = {
  account_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFavoriteProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProductsByAccountQuery = {
  listFavoriteProductsByAccount?:  {
    __typename: "ModelFavoriteProductConnection",
    items:  Array< {
      __typename: "FavoriteProduct",
      id: string,
      account_id: string,
      favorite_list_id?: string | null,
      product_id: string,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
      favorite_list?:  {
        __typename: "FavoriteProductList",
        id: string,
        account_id: string,
        list_name?: string | null,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        products?:  {
          __typename: "ModelFavoriteProductConnection",
          nextToken?: string | null,
        } | null,
        owner?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchFavoriteProductListsQueryVariables = {
  filter?: SearchableFavoriteProductListFilterInput | null,
  sort?: SearchableFavoriteProductListSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFavoriteProductListsQuery = {
  searchFavoriteProductLists?:  {
    __typename: "SearchableFavoriteProductListConnection",
    items:  Array< {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCollectionQueryVariables = {
  id: string,
};

export type GetCollectionQuery = {
  getCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCollectionsQueryVariables = {
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionsQuery = {
  listCollections?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCollectionsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionsByBrandQuery = {
  listCollectionsByBrand?:  {
    __typename: "ModelCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCollectionsQueryVariables = {
  filter?: SearchableCollectionFilterInput | null,
  sort?: SearchableCollectionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCollectionsQuery = {
  searchCollections?:  {
    __typename: "SearchableCollectionConnection",
    items:  Array< {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProductCollectionQueryVariables = {
  id: string,
};

export type GetProductCollectionQuery = {
  getProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListProductCollectionsQueryVariables = {
  filter?: ModelProductCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductCollectionsQuery = {
  listProductCollections?:  {
    __typename: "ModelProductCollectionConnection",
    items:  Array< {
      __typename: "ProductCollection",
      id: string,
      collection_id: string,
      product_id: string,
      product_type_id?: string | null,
      quantity: number,
      sort_order?: number | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collection?:  {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProductCollectionsQueryVariables = {
  filter?: SearchableProductCollectionFilterInput | null,
  sort?: SearchableProductCollectionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProductCollectionsQuery = {
  searchProductCollections?:  {
    __typename: "SearchableProductCollectionConnection",
    items:  Array< {
      __typename: "ProductCollection",
      id: string,
      collection_id: string,
      product_id: string,
      product_type_id?: string | null,
      quantity: number,
      sort_order?: number | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collection?:  {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReferralQueryVariables = {
  id: string,
};

export type GetReferralQuery = {
  getReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReferralsQueryVariables = {
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsQuery = {
  listReferrals?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReferralsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralsByOwnerQuery = {
  listReferralsByOwner?:  {
    __typename: "ModelReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReferralsQueryVariables = {
  filter?: SearchableReferralFilterInput | null,
  sort?: SearchableReferralSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReferralsQuery = {
  searchReferrals?:  {
    __typename: "SearchableReferralConnection",
    items:  Array< {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReferralMessageQueryVariables = {
  id: string,
};

export type GetReferralMessageQuery = {
  getReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReferralMessagesQueryVariables = {
  filter?: ModelReferralMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralMessagesQuery = {
  listReferralMessages?:  {
    __typename: "ModelReferralMessageConnection",
    items:  Array< {
      __typename: "ReferralMessage",
      id: string,
      from: string,
      subject: string,
      message: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReferralMessagesByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReferralMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReferralMessagesByOwnerQuery = {
  listReferralMessagesByOwner?:  {
    __typename: "ModelReferralMessageConnection",
    items:  Array< {
      __typename: "ReferralMessage",
      id: string,
      from: string,
      subject: string,
      message: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReferralMessagesQueryVariables = {
  filter?: SearchableReferralMessageFilterInput | null,
  sort?: SearchableReferralMessageSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReferralMessagesQuery = {
  searchReferralMessages?:  {
    __typename: "SearchableReferralMessageConnection",
    items:  Array< {
      __typename: "ReferralMessage",
      id: string,
      from: string,
      subject: string,
      message: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPointQueryVariables = {
  id: string,
};

export type GetPointQuery = {
  getPoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPointsQueryVariables = {
  filter?: ModelPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointsQuery = {
  listPoints?:  {
    __typename: "ModelPointConnection",
    items:  Array< {
      __typename: "Point",
      id: string,
      account_id: string,
      points: number,
      point_type: number,
      order_id?: string | null,
      referral_id?: string | null,
      referral_brand_id?: string | null,
      createdAt: string,
      duration: number,
      review_id?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      referral?:  {
        __typename: "Referral",
        id: string,
        referral_type: number,
        referral_email?: string | null,
        referral_name?: string | null,
        referral_website?: string | null,
        referral_domain?: string | null,
        referral_by: string,
        referral_status: number,
        referral_brand_id?: string | null,
        referral_account_id?: string | null,
        createdAt: string,
        owner?: string | null,
        updatedAt: string,
        ownerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelPointHistoryConnection",
        items:  Array< {
          __typename: "PointHistory",
          id: string,
          point_id: string,
          account_id: string,
          points: number,
          history_type: number,
          order_id?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPointsByAccountQueryVariables = {
  account_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointsByAccountQuery = {
  listPointsByAccount?:  {
    __typename: "ModelPointConnection",
    items:  Array< {
      __typename: "Point",
      id: string,
      account_id: string,
      points: number,
      point_type: number,
      order_id?: string | null,
      referral_id?: string | null,
      referral_brand_id?: string | null,
      createdAt: string,
      duration: number,
      review_id?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      referral?:  {
        __typename: "Referral",
        id: string,
        referral_type: number,
        referral_email?: string | null,
        referral_name?: string | null,
        referral_website?: string | null,
        referral_domain?: string | null,
        referral_by: string,
        referral_status: number,
        referral_brand_id?: string | null,
        referral_account_id?: string | null,
        createdAt: string,
        owner?: string | null,
        updatedAt: string,
        ownerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelPointHistoryConnection",
        items:  Array< {
          __typename: "PointHistory",
          id: string,
          point_id: string,
          account_id: string,
          points: number,
          history_type: number,
          order_id?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPointsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointsByOrderQuery = {
  listPointsByOrder?:  {
    __typename: "ModelPointConnection",
    items:  Array< {
      __typename: "Point",
      id: string,
      account_id: string,
      points: number,
      point_type: number,
      order_id?: string | null,
      referral_id?: string | null,
      referral_brand_id?: string | null,
      createdAt: string,
      duration: number,
      review_id?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      referral?:  {
        __typename: "Referral",
        id: string,
        referral_type: number,
        referral_email?: string | null,
        referral_name?: string | null,
        referral_website?: string | null,
        referral_domain?: string | null,
        referral_by: string,
        referral_status: number,
        referral_brand_id?: string | null,
        referral_account_id?: string | null,
        createdAt: string,
        owner?: string | null,
        updatedAt: string,
        ownerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelPointHistoryConnection",
        items:  Array< {
          __typename: "PointHistory",
          id: string,
          point_id: string,
          account_id: string,
          points: number,
          history_type: number,
          order_id?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPointsQueryVariables = {
  filter?: SearchablePointFilterInput | null,
  sort?: SearchablePointSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPointsQuery = {
  searchPoints?:  {
    __typename: "SearchablePointConnection",
    items:  Array< {
      __typename: "Point",
      id: string,
      account_id: string,
      points: number,
      point_type: number,
      order_id?: string | null,
      referral_id?: string | null,
      referral_brand_id?: string | null,
      createdAt: string,
      duration: number,
      review_id?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      referral?:  {
        __typename: "Referral",
        id: string,
        referral_type: number,
        referral_email?: string | null,
        referral_name?: string | null,
        referral_website?: string | null,
        referral_domain?: string | null,
        referral_by: string,
        referral_status: number,
        referral_brand_id?: string | null,
        referral_account_id?: string | null,
        createdAt: string,
        owner?: string | null,
        updatedAt: string,
        ownerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      history?:  {
        __typename: "ModelPointHistoryConnection",
        items:  Array< {
          __typename: "PointHistory",
          id: string,
          point_id: string,
          account_id: string,
          points: number,
          history_type: number,
          order_id?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPointHistoryQueryVariables = {
  id: string,
};

export type GetPointHistoryQuery = {
  getPointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListPointHistorysQueryVariables = {
  filter?: ModelPointHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointHistorysQuery = {
  listPointHistorys?:  {
    __typename: "ModelPointHistoryConnection",
    items:  Array< {
      __typename: "PointHistory",
      id: string,
      point_id: string,
      account_id: string,
      points: number,
      history_type: number,
      order_id?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPointHistorysByAccountQueryVariables = {
  account_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPointHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointHistorysByAccountQuery = {
  listPointHistorysByAccount?:  {
    __typename: "ModelPointHistoryConnection",
    items:  Array< {
      __typename: "PointHistory",
      id: string,
      point_id: string,
      account_id: string,
      points: number,
      history_type: number,
      order_id?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPointHistorysByPointQueryVariables = {
  point_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPointHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPointHistorysByPointQuery = {
  listPointHistorysByPoint?:  {
    __typename: "ModelPointHistoryConnection",
    items:  Array< {
      __typename: "PointHistory",
      id: string,
      point_id: string,
      account_id: string,
      points: number,
      history_type: number,
      order_id?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPointHistorysQueryVariables = {
  filter?: SearchablePointHistoryFilterInput | null,
  sort?: SearchablePointHistorySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPointHistorysQuery = {
  searchPointHistorys?:  {
    __typename: "SearchablePointHistoryConnection",
    items:  Array< {
      __typename: "PointHistory",
      id: string,
      point_id: string,
      account_id: string,
      points: number,
      history_type: number,
      order_id?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsQuery = {
  listContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsBySupplierQuery = {
  listContactsBySupplier?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactsByOwnerQueryVariables = {
  owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsByOwnerQuery = {
  listContactsByOwner?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsByBuyerQuery = {
  listContactsByBuyer?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactsByBuyerOwnerQueryVariables = {
  buyer_owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsByBuyerOwnerQuery = {
  listContactsByBuyerOwner?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchContactsQueryVariables = {
  filter?: SearchableContactFilterInput | null,
  sort?: SearchableContactSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchContactsQuery = {
  searchContacts?:  {
    __typename: "SearchableContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetContactListQueryVariables = {
  id: string,
};

export type GetContactListQuery = {
  getContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListContactListsQueryVariables = {
  filter?: ModelContactListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListsQuery = {
  listContactLists?:  {
    __typename: "ModelContactListConnection",
    items:  Array< {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactListsBySupplierQueryVariables = {
  supplier_id?: string | null,
  orderCreatedAt?: ModelContactListBySupplierCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListsBySupplierQuery = {
  listContactListsBySupplier?:  {
    __typename: "ModelContactListConnection",
    items:  Array< {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchContactListsQueryVariables = {
  filter?: SearchableContactListFilterInput | null,
  sort?: SearchableContactListSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchContactListsQuery = {
  searchContactLists?:  {
    __typename: "SearchableContactListConnection",
    items:  Array< {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetContactListConnectionQueryVariables = {
  id: string,
};

export type GetContactListConnectionQuery = {
  getContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListContactListConnectionsQueryVariables = {
  filter?: ModelContactListConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListConnectionsQuery = {
  listContactListConnections?:  {
    __typename: "ModelContactListConnectionConnection",
    items:  Array< {
      __typename: "ContactListConnection",
      id: string,
      supplier_id: string,
      contact_list_id: string,
      contact_id: string,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contactList?:  {
        __typename: "ContactList",
        id: string,
        list_name: string,
        supplier_id: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contactConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactListConnectionsByContactQueryVariables = {
  contact_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactListConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListConnectionsByContactQuery = {
  listContactListConnectionsByContact?:  {
    __typename: "ModelContactListConnectionConnection",
    items:  Array< {
      __typename: "ContactListConnection",
      id: string,
      supplier_id: string,
      contact_list_id: string,
      contact_id: string,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contactList?:  {
        __typename: "ContactList",
        id: string,
        list_name: string,
        supplier_id: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contactConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactListConnectionsByContactListQueryVariables = {
  contact_list_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactListConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListConnectionsByContactListQuery = {
  listContactListConnectionsByContactList?:  {
    __typename: "ModelContactListConnectionConnection",
    items:  Array< {
      __typename: "ContactListConnection",
      id: string,
      supplier_id: string,
      contact_list_id: string,
      contact_id: string,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contactList?:  {
        __typename: "ContactList",
        id: string,
        list_name: string,
        supplier_id: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contactConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactListConnectionsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactListConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactListConnectionsBySupplierQuery = {
  listContactListConnectionsBySupplier?:  {
    __typename: "ModelContactListConnectionConnection",
    items:  Array< {
      __typename: "ContactListConnection",
      id: string,
      supplier_id: string,
      contact_list_id: string,
      contact_id: string,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contactList?:  {
        __typename: "ContactList",
        id: string,
        list_name: string,
        supplier_id: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contactConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchContactListConnectionsQueryVariables = {
  filter?: SearchableContactListConnectionFilterInput | null,
  sort?: SearchableContactListConnectionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchContactListConnectionsQuery = {
  searchContactListConnections?:  {
    __typename: "SearchableContactListConnectionConnection",
    items:  Array< {
      __typename: "ContactListConnection",
      id: string,
      supplier_id: string,
      contact_list_id: string,
      contact_id: string,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contactList?:  {
        __typename: "ContactList",
        id: string,
        list_name: string,
        supplier_id: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contactConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetContactActivityQueryVariables = {
  id: string,
};

export type GetContactActivityQuery = {
  getContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListContactActivitysQueryVariables = {
  filter?: ModelContactActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactActivitysQuery = {
  listContactActivitys?:  {
    __typename: "ModelContactActivityConnection",
    items:  Array< {
      __typename: "ContactActivity",
      id: string,
      contact_id: string,
      activity_date: string,
      activity_type?: string | null,
      order_id?: string | null,
      message_id?: string | null,
      campaign_id?: string | null,
      activity?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactActivitiesByContactQueryVariables = {
  contact_id?: string | null,
  activity_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactActivitiesByContactQuery = {
  listContactActivitiesByContact?:  {
    __typename: "ModelContactActivityConnection",
    items:  Array< {
      __typename: "ContactActivity",
      id: string,
      contact_id: string,
      activity_date: string,
      activity_type?: string | null,
      order_id?: string | null,
      message_id?: string | null,
      campaign_id?: string | null,
      activity?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchContactActivitysQueryVariables = {
  filter?: SearchableContactActivityFilterInput | null,
  sort?: SearchableContactActivitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchContactActivitysQuery = {
  searchContactActivitys?:  {
    __typename: "SearchableContactActivityConnection",
    items:  Array< {
      __typename: "ContactActivity",
      id: string,
      contact_id: string,
      activity_date: string,
      activity_type?: string | null,
      order_id?: string | null,
      message_id?: string | null,
      campaign_id?: string | null,
      activity?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCampaignQueryVariables = {
  id: string,
};

export type GetCampaignQuery = {
  getCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCampaignsQueryVariables = {
  filter?: ModelCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsQuery = {
  listCampaigns?:  {
    __typename: "ModelCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsBySupplierQuery = {
  listCampaignsBySupplier?:  {
    __typename: "ModelCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignsByOwnerQueryVariables = {
  owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsByOwnerQuery = {
  listCampaignsByOwner?:  {
    __typename: "ModelCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCampaignsQueryVariables = {
  filter?: SearchableCampaignFilterInput | null,
  sort?: SearchableCampaignSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCampaignsQuery = {
  searchCampaigns?:  {
    __typename: "SearchableCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCampaignContactQueryVariables = {
  id: string,
};

export type GetCampaignContactQuery = {
  getCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCampaignContactsQueryVariables = {
  filter?: ModelCampaignContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignContactsQuery = {
  listCampaignContacts?:  {
    __typename: "ModelCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignContactsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignContactsBySupplierQuery = {
  listCampaignContactsBySupplier?:  {
    __typename: "ModelCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignContactsByContactQueryVariables = {
  contact_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignContactsByContactQuery = {
  listCampaignContactsByContact?:  {
    __typename: "ModelCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignContactsByCampaignQueryVariables = {
  campaign_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignContactsByCampaignQuery = {
  listCampaignContactsByCampaign?:  {
    __typename: "ModelCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignContactsByEmailQueryVariables = {
  email?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignContactsByEmailQuery = {
  listCampaignContactsByEmail?:  {
    __typename: "ModelCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCampaignContactsQueryVariables = {
  filter?: SearchableCampaignContactFilterInput | null,
  sort?: SearchableCampaignContactSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCampaignContactsQuery = {
  searchCampaignContacts?:  {
    __typename: "SearchableCampaignContactConnection",
    items:  Array< {
      __typename: "CampaignContact",
      id: string,
      supplier_id: string,
      campaign_id?: string | null,
      contact_id?: string | null,
      email?: string | null,
      source?: string | null,
      contact_list_id?: string | null,
      status?: string | null,
      clicks?: number | null,
      views?: number | null,
      sentAt?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        supplier_id: string,
        brand_id: string,
        campaign_sender?: string | null,
        campaign_from?: string | null,
        campaign_title?: string | null,
        campaign_status?: string | null,
        campaign_subject?: string | null,
        preview_text?: string | null,
        campaign_template?: string | null,
        campaign_message?: string | null,
        campaign_components?: string | null,
        campaign_style?: string | null,
        campaign_segments?: Array< string | null > | null,
        deliveryTime?: string | null,
        sentAt?: string | null,
        createdAt: string,
        owner: string,
        stats?:  {
          __typename: "CampaignStats",
          total?: number | null,
          sent?: number | null,
          delivered?: number | null,
          unsent?: number | null,
          open?: number | null,
          click?: number | null,
          bounce?: number | null,
          unsubscribe?: number | null,
          clickRate?: number | null,
          openRate?: number | null,
        } | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelCampaignContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetDailySessionQueryVariables = {
  brand_id: string,
  session_date: string,
};

export type GetDailySessionQuery = {
  getDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDailySessionsQueryVariables = {
  brand_id?: string | null,
  session_date?: ModelStringKeyConditionInput | null,
  filter?: ModelDailySessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDailySessionsQuery = {
  listDailySessions?:  {
    __typename: "ModelDailySessionConnection",
    items:  Array< {
      __typename: "DailySession",
      brand_id: string,
      session_date: string,
      session_count?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDailySessionsQueryVariables = {
  filter?: SearchableDailySessionFilterInput | null,
  sort?: SearchableDailySessionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchDailySessionsQuery = {
  searchDailySessions?:  {
    __typename: "SearchableDailySessionConnection",
    items:  Array< {
      __typename: "DailySession",
      brand_id: string,
      session_date: string,
      session_count?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetConversionRateQueryVariables = {
  brand_id: string,
};

export type GetConversionRateQuery = {
  getConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConversionRatesQueryVariables = {
  brand_id?: string | null,
  filter?: ModelConversionRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConversionRatesQuery = {
  listConversionRates?:  {
    __typename: "ModelConversionRateConnection",
    items:  Array< {
      __typename: "ConversionRate",
      brand_id: string,
      order?: number | null,
      click?: number | null,
      conversion?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchConversionRatesQueryVariables = {
  filter?: SearchableConversionRateFilterInput | null,
  sort?: SearchableConversionRateSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchConversionRatesQuery = {
  searchConversionRates?:  {
    __typename: "SearchableConversionRateConnection",
    items:  Array< {
      __typename: "ConversionRate",
      brand_id: string,
      order?: number | null,
      click?: number | null,
      conversion?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPageViewQueryVariables = {
  brand_id: string,
  buyer_id: string,
};

export type GetPageViewQuery = {
  getPageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPageViewsQueryVariables = {
  brand_id?: string | null,
  buyer_id?: ModelIDKeyConditionInput | null,
  filter?: ModelPageViewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPageViewsQuery = {
  listPageViews?:  {
    __typename: "ModelPageViewConnection",
    items:  Array< {
      __typename: "PageView",
      brand_id: string,
      buyer_id: string,
      email?: string | null,
      page_view?: number | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPageViewsByBuyerQueryVariables = {
  buyer_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageViewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPageViewsByBuyerQuery = {
  listPageViewsByBuyer?:  {
    __typename: "ModelPageViewConnection",
    items:  Array< {
      __typename: "PageView",
      brand_id: string,
      buyer_id: string,
      email?: string | null,
      page_view?: number | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPageViewsByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageViewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPageViewsByEmailQuery = {
  listPageViewsByEmail?:  {
    __typename: "ModelPageViewConnection",
    items:  Array< {
      __typename: "PageView",
      brand_id: string,
      buyer_id: string,
      email?: string | null,
      page_view?: number | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPageViewsQueryVariables = {
  filter?: SearchablePageViewFilterInput | null,
  sort?: SearchablePageViewSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPageViewsQuery = {
  searchPageViews?:  {
    __typename: "SearchablePageViewConnection",
    items:  Array< {
      __typename: "PageView",
      brand_id: string,
      buyer_id: string,
      email?: string | null,
      page_view?: number | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      brand_id: string,
      product_ids?: Array< string | null > | null,
      buyer_id: string,
      order_id: string,
      rate?: number | null,
      title?: string | null,
      comment?: string | null,
      remove_flag?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelReviewReplyConnection",
        items:  Array< {
          __typename: "ReviewReply",
          id: string,
          review_id: string,
          brand_id: string,
          comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsByBrandQuery = {
  listReviewsByBrand?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      brand_id: string,
      product_ids?: Array< string | null > | null,
      buyer_id: string,
      order_id: string,
      rate?: number | null,
      title?: string | null,
      comment?: string | null,
      remove_flag?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelReviewReplyConnection",
        items:  Array< {
          __typename: "ReviewReply",
          id: string,
          review_id: string,
          brand_id: string,
          comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsByBuyerQuery = {
  listReviewsByBuyer?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      brand_id: string,
      product_ids?: Array< string | null > | null,
      buyer_id: string,
      order_id: string,
      rate?: number | null,
      title?: string | null,
      comment?: string | null,
      remove_flag?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelReviewReplyConnection",
        items:  Array< {
          __typename: "ReviewReply",
          id: string,
          review_id: string,
          brand_id: string,
          comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsByOrderQuery = {
  listReviewsByOrder?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      brand_id: string,
      product_ids?: Array< string | null > | null,
      buyer_id: string,
      order_id: string,
      rate?: number | null,
      title?: string | null,
      comment?: string | null,
      remove_flag?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelReviewReplyConnection",
        items:  Array< {
          __typename: "ReviewReply",
          id: string,
          review_id: string,
          brand_id: string,
          comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReviewsQueryVariables = {
  filter?: SearchableReviewFilterInput | null,
  sort?: SearchableReviewSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReviewsQuery = {
  searchReviews?:  {
    __typename: "SearchableReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      brand_id: string,
      product_ids?: Array< string | null > | null,
      buyer_id: string,
      order_id: string,
      rate?: number | null,
      title?: string | null,
      comment?: string | null,
      remove_flag?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      replies?:  {
        __typename: "ModelReviewReplyConnection",
        items:  Array< {
          __typename: "ReviewReply",
          id: string,
          review_id: string,
          brand_id: string,
          comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReviewReplyQueryVariables = {
  id: string,
};

export type GetReviewReplyQuery = {
  getReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListReviewReplysQueryVariables = {
  filter?: ModelReviewReplyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewReplysQuery = {
  listReviewReplys?:  {
    __typename: "ModelReviewReplyConnection",
    items:  Array< {
      __typename: "ReviewReply",
      id: string,
      review_id: string,
      brand_id: string,
      comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewRepliesByReviewQueryVariables = {
  review_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewReplyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewRepliesByReviewQuery = {
  listReviewRepliesByReview?:  {
    __typename: "ModelReviewReplyConnection",
    items:  Array< {
      __typename: "ReviewReply",
      id: string,
      review_id: string,
      brand_id: string,
      comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewRepliesByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewReplyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewRepliesByBrandQuery = {
  listReviewRepliesByBrand?:  {
    __typename: "ModelReviewReplyConnection",
    items:  Array< {
      __typename: "ReviewReply",
      id: string,
      review_id: string,
      brand_id: string,
      comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReviewReplysQueryVariables = {
  filter?: SearchableReviewReplyFilterInput | null,
  sort?: SearchableReviewReplySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReviewReplysQuery = {
  searchReviewReplys?:  {
    __typename: "SearchableReviewReplyConnection",
    items:  Array< {
      __typename: "ReviewReply",
      id: string,
      review_id: string,
      brand_id: string,
      comment?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCouponQueryVariables = {
  id: string,
};

export type GetCouponQuery = {
  getCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCouponsQueryVariables = {
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponsQuery = {
  listCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCouponsQueryVariables = {
  filter?: SearchableCouponFilterInput | null,
  sort?: SearchableCouponSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCouponsQuery = {
  searchCoupons?:  {
    __typename: "SearchableCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCouponLogQueryVariables = {
  id: string,
};

export type GetCouponLogQuery = {
  getCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCouponLogsQueryVariables = {
  filter?: ModelCouponLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponLogsQuery = {
  listCouponLogs?:  {
    __typename: "ModelCouponLogConnection",
    items:  Array< {
      __typename: "CouponLog",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_ids?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCouponLogsByCouponQueryVariables = {
  coupon_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCouponLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponLogsByCouponQuery = {
  listCouponLogsByCoupon?:  {
    __typename: "ModelCouponLogConnection",
    items:  Array< {
      __typename: "CouponLog",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_ids?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCouponLogsQueryVariables = {
  filter?: SearchableCouponLogFilterInput | null,
  sort?: SearchableCouponLogSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCouponLogsQuery = {
  searchCouponLogs?:  {
    __typename: "SearchableCouponLogConnection",
    items:  Array< {
      __typename: "CouponLog",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_ids?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBuyerCouponQueryVariables = {
  id: string,
};

export type GetBuyerCouponQuery = {
  getBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListBuyerCouponsQueryVariables = {
  filter?: ModelBuyerCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerCouponsQuery = {
  listBuyerCoupons?:  {
    __typename: "ModelBuyerCouponConnection",
    items:  Array< {
      __typename: "BuyerCoupon",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      max_redemptions?: number | null,
      times_redeemed?: number | null,
      redemptions_left?: number | null,
      expiresAt?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBuyerCouponsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuyerCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerCouponsByBuyerQuery = {
  listBuyerCouponsByBuyer?:  {
    __typename: "ModelBuyerCouponConnection",
    items:  Array< {
      __typename: "BuyerCoupon",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      max_redemptions?: number | null,
      times_redeemed?: number | null,
      redemptions_left?: number | null,
      expiresAt?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBuyerCouponsByCouponQueryVariables = {
  coupon_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuyerCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerCouponsByCouponQuery = {
  listBuyerCouponsByCoupon?:  {
    __typename: "ModelBuyerCouponConnection",
    items:  Array< {
      __typename: "BuyerCoupon",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      max_redemptions?: number | null,
      times_redeemed?: number | null,
      redemptions_left?: number | null,
      expiresAt?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBuyerCouponsQueryVariables = {
  filter?: SearchableBuyerCouponFilterInput | null,
  sort?: SearchableBuyerCouponSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBuyerCouponsQuery = {
  searchBuyerCoupons?:  {
    __typename: "SearchableBuyerCouponConnection",
    items:  Array< {
      __typename: "BuyerCoupon",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      max_redemptions?: number | null,
      times_redeemed?: number | null,
      redemptions_left?: number | null,
      expiresAt?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCashbackQueryVariables = {
  id: string,
};

export type GetCashbackQuery = {
  getCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListCashbacksQueryVariables = {
  filter?: ModelCashbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashbacksQuery = {
  listCashbacks?:  {
    __typename: "ModelCashbackConnection",
    items:  Array< {
      __typename: "Cashback",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_id?: Array< string | null > | null,
      redeemed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCashbacksByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashbacksByBuyerQuery = {
  listCashbacksByBuyer?:  {
    __typename: "ModelCashbackConnection",
    items:  Array< {
      __typename: "Cashback",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_id?: Array< string | null > | null,
      redeemed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCashbacksByCouponQueryVariables = {
  coupon_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashbacksByCouponQuery = {
  listCashbacksByCoupon?:  {
    __typename: "ModelCashbackConnection",
    items:  Array< {
      __typename: "Cashback",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_id?: Array< string | null > | null,
      redeemed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCashbacksQueryVariables = {
  filter?: SearchableCashbackFilterInput | null,
  sort?: SearchableCashbackSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCashbacksQuery = {
  searchCashbacks?:  {
    __typename: "SearchableCashbackConnection",
    items:  Array< {
      __typename: "Cashback",
      id: string,
      coupon_id?: string | null,
      buyer_id?: string | null,
      order_id?: Array< string | null > | null,
      redeemed?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetStockProductQueryVariables = {
  id: string,
};

export type GetStockProductQuery = {
  getStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStockProductsQueryVariables = {
  filter?: ModelStockProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStockProductsQuery = {
  listStockProducts?:  {
    __typename: "ModelStockProductConnection",
    items:  Array< {
      __typename: "StockProduct",
      id: string,
      stock_id: string,
      src_product_id: string,
      src_product_type_id?: string | null,
      src_brand_id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStockProductsByProductQueryVariables = {
  product_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStockProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStockProductsByProductQuery = {
  listStockProductsByProduct?:  {
    __typename: "ModelStockProductConnection",
    items:  Array< {
      __typename: "StockProduct",
      id: string,
      stock_id: string,
      src_product_id: string,
      src_product_type_id?: string | null,
      src_brand_id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStockProductsBySrcProductQueryVariables = {
  src_product_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStockProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStockProductsBySrcProductQuery = {
  listStockProductsBySrcProduct?:  {
    __typename: "ModelStockProductConnection",
    items:  Array< {
      __typename: "StockProduct",
      id: string,
      stock_id: string,
      src_product_id: string,
      src_product_type_id?: string | null,
      src_brand_id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStockProductsByStockIdQueryVariables = {
  stock_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStockProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStockProductsByStockIdQuery = {
  listStockProductsByStockId?:  {
    __typename: "ModelStockProductConnection",
    items:  Array< {
      __typename: "StockProduct",
      id: string,
      stock_id: string,
      src_product_id: string,
      src_product_type_id?: string | null,
      src_brand_id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchStockProductsQueryVariables = {
  filter?: SearchableStockProductFilterInput | null,
  sort?: SearchableStockProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchStockProductsQuery = {
  searchStockProducts?:  {
    __typename: "SearchableStockProductConnection",
    items:  Array< {
      __typename: "StockProduct",
      id: string,
      stock_id: string,
      src_product_id: string,
      src_product_type_id?: string | null,
      src_brand_id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetLogilessAuthTokenQueryVariables = {
  id: string,
};

export type GetLogilessAuthTokenQuery = {
  getLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListLogilessAuthTokensQueryVariables = {
  filter?: ModelLogilessAuthTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLogilessAuthTokensQuery = {
  listLogilessAuthTokens?:  {
    __typename: "ModelLogilessAuthTokenConnection",
    items:  Array< {
      __typename: "LogilessAuthToken",
      id: string,
      logiless_client_id: string,
      access_token: string,
      refresh_token: string,
      expires_at: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLogilessAuthTokensByClientIdQueryVariables = {
  logiless_client_id?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogilessAuthTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLogilessAuthTokensByClientIdQuery = {
  listLogilessAuthTokensByClientId?:  {
    __typename: "ModelLogilessAuthTokenConnection",
    items:  Array< {
      __typename: "LogilessAuthToken",
      id: string,
      logiless_client_id: string,
      access_token: string,
      refresh_token: string,
      expires_at: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchLogilessAuthTokensQueryVariables = {
  filter?: SearchableLogilessAuthTokenFilterInput | null,
  sort?: SearchableLogilessAuthTokenSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchLogilessAuthTokensQuery = {
  searchLogilessAuthTokens?:  {
    __typename: "SearchableLogilessAuthTokenConnection",
    items:  Array< {
      __typename: "LogilessAuthToken",
      id: string,
      logiless_client_id: string,
      access_token: string,
      refresh_token: string,
      expires_at: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBuyerReadModelQueryVariables = {
  id: string,
};

export type GetBuyerReadModelQuery = {
  getBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListBuyerReadModelsQueryVariables = {
  filter?: ModelBuyerReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerReadModelsQuery = {
  listBuyerReadModels?:  {
    __typename: "ModelBuyerReadModelConnection",
    items:  Array< {
      __typename: "BuyerReadModel",
      id: string,
      account_company_name?: string | null,
      account_first_name?: string | null,
      account_id?: string | null,
      account_instagram_account?: string | null,
      account_last_name?: string | null,
      account_location?: string | null,
      account_tel?: string | null,
      account_web_site_URL?: string | null,
      account_zip_code?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_returen_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: string | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      primary_shipping_building?: string | null,
      primary_shipping_city?: string | null,
      primary_shipping_name?: string | null,
      primary_shipping_phone_number?: string | null,
      primary_shipping_prefecture?: string | null,
      primary_shipping_zip_code?: string | null,
      referral_brand_id?: string | null,
      referral_brand_name?: string | null,
      shop_image_keys?: Array< string | null > | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      total_order_amount?: number | null,
      total_order_amount_last_9m?: number | null,
      total_order_quantity?: number | null,
      total_order_quantity_last_9m?: number | null,
      total_return_amount?: number | null,
      total_return_amount_last_9m?: number | null,
      total_return_quantity?: number | null,
      total_return_quantity_last_9m?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBuyerReadModelsByAccountQueryVariables = {
  account_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBuyerReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuyerReadModelsByAccountQuery = {
  listBuyerReadModelsByAccount?:  {
    __typename: "ModelBuyerReadModelConnection",
    items:  Array< {
      __typename: "BuyerReadModel",
      id: string,
      account_company_name?: string | null,
      account_first_name?: string | null,
      account_id?: string | null,
      account_instagram_account?: string | null,
      account_last_name?: string | null,
      account_location?: string | null,
      account_tel?: string | null,
      account_web_site_URL?: string | null,
      account_zip_code?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_returen_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: string | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      primary_shipping_building?: string | null,
      primary_shipping_city?: string | null,
      primary_shipping_name?: string | null,
      primary_shipping_phone_number?: string | null,
      primary_shipping_prefecture?: string | null,
      primary_shipping_zip_code?: string | null,
      referral_brand_id?: string | null,
      referral_brand_name?: string | null,
      shop_image_keys?: Array< string | null > | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      total_order_amount?: number | null,
      total_order_amount_last_9m?: number | null,
      total_order_quantity?: number | null,
      total_order_quantity_last_9m?: number | null,
      total_return_amount?: number | null,
      total_return_amount_last_9m?: number | null,
      total_return_quantity?: number | null,
      total_return_quantity_last_9m?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBuyerReadModelsQueryVariables = {
  filter?: SearchableBuyerReadModelFilterInput | null,
  sort?: SearchableBuyerReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBuyerReadModelsQuery = {
  searchBuyerReadModels?:  {
    __typename: "SearchableBuyerReadModelConnection",
    items:  Array< {
      __typename: "BuyerReadModel",
      id: string,
      account_company_name?: string | null,
      account_first_name?: string | null,
      account_id?: string | null,
      account_instagram_account?: string | null,
      account_last_name?: string | null,
      account_location?: string | null,
      account_tel?: string | null,
      account_web_site_URL?: string | null,
      account_zip_code?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_returen_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: string | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      primary_shipping_building?: string | null,
      primary_shipping_city?: string | null,
      primary_shipping_name?: string | null,
      primary_shipping_phone_number?: string | null,
      primary_shipping_prefecture?: string | null,
      primary_shipping_zip_code?: string | null,
      referral_brand_id?: string | null,
      referral_brand_name?: string | null,
      shop_image_keys?: Array< string | null > | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      total_order_amount?: number | null,
      total_order_amount_last_9m?: number | null,
      total_order_quantity?: number | null,
      total_order_quantity_last_9m?: number | null,
      total_return_amount?: number | null,
      total_return_amount_last_9m?: number | null,
      total_return_quantity?: number | null,
      total_return_quantity_last_9m?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBrandReadModelQueryVariables = {
  id: string,
};

export type GetBrandReadModelQuery = {
  getBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBrandReadModelsQueryVariables = {
  filter?: ModelBrandReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandReadModelsQuery = {
  listBrandReadModels?:  {
    __typename: "ModelBrandReadModelConnection",
    items:  Array< {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductReadModelQueryVariables = {
  id: string,
};

export type GetProductReadModelQuery = {
  getProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type ListProductReadModelsQueryVariables = {
  filter?: ModelProductReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReadModelsQuery = {
  listProductReadModels?:  {
    __typename: "ModelProductReadModelConnection",
    items:  Array< {
      __typename: "ProductReadModel",
      id: string,
      product_name?: string | null,
      product_number?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_yomigana?: string | null,
      brand_image_key?: string | null,
      brand_published?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_individual_minimum_buy?: number | null,
      brand_individual_wholesale_rate?: number | null,
      brand_tags?: Array< string > | null,
      product_content?: string | null,
      product_description?: string | null,
      product_size?: string | null,
      product_owner?: string | null,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      origin_wholesale_price?: number | null,
      wholesale_rate?: number | null,
      wholesale_price?: number | null,
      individual_wholesale_rate?: number | null,
      individual_price?: number | null,
      individual_minimum_buy?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      is_published?: boolean | null,
      is_limited?: boolean | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      category_page_id?: Array< string > | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_preorder_shipping_date_timestamp?: number | null,
      product_types?: Array< string > | null,
      product_colors?: Array< string > | null,
      product_sizes?: Array< string > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      image_key?: string | null,
      image_keys?: Array< string > | null,
      video_keys?: Array< string > | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string > | null,
      protected_zip_code?: Array< string > | null,
      options?:  Array< {
        __typename: "ProductOption",
        id?: string | null,
        product_id?: string | null,
        size?: string | null,
        color?: string | null,
        type_name?: string | null,
        sku?: string | null,
        imageKey?: string | null,
        out_of_stock?: boolean | null,
        suspended?: boolean | null,
        published?: boolean | null,
        createdAt?: string | null,
        copied_product_type_id?: string | null,
      } > | null,
      publishedAt?: string | null,
      publishedAtTimestamp?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      wholesale_rate_tier?: number | null,
      brand_tier?: number | null,
      brand_conversion_rate_tier?: number | null,
      brand_sell_through_rate_tier?: number | null,
      brand_service_rate_rate_tier?: number | null,
      brand_average_shipping_days_tier?: number | null,
      brand_minimum_tier?: number | null,
      brand_items_tier?: number | null,
      brand_contacts_tier?: number | null,
      collection_id?: Array< string | null > | null,
      sell_only_direct?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductReadModelsByBrandQueryVariables = {
  brand_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReadModelsByBrandQuery = {
  listProductReadModelsByBrand?:  {
    __typename: "ModelProductReadModelConnection",
    items:  Array< {
      __typename: "ProductReadModel",
      id: string,
      product_name?: string | null,
      product_number?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_yomigana?: string | null,
      brand_image_key?: string | null,
      brand_published?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_individual_minimum_buy?: number | null,
      brand_individual_wholesale_rate?: number | null,
      brand_tags?: Array< string > | null,
      product_content?: string | null,
      product_description?: string | null,
      product_size?: string | null,
      product_owner?: string | null,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      origin_wholesale_price?: number | null,
      wholesale_rate?: number | null,
      wholesale_price?: number | null,
      individual_wholesale_rate?: number | null,
      individual_price?: number | null,
      individual_minimum_buy?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      is_published?: boolean | null,
      is_limited?: boolean | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      category_page_id?: Array< string > | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_preorder_shipping_date_timestamp?: number | null,
      product_types?: Array< string > | null,
      product_colors?: Array< string > | null,
      product_sizes?: Array< string > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      image_key?: string | null,
      image_keys?: Array< string > | null,
      video_keys?: Array< string > | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string > | null,
      protected_zip_code?: Array< string > | null,
      options?:  Array< {
        __typename: "ProductOption",
        id?: string | null,
        product_id?: string | null,
        size?: string | null,
        color?: string | null,
        type_name?: string | null,
        sku?: string | null,
        imageKey?: string | null,
        out_of_stock?: boolean | null,
        suspended?: boolean | null,
        published?: boolean | null,
        createdAt?: string | null,
        copied_product_type_id?: string | null,
      } > | null,
      publishedAt?: string | null,
      publishedAtTimestamp?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      wholesale_rate_tier?: number | null,
      brand_tier?: number | null,
      brand_conversion_rate_tier?: number | null,
      brand_sell_through_rate_tier?: number | null,
      brand_service_rate_rate_tier?: number | null,
      brand_average_shipping_days_tier?: number | null,
      brand_minimum_tier?: number | null,
      brand_items_tier?: number | null,
      brand_contacts_tier?: number | null,
      collection_id?: Array< string | null > | null,
      sell_only_direct?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductReadModelsBySkuQueryVariables = {
  sku?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReadModelsBySkuQuery = {
  listProductReadModelsBySku?:  {
    __typename: "ModelProductReadModelConnection",
    items:  Array< {
      __typename: "ProductReadModel",
      id: string,
      product_name?: string | null,
      product_number?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_yomigana?: string | null,
      brand_image_key?: string | null,
      brand_published?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_individual_minimum_buy?: number | null,
      brand_individual_wholesale_rate?: number | null,
      brand_tags?: Array< string > | null,
      product_content?: string | null,
      product_description?: string | null,
      product_size?: string | null,
      product_owner?: string | null,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      origin_wholesale_price?: number | null,
      wholesale_rate?: number | null,
      wholesale_price?: number | null,
      individual_wholesale_rate?: number | null,
      individual_price?: number | null,
      individual_minimum_buy?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      is_published?: boolean | null,
      is_limited?: boolean | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      category_page_id?: Array< string > | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_preorder_shipping_date_timestamp?: number | null,
      product_types?: Array< string > | null,
      product_colors?: Array< string > | null,
      product_sizes?: Array< string > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      image_key?: string | null,
      image_keys?: Array< string > | null,
      video_keys?: Array< string > | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string > | null,
      protected_zip_code?: Array< string > | null,
      options?:  Array< {
        __typename: "ProductOption",
        id?: string | null,
        product_id?: string | null,
        size?: string | null,
        color?: string | null,
        type_name?: string | null,
        sku?: string | null,
        imageKey?: string | null,
        out_of_stock?: boolean | null,
        suspended?: boolean | null,
        published?: boolean | null,
        createdAt?: string | null,
        copied_product_type_id?: string | null,
      } > | null,
      publishedAt?: string | null,
      publishedAtTimestamp?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      wholesale_rate_tier?: number | null,
      brand_tier?: number | null,
      brand_conversion_rate_tier?: number | null,
      brand_sell_through_rate_tier?: number | null,
      brand_service_rate_rate_tier?: number | null,
      brand_average_shipping_days_tier?: number | null,
      brand_minimum_tier?: number | null,
      brand_items_tier?: number | null,
      brand_contacts_tier?: number | null,
      collection_id?: Array< string | null > | null,
      sell_only_direct?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionReadModelQueryVariables = {
  id: string,
};

export type GetCollectionReadModelQuery = {
  getCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type ListCollectionReadModelsQueryVariables = {
  filter?: ModelCollectionReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionReadModelsQuery = {
  listCollectionReadModels?:  {
    __typename: "ModelCollectionReadModelConnection",
    items:  Array< {
      __typename: "CollectionReadModel",
      id: string,
      name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_categories?: Array< string | null > | null,
      brand_tags?: Array< string | null > | null,
      src_brand_id?: string | null,
      src_brand_name?: string | null,
      src_brand_categories?: Array< string | null > | null,
      src_brand_tags?: Array< string | null > | null,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_image_keys?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      product_categories?: Array< string | null > | null,
      product_subcategories?: Array< string | null > | null,
      total_quantity?: number | null,
      total_retail_amount?: number | null,
      total_original_amount?: number | null,
      total_amount?: number | null,
      total_items?: number | null,
      wholesale_rate?: number | null,
      minimum_wholesale_rate?: number | null,
      sell_only_direct?: boolean | null,
      display_order?: number | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCollectionReadModelsByBrandQueryVariables = {
  brand_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionReadModelsByBrandQuery = {
  listCollectionReadModelsByBrand?:  {
    __typename: "ModelCollectionReadModelConnection",
    items:  Array< {
      __typename: "CollectionReadModel",
      id: string,
      name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_categories?: Array< string | null > | null,
      brand_tags?: Array< string | null > | null,
      src_brand_id?: string | null,
      src_brand_name?: string | null,
      src_brand_categories?: Array< string | null > | null,
      src_brand_tags?: Array< string | null > | null,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_image_keys?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      product_categories?: Array< string | null > | null,
      product_subcategories?: Array< string | null > | null,
      total_quantity?: number | null,
      total_retail_amount?: number | null,
      total_original_amount?: number | null,
      total_amount?: number | null,
      total_items?: number | null,
      wholesale_rate?: number | null,
      minimum_wholesale_rate?: number | null,
      sell_only_direct?: boolean | null,
      display_order?: number | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCollectionReadModelsQueryVariables = {
  filter?: SearchableCollectionReadModelFilterInput | null,
  sort?: SearchableCollectionReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCollectionReadModelsQuery = {
  searchCollectionReadModels?:  {
    __typename: "SearchableCollectionReadModelConnection",
    items:  Array< {
      __typename: "CollectionReadModel",
      id: string,
      name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_categories?: Array< string | null > | null,
      brand_tags?: Array< string | null > | null,
      src_brand_id?: string | null,
      src_brand_name?: string | null,
      src_brand_categories?: Array< string | null > | null,
      src_brand_tags?: Array< string | null > | null,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner?: string | null,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_image_keys?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      product_categories?: Array< string | null > | null,
      product_subcategories?: Array< string | null > | null,
      total_quantity?: number | null,
      total_retail_amount?: number | null,
      total_original_amount?: number | null,
      total_amount?: number | null,
      total_items?: number | null,
      wholesale_rate?: number | null,
      minimum_wholesale_rate?: number | null,
      sell_only_direct?: boolean | null,
      display_order?: number | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderReadModelQueryVariables = {
  id: string,
};

export type GetOrderReadModelQuery = {
  getOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrderReadModelsQueryVariables = {
  filter?: ModelOrderReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderReadModelsQuery = {
  listOrderReadModels?:  {
    __typename: "ModelOrderReadModelConnection",
    items:  Array< {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderReadModelsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderReadModelsByBuyerQuery = {
  listOrderReadModelsByBuyer?:  {
    __typename: "ModelOrderReadModelConnection",
    items:  Array< {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderReadModelsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderReadModelsBySupplierQuery = {
  listOrderReadModelsBySupplier?:  {
    __typename: "ModelOrderReadModelConnection",
    items:  Array< {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderReadModelsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderReadModelsByBrandQuery = {
  listOrderReadModelsByBrand?:  {
    __typename: "ModelOrderReadModelConnection",
    items:  Array< {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderReadModelsQueryVariables = {
  filter?: SearchableOrderReadModelFilterInput | null,
  sort?: SearchableOrderReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderReadModelsQuery = {
  searchOrderReadModels?:  {
    __typename: "SearchableOrderReadModelConnection",
    items:  Array< {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderDetailReadModelQueryVariables = {
  id: string,
};

export type GetOrderDetailReadModelQuery = {
  getOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListOrderDetailReadModelsQueryVariables = {
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsQuery = {
  listOrderDetailReadModels?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderDetailReadModelsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsByBuyerQuery = {
  listOrderDetailReadModelsByBuyer?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderDetailReadModelsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsBySupplierQuery = {
  listOrderDetailReadModelsBySupplier?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderDetailReadModelsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsByBrandQuery = {
  listOrderDetailReadModelsByBrand?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderDetailReadModelsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsByProductQuery = {
  listOrderDetailReadModelsByProduct?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderDetailReadModelsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderDetailReadModelsByOrderQuery = {
  listOrderDetailReadModelsByOrder?:  {
    __typename: "ModelOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchOrderDetailReadModelsQueryVariables = {
  filter?: SearchableOrderDetailReadModelFilterInput | null,
  sort?: SearchableOrderDetailReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchOrderDetailReadModelsQuery = {
  searchOrderDetailReadModels?:  {
    __typename: "SearchableOrderDetailReadModelConnection",
    items:  Array< {
      __typename: "OrderDetailReadModel",
      id: string,
      order_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      initial_quantity?: number | null,
      initial_price?: number | null,
      initial_wholesale_rate?: number | null,
      quantity?: number | null,
      price?: number | null,
      wholesale_rate?: number | null,
      subtotal?: number | null,
      amount?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_approved_amount?: number | null,
      report_approved_quantity?: number | null,
      report_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      discount_percentage?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      memo?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReturnDetailReadModelQueryVariables = {
  id: string,
};

export type GetReturnDetailReadModelQuery = {
  getReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReturnDetailReadModelsQueryVariables = {
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsQuery = {
  listReturnDetailReadModels?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnDetailReadModelsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsByBuyerQuery = {
  listReturnDetailReadModelsByBuyer?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnDetailReadModelsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsBySupplierQuery = {
  listReturnDetailReadModelsBySupplier?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnDetailReadModelsByBrandQueryVariables = {
  brand_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsByBrandQuery = {
  listReturnDetailReadModelsByBrand?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnDetailReadModelsByProductQueryVariables = {
  product_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsByProductQuery = {
  listReturnDetailReadModelsByProduct?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReturnDetailReadModelsByOrderQueryVariables = {
  order_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReturnDetailReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReturnDetailReadModelsByOrderQuery = {
  listReturnDetailReadModelsByOrder?:  {
    __typename: "ModelReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReturnDetailReadModelsQueryVariables = {
  filter?: SearchableReturnDetailReadModelFilterInput | null,
  sort?: SearchableReturnDetailReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReturnDetailReadModelsQuery = {
  searchReturnDetailReadModels?:  {
    __typename: "SearchableReturnDetailReadModelConnection",
    items:  Array< {
      __typename: "ReturnDetailReadModel",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      product_id?: string | null,
      product_name?: string | null,
      product_number?: string | null,
      sku?: string | null,
      product_type_id?: string | null,
      product_image_key?: string | null,
      color?: string | null,
      size?: string | null,
      type_name?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_request_due_date?: string | null,
      return_request_date?: string | null,
      return_due_date?: string | null,
      return_date?: string | null,
      order_quantity?: number | null,
      order_price?: number | null,
      wholesale_rate?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      order_tax?: number | null,
      tax_rate?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      status?: ReturnStatus | null,
      status_label?: string | null,
      return_destination?: ReturnDestination | null,
      return_destination_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      stripe_payment_id?: string | null,
      mf_transaction_id?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "OrderReadModel",
        id: string,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_image_key?: string | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        shipping_date?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        return_due_date?: string | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        order_quantity?: number | null,
        order_subtotal?: number | null,
        order_amount?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_amount?: number | null,
        report_approved_quantity?: number | null,
        report_approved_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        points?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        campaign_code?: string | null,
        cashback_amount?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        origin_order_id?: string | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        billing_count?: number | null,
        is_first?: boolean | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        is_preorder?: boolean | null,
        product_ids?: Array< string | null > | null,
        product_names?: Array< string | null > | null,
        product_numbers?: Array< string | null > | null,
        skus?: Array< string | null > | null,
        bill_payment_status?: BillPaymentStatus | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        details?:  {
          __typename: "ModelOrderDetailReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetContactReadModelQueryVariables = {
  id: string,
};

export type GetContactReadModelQuery = {
  getContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListContactReadModelsQueryVariables = {
  filter?: ModelContactReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactReadModelsQuery = {
  listContactReadModels?:  {
    __typename: "ModelContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactReadModelsBySupplierQueryVariables = {
  supplier_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactReadModelsBySupplierQuery = {
  listContactReadModelsBySupplier?:  {
    __typename: "ModelContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactReadModelsByOwnerQueryVariables = {
  owner?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactReadModelsByOwnerQuery = {
  listContactReadModelsByOwner?:  {
    __typename: "ModelContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactReadModelsByBuyerQueryVariables = {
  buyer_id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactReadModelsByBuyerQuery = {
  listContactReadModelsByBuyer?:  {
    __typename: "ModelContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContactReadModelsByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactReadModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactReadModelsByEmailQuery = {
  listContactReadModelsByEmail?:  {
    __typename: "ModelContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchContactReadModelsQueryVariables = {
  filter?: SearchableContactReadModelFilterInput | null,
  sort?: SearchableContactReadModelSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchContactReadModelsQuery = {
  searchContactReadModels?:  {
    __typename: "SearchableContactReadModelConnection",
    items:  Array< {
      __typename: "ContactReadModel",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      buyer_web_site_URL?: string | null,
      buyer_instagram_account?: string | null,
      buyer_company_name?: string | null,
      contact_list_id?: Array< string | null > | null,
      orderCount?: number | null,
      pageViewCount?: number | null,
      updatedAt: string,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetOrderEventQueryVariables = {
  id: string,
};

export type GetOrderEventQuery = {
  getOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrderEventsQueryVariables = {
  filter?: ModelOrderEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderEventsQuery = {
  listOrderEvents?:  {
    __typename: "ModelOrderEventConnection",
    items:  Array< {
      __typename: "OrderEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      order_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderEventsByEventQueryVariables = {
  event_name?: EventName | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderEventsByEventQuery = {
  listOrderEventsByEvent?:  {
    __typename: "ModelOrderEventConnection",
    items:  Array< {
      __typename: "OrderEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      order_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderProductEventQueryVariables = {
  id: string,
};

export type GetOrderProductEventQuery = {
  getOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrderProductEventsQueryVariables = {
  filter?: ModelOrderProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderProductEventsQuery = {
  listOrderProductEvents?:  {
    __typename: "ModelOrderProductEventConnection",
    items:  Array< {
      __typename: "OrderProductEvent",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderEventsByOrderQueryVariables = {
  order_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderEventsByOrderQuery = {
  listOrderEventsByOrder?:  {
    __typename: "ModelOrderEventConnection",
    items:  Array< {
      __typename: "OrderEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      order_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderProductEventsByOrderQueryVariables = {
  order_product_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderProductEventsByOrderQuery = {
  listOrderProductEventsByOrder?:  {
    __typename: "ModelOrderProductEventConnection",
    items:  Array< {
      __typename: "OrderProductEvent",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderProductEventsByEventQueryVariables = {
  event_name?: EventName | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderProductEventsByEventQuery = {
  listOrderProductEventsByEvent?:  {
    __typename: "ModelOrderProductEventConnection",
    items:  Array< {
      __typename: "OrderProductEvent",
      id: string,
      order_id?: string | null,
      order_product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandEventQueryVariables = {
  id: string,
};

export type GetBrandEventQuery = {
  getBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandEventsQueryVariables = {
  filter?: ModelBrandEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandEventsQuery = {
  listBrandEvents?:  {
    __typename: "ModelBrandEventConnection",
    items:  Array< {
      __typename: "BrandEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      brand_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBrandEventsByBrandQueryVariables = {
  brand_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandEventsByBrandQuery = {
  listBrandEventsByBrand?:  {
    __typename: "ModelBrandEventConnection",
    items:  Array< {
      __typename: "BrandEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      brand_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBrandEventsByEventQueryVariables = {
  event_name?: EventName | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBrandEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandEventsByEventQuery = {
  listBrandEventsByEvent?:  {
    __typename: "ModelBrandEventConnection",
    items:  Array< {
      __typename: "BrandEvent",
      id: string,
      event_name?: EventName | null,
      event_date: string,
      brand_id?: string | null,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductEventQueryVariables = {
  id: string,
};

export type GetProductEventQuery = {
  getProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductEventsQueryVariables = {
  filter?: ModelProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductEventsQuery = {
  listProductEvents?:  {
    __typename: "ModelProductEventConnection",
    items:  Array< {
      __typename: "ProductEvent",
      id: string,
      product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductEventsByProductQueryVariables = {
  product_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductEventsByProductQuery = {
  listProductEventsByProduct?:  {
    __typename: "ModelProductEventConnection",
    items:  Array< {
      __typename: "ProductEvent",
      id: string,
      product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductEventsByEventQueryVariables = {
  event_name?: EventName | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductEventsByEventQuery = {
  listProductEventsByEvent?:  {
    __typename: "ModelProductEventConnection",
    items:  Array< {
      __typename: "ProductEvent",
      id: string,
      product_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductTypeEventQueryVariables = {
  id: string,
};

export type GetProductTypeEventQuery = {
  getProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductTypeEventsQueryVariables = {
  filter?: ModelProductTypeEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypeEventsQuery = {
  listProductTypeEvents?:  {
    __typename: "ModelProductTypeEventConnection",
    items:  Array< {
      __typename: "ProductTypeEvent",
      id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypeEventsByProductQueryVariables = {
  product_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypeEventsByProductQuery = {
  listProductTypeEventsByProduct?:  {
    __typename: "ModelProductTypeEventConnection",
    items:  Array< {
      __typename: "ProductTypeEvent",
      id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypeEventsByProductTypeQueryVariables = {
  product_type_id?: string | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypeEventsByProductTypeQuery = {
  listProductTypeEventsByProductType?:  {
    __typename: "ModelProductTypeEventConnection",
    items:  Array< {
      __typename: "ProductTypeEvent",
      id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductTypeEventsByEventQueryVariables = {
  event_name?: EventName | null,
  event_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductTypeEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductTypeEventsByEventQuery = {
  listProductTypeEventsByEvent?:  {
    __typename: "ModelProductTypeEventConnection",
    items:  Array< {
      __typename: "ProductTypeEvent",
      id: string,
      product_id?: string | null,
      product_type_id?: string | null,
      event_name?: EventName | null,
      event_date: string,
      new_item?: string | null,
      old_item?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandNameQueryVariables = {
  brandId: string,
};

export type GetBrandNameQuery = {
  getBrandName?: string | null,
};

export type GetContentQueryVariables = {
  endpoint: string,
  contentId?: string | null,
  draftKey?: string | null,
};

export type GetContentQuery = {
  getContent?: string | null,
};

export type OnCreateMessageSubscriptionVariables = {
  thread_id: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  thread_id: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  thread_id: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBrandOrderSubscriptionVariables = {
  brand_owner: string,
};

export type OnCreateBrandOrderSubscription = {
  onCreateBrandOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateMessageRecipientSubscriptionVariables = {
  message_recipient: string,
};

export type OnCreateMessageRecipientSubscription = {
  onCreateMessageRecipient?:  {
    __typename: "Message",
    id: string,
    thread_id: string,
    message_owner?: string | null,
    message_recipient?: string | null,
    message_content: string,
    message_product_name?: string | null,
    message_is_open: boolean,
    message_files?:  Array< {
      __typename: "MessageFile",
      object_key: string,
      file_name: string,
      file_type: string,
    } | null > | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    thread?:  {
      __typename: "Thread",
      id: string,
      thread_buyer_owner: string,
      thread_buyer_name?: string | null,
      thread_brand_owner: string,
      thread_brand_id: string,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          thread_id: string,
          message_owner?: string | null,
          message_recipient?: string | null,
          message_content: string,
          message_product_name?: string | null,
          message_is_open: boolean,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    email?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    web_site_URL?: string | null,
    instagram_account?: string | null,
    zip_code?: string | null,
    location?: string | null,
    tel?: string | null,
    company_name?: string | null,
    term_approval_status?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    supplierInfo?:  {
      __typename: "ModelSupplierInfoConnection",
      items:  Array< {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateBuyerInfoSubscriptionVariables = {
};

export type OnCreateBuyerInfoSubscription = {
  onCreateBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateBuyerInfoSubscriptionVariables = {
};

export type OnUpdateBuyerInfoSubscription = {
  onUpdateBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteBuyerInfoSubscriptionVariables = {
};

export type OnDeleteBuyerInfoSubscription = {
  onDeleteBuyerInfo?:  {
    __typename: "BuyerInfo",
    id: string,
    account_id?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_id?: string | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_return_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    invoice_credit_facility?: number | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: Array< string | null > | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    referral_brand_id?: string | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    shop_images?:  {
      __typename: "ModelShopImageConnection",
      items:  Array< {
        __typename: "ShopImage",
        id: string,
        buyer_id: string,
        image_key?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    shipping_address?:  {
      __typename: "ShippingAddress",
      id: string,
      name: string,
      buyer_id: string,
      zip_code: string,
      prefecture: string,
      city: string,
      building?: string | null,
      phone_number: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    referralBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateShopImageSubscriptionVariables = {
};

export type OnCreateShopImageSubscription = {
  onCreateShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateShopImageSubscriptionVariables = {
};

export type OnUpdateShopImageSubscription = {
  onUpdateShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteShopImageSubscriptionVariables = {
};

export type OnDeleteShopImageSubscription = {
  onDeleteShopImage?:  {
    __typename: "ShopImage",
    id: string,
    buyer_id: string,
    image_key?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateIndividualSubscriptionVariables = {
};

export type OnCreateIndividualSubscription = {
  onCreateIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateIndividualSubscriptionVariables = {
};

export type OnUpdateIndividualSubscription = {
  onUpdateIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteIndividualSubscriptionVariables = {
};

export type OnDeleteIndividualSubscription = {
  onDeleteIndividual?:  {
    __typename: "Individual",
    brand_id: string,
    buyer_id: string,
    supplier_group_id?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "BrandReadModel",
      id: string,
      company_name?: string | null,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      createdAtTimestamp?: number | null,
      updatedAt?: string | null,
      updatedAtTimestamp?: number | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string > | null,
      tags?: Array< string > | null,
      brand_collections?: Array< string > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } > | null,
      featured_in?: Array< string > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      is_published?: boolean | null,
      firstPublishedAt?: string | null,
      firstPublishedAtTimestamp?: number | null,
      lastPublishedAt?: string | null,
      lastPublishedAtTimestamp?: number | null,
      category_page_id?: Array< string > | null,
      product_category?: Array< string > | null,
      product_subcategory?: Array< string > | null,
      average_shipping_days?: number | null,
      service_rate?: number | null,
      review_average?: number | null,
      conversion_rate?: number | null,
      sell_through_rate?: number | null,
      reviews?: number | null,
      items?: number | null,
      conversion_rate_tier?: number | null,
      sell_through_rate_tier?: number | null,
      service_rate_rate_tier?: number | null,
      average_shipping_days_tier?: number | null,
      minimum_tier?: number | null,
      items_tier?: number | null,
      contacts_tier?: number | null,
      supplier_group_id?: string | null,
      supplier_group_title?: string | null,
      supplier_group_description?: string | null,
      supplier_group_image_key?: string | null,
      supplier_group_published?: boolean | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      review?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      products?:  {
        __typename: "ModelProductReadModelConnection",
        items:  Array< {
          __typename: "ProductReadModel",
          id: string,
          product_name?: string | null,
          product_number?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_yomigana?: string | null,
          brand_image_key?: string | null,
          brand_published?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_individual_minimum_buy?: number | null,
          brand_individual_wholesale_rate?: number | null,
          brand_tags?: Array< string > | null,
          product_content?: string | null,
          product_description?: string | null,
          product_size?: string | null,
          product_owner?: string | null,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          origin_wholesale_price?: number | null,
          wholesale_rate?: number | null,
          wholesale_price?: number | null,
          individual_wholesale_rate?: number | null,
          individual_price?: number | null,
          individual_minimum_buy?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          is_published?: boolean | null,
          is_limited?: boolean | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          category_page_id?: Array< string > | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_preorder_shipping_date_timestamp?: number | null,
          product_types?: Array< string > | null,
          product_colors?: Array< string > | null,
          product_sizes?: Array< string > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          image_key?: string | null,
          image_keys?: Array< string > | null,
          video_keys?: Array< string > | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string > | null,
          protected_zip_code?: Array< string > | null,
          publishedAt?: string | null,
          publishedAtTimestamp?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          wholesale_rate_tier?: number | null,
          brand_tier?: number | null,
          brand_conversion_rate_tier?: number | null,
          brand_sell_through_rate_tier?: number | null,
          brand_service_rate_rate_tier?: number | null,
          brand_average_shipping_days_tier?: number | null,
          brand_minimum_tier?: number | null,
          brand_items_tier?: number | null,
          brand_contacts_tier?: number | null,
          collection_id?: Array< string | null > | null,
          sell_only_direct?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSupplierGroupSubscriptionVariables = {
};

export type OnCreateSupplierGroupSubscription = {
  onCreateSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSupplierGroupSubscriptionVariables = {
};

export type OnUpdateSupplierGroupSubscription = {
  onUpdateSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSupplierGroupSubscriptionVariables = {
};

export type OnDeleteSupplierGroupSubscription = {
  onDeleteSupplierGroup?:  {
    __typename: "SupplierGroup",
    id: string,
    title?: string | null,
    description?: string | null,
    image_key?: string | null,
    published: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    brands?:  {
      __typename: "ModelBrandReadModelConnection",
      items:  Array< {
        __typename: "BrandReadModel",
        id: string,
        company_name?: string | null,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string > | null,
        tags?: Array< string > | null,
        brand_collections?: Array< string > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } > | null,
        featured_in?: Array< string > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        is_published?: boolean | null,
        firstPublishedAt?: string | null,
        firstPublishedAtTimestamp?: number | null,
        lastPublishedAt?: string | null,
        lastPublishedAtTimestamp?: number | null,
        category_page_id?: Array< string > | null,
        product_category?: Array< string > | null,
        product_subcategory?: Array< string > | null,
        average_shipping_days?: number | null,
        service_rate?: number | null,
        review_average?: number | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        reviews?: number | null,
        items?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        service_rate_rate_tier?: number | null,
        average_shipping_days_tier?: number | null,
        minimum_tier?: number | null,
        items_tier?: number | null,
        contacts_tier?: number | null,
        supplier_group_id?: string | null,
        supplier_group_title?: string | null,
        supplier_group_description?: string | null,
        supplier_group_image_key?: string | null,
        supplier_group_published?: boolean | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        review?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        products?:  {
          __typename: "ModelProductReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSupplierInfoSubscriptionVariables = {
};

export type OnCreateSupplierInfoSubscription = {
  onCreateSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSupplierInfoSubscriptionVariables = {
};

export type OnUpdateSupplierInfoSubscription = {
  onUpdateSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSupplierInfoSubscriptionVariables = {
};

export type OnDeleteSupplierInfoSubscription = {
  onDeleteSupplierInfo?:  {
    __typename: "SupplierInfo",
    id: string,
    account_id: string,
    supplier_contract_type?: number | null,
    bank_name?: string | null,
    bank_branch_name?: string | null,
    bank_account_number?: string | null,
    bank_account_name?: string | null,
    bank_account_type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateShippingAddressSubscriptionVariables = {
};

export type OnCreateShippingAddressSubscription = {
  onCreateShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateShippingAddressSubscriptionVariables = {
};

export type OnUpdateShippingAddressSubscription = {
  onUpdateShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteShippingAddressSubscriptionVariables = {
};

export type OnDeleteShippingAddressSubscription = {
  onDeleteShippingAddress?:  {
    __typename: "ShippingAddress",
    id: string,
    name: string,
    buyer_id: string,
    zip_code: string,
    prefecture: string,
    city: string,
    building?: string | null,
    phone_number: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateEmailPreferencesSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEmailPreferencesSubscription = {
  onCreateEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmailPreferencesSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEmailPreferencesSubscription = {
  onUpdateEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmailPreferencesSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEmailPreferencesSubscription = {
  onDeleteEmailPreferences?:  {
    __typename: "EmailPreferences",
    id: string,
    new_arrivals?: boolean | null,
    sale_items?: boolean | null,
    bounced?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    product_name: string,
    product_number?: string | null,
    product_brand_id: string,
    product_content?: string | null,
    product_description?: string | null,
    product_owner: string,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    product_price?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    product_type?: string | null,
    product_color?: string | null,
    product_size?: string | null,
    product_public_status?: string | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_types?: Array< string | null > | null,
    product_colors?: Array< string | null > | null,
    product_sizes?: Array< string | null > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    publishedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sort_recommended?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string | null > | null,
    owner: string,
    contacts?:  {
      __typename: "ContactConnection",
      items?:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    imageKeys?:  {
      __typename: "ModelProductImageKeyConnection",
      items:  Array< {
        __typename: "ProductImageKey",
        id: string,
        product_id: string,
        imageKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    videoKeys?:  {
      __typename: "ModelProductVideoKeyConnection",
      items:  Array< {
        __typename: "ProductVideoKey",
        id: string,
        product_id: string,
        videoKey?: string | null,
        createdAt?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    producttype?:  {
      __typename: "ModelProductTypeConnection",
      items:  Array< {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    copiedBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProductImageKeySubscriptionVariables = {
};

export type OnCreateProductImageKeySubscription = {
  onCreateProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateProductImageKeySubscriptionVariables = {
};

export type OnUpdateProductImageKeySubscription = {
  onUpdateProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteProductImageKeySubscriptionVariables = {
};

export type OnDeleteProductImageKeySubscription = {
  onDeleteProductImageKey?:  {
    __typename: "ProductImageKey",
    id: string,
    product_id: string,
    imageKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateProductVideoKeySubscriptionVariables = {
};

export type OnCreateProductVideoKeySubscription = {
  onCreateProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateProductVideoKeySubscriptionVariables = {
};

export type OnUpdateProductVideoKeySubscription = {
  onUpdateProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteProductVideoKeySubscriptionVariables = {
};

export type OnDeleteProductVideoKeySubscription = {
  onDeleteProductVideoKey?:  {
    __typename: "ProductVideoKey",
    id: string,
    product_id: string,
    videoKey?: string | null,
    createdAt?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateProductTypeSubscriptionVariables = {
};

export type OnCreateProductTypeSubscription = {
  onCreateProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateProductTypeSubscriptionVariables = {
};

export type OnUpdateProductTypeSubscription = {
  onUpdateProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteProductTypeSubscriptionVariables = {
};

export type OnDeleteProductTypeSubscription = {
  onDeleteProductType?:  {
    __typename: "ProductType",
    id: string,
    product_id: string,
    imageKey?: string | null,
    type_name?: string | null,
    color?: string | null,
    size?: string | null,
    createdAt?: string | null,
    suspended?: boolean | null,
    published?: boolean | null,
    sku?: string | null,
    out_of_stock?: boolean | null,
    copied_product_type_id?: string | null,
    copied_brand_id?: string | null,
    owner: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    inventoryConnection?:  {
      __typename: "ModelInventoryConnection",
      items:  Array< {
        __typename: "Inventory",
        id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        inventory?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        logs?:  {
          __typename: "ModelInventoryLogConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateInventorySubscriptionVariables = {
};

export type OnCreateInventorySubscription = {
  onCreateInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateInventorySubscriptionVariables = {
};

export type OnUpdateInventorySubscription = {
  onUpdateInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteInventorySubscriptionVariables = {
};

export type OnDeleteInventorySubscription = {
  onDeleteInventory?:  {
    __typename: "Inventory",
    id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    inventory?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    logs?:  {
      __typename: "ModelInventoryLogConnection",
      items:  Array< {
        __typename: "InventoryLog",
        id: string,
        inventory_id: string,
        brand_id: string,
        product_id: string,
        product_type_id?: string | null,
        sku: string,
        stock_type: StockType,
        stock_mode: StockMode,
        stock_date: string,
        quantity: number,
        order_id?: string | null,
        order_product_id?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReturnInventoryLogSubscriptionVariables = {
};

export type OnCreateReturnInventoryLogSubscription = {
  onCreateReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReturnInventoryLogSubscriptionVariables = {
};

export type OnUpdateReturnInventoryLogSubscription = {
  onUpdateReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReturnInventoryLogSubscriptionVariables = {
};

export type OnDeleteReturnInventoryLogSubscription = {
  onDeleteReturnInventoryLog?:  {
    __typename: "ReturnInventoryLog",
    id: string,
    inventory_id?: string | null,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateInventoryLogSubscriptionVariables = {
};

export type OnCreateInventoryLogSubscription = {
  onCreateInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateInventoryLogSubscriptionVariables = {
};

export type OnUpdateInventoryLogSubscription = {
  onUpdateInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteInventoryLogSubscriptionVariables = {
};

export type OnDeleteInventoryLogSubscription = {
  onDeleteInventoryLog?:  {
    __typename: "InventoryLog",
    id: string,
    inventory_id: string,
    brand_id: string,
    product_id: string,
    product_type_id?: string | null,
    sku: string,
    stock_type: StockType,
    stock_mode: StockMode,
    stock_date: string,
    quantity: number,
    order_id?: string | null,
    order_product_id?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateInStockNotificationSubscriptionVariables = {
};

export type OnCreateInStockNotificationSubscription = {
  onCreateInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateInStockNotificationSubscriptionVariables = {
};

export type OnUpdateInStockNotificationSubscription = {
  onUpdateInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteInStockNotificationSubscriptionVariables = {
};

export type OnDeleteInStockNotificationSubscription = {
  onDeleteInStockNotification?:  {
    __typename: "InStockNotification",
    id: string,
    buyer_id?: string | null,
    brand_id?: string | null,
    product_id?: string | null,
    product_type_id?: string | null,
    completed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBrandSubscriptionVariables = {
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateBrandSubscriptionVariables = {
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteBrandSubscriptionVariables = {
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    brand_collections?: Array< string | null > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string | null > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } | null > | null,
    featured_in?: Array< string | null > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string | null > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    supplier_group_id?: string | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierGroup?:  {
      __typename: "SupplierGroup",
      id: string,
      title?: string | null,
      description?: string | null,
      image_key?: string | null,
      published: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      brands?:  {
        __typename: "ModelBrandReadModelConnection",
        items:  Array< {
          __typename: "BrandReadModel",
          id: string,
          company_name?: string | null,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          createdAtTimestamp?: number | null,
          updatedAt?: string | null,
          updatedAtTimestamp?: number | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string > | null,
          tags?: Array< string > | null,
          brand_collections?: Array< string > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          is_published?: boolean | null,
          firstPublishedAt?: string | null,
          firstPublishedAtTimestamp?: number | null,
          lastPublishedAt?: string | null,
          lastPublishedAtTimestamp?: number | null,
          category_page_id?: Array< string > | null,
          product_category?: Array< string > | null,
          product_subcategory?: Array< string > | null,
          average_shipping_days?: number | null,
          service_rate?: number | null,
          review_average?: number | null,
          conversion_rate?: number | null,
          sell_through_rate?: number | null,
          reviews?: number | null,
          items?: number | null,
          conversion_rate_tier?: number | null,
          sell_through_rate_tier?: number | null,
          service_rate_rate_tier?: number | null,
          average_shipping_days_tier?: number | null,
          minimum_tier?: number | null,
          items_tier?: number | null,
          contacts_tier?: number | null,
          supplier_group_id?: string | null,
          supplier_group_title?: string | null,
          supplier_group_description?: string | null,
          supplier_group_image_key?: string | null,
          supplier_group_published?: boolean | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    product?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    tag_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBrandCollectionSubscriptionVariables = {
};

export type OnCreateBrandCollectionSubscription = {
  onCreateBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandCollectionSubscriptionVariables = {
};

export type OnUpdateBrandCollectionSubscription = {
  onUpdateBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandCollectionSubscriptionVariables = {
};

export type OnDeleteBrandCollectionSubscription = {
  onDeleteBrandCollection?:  {
    __typename: "BrandCollection",
    id: string,
    collection_name?: string | null,
    visible?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateShopCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateShopCartSubscription = {
  onCreateShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShopCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateShopCartSubscription = {
  onUpdateShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShopCartSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteShopCartSubscription = {
  onDeleteShopCart?:  {
    __typename: "ShopCart",
    id?: string | null,
    shopcart_owner: string,
    shopcart_quantity: number,
    product_id: string,
    product_type_id?: string | null,
    brand_id?: string | null,
    is_direct?: boolean | null,
    is_pay_directly?: boolean | null,
    is_consign?: boolean | null,
    createdAt?: string | null,
    saved_for_later?: boolean | null,
    collection_id?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    producttype?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    order_owner?: string | null,
    brand_id?: string | null,
    brand_owner?: string | null,
    campaign_code?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owners: Array< string | null >,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    fee?: number | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax?: number | null,
    payment_term?: number | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    shipping_date?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    first_order?: boolean | null,
    order_points?: number | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    transferred_date?: string | null,
    order_price?: number | null,
    origin_order_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    cashback?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    billing_count?: number | null,
    return_due_date?: string | null,
    bill_payment_status?: BillPaymentStatus | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyer?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    originOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    chargeStatuses?:  {
      __typename: "ModelChargeStatusConnection",
      items:  Array< {
        __typename: "ChargeStatus",
        id: string,
        order_id: string,
        status: number,
        payment_id?: string | null,
        amount?: number | null,
        createdAt?: string | null,
        owners: Array< string | null >,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    orderproducts?:  {
      __typename: "ModelOrderProductConnection",
      items:  Array< {
        __typename: "OrderProduct",
        id: string,
        order_id: string,
        product_id: string,
        product_type_id?: string | null,
        order_product_quantity?: number | null,
        order_product_price?: number | null,
        order_product_wholesale_rate?: number | null,
        discount_percentage?: number | null,
        initial_order_product_quantity?: number | null,
        initial_order_product_price?: number | null,
        initial_order_product_wholesale_rate?: number | null,
        order_product_payment_status?: number | null,
        order_product_payment_method?: number | null,
        order_product_return_reason?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderstatus?:  {
          __typename: "ModelOrderStatusConnection",
          nextToken?: string | null,
        } | null,
        returnProducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnproducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateChargeStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateChargeStatusSubscription = {
  onCreateChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type OnUpdateChargeStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateChargeStatusSubscription = {
  onUpdateChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type OnDeleteChargeStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteChargeStatusSubscription = {
  onDeleteChargeStatus?:  {
    __typename: "ChargeStatus",
    id: string,
    order_id: string,
    status: number,
    payment_id?: string | null,
    amount?: number | null,
    createdAt?: string | null,
    owners: Array< string | null >,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateOrderProductSubscription = {
  onCreateOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrderProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateOrderProductSubscription = {
  onUpdateOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrderProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteOrderProductSubscription = {
  onDeleteOrderProduct?:  {
    __typename: "OrderProduct",
    id: string,
    order_id: string,
    product_id: string,
    product_type_id?: string | null,
    order_product_quantity?: number | null,
    order_product_price?: number | null,
    order_product_wholesale_rate?: number | null,
    discount_percentage?: number | null,
    initial_order_product_quantity?: number | null,
    initial_order_product_price?: number | null,
    initial_order_product_wholesale_rate?: number | null,
    order_product_payment_status?: number | null,
    order_product_payment_method?: number | null,
    order_product_return_reason?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderstatus?:  {
      __typename: "ModelOrderStatusConnection",
      items:  Array< {
        __typename: "OrderStatus",
        id: string,
        order_id?: string | null,
        order_product_id: string,
        quantity?: number | null,
        status?: number | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt?: string | null,
        orderProduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    returnProducts?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportProducts?:  {
      __typename: "ModelReportProductConnection",
      items:  Array< {
        __typename: "ReportProduct",
        id: string,
        type?: string | null,
        order_id: string,
        order_product_id: string,
        report_type?: string | null,
        request_type?: string | null,
        report_quantity?: number | null,
        report_product_price?: number | null,
        report_status?: string | null,
        report_comment?: string | null,
        replace_order_id?: string | null,
        replace_order_product_id?: string | null,
        approval_date?: string | null,
        approval_quantity?: number | null,
        image_keys?: Array< string | null > | null,
        stripe_refund_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replaceOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        replaceOrderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOrderStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateOrderStatusSubscription = {
  onCreateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateOrderStatusSubscription = {
  onUpdateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderStatusSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteOrderStatusSubscription = {
  onDeleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    order_id?: string | null,
    order_product_id: string,
    quantity?: number | null,
    status?: number | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt?: string | null,
    orderProduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReturnProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateReturnProductSubscription = {
  onCreateReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateReturnProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateReturnProductSubscription = {
  onUpdateReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteReturnProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteReturnProductSubscription = {
  onDeleteReturnProduct?:  {
    __typename: "ReturnProduct",
    id: string,
    return_product_id?: string | null,
    order_id: string,
    order_product_id: string,
    return_quantity?: number | null,
    return_product_price?: number | null,
    return_status?: number | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    logiless_inbound_delivery_code?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    children?:  {
      __typename: "ModelReturnProductConnection",
      items:  Array< {
        __typename: "ReturnProduct",
        id: string,
        return_product_id?: string | null,
        order_id: string,
        order_product_id: string,
        return_quantity?: number | null,
        return_product_price?: number | null,
        return_status?: number | null,
        stripe_payment_id?: string | null,
        mf_transaction_id?: string | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        logiless_inbound_delivery_code?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        orderproduct?:  {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        children?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateReportProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateReportProductSubscription = {
  onCreateReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReportProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateReportProductSubscription = {
  onUpdateReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReportProductSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteReportProductSubscription = {
  onDeleteReportProduct?:  {
    __typename: "ReportProduct",
    id: string,
    type?: string | null,
    order_id: string,
    order_product_id: string,
    report_type?: string | null,
    request_type?: string | null,
    report_quantity?: number | null,
    report_product_price?: number | null,
    report_status?: string | null,
    report_comment?: string | null,
    replace_order_id?: string | null,
    replace_order_product_id?: string | null,
    approval_date?: string | null,
    approval_quantity?: number | null,
    image_keys?: Array< string | null > | null,
    stripe_refund_id?: string | null,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replaceOrder?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    replaceOrderproduct?:  {
      __typename: "OrderProduct",
      id: string,
      order_id: string,
      product_id: string,
      product_type_id?: string | null,
      order_product_quantity?: number | null,
      order_product_price?: number | null,
      order_product_wholesale_rate?: number | null,
      discount_percentage?: number | null,
      initial_order_product_quantity?: number | null,
      initial_order_product_price?: number | null,
      initial_order_product_wholesale_rate?: number | null,
      order_product_payment_status?: number | null,
      order_product_payment_method?: number | null,
      order_product_return_reason?: string | null,
      owners: Array< string | null >,
      createdAt?: string | null,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id: string,
        product_id: string,
        imageKey?: string | null,
        type_name?: string | null,
        color?: string | null,
        size?: string | null,
        createdAt?: string | null,
        suspended?: boolean | null,
        published?: boolean | null,
        sku?: string | null,
        out_of_stock?: boolean | null,
        copied_product_type_id?: string | null,
        copied_brand_id?: string | null,
        owner: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      order?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      orderstatus?:  {
        __typename: "ModelOrderStatusConnection",
        items:  Array< {
          __typename: "OrderStatus",
          id: string,
          order_id?: string | null,
          order_product_id: string,
          quantity?: number | null,
          status?: number | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnProducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateFeedbackSubscriptionVariables = {
};

export type OnCreateFeedbackSubscription = {
  onCreateFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateFeedbackSubscriptionVariables = {
};

export type OnUpdateFeedbackSubscription = {
  onUpdateFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteFeedbackSubscriptionVariables = {
};

export type OnDeleteFeedbackSubscription = {
  onDeleteFeedback?:  {
    __typename: "Feedback",
    id: string,
    order_id: string,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrderEditReasonSubscriptionVariables = {
};

export type OnCreateOrderEditReasonSubscription = {
  onCreateOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderEditReasonSubscriptionVariables = {
};

export type OnUpdateOrderEditReasonSubscription = {
  onUpdateOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderEditReasonSubscriptionVariables = {
};

export type OnDeleteOrderEditReasonSubscription = {
  onDeleteOrderEditReason?:  {
    __typename: "OrderEditReason",
    id: string,
    order_id: string,
    edit_reason?: EditReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrderCancelReasonSubscriptionVariables = {
};

export type OnCreateOrderCancelReasonSubscription = {
  onCreateOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderCancelReasonSubscriptionVariables = {
};

export type OnUpdateOrderCancelReasonSubscription = {
  onUpdateOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderCancelReasonSubscriptionVariables = {
};

export type OnDeleteOrderCancelReasonSubscription = {
  onDeleteOrderCancelReason?:  {
    __typename: "OrderCancelReason",
    id: string,
    order_id: string,
    cancel_reason?: CancelReason | null,
    feedback_comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateThreadSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateThreadSubscription = {
  onCreateThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateThreadSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateThreadSubscription = {
  onUpdateThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteThreadSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteThreadSubscription = {
  onDeleteThread?:  {
    __typename: "Thread",
    id: string,
    thread_buyer_owner: string,
    thread_buyer_name?: string | null,
    thread_brand_owner: string,
    thread_brand_id: string,
    owners: Array< string | null >,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        thread_id: string,
        message_owner?: string | null,
        message_recipient?: string | null,
        message_content: string,
        message_product_name?: string | null,
        message_is_open: boolean,
        message_files?:  Array< {
          __typename: "MessageFile",
          object_key: string,
          file_name: string,
          file_type: string,
        } | null > | null,
        owners: Array< string | null >,
        createdAt?: string | null,
        updatedAt: string,
        thread?:  {
          __typename: "Thread",
          id: string,
          thread_buyer_owner: string,
          thread_buyer_name?: string | null,
          thread_brand_owner: string,
          thread_brand_id: string,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreatePickupProductSubscriptionVariables = {
};

export type OnCreatePickupProductSubscription = {
  onCreatePickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePickupProductSubscriptionVariables = {
};

export type OnUpdatePickupProductSubscription = {
  onUpdatePickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePickupProductSubscriptionVariables = {
};

export type OnDeletePickupProductSubscription = {
  onDeletePickupProduct?:  {
    __typename: "PickupProduct",
    id: string,
    pickup_list_id: string,
    brand_id: string,
    product_id: string,
    display_order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreatePickupBrandsSubscriptionVariables = {
};

export type OnCreatePickupBrandsSubscription = {
  onCreatePickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePickupBrandsSubscriptionVariables = {
};

export type OnUpdatePickupBrandsSubscription = {
  onUpdatePickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePickupBrandsSubscriptionVariables = {
};

export type OnDeletePickupBrandsSubscription = {
  onDeletePickupBrands?:  {
    __typename: "PickupBrands",
    id: string,
    title: string,
    brands?:  Array< {
      __typename: "PickupBrand",
      brand_id: string,
      display_order?: number | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    display_num?: number | null,
    visible?: boolean | null,
    item_type?: PickupItemType | null,
    display_size?: number | null,
    createdAt: string,
    updatedAt: string,
    products?:  {
      __typename: "ModelPickupProductConnection",
      items:  Array< {
        __typename: "PickupProduct",
        id: string,
        pickup_list_id: string,
        brand_id: string,
        product_id: string,
        display_order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateMenuSubscriptionVariables = {
};

export type OnCreateMenuSubscription = {
  onCreateMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMenuSubscriptionVariables = {
};

export type OnUpdateMenuSubscription = {
  onUpdateMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMenuSubscriptionVariables = {
};

export type OnDeleteMenuSubscription = {
  onDeleteMenu?:  {
    __typename: "Menu",
    id: string,
    title: string,
    product_list_path: string,
    brand_list_path?: string | null,
    search_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    sub_categories?:  Array< {
      __typename: "Category",
      name: string,
      path?: string | null,
    } | null > | null,
    display_order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFavoriteBrandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFavoriteBrandSubscription = {
  onCreateFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateFavoriteBrandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFavoriteBrandSubscription = {
  onUpdateFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteFavoriteBrandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFavoriteBrandSubscription = {
  onDeleteFavoriteBrand?:  {
    __typename: "FavoriteBrand",
    id: string,
    account_id: string,
    brand_id: string,
    createdAt?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFavoriteProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFavoriteProductSubscription = {
  onCreateFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnUpdateFavoriteProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFavoriteProductSubscription = {
  onUpdateFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnDeleteFavoriteProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFavoriteProductSubscription = {
  onDeleteFavoriteProduct?:  {
    __typename: "FavoriteProduct",
    id: string,
    account_id: string,
    favorite_list_id?: string | null,
    product_id: string,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
    favorite_list?:  {
      __typename: "FavoriteProductList",
      id: string,
      account_id: string,
      list_name?: string | null,
      order?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      products?:  {
        __typename: "ModelFavoriteProductConnection",
        items:  Array< {
          __typename: "FavoriteProduct",
          id: string,
          account_id: string,
          favorite_list_id?: string | null,
          product_id: string,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnCreateFavoriteProductListSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFavoriteProductListSubscription = {
  onCreateFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateFavoriteProductListSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFavoriteProductListSubscription = {
  onUpdateFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteFavoriteProductListSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFavoriteProductListSubscription = {
  onDeleteFavoriteProductList?:  {
    __typename: "FavoriteProductList",
    id: string,
    account_id: string,
    list_name?: string | null,
    order?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    products?:  {
      __typename: "ModelFavoriteProductConnection",
      items:  Array< {
        __typename: "FavoriteProduct",
        id: string,
        account_id: string,
        favorite_list_id?: string | null,
        product_id: string,
        order?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        owner?: string | null,
        favorite_list?:  {
          __typename: "FavoriteProductList",
          id: string,
          account_id: string,
          list_name?: string | null,
          order?: number | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCollectionSubscriptionVariables = {
};

export type OnCreateCollectionSubscription = {
  onCreateCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCollectionSubscriptionVariables = {
};

export type OnUpdateCollectionSubscription = {
  onUpdateCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCollectionSubscriptionVariables = {
};

export type OnDeleteCollectionSubscription = {
  onDeleteCollection?:  {
    __typename: "Collection",
    id: string,
    brand_id: string,
    src_brand_id?: string | null,
    name: string,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner: string,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    display_order?: number | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    srcBrand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productCollections?:  {
      __typename: "ModelProductCollectionConnection",
      items:  Array< {
        __typename: "ProductCollection",
        id: string,
        collection_id: string,
        product_id: string,
        product_type_id?: string | null,
        quantity: number,
        sort_order?: number | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        collection?:  {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProductCollectionSubscriptionVariables = {
};

export type OnCreateProductCollectionSubscription = {
  onCreateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateProductCollectionSubscriptionVariables = {
};

export type OnUpdateProductCollectionSubscription = {
  onUpdateProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteProductCollectionSubscriptionVariables = {
};

export type OnDeleteProductCollectionSubscription = {
  onDeleteProductCollection?:  {
    __typename: "ProductCollection",
    id: string,
    collection_id: string,
    product_id: string,
    product_type_id?: string | null,
    quantity: number,
    sort_order?: number | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    collection?:  {
      __typename: "Collection",
      id: string,
      brand_id: string,
      src_brand_id?: string | null,
      name: string,
      description?: string | null,
      image_key?: string | null,
      video_key?: string | null,
      published?: boolean | null,
      createdAt?: string | null,
      owner: string,
      preorder?: boolean | null,
      is_consign?: boolean | null,
      season?: string | null,
      season_year?: number | null,
      display_order?: number | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      srcBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      productCollections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReferralSubscriptionVariables = {
  owner?: string | null,
  referral_account_id?: string | null,
};

export type OnCreateReferralSubscription = {
  onCreateReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReferralSubscriptionVariables = {
  owner?: string | null,
  referral_account_id?: string | null,
};

export type OnUpdateReferralSubscription = {
  onUpdateReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReferralSubscriptionVariables = {
  owner?: string | null,
  referral_account_id?: string | null,
};

export type OnDeleteReferralSubscription = {
  onDeleteReferral?:  {
    __typename: "Referral",
    id: string,
    referral_type: number,
    referral_email?: string | null,
    referral_name?: string | null,
    referral_website?: string | null,
    referral_domain?: string | null,
    referral_by: string,
    referral_status: number,
    referral_brand_id?: string | null,
    referral_account_id?: string | null,
    createdAt: string,
    owner?: string | null,
    updatedAt: string,
    ownerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReferralMessageSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateReferralMessageSubscription = {
  onCreateReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReferralMessageSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateReferralMessageSubscription = {
  onUpdateReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReferralMessageSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteReferralMessageSubscription = {
  onDeleteReferralMessage?:  {
    __typename: "ReferralMessage",
    id: string,
    from: string,
    subject: string,
    message: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePointSubscriptionVariables = {
  account_id?: string | null,
};

export type OnCreatePointSubscription = {
  onCreatePoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePointSubscriptionVariables = {
  account_id?: string | null,
};

export type OnUpdatePointSubscription = {
  onUpdatePoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePointSubscriptionVariables = {
  account_id?: string | null,
};

export type OnDeletePointSubscription = {
  onDeletePoint?:  {
    __typename: "Point",
    id: string,
    account_id: string,
    points: number,
    point_type: number,
    order_id?: string | null,
    referral_id?: string | null,
    referral_brand_id?: string | null,
    createdAt: string,
    duration: number,
    review_id?: string | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    referral?:  {
      __typename: "Referral",
      id: string,
      referral_type: number,
      referral_email?: string | null,
      referral_name?: string | null,
      referral_website?: string | null,
      referral_domain?: string | null,
      referral_by: string,
      referral_status: number,
      referral_brand_id?: string | null,
      referral_account_id?: string | null,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      ownerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    history?:  {
      __typename: "ModelPointHistoryConnection",
      items:  Array< {
        __typename: "PointHistory",
        id: string,
        point_id: string,
        account_id: string,
        points: number,
        history_type: number,
        order_id?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreatePointHistorySubscriptionVariables = {
  account_id?: string | null,
};

export type OnCreatePointHistorySubscription = {
  onCreatePointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePointHistorySubscriptionVariables = {
  account_id?: string | null,
};

export type OnUpdatePointHistorySubscription = {
  onUpdatePointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePointHistorySubscriptionVariables = {
  account_id?: string | null,
};

export type OnDeletePointHistorySubscription = {
  onDeletePointHistory?:  {
    __typename: "PointHistory",
    id: string,
    point_id: string,
    account_id: string,
    points: number,
    history_type: number,
    order_id?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateContactSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateContactSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteContactSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "Contact",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    updatedAt: string,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    buyerInfo?:  {
      __typename: "BuyerInfo",
      id: string,
      account_id?: string | null,
      billing_close_day?: number | null,
      buyer_brand?: string | null,
      buyer_credit?: number | null,
      buyer_id?: string | null,
      buyer_kyc_status?: number | null,
      buyer_product_category?: string | null,
      buyer_rating?: number | null,
      buyer_return_credit?: number | null,
      buyer_return_limit_per_brand?: number | null,
      buyer_type?: string | null,
      invoice_credit_facility?: number | null,
      item_category?: Array< string | null > | null,
      metadata?: string | null,
      mf_customer_id?: string | null,
      mf_destination_id?: string | null,
      mf_examination_status?: string | null,
      owner?: Array< string | null > | null,
      owner_name?: string | null,
      payment_type?: number | null,
      primary_payment_method_id?: string | null,
      primary_payment_term?: number | null,
      primary_shipping_address_id?: string | null,
      referral_brand_id?: string | null,
      shop_name?: string | null,
      store_category?: string | null,
      stripe_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      shop_images?:  {
        __typename: "ModelShopImageConnection",
        items:  Array< {
          __typename: "ShopImage",
          id: string,
          buyer_id: string,
          image_key?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      shipping_address?:  {
        __typename: "ShippingAddress",
        id: string,
        name: string,
        buyer_id: string,
        zip_code: string,
        prefecture: string,
        city: string,
        building?: string | null,
        phone_number: string,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      referralBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactListConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateContactListSubscriptionVariables = {
};

export type OnCreateContactListSubscription = {
  onCreateContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateContactListSubscriptionVariables = {
};

export type OnUpdateContactListSubscription = {
  onUpdateContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteContactListSubscriptionVariables = {
};

export type OnDeleteContactListSubscription = {
  onDeleteContactList?:  {
    __typename: "ContactList",
    id: string,
    list_name: string,
    supplier_id: string,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contactConnections?:  {
      __typename: "ModelContactListConnectionConnection",
      items:  Array< {
        __typename: "ContactListConnection",
        id: string,
        supplier_id: string,
        contact_list_id: string,
        contact_id: string,
        createdAt: string,
        updatedAt: string,
        owner: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        contactList?:  {
          __typename: "ContactList",
          id: string,
          list_name: string,
          supplier_id: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateContactListConnectionSubscriptionVariables = {
};

export type OnCreateContactListConnectionSubscription = {
  onCreateContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateContactListConnectionSubscriptionVariables = {
};

export type OnUpdateContactListConnectionSubscription = {
  onUpdateContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteContactListConnectionSubscriptionVariables = {
};

export type OnDeleteContactListConnectionSubscription = {
  onDeleteContactListConnection?:  {
    __typename: "ContactListConnection",
    id: string,
    supplier_id: string,
    contact_list_id: string,
    contact_id: string,
    createdAt: string,
    updatedAt: string,
    owner: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contactList?:  {
      __typename: "ContactList",
      id: string,
      list_name: string,
      supplier_id: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
      owner: string,
      contactConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateContactActivitySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateContactActivitySubscription = {
  onCreateContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateContactActivitySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateContactActivitySubscription = {
  onUpdateContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteContactActivitySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteContactActivitySubscription = {
  onDeleteContactActivity?:  {
    __typename: "ContactActivity",
    id: string,
    contact_id: string,
    activity_date: string,
    activity_type?: string | null,
    order_id?: string | null,
    message_id?: string | null,
    campaign_id?: string | null,
    activity?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCampaignSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCampaignSubscription = {
  onCreateCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCampaignSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCampaignSubscription = {
  onUpdateCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCampaignSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCampaignSubscription = {
  onDeleteCampaign?:  {
    __typename: "Campaign",
    id: string,
    supplier_id: string,
    brand_id: string,
    campaign_sender?: string | null,
    campaign_from?: string | null,
    campaign_title?: string | null,
    campaign_status?: string | null,
    campaign_subject?: string | null,
    preview_text?: string | null,
    campaign_template?: string | null,
    campaign_message?: string | null,
    campaign_components?: string | null,
    campaign_style?: string | null,
    campaign_segments?: Array< string | null > | null,
    deliveryTime?: string | null,
    sentAt?: string | null,
    createdAt: string,
    owner: string,
    stats?:  {
      __typename: "CampaignStats",
      total?: number | null,
      sent?: number | null,
      delivered?: number | null,
      unsent?: number | null,
      open?: number | null,
      click?: number | null,
      bounce?: number | null,
      unsubscribe?: number | null,
      clickRate?: number | null,
      openRate?: number | null,
    } | null,
    updatedAt: string,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    contacts?:  {
      __typename: "ModelCampaignContactConnection",
      items:  Array< {
        __typename: "CampaignContact",
        id: string,
        supplier_id: string,
        campaign_id?: string | null,
        contact_id?: string | null,
        email?: string | null,
        source?: string | null,
        contact_list_id?: string | null,
        status?: string | null,
        clicks?: number | null,
        views?: number | null,
        sentAt?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
        contact?:  {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCampaignContactSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCampaignContactSubscription = {
  onCreateCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCampaignContactSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCampaignContactSubscription = {
  onUpdateCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCampaignContactSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCampaignContactSubscription = {
  onDeleteCampaignContact?:  {
    __typename: "CampaignContact",
    id: string,
    supplier_id: string,
    campaign_id?: string | null,
    contact_id?: string | null,
    email?: string | null,
    source?: string | null,
    contact_list_id?: string | null,
    status?: string | null,
    clicks?: number | null,
    views?: number | null,
    sentAt?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      supplier_id: string,
      buyer_id?: string | null,
      buyer_owner?: string | null,
      email?: string | null,
      store_name?: string | null,
      contact_name?: string | null,
      tel?: string | null,
      zipcode?: string | null,
      address?: string | null,
      discount_rate?: number | null,
      contact_status?: string | null,
      contact_source?: string | null,
      direct_payment?: boolean | null,
      contacted?: boolean | null,
      signedup?: boolean | null,
      ordered?: boolean | null,
      invited?: boolean | null,
      createdAt: string,
      owner: string,
      updatedAt: string,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      buyerInfo?:  {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierInfo?:  {
        __typename: "SupplierInfo",
        id: string,
        account_id: string,
        supplier_contract_type?: number | null,
        bank_name?: string | null,
        bank_branch_name?: string | null,
        bank_account_number?: string | null,
        bank_account_name?: string | null,
        bank_account_type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      contactListConnections?:  {
        __typename: "ModelContactListConnectionConnection",
        items:  Array< {
          __typename: "ContactListConnection",
          id: string,
          supplier_id: string,
          contact_list_id: string,
          contact_id: string,
          createdAt: string,
          updatedAt: string,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      activities?:  {
        __typename: "ModelContactActivityConnection",
        items:  Array< {
          __typename: "ContactActivity",
          id: string,
          contact_id: string,
          activity_date: string,
          activity_type?: string | null,
          order_id?: string | null,
          message_id?: string | null,
          campaign_id?: string | null,
          activity?: string | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViews?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      pageViewsByEmail?:  {
        __typename: "ModelPageViewConnection",
        items:  Array< {
          __typename: "PageView",
          brand_id: string,
          buyer_id: string,
          email?: string | null,
          page_view?: number | null,
          owner?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      supplier_id: string,
      brand_id: string,
      campaign_sender?: string | null,
      campaign_from?: string | null,
      campaign_title?: string | null,
      campaign_status?: string | null,
      campaign_subject?: string | null,
      preview_text?: string | null,
      campaign_template?: string | null,
      campaign_message?: string | null,
      campaign_components?: string | null,
      campaign_style?: string | null,
      campaign_segments?: Array< string | null > | null,
      deliveryTime?: string | null,
      sentAt?: string | null,
      createdAt: string,
      owner: string,
      stats?:  {
        __typename: "CampaignStats",
        total?: number | null,
        sent?: number | null,
        delivered?: number | null,
        unsent?: number | null,
        open?: number | null,
        click?: number | null,
        bounce?: number | null,
        unsubscribe?: number | null,
        clickRate?: number | null,
        openRate?: number | null,
      } | null,
      updatedAt: string,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      contacts?:  {
        __typename: "ModelCampaignContactConnection",
        items:  Array< {
          __typename: "CampaignContact",
          id: string,
          supplier_id: string,
          campaign_id?: string | null,
          contact_id?: string | null,
          email?: string | null,
          source?: string | null,
          contact_list_id?: string | null,
          status?: string | null,
          clicks?: number | null,
          views?: number | null,
          sentAt?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateDailySessionSubscriptionVariables = {
};

export type OnCreateDailySessionSubscription = {
  onCreateDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDailySessionSubscriptionVariables = {
};

export type OnUpdateDailySessionSubscription = {
  onUpdateDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDailySessionSubscriptionVariables = {
};

export type OnDeleteDailySessionSubscription = {
  onDeleteDailySession?:  {
    __typename: "DailySession",
    brand_id: string,
    session_date: string,
    session_count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConversionRateSubscriptionVariables = {
};

export type OnCreateConversionRateSubscription = {
  onCreateConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversionRateSubscriptionVariables = {
};

export type OnUpdateConversionRateSubscription = {
  onUpdateConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConversionRateSubscriptionVariables = {
};

export type OnDeleteConversionRateSubscription = {
  onDeleteConversionRate?:  {
    __typename: "ConversionRate",
    brand_id: string,
    order?: number | null,
    click?: number | null,
    conversion?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePageViewSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePageViewSubscription = {
  onCreatePageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePageViewSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePageViewSubscription = {
  onUpdatePageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePageViewSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePageViewSubscription = {
  onDeletePageView?:  {
    __typename: "PageView",
    brand_id: string,
    buyer_id: string,
    email?: string | null,
    page_view?: number | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    brand_id: string,
    product_ids?: Array< string | null > | null,
    buyer_id: string,
    order_id: string,
    rate?: number | null,
    title?: string | null,
    comment?: string | null,
    remove_flag?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    buyerInfo?:  {
      __typename: "ModelBuyerInfoConnection",
      items:  Array< {
        __typename: "BuyerInfo",
        id: string,
        account_id?: string | null,
        billing_close_day?: number | null,
        buyer_brand?: string | null,
        buyer_credit?: number | null,
        buyer_id?: string | null,
        buyer_kyc_status?: number | null,
        buyer_product_category?: string | null,
        buyer_rating?: number | null,
        buyer_return_credit?: number | null,
        buyer_return_limit_per_brand?: number | null,
        buyer_type?: string | null,
        invoice_credit_facility?: number | null,
        item_category?: Array< string | null > | null,
        metadata?: string | null,
        mf_customer_id?: string | null,
        mf_destination_id?: string | null,
        mf_examination_status?: string | null,
        owner?: Array< string | null > | null,
        owner_name?: string | null,
        payment_type?: number | null,
        primary_payment_method_id?: string | null,
        primary_payment_term?: number | null,
        primary_shipping_address_id?: string | null,
        referral_brand_id?: string | null,
        shop_name?: string | null,
        store_category?: string | null,
        stripe_id?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        shop_images?:  {
          __typename: "ModelShopImageConnection",
          nextToken?: string | null,
        } | null,
        shipping_address?:  {
          __typename: "ShippingAddress",
          id: string,
          name: string,
          buyer_id: string,
          zip_code: string,
          prefecture: string,
          city: string,
          building?: string | null,
          phone_number: string,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        referralBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "Order",
      id: string,
      order_owner?: string | null,
      brand_id?: string | null,
      brand_owner?: string | null,
      campaign_code?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owners: Array< string | null >,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      fee?: number | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax?: number | null,
      payment_term?: number | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      shipping_date?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      first_order?: boolean | null,
      order_points?: number | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      transferred_date?: string | null,
      order_price?: number | null,
      origin_order_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      cashback?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      billing_count?: number | null,
      return_due_date?: string | null,
      bill_payment_status?: BillPaymentStatus | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyer?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      originOrder?:  {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      chargeStatuses?:  {
        __typename: "ModelChargeStatusConnection",
        items:  Array< {
          __typename: "ChargeStatus",
          id: string,
          order_id: string,
          status: number,
          payment_id?: string | null,
          amount?: number | null,
          createdAt?: string | null,
          owners: Array< string | null >,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      orderproducts?:  {
        __typename: "ModelOrderProductConnection",
        items:  Array< {
          __typename: "OrderProduct",
          id: string,
          order_id: string,
          product_id: string,
          product_type_id?: string | null,
          order_product_quantity?: number | null,
          order_product_price?: number | null,
          order_product_wholesale_rate?: number | null,
          discount_percentage?: number | null,
          initial_order_product_quantity?: number | null,
          initial_order_product_price?: number | null,
          initial_order_product_wholesale_rate?: number | null,
          order_product_payment_status?: number | null,
          order_product_payment_method?: number | null,
          order_product_return_reason?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      returnproducts?:  {
        __typename: "ModelReturnProductConnection",
        items:  Array< {
          __typename: "ReturnProduct",
          id: string,
          return_product_id?: string | null,
          order_id: string,
          order_product_id: string,
          return_quantity?: number | null,
          return_product_price?: number | null,
          return_status?: number | null,
          stripe_payment_id?: string | null,
          mf_transaction_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          logiless_inbound_delivery_code?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reportProducts?:  {
        __typename: "ModelReportProductConnection",
        items:  Array< {
          __typename: "ReportProduct",
          id: string,
          type?: string | null,
          order_id: string,
          order_product_id: string,
          report_type?: string | null,
          request_type?: string | null,
          report_quantity?: number | null,
          report_product_price?: number | null,
          report_status?: string | null,
          report_comment?: string | null,
          replace_order_id?: string | null,
          replace_order_product_id?: string | null,
          approval_date?: string | null,
          approval_quantity?: number | null,
          image_keys?: Array< string | null > | null,
          stripe_refund_id?: string | null,
          owners: Array< string | null >,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      editReasons?:  {
        __typename: "ModelOrderEditReasonConnection",
        items:  Array< {
          __typename: "OrderEditReason",
          id: string,
          order_id: string,
          edit_reason?: EditReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      cancelReasons?:  {
        __typename: "ModelOrderCancelReasonConnection",
        items:  Array< {
          __typename: "OrderCancelReason",
          id: string,
          order_id: string,
          cancel_reason?: CancelReason | null,
          feedback_comment?: string | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    replies?:  {
      __typename: "ModelReviewReplyConnection",
      items:  Array< {
        __typename: "ReviewReply",
        id: string,
        review_id: string,
        brand_id: string,
        comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateReviewReplySubscriptionVariables = {
};

export type OnCreateReviewReplySubscription = {
  onCreateReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewReplySubscriptionVariables = {
};

export type OnUpdateReviewReplySubscription = {
  onUpdateReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewReplySubscriptionVariables = {
};

export type OnDeleteReviewReplySubscription = {
  onDeleteReviewReply?:  {
    __typename: "ReviewReply",
    id: string,
    review_id: string,
    brand_id: string,
    comment?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCouponSubscriptionVariables = {
};

export type OnCreateCouponSubscription = {
  onCreateCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCouponSubscriptionVariables = {
};

export type OnUpdateCouponSubscription = {
  onUpdateCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCouponSubscriptionVariables = {
};

export type OnDeleteCouponSubscription = {
  onDeleteCoupon?:  {
    __typename: "Coupon",
    id: string,
    coupon_name?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    brand_ids?: Array< string | null > | null,
    discount_type?: DiscountType | null,
    discount_value?: number | null,
    maximum_amount?: number | null,
    available_times?: number | null,
    duration?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    logs?:  {
      __typename: "ModelCouponLogConnection",
      items:  Array< {
        __typename: "CouponLog",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        order_ids?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    buyers?:  {
      __typename: "ModelBuyerCouponConnection",
      items:  Array< {
        __typename: "BuyerCoupon",
        id: string,
        coupon_id?: string | null,
        buyer_id?: string | null,
        max_redemptions?: number | null,
        times_redeemed?: number | null,
        redemptions_left?: number | null,
        expiresAt?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCouponLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCouponLogSubscription = {
  onCreateCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCouponLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCouponLogSubscription = {
  onUpdateCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCouponLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCouponLogSubscription = {
  onDeleteCouponLog?:  {
    __typename: "CouponLog",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_ids?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBuyerCouponSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnCreateBuyerCouponSubscription = {
  onCreateBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateBuyerCouponSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnUpdateBuyerCouponSubscription = {
  onUpdateBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteBuyerCouponSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnDeleteBuyerCouponSubscription = {
  onDeleteBuyerCoupon?:  {
    __typename: "BuyerCoupon",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    max_redemptions?: number | null,
    times_redeemed?: number | null,
    redemptions_left?: number | null,
    expiresAt?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCashbackSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnCreateCashbackSubscription = {
  onCreateCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateCashbackSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnUpdateCashbackSubscription = {
  onUpdateCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteCashbackSubscriptionVariables = {
  owner?: string | null,
  buyer_id?: string | null,
};

export type OnDeleteCashbackSubscription = {
  onDeleteCashback?:  {
    __typename: "Cashback",
    id: string,
    coupon_id?: string | null,
    buyer_id?: string | null,
    order_id?: Array< string | null > | null,
    redeemed?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    account?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateStockProductSubscriptionVariables = {
};

export type OnCreateStockProductSubscription = {
  onCreateStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStockProductSubscriptionVariables = {
};

export type OnUpdateStockProductSubscription = {
  onUpdateStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStockProductSubscriptionVariables = {
};

export type OnDeleteStockProductSubscription = {
  onDeleteStockProduct?:  {
    __typename: "StockProduct",
    id: string,
    stock_id: string,
    src_product_id: string,
    src_product_type_id?: string | null,
    src_brand_id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLogilessAuthTokenSubscriptionVariables = {
};

export type OnCreateLogilessAuthTokenSubscription = {
  onCreateLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateLogilessAuthTokenSubscriptionVariables = {
};

export type OnUpdateLogilessAuthTokenSubscription = {
  onUpdateLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteLogilessAuthTokenSubscriptionVariables = {
};

export type OnDeleteLogilessAuthTokenSubscription = {
  onDeleteLogilessAuthToken?:  {
    __typename: "LogilessAuthToken",
    id: string,
    logiless_client_id: string,
    access_token: string,
    refresh_token: string,
    expires_at: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateBuyerReadModelSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateBuyerReadModelSubscription = {
  onCreateBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateBuyerReadModelSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateBuyerReadModelSubscription = {
  onUpdateBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteBuyerReadModelSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteBuyerReadModelSubscription = {
  onDeleteBuyerReadModel?:  {
    __typename: "BuyerReadModel",
    id: string,
    account_company_name?: string | null,
    account_first_name?: string | null,
    account_id?: string | null,
    account_instagram_account?: string | null,
    account_last_name?: string | null,
    account_location?: string | null,
    account_tel?: string | null,
    account_web_site_URL?: string | null,
    account_zip_code?: string | null,
    billing_close_day?: number | null,
    buyer_brand?: string | null,
    buyer_credit?: number | null,
    buyer_kyc_status?: number | null,
    buyer_product_category?: string | null,
    buyer_rating?: number | null,
    buyer_returen_credit?: number | null,
    buyer_return_limit_per_brand?: number | null,
    buyer_type?: string | null,
    item_category?: Array< string | null > | null,
    metadata?: string | null,
    mf_customer_id?: string | null,
    mf_destination_id?: string | null,
    mf_examination_status?: string | null,
    owner?: string | null,
    owner_name?: string | null,
    payment_type?: number | null,
    primary_payment_method_id?: string | null,
    primary_payment_term?: number | null,
    primary_shipping_address_id?: string | null,
    primary_shipping_building?: string | null,
    primary_shipping_city?: string | null,
    primary_shipping_name?: string | null,
    primary_shipping_phone_number?: string | null,
    primary_shipping_prefecture?: string | null,
    primary_shipping_zip_code?: string | null,
    referral_brand_id?: string | null,
    referral_brand_name?: string | null,
    shop_image_keys?: Array< string | null > | null,
    shop_name?: string | null,
    store_category?: string | null,
    stripe_id?: string | null,
    total_order_amount?: number | null,
    total_order_amount_last_9m?: number | null,
    total_order_quantity?: number | null,
    total_order_quantity_last_9m?: number | null,
    total_return_amount?: number | null,
    total_return_amount_last_9m?: number | null,
    total_return_quantity?: number | null,
    total_return_quantity_last_9m?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateBrandReadModelSubscriptionVariables = {
};

export type OnCreateBrandReadModelSubscription = {
  onCreateBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateBrandReadModelSubscriptionVariables = {
};

export type OnUpdateBrandReadModelSubscription = {
  onUpdateBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteBrandReadModelSubscriptionVariables = {
};

export type OnDeleteBrandReadModelSubscription = {
  onDeleteBrandReadModel?:  {
    __typename: "BrandReadModel",
    id: string,
    company_name?: string | null,
    brand_owner: string,
    brand_name: string,
    brand_yomigana?: string | null,
    brand_type?: string | null,
    brand_content?: string | null,
    brand_imageKey?: string | null,
    brand_icon_imageKey?: string | null,
    brand_logo_imageKey?: string | null,
    brand_publication?: string | null,
    brand_retail_stores_number?: string | null,
    brand_category?: string | null,
    brand_target_age?: string | null,
    brand_target_gender?: string | null,
    brand_website_url?: string | null,
    brand_work_with_big_box?: string | null,
    brand_address?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_shippinng_fee?: number | null,
    brand_shippinng_fee_criteria?: number | null,
    brand_instagram_account?: string | null,
    brand_facebook_account?: string | null,
    brand_rating?: number | null,
    brand_annual_turnover?: number | null,
    brand_public_status?: string | null,
    brand_howtoknowhomula?: string | null,
    brand_audit_status?: number | null,
    brand_stripe_id?: string | null,
    brand_first_order_fee?: number | null,
    brand_additional_order_fee?: number | null,
    brand_first_order_fee_referral?: number | null,
    brand_additional_order_fee_referral?: number | null,
    brand_direct_order_fee?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    owner?: Array< string | null > | null,
    designed_in?: string | null,
    established?: string | null,
    tagIds?: Array< string > | null,
    tags?: Array< string > | null,
    brand_collections?: Array< string > | null,
    movie_url?: string | null,
    brand_modal_imageKeys?: Array< string > | null,
    brand_online_sale?: boolean | null,
    product_image_reprint?: boolean | null,
    brand_logo_reprint?: boolean | null,
    post_to_sns?: boolean | null,
    allow_direct_shipping?: boolean | null,
    allow_sell_before_buy?: boolean | null,
    other_terms?: string | null,
    stockists?:  Array< {
      __typename: "Stockist",
      stockist?: string | null,
      address?: string | null,
    } > | null,
    featured_in?: Array< string > | null,
    maximum_wholesale_rate?: number | null,
    exclude_zip_code?: Array< string > | null,
    is_sale_brand?: boolean | null,
    hubspot_company_id?: string | null,
    return_to_brand?: boolean | null,
    terms_of_sample?: string | null,
    auto_message_enabled?: boolean | null,
    auto_message?: string | null,
    sell_only_direct?: boolean | null,
    is_published?: boolean | null,
    firstPublishedAt?: string | null,
    firstPublishedAtTimestamp?: number | null,
    lastPublishedAt?: string | null,
    lastPublishedAtTimestamp?: number | null,
    category_page_id?: Array< string > | null,
    product_category?: Array< string > | null,
    product_subcategory?: Array< string > | null,
    average_shipping_days?: number | null,
    service_rate?: number | null,
    review_average?: number | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    reviews?: number | null,
    items?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    service_rate_rate_tier?: number | null,
    average_shipping_days_tier?: number | null,
    minimum_tier?: number | null,
    items_tier?: number | null,
    contacts_tier?: number | null,
    supplier_group_id?: string | null,
    supplier_group_title?: string | null,
    supplier_group_description?: string | null,
    supplier_group_image_key?: string | null,
    supplier_group_published?: boolean | null,
    individual_minimum_buy?: number | null,
    individual_shippinng_fee?: number | null,
    individual_shippinng_fee_criteria?: number | null,
    individual_first_order_fee?: number | null,
    individual_additional_order_fee?: number | null,
    individual_wholesale_rate?: number | null,
    collections?:  {
      __typename: "ModelCollectionConnection",
      items:  Array< {
        __typename: "Collection",
        id: string,
        brand_id: string,
        src_brand_id?: string | null,
        name: string,
        description?: string | null,
        image_key?: string | null,
        video_key?: string | null,
        published?: boolean | null,
        createdAt?: string | null,
        owner: string,
        preorder?: boolean | null,
        is_consign?: boolean | null,
        season?: string | null,
        season_year?: number | null,
        display_order?: number | null,
        updatedAt: string,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        srcBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        productCollections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        supplier_id: string,
        buyer_id?: string | null,
        buyer_owner?: string | null,
        email?: string | null,
        store_name?: string | null,
        contact_name?: string | null,
        tel?: string | null,
        zipcode?: string | null,
        address?: string | null,
        discount_rate?: number | null,
        contact_status?: string | null,
        contact_source?: string | null,
        direct_payment?: boolean | null,
        contacted?: boolean | null,
        signedup?: boolean | null,
        ordered?: boolean | null,
        invited?: boolean | null,
        createdAt: string,
        owner: string,
        updatedAt: string,
        buyerAccount?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        buyerInfo?:  {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          nextToken?: string | null,
        } | null,
        contactListConnections?:  {
          __typename: "ModelContactListConnectionConnection",
          nextToken?: string | null,
        } | null,
        activities?:  {
          __typename: "ModelContactActivityConnection",
          nextToken?: string | null,
        } | null,
        pageViews?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
        pageViewsByEmail?:  {
          __typename: "ModelPageViewConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    review?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelProductReadModelConnection",
      items:  Array< {
        __typename: "ProductReadModel",
        id: string,
        product_name?: string | null,
        product_number?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        brand_yomigana?: string | null,
        brand_image_key?: string | null,
        brand_published?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_individual_minimum_buy?: number | null,
        brand_individual_wholesale_rate?: number | null,
        brand_tags?: Array< string > | null,
        product_content?: string | null,
        product_description?: string | null,
        product_size?: string | null,
        product_owner?: string | null,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        origin_wholesale_price?: number | null,
        wholesale_rate?: number | null,
        wholesale_price?: number | null,
        individual_wholesale_rate?: number | null,
        individual_price?: number | null,
        individual_minimum_buy?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        is_published?: boolean | null,
        is_limited?: boolean | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        category_page_id?: Array< string > | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_preorder_shipping_date_timestamp?: number | null,
        product_types?: Array< string > | null,
        product_colors?: Array< string > | null,
        product_sizes?: Array< string > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        image_key?: string | null,
        image_keys?: Array< string > | null,
        video_keys?: Array< string > | null,
        conversion_rate?: number | null,
        sell_through_rate?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string > | null,
        protected_zip_code?: Array< string > | null,
        options?:  Array< {
          __typename: "ProductOption",
          id?: string | null,
          product_id?: string | null,
          size?: string | null,
          color?: string | null,
          type_name?: string | null,
          sku?: string | null,
          imageKey?: string | null,
          out_of_stock?: boolean | null,
          suspended?: boolean | null,
          published?: boolean | null,
          createdAt?: string | null,
          copied_product_type_id?: string | null,
        } > | null,
        publishedAt?: string | null,
        publishedAtTimestamp?: number | null,
        createdAt?: string | null,
        createdAtTimestamp?: number | null,
        updatedAt?: string | null,
        updatedAtTimestamp?: number | null,
        conversion_rate_tier?: number | null,
        sell_through_rate_tier?: number | null,
        wholesale_rate_tier?: number | null,
        brand_tier?: number | null,
        brand_conversion_rate_tier?: number | null,
        brand_sell_through_rate_tier?: number | null,
        brand_service_rate_rate_tier?: number | null,
        brand_average_shipping_days_tier?: number | null,
        brand_minimum_tier?: number | null,
        brand_items_tier?: number | null,
        brand_contacts_tier?: number | null,
        collection_id?: Array< string | null > | null,
        sell_only_direct?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProductReadModelSubscriptionVariables = {
};

export type OnCreateProductReadModelSubscription = {
  onCreateProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type OnUpdateProductReadModelSubscriptionVariables = {
};

export type OnUpdateProductReadModelSubscription = {
  onUpdateProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type OnDeleteProductReadModelSubscriptionVariables = {
};

export type OnDeleteProductReadModelSubscription = {
  onDeleteProductReadModel?:  {
    __typename: "ProductReadModel",
    id: string,
    product_name?: string | null,
    product_number?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_yomigana?: string | null,
    brand_image_key?: string | null,
    brand_published?: string | null,
    brand_minimum_buy?: number | null,
    brand_additional_minimum_buy?: number | null,
    brand_individual_minimum_buy?: number | null,
    brand_individual_wholesale_rate?: number | null,
    brand_tags?: Array< string > | null,
    product_content?: string | null,
    product_description?: string | null,
    product_size?: string | null,
    product_owner?: string | null,
    product_wholesale_rate?: number | null,
    product_sale_wholesale_rate?: number | null,
    product_retail_price?: number | null,
    origin_wholesale_price?: number | null,
    wholesale_rate?: number | null,
    wholesale_price?: number | null,
    individual_wholesale_rate?: number | null,
    individual_price?: number | null,
    individual_minimum_buy?: number | null,
    is_open_price?: boolean | null,
    product_jancode?: string | null,
    product_stock_quantity?: number | null,
    is_published?: boolean | null,
    is_limited?: boolean | null,
    product_category?: string | null,
    product_subcategory?: string | null,
    category_page_id?: Array< string > | null,
    product_minimum_quantity?: number | null,
    product_estimated_ship_date_min?: number | null,
    product_estimated_ship_date_max?: number | null,
    product_order_lot?: number | null,
    product_preorder?: number | null,
    product_preorder_shipping_date?: string | null,
    product_preorder_shipping_date_timestamp?: number | null,
    product_types?: Array< string > | null,
    product_colors?: Array< string > | null,
    product_sizes?: Array< string > | null,
    product_display_order?: number | null,
    product_season?: string | null,
    product_year?: number | null,
    image_key?: string | null,
    image_keys?: Array< string > | null,
    video_keys?: Array< string > | null,
    conversion_rate?: number | null,
    sell_through_rate?: number | null,
    suspended?: boolean | null,
    continue_selling?: boolean | null,
    out_of_stock?: boolean | null,
    sku?: string | null,
    copied_product_id?: string | null,
    copied_brand_id?: string | null,
    limited_publishing_list?: Array< string > | null,
    protected_zip_code?: Array< string > | null,
    options?:  Array< {
      __typename: "ProductOption",
      id?: string | null,
      product_id?: string | null,
      size?: string | null,
      color?: string | null,
      type_name?: string | null,
      sku?: string | null,
      imageKey?: string | null,
      out_of_stock?: boolean | null,
      suspended?: boolean | null,
      published?: boolean | null,
      createdAt?: string | null,
      copied_product_type_id?: string | null,
    } > | null,
    publishedAt?: string | null,
    publishedAtTimestamp?: number | null,
    createdAt?: string | null,
    createdAtTimestamp?: number | null,
    updatedAt?: string | null,
    updatedAtTimestamp?: number | null,
    conversion_rate_tier?: number | null,
    sell_through_rate_tier?: number | null,
    wholesale_rate_tier?: number | null,
    brand_tier?: number | null,
    brand_conversion_rate_tier?: number | null,
    brand_sell_through_rate_tier?: number | null,
    brand_service_rate_rate_tier?: number | null,
    brand_average_shipping_days_tier?: number | null,
    brand_minimum_tier?: number | null,
    brand_items_tier?: number | null,
    brand_contacts_tier?: number | null,
    collection_id?: Array< string | null > | null,
    sell_only_direct?: boolean | null,
  } | null,
};

export type OnCreateCollectionReadModelSubscriptionVariables = {
};

export type OnCreateCollectionReadModelSubscription = {
  onCreateCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCollectionReadModelSubscriptionVariables = {
};

export type OnUpdateCollectionReadModelSubscription = {
  onUpdateCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCollectionReadModelSubscriptionVariables = {
};

export type OnDeleteCollectionReadModelSubscription = {
  onDeleteCollectionReadModel?:  {
    __typename: "CollectionReadModel",
    id: string,
    name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_categories?: Array< string | null > | null,
    brand_tags?: Array< string | null > | null,
    src_brand_id?: string | null,
    src_brand_name?: string | null,
    src_brand_categories?: Array< string | null > | null,
    src_brand_tags?: Array< string | null > | null,
    description?: string | null,
    image_key?: string | null,
    video_key?: string | null,
    published?: boolean | null,
    createdAt?: string | null,
    owner?: string | null,
    preorder?: boolean | null,
    is_consign?: boolean | null,
    season?: string | null,
    season_year?: number | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_image_keys?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    product_categories?: Array< string | null > | null,
    product_subcategories?: Array< string | null > | null,
    total_quantity?: number | null,
    total_retail_amount?: number | null,
    total_original_amount?: number | null,
    total_amount?: number | null,
    total_items?: number | null,
    wholesale_rate?: number | null,
    minimum_wholesale_rate?: number | null,
    sell_only_direct?: boolean | null,
    display_order?: number | null,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnCreateOrderReadModelSubscription = {
  onCreateOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrderReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnUpdateOrderReadModelSubscription = {
  onUpdateOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrderReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnDeleteOrderReadModelSubscription = {
  onDeleteOrderReadModel?:  {
    __typename: "OrderReadModel",
    id: string,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    brand_image_key?: string | null,
    shipping_zip_code?: string | null,
    shipping_address?: string | null,
    shipping_name?: string | null,
    shipping_phone_number?: string | null,
    carrier?: string | null,
    tracking_number?: string | null,
    shipping_date?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_due_date?: string | null,
    shipping_fee?: number | null,
    invoice_shipping_fee?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    order_quantity?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_amount?: number | null,
    report_approved_quantity?: number | null,
    report_approved_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    points?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    campaign_code?: string | null,
    cashback_amount?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    origin_order_id?: string | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    stripe_payment_id?: string | null,
    stripe_client_secret?: string | null,
    stripe_payment_method_id?: string | null,
    mf_authorization_id?: string | null,
    mf_transaction_id?: string | null,
    mf_canceled_transaction_id?: string | null,
    billing_count?: number | null,
    is_first?: boolean | null,
    is_direct?: boolean | null,
    is_consign?: boolean | null,
    is_preorder?: boolean | null,
    product_ids?: Array< string | null > | null,
    product_names?: Array< string | null > | null,
    product_numbers?: Array< string | null > | null,
    skus?: Array< string | null > | null,
    bill_payment_status?: BillPaymentStatus | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    memo?: string | null,
    split_source_id?: string | null,
    split_date?: string | null,
    buyerAccount?:  {
      __typename: "Account",
      id: string,
      email?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      web_site_URL?: string | null,
      instagram_account?: string | null,
      zip_code?: string | null,
      location?: string | null,
      tel?: string | null,
      company_name?: string | null,
      term_approval_status?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      buyerInfo?:  {
        __typename: "ModelBuyerInfoConnection",
        items:  Array< {
          __typename: "BuyerInfo",
          id: string,
          account_id?: string | null,
          billing_close_day?: number | null,
          buyer_brand?: string | null,
          buyer_credit?: number | null,
          buyer_id?: string | null,
          buyer_kyc_status?: number | null,
          buyer_product_category?: string | null,
          buyer_rating?: number | null,
          buyer_return_credit?: number | null,
          buyer_return_limit_per_brand?: number | null,
          buyer_type?: string | null,
          invoice_credit_facility?: number | null,
          item_category?: Array< string | null > | null,
          metadata?: string | null,
          mf_customer_id?: string | null,
          mf_destination_id?: string | null,
          mf_examination_status?: string | null,
          owner?: Array< string | null > | null,
          owner_name?: string | null,
          payment_type?: number | null,
          primary_payment_method_id?: string | null,
          primary_payment_term?: number | null,
          primary_shipping_address_id?: string | null,
          referral_brand_id?: string | null,
          shop_name?: string | null,
          store_category?: string | null,
          stripe_id?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      supplierInfo?:  {
        __typename: "ModelSupplierInfoConnection",
        items:  Array< {
          __typename: "SupplierInfo",
          id: string,
          account_id: string,
          supplier_contract_type?: number | null,
          bank_name?: string | null,
          bank_branch_name?: string | null,
          bank_account_number?: string | null,
          bank_account_name?: string | null,
          bank_account_type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        brand_id: string,
        product_ids?: Array< string | null > | null,
        buyer_id: string,
        order_id: string,
        rate?: number | null,
        title?: string | null,
        comment?: string | null,
        remove_flag?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        replies?:  {
          __typename: "ModelReviewReplyConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    coupon?:  {
      __typename: "Coupon",
      id: string,
      coupon_name?: string | null,
      start_date?: string | null,
      end_date?: string | null,
      brand_ids?: Array< string | null > | null,
      discount_type?: DiscountType | null,
      discount_value?: number | null,
      maximum_amount?: number | null,
      available_times?: number | null,
      duration?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      logs?:  {
        __typename: "ModelCouponLogConnection",
        items:  Array< {
          __typename: "CouponLog",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          order_ids?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      buyers?:  {
        __typename: "ModelBuyerCouponConnection",
        items:  Array< {
          __typename: "BuyerCoupon",
          id: string,
          coupon_id?: string | null,
          buyer_id?: string | null,
          max_redemptions?: number | null,
          times_redeemed?: number | null,
          redemptions_left?: number | null,
          expiresAt?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    details?:  {
      __typename: "ModelOrderDetailReadModelConnection",
      items:  Array< {
        __typename: "OrderDetailReadModel",
        id: string,
        order_id?: string | null,
        buyer_id?: string | null,
        buyer_company_name?: string | null,
        buyer_shop_name?: string | null,
        supplier_id?: string | null,
        supplier_company_name?: string | null,
        brand_id?: string | null,
        brand_name?: string | null,
        product_id?: string | null,
        product_name?: string | null,
        product_number?: string | null,
        sku?: string | null,
        product_type_id?: string | null,
        product_image_key?: string | null,
        color?: string | null,
        size?: string | null,
        type_name?: string | null,
        shipped_date?: string | null,
        extended_date?: string | null,
        payment_date?: string | null,
        extended_payment_date?: string | null,
        initial_quantity?: number | null,
        initial_price?: number | null,
        initial_wholesale_rate?: number | null,
        quantity?: number | null,
        price?: number | null,
        wholesale_rate?: number | null,
        subtotal?: number | null,
        amount?: number | null,
        tax_rate?: number | null,
        tax?: number | null,
        return_request_quantity?: number | null,
        return_request_amount?: number | null,
        return_quantity?: number | null,
        return_amount?: number | null,
        report_quantity?: number | null,
        report_approved_amount?: number | null,
        report_approved_quantity?: number | null,
        report_amount?: number | null,
        extended_quantity?: number | null,
        extended_amount?: number | null,
        payment_quantity?: number | null,
        payment_amount?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        discount_percentage?: number | null,
        order_fee_rate?: number | null,
        order_fee?: number | null,
        status?: OrderReadModelStatus | null,
        status_label?: string | null,
        payment_term?: PaymentTerm | null,
        payment_term_label?: string | null,
        payment_status?: OrderPaymentStatus | null,
        payment_status_label?: string | null,
        memo?: string | null,
        owners?: Array< string | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        order?:  {
          __typename: "OrderReadModel",
          id: string,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          brand_image_key?: string | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          shipping_date?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          return_due_date?: string | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          order_quantity?: number | null,
          order_subtotal?: number | null,
          order_amount?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_amount?: number | null,
          report_approved_quantity?: number | null,
          report_approved_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          points?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          campaign_code?: string | null,
          cashback_amount?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          origin_order_id?: string | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          billing_count?: number | null,
          is_first?: boolean | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          is_preorder?: boolean | null,
          product_ids?: Array< string | null > | null,
          product_names?: Array< string | null > | null,
          product_numbers?: Array< string | null > | null,
          skus?: Array< string | null > | null,
          bill_payment_status?: BillPaymentStatus | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOrderDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnCreateOrderDetailReadModelSubscription = {
  onCreateOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateOrderDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnUpdateOrderDetailReadModelSubscription = {
  onUpdateOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteOrderDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnDeleteOrderDetailReadModelSubscription = {
  onDeleteOrderDetailReadModel?:  {
    __typename: "OrderDetailReadModel",
    id: string,
    order_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    initial_quantity?: number | null,
    initial_price?: number | null,
    initial_wholesale_rate?: number | null,
    quantity?: number | null,
    price?: number | null,
    wholesale_rate?: number | null,
    subtotal?: number | null,
    amount?: number | null,
    tax_rate?: number | null,
    tax?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    report_quantity?: number | null,
    report_approved_amount?: number | null,
    report_approved_quantity?: number | null,
    report_amount?: number | null,
    extended_quantity?: number | null,
    extended_amount?: number | null,
    payment_quantity?: number | null,
    payment_amount?: number | null,
    coupon_id?: string | null,
    discount_amount?: number | null,
    discount_percentage?: number | null,
    order_fee_rate?: number | null,
    order_fee?: number | null,
    status?: OrderReadModelStatus | null,
    status_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    memo?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    editReasons?:  {
      __typename: "ModelOrderEditReasonConnection",
      items:  Array< {
        __typename: "OrderEditReason",
        id: string,
        order_id: string,
        edit_reason?: EditReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    cancelReasons?:  {
      __typename: "ModelOrderCancelReasonConnection",
      items:  Array< {
        __typename: "OrderCancelReason",
        id: string,
        order_id: string,
        cancel_reason?: CancelReason | null,
        feedback_comment?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateReturnDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnCreateReturnDetailReadModelSubscription = {
  onCreateReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateReturnDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnUpdateReturnDetailReadModelSubscription = {
  onUpdateReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteReturnDetailReadModelSubscriptionVariables = {
  buyer_id?: string | null,
  supplier_id?: string | null,
};

export type OnDeleteReturnDetailReadModelSubscription = {
  onDeleteReturnDetailReadModel?:  {
    __typename: "ReturnDetailReadModel",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    buyer_id?: string | null,
    buyer_company_name?: string | null,
    buyer_shop_name?: string | null,
    supplier_id?: string | null,
    supplier_company_name?: string | null,
    brand_id?: string | null,
    brand_name?: string | null,
    product_id?: string | null,
    product_name?: string | null,
    product_number?: string | null,
    sku?: string | null,
    product_type_id?: string | null,
    product_image_key?: string | null,
    color?: string | null,
    size?: string | null,
    type_name?: string | null,
    shipped_date?: string | null,
    extended_date?: string | null,
    payment_date?: string | null,
    extended_payment_date?: string | null,
    return_request_due_date?: string | null,
    return_request_date?: string | null,
    return_due_date?: string | null,
    return_date?: string | null,
    order_quantity?: number | null,
    order_price?: number | null,
    wholesale_rate?: number | null,
    order_subtotal?: number | null,
    order_amount?: number | null,
    order_tax?: number | null,
    tax_rate?: number | null,
    return_request_quantity?: number | null,
    return_request_amount?: number | null,
    return_quantity?: number | null,
    return_amount?: number | null,
    status?: ReturnStatus | null,
    status_label?: string | null,
    return_destination?: ReturnDestination | null,
    return_destination_label?: string | null,
    payment_term?: PaymentTerm | null,
    payment_term_label?: string | null,
    payment_status?: OrderPaymentStatus | null,
    payment_status_label?: string | null,
    stripe_payment_id?: string | null,
    mf_transaction_id?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
    product?:  {
      __typename: "Product",
      id: string,
      product_name: string,
      product_number?: string | null,
      product_brand_id: string,
      product_content?: string | null,
      product_description?: string | null,
      product_owner: string,
      product_wholesale_rate?: number | null,
      product_sale_wholesale_rate?: number | null,
      product_retail_price?: number | null,
      product_price?: number | null,
      is_open_price?: boolean | null,
      product_jancode?: string | null,
      product_stock_quantity?: number | null,
      product_type?: string | null,
      product_color?: string | null,
      product_size?: string | null,
      product_public_status?: string | null,
      product_category?: string | null,
      product_subcategory?: string | null,
      product_minimum_quantity?: number | null,
      product_estimated_ship_date_min?: number | null,
      product_estimated_ship_date_max?: number | null,
      product_order_lot?: number | null,
      product_preorder?: number | null,
      product_preorder_shipping_date?: string | null,
      product_types?: Array< string | null > | null,
      product_colors?: Array< string | null > | null,
      product_sizes?: Array< string | null > | null,
      product_display_order?: number | null,
      product_season?: string | null,
      product_year?: number | null,
      publishedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sort_recommended?: number | null,
      suspended?: boolean | null,
      continue_selling?: boolean | null,
      out_of_stock?: boolean | null,
      sku?: string | null,
      copied_product_id?: string | null,
      copied_brand_id?: string | null,
      limited_publishing_list?: Array< string | null > | null,
      owner: string,
      contacts?:  {
        __typename: "ContactConnection",
        items?:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      imageKeys?:  {
        __typename: "ModelProductImageKeyConnection",
        items:  Array< {
          __typename: "ProductImageKey",
          id: string,
          product_id: string,
          imageKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      videoKeys?:  {
        __typename: "ModelProductVideoKeyConnection",
        items:  Array< {
          __typename: "ProductVideoKey",
          id: string,
          product_id: string,
          videoKey?: string | null,
          createdAt?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      producttype?:  {
        __typename: "ModelProductTypeConnection",
        items:  Array< {
          __typename: "ProductType",
          id: string,
          product_id: string,
          imageKey?: string | null,
          type_name?: string | null,
          color?: string | null,
          size?: string | null,
          createdAt?: string | null,
          suspended?: boolean | null,
          published?: boolean | null,
          sku?: string | null,
          out_of_stock?: boolean | null,
          copied_product_type_id?: string | null,
          copied_brand_id?: string | null,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      copiedBrand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      collections?:  {
        __typename: "ModelProductCollectionConnection",
        items:  Array< {
          __typename: "ProductCollection",
          id: string,
          collection_id: string,
          product_id: string,
          product_type_id?: string | null,
          quantity: number,
          sort_order?: number | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id: string,
      product_id: string,
      imageKey?: string | null,
      type_name?: string | null,
      color?: string | null,
      size?: string | null,
      createdAt?: string | null,
      suspended?: boolean | null,
      published?: boolean | null,
      sku?: string | null,
      out_of_stock?: boolean | null,
      copied_product_type_id?: string | null,
      copied_brand_id?: string | null,
      owner: string,
      updatedAt: string,
      product?:  {
        __typename: "Product",
        id: string,
        product_name: string,
        product_number?: string | null,
        product_brand_id: string,
        product_content?: string | null,
        product_description?: string | null,
        product_owner: string,
        product_wholesale_rate?: number | null,
        product_sale_wholesale_rate?: number | null,
        product_retail_price?: number | null,
        product_price?: number | null,
        is_open_price?: boolean | null,
        product_jancode?: string | null,
        product_stock_quantity?: number | null,
        product_type?: string | null,
        product_color?: string | null,
        product_size?: string | null,
        product_public_status?: string | null,
        product_category?: string | null,
        product_subcategory?: string | null,
        product_minimum_quantity?: number | null,
        product_estimated_ship_date_min?: number | null,
        product_estimated_ship_date_max?: number | null,
        product_order_lot?: number | null,
        product_preorder?: number | null,
        product_preorder_shipping_date?: string | null,
        product_types?: Array< string | null > | null,
        product_colors?: Array< string | null > | null,
        product_sizes?: Array< string | null > | null,
        product_display_order?: number | null,
        product_season?: string | null,
        product_year?: number | null,
        publishedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort_recommended?: number | null,
        suspended?: boolean | null,
        continue_selling?: boolean | null,
        out_of_stock?: boolean | null,
        sku?: string | null,
        copied_product_id?: string | null,
        copied_brand_id?: string | null,
        limited_publishing_list?: Array< string | null > | null,
        owner: string,
        contacts?:  {
          __typename: "ContactConnection",
          nextToken?: string | null,
        } | null,
        imageKeys?:  {
          __typename: "ModelProductImageKeyConnection",
          nextToken?: string | null,
        } | null,
        videoKeys?:  {
          __typename: "ModelProductVideoKeyConnection",
          nextToken?: string | null,
        } | null,
        producttype?:  {
          __typename: "ModelProductTypeConnection",
          nextToken?: string | null,
        } | null,
        inventoryConnection?:  {
          __typename: "ModelInventoryConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        copiedBrand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        collections?:  {
          __typename: "ModelProductCollectionConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      inventoryConnection?:  {
        __typename: "ModelInventoryConnection",
        items:  Array< {
          __typename: "Inventory",
          id: string,
          brand_id: string,
          product_id: string,
          product_type_id?: string | null,
          sku: string,
          inventory?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "Brand",
      id: string,
      brand_owner: string,
      brand_name: string,
      brand_yomigana?: string | null,
      brand_type?: string | null,
      brand_content?: string | null,
      brand_imageKey?: string | null,
      brand_icon_imageKey?: string | null,
      brand_logo_imageKey?: string | null,
      brand_publication?: string | null,
      brand_retail_stores_number?: string | null,
      brand_category?: string | null,
      brand_target_age?: string | null,
      brand_target_gender?: string | null,
      brand_website_url?: string | null,
      brand_work_with_big_box?: string | null,
      brand_address?: string | null,
      brand_minimum_buy?: number | null,
      brand_additional_minimum_buy?: number | null,
      brand_shippinng_fee?: number | null,
      brand_shippinng_fee_criteria?: number | null,
      brand_instagram_account?: string | null,
      brand_facebook_account?: string | null,
      brand_rating?: number | null,
      brand_annual_turnover?: number | null,
      brand_public_status?: string | null,
      brand_howtoknowhomula?: string | null,
      brand_audit_status?: number | null,
      brand_stripe_id?: string | null,
      brand_first_order_fee?: number | null,
      brand_additional_order_fee?: number | null,
      brand_first_order_fee_referral?: number | null,
      brand_additional_order_fee_referral?: number | null,
      brand_direct_order_fee?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      designed_in?: string | null,
      established?: string | null,
      tagIds?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      brand_collections?: Array< string | null > | null,
      movie_url?: string | null,
      brand_modal_imageKeys?: Array< string | null > | null,
      brand_online_sale?: boolean | null,
      product_image_reprint?: boolean | null,
      brand_logo_reprint?: boolean | null,
      post_to_sns?: boolean | null,
      allow_direct_shipping?: boolean | null,
      allow_sell_before_buy?: boolean | null,
      other_terms?: string | null,
      stockists?:  Array< {
        __typename: "Stockist",
        stockist?: string | null,
        address?: string | null,
      } | null > | null,
      featured_in?: Array< string | null > | null,
      maximum_wholesale_rate?: number | null,
      exclude_zip_code?: Array< string | null > | null,
      is_sale_brand?: boolean | null,
      hubspot_company_id?: string | null,
      return_to_brand?: boolean | null,
      terms_of_sample?: string | null,
      auto_message_enabled?: boolean | null,
      auto_message?: string | null,
      sell_only_direct?: boolean | null,
      supplier_group_id?: string | null,
      individual_minimum_buy?: number | null,
      individual_shippinng_fee?: number | null,
      individual_shippinng_fee_criteria?: number | null,
      individual_first_order_fee?: number | null,
      individual_additional_order_fee?: number | null,
      individual_wholesale_rate?: number | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      supplierGroup?:  {
        __typename: "SupplierGroup",
        id: string,
        title?: string | null,
        description?: string | null,
        image_key?: string | null,
        published: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        brands?:  {
          __typename: "ModelBrandReadModelConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      product?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          product_name: string,
          product_number?: string | null,
          product_brand_id: string,
          product_content?: string | null,
          product_description?: string | null,
          product_owner: string,
          product_wholesale_rate?: number | null,
          product_sale_wholesale_rate?: number | null,
          product_retail_price?: number | null,
          product_price?: number | null,
          is_open_price?: boolean | null,
          product_jancode?: string | null,
          product_stock_quantity?: number | null,
          product_type?: string | null,
          product_color?: string | null,
          product_size?: string | null,
          product_public_status?: string | null,
          product_category?: string | null,
          product_subcategory?: string | null,
          product_minimum_quantity?: number | null,
          product_estimated_ship_date_min?: number | null,
          product_estimated_ship_date_max?: number | null,
          product_order_lot?: number | null,
          product_preorder?: number | null,
          product_preorder_shipping_date?: string | null,
          product_types?: Array< string | null > | null,
          product_colors?: Array< string | null > | null,
          product_sizes?: Array< string | null > | null,
          product_display_order?: number | null,
          product_season?: string | null,
          product_year?: number | null,
          publishedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          sort_recommended?: number | null,
          suspended?: boolean | null,
          continue_selling?: boolean | null,
          out_of_stock?: boolean | null,
          sku?: string | null,
          copied_product_id?: string | null,
          copied_brand_id?: string | null,
          limited_publishing_list?: Array< string | null > | null,
          owner: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collections?:  {
        __typename: "ModelCollectionConnection",
        items:  Array< {
          __typename: "Collection",
          id: string,
          brand_id: string,
          src_brand_id?: string | null,
          name: string,
          description?: string | null,
          image_key?: string | null,
          video_key?: string | null,
          published?: boolean | null,
          createdAt?: string | null,
          owner: string,
          preorder?: boolean | null,
          is_consign?: boolean | null,
          season?: string | null,
          season_year?: number | null,
          display_order?: number | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          supplier_id: string,
          buyer_id?: string | null,
          buyer_owner?: string | null,
          email?: string | null,
          store_name?: string | null,
          contact_name?: string | null,
          tel?: string | null,
          zipcode?: string | null,
          address?: string | null,
          discount_rate?: number | null,
          contact_status?: string | null,
          contact_source?: string | null,
          direct_payment?: boolean | null,
          contacted?: boolean | null,
          signedup?: boolean | null,
          ordered?: boolean | null,
          invited?: boolean | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    order?:  {
      __typename: "OrderReadModel",
      id: string,
      buyer_id?: string | null,
      buyer_company_name?: string | null,
      buyer_shop_name?: string | null,
      supplier_id?: string | null,
      supplier_company_name?: string | null,
      brand_id?: string | null,
      brand_name?: string | null,
      brand_image_key?: string | null,
      shipping_zip_code?: string | null,
      shipping_address?: string | null,
      shipping_name?: string | null,
      shipping_phone_number?: string | null,
      carrier?: string | null,
      tracking_number?: string | null,
      shipping_date?: string | null,
      shipped_date?: string | null,
      extended_date?: string | null,
      payment_date?: string | null,
      extended_payment_date?: string | null,
      return_due_date?: string | null,
      shipping_fee?: number | null,
      invoice_shipping_fee?: number | null,
      tax_rate?: number | null,
      tax?: number | null,
      order_quantity?: number | null,
      order_subtotal?: number | null,
      order_amount?: number | null,
      return_request_quantity?: number | null,
      return_request_amount?: number | null,
      return_quantity?: number | null,
      return_amount?: number | null,
      report_quantity?: number | null,
      report_amount?: number | null,
      report_approved_quantity?: number | null,
      report_approved_amount?: number | null,
      extended_quantity?: number | null,
      extended_amount?: number | null,
      payment_quantity?: number | null,
      payment_amount?: number | null,
      points?: number | null,
      coupon_id?: string | null,
      discount_amount?: number | null,
      campaign_code?: string | null,
      cashback_amount?: number | null,
      order_fee_rate?: number | null,
      order_fee?: number | null,
      origin_order_id?: string | null,
      status?: OrderReadModelStatus | null,
      status_label?: string | null,
      payment_status?: OrderPaymentStatus | null,
      payment_status_label?: string | null,
      payment_term?: PaymentTerm | null,
      payment_term_label?: string | null,
      stripe_payment_id?: string | null,
      stripe_client_secret?: string | null,
      stripe_payment_method_id?: string | null,
      mf_authorization_id?: string | null,
      mf_transaction_id?: string | null,
      mf_canceled_transaction_id?: string | null,
      billing_count?: number | null,
      is_first?: boolean | null,
      is_direct?: boolean | null,
      is_consign?: boolean | null,
      is_preorder?: boolean | null,
      product_ids?: Array< string | null > | null,
      product_names?: Array< string | null > | null,
      product_numbers?: Array< string | null > | null,
      skus?: Array< string | null > | null,
      bill_payment_status?: BillPaymentStatus | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      memo?: string | null,
      split_source_id?: string | null,
      split_date?: string | null,
      buyerAccount?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      brand?:  {
        __typename: "Brand",
        id: string,
        brand_owner: string,
        brand_name: string,
        brand_yomigana?: string | null,
        brand_type?: string | null,
        brand_content?: string | null,
        brand_imageKey?: string | null,
        brand_icon_imageKey?: string | null,
        brand_logo_imageKey?: string | null,
        brand_publication?: string | null,
        brand_retail_stores_number?: string | null,
        brand_category?: string | null,
        brand_target_age?: string | null,
        brand_target_gender?: string | null,
        brand_website_url?: string | null,
        brand_work_with_big_box?: string | null,
        brand_address?: string | null,
        brand_minimum_buy?: number | null,
        brand_additional_minimum_buy?: number | null,
        brand_shippinng_fee?: number | null,
        brand_shippinng_fee_criteria?: number | null,
        brand_instagram_account?: string | null,
        brand_facebook_account?: string | null,
        brand_rating?: number | null,
        brand_annual_turnover?: number | null,
        brand_public_status?: string | null,
        brand_howtoknowhomula?: string | null,
        brand_audit_status?: number | null,
        brand_stripe_id?: string | null,
        brand_first_order_fee?: number | null,
        brand_additional_order_fee?: number | null,
        brand_first_order_fee_referral?: number | null,
        brand_additional_order_fee_referral?: number | null,
        brand_direct_order_fee?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        designed_in?: string | null,
        established?: string | null,
        tagIds?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        brand_collections?: Array< string | null > | null,
        movie_url?: string | null,
        brand_modal_imageKeys?: Array< string | null > | null,
        brand_online_sale?: boolean | null,
        product_image_reprint?: boolean | null,
        brand_logo_reprint?: boolean | null,
        post_to_sns?: boolean | null,
        allow_direct_shipping?: boolean | null,
        allow_sell_before_buy?: boolean | null,
        other_terms?: string | null,
        stockists?:  Array< {
          __typename: "Stockist",
          stockist?: string | null,
          address?: string | null,
        } | null > | null,
        featured_in?: Array< string | null > | null,
        maximum_wholesale_rate?: number | null,
        exclude_zip_code?: Array< string | null > | null,
        is_sale_brand?: boolean | null,
        hubspot_company_id?: string | null,
        return_to_brand?: boolean | null,
        terms_of_sample?: string | null,
        auto_message_enabled?: boolean | null,
        auto_message?: string | null,
        sell_only_direct?: boolean | null,
        supplier_group_id?: string | null,
        individual_minimum_buy?: number | null,
        individual_shippinng_fee?: number | null,
        individual_shippinng_fee_criteria?: number | null,
        individual_first_order_fee?: number | null,
        individual_additional_order_fee?: number | null,
        individual_wholesale_rate?: number | null,
        account?:  {
          __typename: "Account",
          id: string,
          email?: string | null,
          first_name?: string | null,
          last_name?: string | null,
          web_site_URL?: string | null,
          instagram_account?: string | null,
          zip_code?: string | null,
          location?: string | null,
          tel?: string | null,
          company_name?: string | null,
          term_approval_status?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        supplierGroup?:  {
          __typename: "SupplierGroup",
          id: string,
          title?: string | null,
          description?: string | null,
          image_key?: string | null,
          published: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
        } | null,
        product?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        collections?:  {
          __typename: "ModelCollectionConnection",
          nextToken?: string | null,
        } | null,
        contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        items:  Array< {
          __typename: "Review",
          id: string,
          brand_id: string,
          product_ids?: Array< string | null > | null,
          buyer_id: string,
          order_id: string,
          rate?: number | null,
          title?: string | null,
          comment?: string | null,
          remove_flag?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      coupon?:  {
        __typename: "Coupon",
        id: string,
        coupon_name?: string | null,
        start_date?: string | null,
        end_date?: string | null,
        brand_ids?: Array< string | null > | null,
        discount_type?: DiscountType | null,
        discount_value?: number | null,
        maximum_amount?: number | null,
        available_times?: number | null,
        duration?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        logs?:  {
          __typename: "ModelCouponLogConnection",
          nextToken?: string | null,
        } | null,
        buyers?:  {
          __typename: "ModelBuyerCouponConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      details?:  {
        __typename: "ModelOrderDetailReadModelConnection",
        items:  Array< {
          __typename: "OrderDetailReadModel",
          id: string,
          order_id?: string | null,
          buyer_id?: string | null,
          buyer_company_name?: string | null,
          buyer_shop_name?: string | null,
          supplier_id?: string | null,
          supplier_company_name?: string | null,
          brand_id?: string | null,
          brand_name?: string | null,
          product_id?: string | null,
          product_name?: string | null,
          product_number?: string | null,
          sku?: string | null,
          product_type_id?: string | null,
          product_image_key?: string | null,
          color?: string | null,
          size?: string | null,
          type_name?: string | null,
          shipped_date?: string | null,
          extended_date?: string | null,
          payment_date?: string | null,
          extended_payment_date?: string | null,
          initial_quantity?: number | null,
          initial_price?: number | null,
          initial_wholesale_rate?: number | null,
          quantity?: number | null,
          price?: number | null,
          wholesale_rate?: number | null,
          subtotal?: number | null,
          amount?: number | null,
          tax_rate?: number | null,
          tax?: number | null,
          return_request_quantity?: number | null,
          return_request_amount?: number | null,
          return_quantity?: number | null,
          return_amount?: number | null,
          report_quantity?: number | null,
          report_approved_amount?: number | null,
          report_approved_quantity?: number | null,
          report_amount?: number | null,
          extended_quantity?: number | null,
          extended_amount?: number | null,
          payment_quantity?: number | null,
          payment_amount?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          discount_percentage?: number | null,
          order_fee_rate?: number | null,
          order_fee?: number | null,
          status?: OrderReadModelStatus | null,
          status_label?: string | null,
          payment_term?: PaymentTerm | null,
          payment_term_label?: string | null,
          payment_status?: OrderPaymentStatus | null,
          payment_status_label?: string | null,
          memo?: string | null,
          owners?: Array< string | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateContactReadModelSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnCreateContactReadModelSubscription = {
  onCreateContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateContactReadModelSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnUpdateContactReadModelSubscription = {
  onUpdateContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteContactReadModelSubscriptionVariables = {
  owner?: string | null,
  buyer_owner?: string | null,
};

export type OnDeleteContactReadModelSubscription = {
  onDeleteContactReadModel?:  {
    __typename: "ContactReadModel",
    id: string,
    supplier_id: string,
    buyer_id?: string | null,
    buyer_owner?: string | null,
    email?: string | null,
    store_name?: string | null,
    contact_name?: string | null,
    tel?: string | null,
    zipcode?: string | null,
    address?: string | null,
    discount_rate?: number | null,
    contact_status?: string | null,
    contact_source?: string | null,
    direct_payment?: boolean | null,
    contacted?: boolean | null,
    signedup?: boolean | null,
    ordered?: boolean | null,
    invited?: boolean | null,
    createdAt: string,
    owner: string,
    buyer_web_site_URL?: string | null,
    buyer_instagram_account?: string | null,
    buyer_company_name?: string | null,
    contact_list_id?: Array< string | null > | null,
    orderCount?: number | null,
    pageViewCount?: number | null,
    updatedAt: string,
    supplierInfo?:  {
      __typename: "SupplierInfo",
      id: string,
      account_id: string,
      supplier_contract_type?: number | null,
      bank_name?: string | null,
      bank_branch_name?: string | null,
      bank_account_number?: string | null,
      bank_account_name?: string | null,
      bank_account_type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: Array< string | null > | null,
      account?:  {
        __typename: "Account",
        id: string,
        email?: string | null,
        first_name?: string | null,
        last_name?: string | null,
        web_site_URL?: string | null,
        instagram_account?: string | null,
        zip_code?: string | null,
        location?: string | null,
        tel?: string | null,
        company_name?: string | null,
        term_approval_status?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owner?: Array< string | null > | null,
        buyerInfo?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        supplierInfo?:  {
          __typename: "ModelSupplierInfoConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        order_owner?: string | null,
        brand_id?: string | null,
        brand_owner?: string | null,
        campaign_code?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        owners: Array< string | null >,
        stripe_payment_id?: string | null,
        stripe_client_secret?: string | null,
        stripe_payment_method_id?: string | null,
        fee?: number | null,
        shipping_fee?: number | null,
        invoice_shipping_fee?: number | null,
        tax?: number | null,
        payment_term?: number | null,
        shipping_zip_code?: string | null,
        shipping_address?: string | null,
        shipping_name?: string | null,
        shipping_phone_number?: string | null,
        shipping_date?: string | null,
        carrier?: string | null,
        tracking_number?: string | null,
        first_order?: boolean | null,
        order_points?: number | null,
        is_direct?: boolean | null,
        is_consign?: boolean | null,
        transferred_date?: string | null,
        order_price?: number | null,
        origin_order_id?: string | null,
        mf_authorization_id?: string | null,
        mf_transaction_id?: string | null,
        mf_canceled_transaction_id?: string | null,
        cashback?: number | null,
        coupon_id?: string | null,
        discount_amount?: number | null,
        billing_count?: number | null,
        return_due_date?: string | null,
        bill_payment_status?: BillPaymentStatus | null,
        memo?: string | null,
        split_source_id?: string | null,
        split_date?: string | null,
        buyer?:  {
          __typename: "ModelBuyerInfoConnection",
          nextToken?: string | null,
        } | null,
        brand?:  {
          __typename: "Brand",
          id: string,
          brand_owner: string,
          brand_name: string,
          brand_yomigana?: string | null,
          brand_type?: string | null,
          brand_content?: string | null,
          brand_imageKey?: string | null,
          brand_icon_imageKey?: string | null,
          brand_logo_imageKey?: string | null,
          brand_publication?: string | null,
          brand_retail_stores_number?: string | null,
          brand_category?: string | null,
          brand_target_age?: string | null,
          brand_target_gender?: string | null,
          brand_website_url?: string | null,
          brand_work_with_big_box?: string | null,
          brand_address?: string | null,
          brand_minimum_buy?: number | null,
          brand_additional_minimum_buy?: number | null,
          brand_shippinng_fee?: number | null,
          brand_shippinng_fee_criteria?: number | null,
          brand_instagram_account?: string | null,
          brand_facebook_account?: string | null,
          brand_rating?: number | null,
          brand_annual_turnover?: number | null,
          brand_public_status?: string | null,
          brand_howtoknowhomula?: string | null,
          brand_audit_status?: number | null,
          brand_stripe_id?: string | null,
          brand_first_order_fee?: number | null,
          brand_additional_order_fee?: number | null,
          brand_first_order_fee_referral?: number | null,
          brand_additional_order_fee_referral?: number | null,
          brand_direct_order_fee?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owner?: Array< string | null > | null,
          designed_in?: string | null,
          established?: string | null,
          tagIds?: Array< string | null > | null,
          tags?: Array< string | null > | null,
          brand_collections?: Array< string | null > | null,
          movie_url?: string | null,
          brand_modal_imageKeys?: Array< string | null > | null,
          brand_online_sale?: boolean | null,
          product_image_reprint?: boolean | null,
          brand_logo_reprint?: boolean | null,
          post_to_sns?: boolean | null,
          allow_direct_shipping?: boolean | null,
          allow_sell_before_buy?: boolean | null,
          other_terms?: string | null,
          featured_in?: Array< string | null > | null,
          maximum_wholesale_rate?: number | null,
          exclude_zip_code?: Array< string | null > | null,
          is_sale_brand?: boolean | null,
          hubspot_company_id?: string | null,
          return_to_brand?: boolean | null,
          terms_of_sample?: string | null,
          auto_message_enabled?: boolean | null,
          auto_message?: string | null,
          sell_only_direct?: boolean | null,
          supplier_group_id?: string | null,
          individual_minimum_buy?: number | null,
          individual_shippinng_fee?: number | null,
          individual_shippinng_fee_criteria?: number | null,
          individual_first_order_fee?: number | null,
          individual_additional_order_fee?: number | null,
          individual_wholesale_rate?: number | null,
        } | null,
        originOrder?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        chargeStatuses?:  {
          __typename: "ModelChargeStatusConnection",
          nextToken?: string | null,
        } | null,
        orderproducts?:  {
          __typename: "ModelOrderProductConnection",
          nextToken?: string | null,
        } | null,
        returnproducts?:  {
          __typename: "ModelReturnProductConnection",
          nextToken?: string | null,
        } | null,
        reportProducts?:  {
          __typename: "ModelReportProductConnection",
          nextToken?: string | null,
        } | null,
        editReasons?:  {
          __typename: "ModelOrderEditReasonConnection",
          nextToken?: string | null,
        } | null,
        cancelReasons?:  {
          __typename: "ModelOrderCancelReasonConnection",
          nextToken?: string | null,
        } | null,
        reviews?:  {
          __typename: "ModelReviewConnection",
          nextToken?: string | null,
        } | null,
        coupon?:  {
          __typename: "Coupon",
          id: string,
          coupon_name?: string | null,
          start_date?: string | null,
          end_date?: string | null,
          brand_ids?: Array< string | null > | null,
          discount_type?: DiscountType | null,
          discount_value?: number | null,
          maximum_amount?: number | null,
          available_times?: number | null,
          duration?: number | null,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activities?:  {
      __typename: "ModelContactActivityConnection",
      items:  Array< {
        __typename: "ContactActivity",
        id: string,
        contact_id: string,
        activity_date: string,
        activity_type?: string | null,
        order_id?: string | null,
        message_id?: string | null,
        campaign_id?: string | null,
        activity?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        order?:  {
          __typename: "Order",
          id: string,
          order_owner?: string | null,
          brand_id?: string | null,
          brand_owner?: string | null,
          campaign_code?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          owners: Array< string | null >,
          stripe_payment_id?: string | null,
          stripe_client_secret?: string | null,
          stripe_payment_method_id?: string | null,
          fee?: number | null,
          shipping_fee?: number | null,
          invoice_shipping_fee?: number | null,
          tax?: number | null,
          payment_term?: number | null,
          shipping_zip_code?: string | null,
          shipping_address?: string | null,
          shipping_name?: string | null,
          shipping_phone_number?: string | null,
          shipping_date?: string | null,
          carrier?: string | null,
          tracking_number?: string | null,
          first_order?: boolean | null,
          order_points?: number | null,
          is_direct?: boolean | null,
          is_consign?: boolean | null,
          transferred_date?: string | null,
          order_price?: number | null,
          origin_order_id?: string | null,
          mf_authorization_id?: string | null,
          mf_transaction_id?: string | null,
          mf_canceled_transaction_id?: string | null,
          cashback?: number | null,
          coupon_id?: string | null,
          discount_amount?: number | null,
          billing_count?: number | null,
          return_due_date?: string | null,
          bill_payment_status?: BillPaymentStatus | null,
          memo?: string | null,
          split_source_id?: string | null,
          split_date?: string | null,
        } | null,
        campaign?:  {
          __typename: "Campaign",
          id: string,
          supplier_id: string,
          brand_id: string,
          campaign_sender?: string | null,
          campaign_from?: string | null,
          campaign_title?: string | null,
          campaign_status?: string | null,
          campaign_subject?: string | null,
          preview_text?: string | null,
          campaign_template?: string | null,
          campaign_message?: string | null,
          campaign_components?: string | null,
          campaign_style?: string | null,
          campaign_segments?: Array< string | null > | null,
          deliveryTime?: string | null,
          sentAt?: string | null,
          createdAt: string,
          owner: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViews?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pageViewsByEmail?:  {
      __typename: "ModelPageViewConnection",
      items:  Array< {
        __typename: "PageView",
        brand_id: string,
        buyer_id: string,
        email?: string | null,
        page_view?: number | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOrderEventSubscriptionVariables = {
};

export type OnCreateOrderEventSubscription = {
  onCreateOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderEventSubscriptionVariables = {
};

export type OnUpdateOrderEventSubscription = {
  onUpdateOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderEventSubscriptionVariables = {
};

export type OnDeleteOrderEventSubscription = {
  onDeleteOrderEvent?:  {
    __typename: "OrderEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    order_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderProductEventSubscriptionVariables = {
};

export type OnCreateOrderProductEventSubscription = {
  onCreateOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderProductEventSubscriptionVariables = {
};

export type OnUpdateOrderProductEventSubscription = {
  onUpdateOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderProductEventSubscriptionVariables = {
};

export type OnDeleteOrderProductEventSubscription = {
  onDeleteOrderProductEvent?:  {
    __typename: "OrderProductEvent",
    id: string,
    order_id?: string | null,
    order_product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBrandEventSubscriptionVariables = {
};

export type OnCreateBrandEventSubscription = {
  onCreateBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandEventSubscriptionVariables = {
};

export type OnUpdateBrandEventSubscription = {
  onUpdateBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandEventSubscriptionVariables = {
};

export type OnDeleteBrandEventSubscription = {
  onDeleteBrandEvent?:  {
    __typename: "BrandEvent",
    id: string,
    event_name?: EventName | null,
    event_date: string,
    brand_id?: string | null,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductEventSubscriptionVariables = {
};

export type OnCreateProductEventSubscription = {
  onCreateProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductEventSubscriptionVariables = {
};

export type OnUpdateProductEventSubscription = {
  onUpdateProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductEventSubscriptionVariables = {
};

export type OnDeleteProductEventSubscription = {
  onDeleteProductEvent?:  {
    __typename: "ProductEvent",
    id: string,
    product_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductTypeEventSubscriptionVariables = {
};

export type OnCreateProductTypeEventSubscription = {
  onCreateProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductTypeEventSubscriptionVariables = {
};

export type OnUpdateProductTypeEventSubscription = {
  onUpdateProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductTypeEventSubscriptionVariables = {
};

export type OnDeleteProductTypeEventSubscription = {
  onDeleteProductTypeEvent?:  {
    __typename: "ProductTypeEvent",
    id: string,
    product_id?: string | null,
    product_type_id?: string | null,
    event_name?: EventName | null,
    event_date: string,
    new_item?: string | null,
    old_item?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
